import React from "react";
import { Modal, Input, Select, Form, Button, InputNumber, Divider } from "antd";
import {
  SaveOutlined,
  ArrowLeftOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { FormRules } from "../../../dist/functions/formRules";

const { Option } = Select;
export const ModuleFormModalComponent = (props) => {
  const {
    form = null,
    isVisible,
    onClickSubmit,
    onClickCancel,
    platforms,
    isUpdate,
  } = props;

  /**
   * Form Rules
   *
   */

  // const {
  //   REQUIRED_ASSET_CUSTOM_FIELD_NAME,
  //   REQUIRED_ASSET_CUSTOM_FIELD_LABEL,
  //   REQUIRED_ASSET_CUSTOM_FIELD_TYPE,
  // } = FormRules;

  return (
    <Modal
      title={isUpdate ? "Update Module" : "Create Module"}
      visible={isVisible}
      onOk={onClickSubmit}
      onCancel={onClickCancel}
      footer={[
        <Button key="back" onClick={onClickCancel} icon={<ArrowLeftOutlined />}>
          Return
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onClickSubmit}
          icon={<SaveOutlined />}
        >
          Submit
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="platform_id" label="Platform">
          <Select placeholder="Select Platform">
            {platforms.data.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="name" label="Name">
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Description">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
