import React, { useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { Button, Result } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import { useFetch } from "../hooks/useFetch";
import { DashboardComponent } from "./modules/DashboardComponent";
import { ModulesComponent } from "./modules/ModulesComponent";
import { PlatformComponent } from "./modules/PlatformComponent";
import { RolesAccessComponent } from "./modules/RolesAccessComponent";
import { PermissionIndexComponent } from "./modules/PermissionIndexComponent";
import { UserManagementComponent } from "./modules/UserManagementComponent";
import { ActivityLogsManagementComponent } from "./modules/ActivityLogsManagementComponent";
import { ListingsManagementComponent } from "./modules/ListingsManagementComponent";
import { CityComponent } from "./modules/CityComponent";
import { LoginComponent } from "./LoginComponent";
import { MenuComponent } from "./MenuComponent";
import { CustomFieldManagementComponent } from "./modules/CustomFieldManagementComponent";
import { BulkUploaderManagementComponent } from "./modules/BulkUploaderManagementComponent";
import { APlusMemberManagementComponent } from "./modules/APlusMemberManagementComponent";
import { AssetsManagementComponent } from "./modules/AssetsManagementComponent";
import { PropertyCategoryManagementComponent } from "./modules/PropertyCategoryManagementComponent";
import { PartnerTypesComponent } from "./modules/PartnerTypesManagementComponent";
import { OptionsComponent } from "./modules/OptionsComponent";
import { PartnersComponent } from "./modules/PartnersComponent";
import { ForbiddenResult403Component } from "./ForbiddenResult403Component";
import {
  permissionsList,
  createDefaultPermissionSet,
} from "../dist/statics/permission_directory";
import { PropertyProjectsComponent } from "./modules/PropertyProjectsComponent";
import { PropertyDevelopersComponent } from "./modules/PropertyDevelopersComponent";
import { AssetInquiriesComponent } from "./modules/AssetInquiriesComponent";
import { ProvincesComponent } from "./modules/ProvincesComponent";
import { IslandGroupsComponent } from "./modules/IslandGroupComponent";
import { BarangayManagementComponent } from "./modules/BarangayManagementComponent";
import { RegionsComponent } from "./modules/RegionsComponent";
import useFetchPermissionSets from "../hooks/useFetchPermissions";
import useFetchIfhasMenuAccess from "../hooks/useFetchIfhasMenuAccess";

const initialRolesRestriction = createDefaultPermissionSet();

export const RoutesComponent = () => {
  const [permissions, setPermissions] = useState([]);

  const rolesRestriction = useFetchPermissionSets(
    permissionsList,
    initialRolesRestriction
  );

  const FetchUser = async (token) => {
    return await useFetch(
      `${process.env.REACT_APP_API_URL}/authentications/tokens/${token}`
    );
  };

  const ReturnAuthenticationStatus = async (token) => {
    const result = await FetchUser(token);

    if (result.data[0].user.user_type !== "admin") {
      return false;
    }

    if (result.data[0].is_revoked === 0) {
      localStorage.setItem("first_name", result.data[0].user.users_first_name);
      return true;
    }

    return false;
  };

  const {
    CREATE_ASSETS,
    UPDATE_ASSETS,
    VALIDATE_DATA,
    UPSERT_DATA,
    CREATE_CUSTOM_FIELD,
    UPDATE_CUSTOM_FIELD,
    CREATE_PROPERTY_CATEGORY,
    UPDATE_PROPERTY_CATEGORY,
    DELETE_PROPERTY_CATEGORY,
    CREATE_CITY,
    UPDATE_CITY,
    CREATE_LISTING,
    UPDATE_LISTING,
    CREATE_PARTNERS,
    UPDATE_PARTNERS,
    DELETE_PARTNERS,
    ARCHIVE_PARTNERS,
    CREATE_PARTNER_TYPES,
    UPDATE_PARTNER_TYPES,
    DELETE_PARTNER_TYPES,
    CREATE_PROPERTY_PROJECTS,
    UPDATE_PROPERTY_PROJECTS,
    DELETE_PROPERTY_PROJECTS,
    UPDATE_PROPERTY_PROJECTS_PROPERTY_DEVELOPER_PROJECTS_PIVOT,
    CREATE_PROPERTY_DEVELOPERS,
    UPDATE_PROPERTY_DEVELOPERS,
    DELETE_PROPERTY_DEVELOPERS,
    UPDATE_PROPERTY_DEVELOPER_PROPERTY_DEVELOPER_PROJECTS_PIVOT,
    CREATE_ASSET_INQUIRIES,
    UPDATE_ASSET_INQUIRIES,
    DELETE_ASSET_INQUIRIES,
    CREATE_PROVINCES,
    UPDATE_PROVINCES,
    DELETE_PROVINCES,
    CREATE_ISLAND_GROUPS,
    UPDATE_ISLAND_GROUPS,
    DELETE_ISLAND_GROUPS,
    CREATE_SETTINGS,
    UPDATE_SETTINGS,
    CREATE_BARANGAY,
    UPDATE_BARANGAY,
    DELETE_BARANGAY,
    CREATE_REGIONS,
    UPDATE_REGIONS,
    DELETE_REGIONS,
  } = rolesRestriction;

  const assetPermissionSet = [CREATE_ASSETS, UPDATE_ASSETS];
  const bulkUploaderPermissionSet = [VALIDATE_DATA, UPSERT_DATA];
  const customFieldPermissionSet = [CREATE_CUSTOM_FIELD, UPDATE_CUSTOM_FIELD];
  const cityPermissionSet = [CREATE_CITY, UPDATE_CITY];
  const listingPermissionSet = [CREATE_LISTING, UPDATE_LISTING];
  const propertyCategoryPermissionSet = [
    CREATE_PROPERTY_CATEGORY,
    UPDATE_PROPERTY_CATEGORY,
    DELETE_PROPERTY_CATEGORY,
  ];

  const partnersPermissionSet = [
    CREATE_PARTNERS,
    UPDATE_PARTNERS,
    DELETE_PARTNERS,
    ARCHIVE_PARTNERS,
  ];

  const partnerTypesPermissionSet = [
    CREATE_PARTNER_TYPES,
    UPDATE_PARTNER_TYPES,
    DELETE_PARTNER_TYPES,
  ];

  const propertyProjectPermissionSet = [
    CREATE_PROPERTY_PROJECTS,
    UPDATE_PROPERTY_PROJECTS,
    DELETE_PROPERTY_PROJECTS,
    UPDATE_PROPERTY_PROJECTS_PROPERTY_DEVELOPER_PROJECTS_PIVOT,
  ];

  const propertyDeveloperPermissionSet = [
    CREATE_PROPERTY_DEVELOPERS,
    UPDATE_PROPERTY_DEVELOPERS,
    DELETE_PROPERTY_DEVELOPERS,
    UPDATE_PROPERTY_DEVELOPER_PROPERTY_DEVELOPER_PROJECTS_PIVOT,
  ];

  const assetInquiriesPermissionSet = [
    CREATE_ASSET_INQUIRIES,
    UPDATE_ASSET_INQUIRIES,
    DELETE_ASSET_INQUIRIES,
  ];

  const provincesPermissionSet = [
    CREATE_PROVINCES,
    UPDATE_PROVINCES,
    DELETE_PROVINCES,
  ];

  const islandGroupsPermissionSet = [
    CREATE_ISLAND_GROUPS,
    UPDATE_ISLAND_GROUPS,
    DELETE_ISLAND_GROUPS,
  ];

  const optionGroupAccessSet = [CREATE_SETTINGS, UPDATE_SETTINGS];
  const barangayGroupAccessSet = [
    CREATE_BARANGAY,
    UPDATE_BARANGAY,
    DELETE_BARANGAY,
  ];
  const regionsPermissionSet = [CREATE_REGIONS, UPDATE_REGIONS, DELETE_REGIONS];

  const assetHasAccess = useFetchIfhasMenuAccess(assetPermissionSet);
  const cityHasAccess = useFetchIfhasMenuAccess(cityPermissionSet);
  const listingHasAccess = useFetchIfhasMenuAccess(listingPermissionSet);
  const propertyCategoryHasAccess = useFetchIfhasMenuAccess(
    propertyCategoryPermissionSet
  );
  const customFieldHasAccess = useFetchIfhasMenuAccess(
    customFieldPermissionSet
  );
  const bulkUploaderHasAccess = useFetchIfhasMenuAccess(
    bulkUploaderPermissionSet
  );
  const partnersHasAccess = useFetchIfhasMenuAccess(partnersPermissionSet);
  const partnerTypesHasAccess = useFetchIfhasMenuAccess(
    partnerTypesPermissionSet
  );

  const propertyProjectHasAccess = useFetchIfhasMenuAccess(
    propertyProjectPermissionSet
  );

  const propertyDeveloperHasAccess = useFetchIfhasMenuAccess(
    propertyDeveloperPermissionSet
  );

  const assetInquiriesHasAccess = useFetchIfhasMenuAccess(
    assetInquiriesPermissionSet
  );

  const provincesHasAccess = useFetchIfhasMenuAccess(provincesPermissionSet);
  const barangayHasAccess = useFetchIfhasMenuAccess(barangayGroupAccessSet);

  const islandGroupsHasAccess = useFetchIfhasMenuAccess(
    islandGroupsPermissionSet
  );

  const optionGroupHasAccess = useFetchIfhasMenuAccess(optionGroupAccessSet);
  const regionsHasAccess = useFetchIfhasMenuAccess(regionsPermissionSet);

  /**
   *
   * GLOBAL ROUTES - Routes that is not needed of authentication (eg. forbidden results page, login page)
   * PROTECTED ROUTES - Routes that is needed to undergo authentication, redirects if needed
   *
   */

  const routes = {
    globalRoutes: [
      {
        path: "/login",
        component: LoginComponent,
        subRoutes: [],
      },
      {
        path: "/forbidden",
        component: ForbiddenResult403Component,
        subRoutes: [],
      },
    ],
    protectedRoutes: [
      {
        path: "/",
        component: DashboardComponent,
        hideModule: false,
      },
      {
        path: "/dashboard",
        component: DashboardComponent,
        hideModule: false,
      },
      {
        path: "/administration/platform",
        component: PlatformComponent,
        hideModule: false,
      },
      {
        path: "/administration/modules",
        component: ModulesComponent,
        hideModule: false,
      },
      {
        path: "/administration/permission-index",
        component: PermissionIndexComponent,
        hideModule: false,
      },
      {
        path: "/administration/roles-access",
        component: RolesAccessComponent,
        hideModule: false,
      },
      {
        path: "/administration/user-management",
        component: UserManagementComponent,
        hideModule: false,
      },
      {
        path: "/listings",
        component: ListingsManagementComponent,
        hideModule: !listingHasAccess,
      },
      {
        path: "/assets",
        component: AssetsManagementComponent,
        hideModule: !assetHasAccess,
      },
      {
        path: "/bulk-uploader",
        component: BulkUploaderManagementComponent,
        hideModule: !bulkUploaderHasAccess,
      },
      {
        path: "/city",
        component: CityComponent,
        hideModule: !cityHasAccess,
      },
      {
        path: "/partner-types",
        component: PartnerTypesComponent,
        hideModule: !partnerTypesHasAccess,
      },
      {
        path: "/partners",
        component: PartnersComponent,
        hideModule: !partnersHasAccess,
      },
      {
        path: "/settings/options",
        component: OptionsComponent,
        hideModule: !optionGroupHasAccess,
      },
      {
        path: "/settings/custom-field",
        component: CustomFieldManagementComponent,
        hideModule: !customFieldHasAccess,
      },
      {
        path: "/settings/property-category",
        component: PropertyCategoryManagementComponent,
        hideModule: !propertyCategoryHasAccess,
      },
      {
        path: "/activity",
        component: ActivityLogsManagementComponent,
        hideModule: true,
      },
      {
        path: "/property-projects",
        component: PropertyProjectsComponent,
        hideModule: !propertyProjectHasAccess,
      },
      {
        path: "/property-developers",
        component: PropertyDevelopersComponent,
        hideModule: !propertyDeveloperHasAccess,
      },
      {
        path: "/asset-inquiries",
        component: AssetInquiriesComponent,
        hideModule: !assetInquiriesHasAccess,
      },
      {
        path: "/barangay",
        component: BarangayManagementComponent,
        hideModule: !barangayHasAccess,
      },
      {
        path: "/province",
        component: ProvincesComponent,
        hideModule: !provincesHasAccess,
      },
      {
        path: "/island-group",
        component: IslandGroupsComponent,
        hideModule: !islandGroupsHasAccess,
      },
      {
        path: "/region",
        component: RegionsComponent,
        hideModule: !regionsHasAccess,
      },
      {
        path: "/a-plus-network",
        component: APlusMemberManagementComponent,
        hideModule: false,
      },
    ],
  };

  return (
    <>
      <Router>
        {/* Render Menu Component Here, and pass the permission data from the state for better code readability and authentication */}
        <Switch>
          {routes.globalRoutes.map(({ path, component }) => {
            const CustomRouteComponent = component;
            return (
              <Route exact path={path}>
                <CustomRouteComponent
                  setPermissions={setPermissions}
                  ReturnAuthenticationStatus={ReturnAuthenticationStatus}
                />
              </Route>
            );
          })}
          {routes.protectedRoutes.map(({ path, hideModule, component }) => (
            <Route exact path={path}>
              <MenuComponent
                component={component}
                hideModule={hideModule}
                permissions={permissions}
              />
            </Route>
          ))}
          <Route path="*">
            <Result
              status="404"
              title="404"
              subTitle="Sorry, the page you visited does not exist."
              extra={
                <Link to="/dashboard">
                  <Button type="primary" icon={<LeftOutlined />}>
                    Back to Dashboard
                  </Button>
                </Link>
              }
            />
          </Route>
        </Switch>
      </Router>
    </>
  );
};
