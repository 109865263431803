// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.projectsUploadedImagesContainer {
  overflow-y: auto;
  height: 415px;
}

.projectsUploadedImagesContainer::-webkit-scrollbar {
  display: none;
}

.uploadedProjectsImageListItemContainer {
  display: flex !important;
  justify-content: center !important;
}

.projectsDraggerUploadContainer {
  height: 85% !important;
}

.projectsCustomFieldEmptyDiv {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.projectCustomFieldsContainer {
  overflow-y: auto;
  max-height: 750px;
}

.projectCustomFieldsContainer::-webkit-scrollbar {
  display: none;
}

.projectMainFieldsContainer {
  overflow-y: auto;
  max-height: 700px;
}

.projectMainFieldsContainer::-webkit-scrollbar {
  display: none;
}

.projectsMetaFieldsInformationContainer {
  overflow-y: auto;
  max-height: 300px;
}

.projectsMetaFieldsInformationContainer::-webkit-scrollbar {
  display: none;
}

.projectsImageContainer {
  overflow-y: auto;
  max-height: 400px;
}

.projectsImageContainer::-webkit-scrollbar {
  display: none;
}

.adviser-photo-avatar {
  width: 5rem !important;
  height: 5rem !important;
}
`, "",{"version":3,"sources":["webpack://./src/dist/style/projects.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,wBAAwB;EACxB,kCAAkC;AACpC;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,sBAAsB;EACtB,uBAAuB;AACzB","sourcesContent":[".projectsUploadedImagesContainer {\n  overflow-y: auto;\n  height: 415px;\n}\n\n.projectsUploadedImagesContainer::-webkit-scrollbar {\n  display: none;\n}\n\n.uploadedProjectsImageListItemContainer {\n  display: flex !important;\n  justify-content: center !important;\n}\n\n.projectsDraggerUploadContainer {\n  height: 85% !important;\n}\n\n.projectsCustomFieldEmptyDiv {\n  height: 100%;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.projectCustomFieldsContainer {\n  overflow-y: auto;\n  max-height: 750px;\n}\n\n.projectCustomFieldsContainer::-webkit-scrollbar {\n  display: none;\n}\n\n.projectMainFieldsContainer {\n  overflow-y: auto;\n  max-height: 700px;\n}\n\n.projectMainFieldsContainer::-webkit-scrollbar {\n  display: none;\n}\n\n.projectsMetaFieldsInformationContainer {\n  overflow-y: auto;\n  max-height: 300px;\n}\n\n.projectsMetaFieldsInformationContainer::-webkit-scrollbar {\n  display: none;\n}\n\n.projectsImageContainer {\n  overflow-y: auto;\n  max-height: 400px;\n}\n\n.projectsImageContainer::-webkit-scrollbar {\n  display: none;\n}\n\n.adviser-photo-avatar {\n  width: 5rem !important;\n  height: 5rem !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
