import { message } from "antd";
import { FetchAssetsColumns, FetchAssetsList } from "../../api/Assets";

export const useFetchAssetColumns = async () => {
  await message.loading("Fetching Asset Columns", 3);
  await message.success("Successfuly Fetched Asset Columns", 1);
  const data = await FetchAssetsColumns();
  return data.data;
};

export const useFetchAssets = async (
  isForTable = true,
  query = { page: 1, limit: 10, columnFilter: [] }
) => {
  if (isForTable) {
    const data = await FetchAssetsList(query);

    for (const item of data.data.data) {
      item["key"] = item.asset_id;
    }

    return data.data;
  }

  const data = await FetchAssetsList({
    page: 1,
    limit: null,
    columnFilter: [],
  });

  for (const item of data.data.data) {
    item["key"] = item.asset_id;
  }

  return data.data.data;
};
