import React from "react";
import { Modal, Input, Select, Form, Button, InputNumber, Divider } from "antd";
import {
  SaveOutlined,
  ArrowLeftOutlined,
  PlusOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { FormRules } from "../../../dist/functions/formRules";

const { Option } = Select;
export const ModuleBulkCreationFormModalComponent = (props) => {
  const {
    form = null,
    isVisible,
    onClickSubmit,
    onClickCancel,
    platforms,
  } = props;

  /**
   * Form Rules
   *
   */

  // const {
  //   REQUIRED_ASSET_CUSTOM_FIELD_NAME,
  //   REQUIRED_ASSET_CUSTOM_FIELD_LABEL,
  //   REQUIRED_ASSET_CUSTOM_FIELD_TYPE,
  // } = FormRules;

  return (
    <Modal
      title="Create Module"
      visible={isVisible}
      onOk={onClickSubmit}
      onCancel={onClickCancel}
      footer={[
        <Button key="back" onClick={onClickCancel} icon={<ArrowLeftOutlined />}>
          Return
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onClickSubmit}
          icon={<SaveOutlined />}
        >
          Submit
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="platform_id" label="Platform">
          <Select placeholder="Select Platform">
            {platforms.data.map((item, index) => (
              <Option key={index} value={item.id}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Divider />
        <div
          style={{
            overflowX: "hidden",
            overflowY: "auto",
            height: "300px",
          }}
        >
          <Form.List name="modules">
            {(fields, { add, remove }) => (
              <>
                {fields.map((field, index) => (
                  <>
                    <div style={{ marginTop: "2%" }}>
                      <Form.Item
                        {...field}
                        name={[field.name, "name"]}
                        key={[field.fieldKey, "name"]}
                        fieldKey={[field.fieldKey, "name"]}
                        rules={[
                          { required: true, message: "Missing module name" },
                        ]}
                      >
                        <Input placeholder="module Name" />
                      </Form.Item>
                      <Form.Item
                        {...field}
                        name={[field.name, "description"]}
                        fieldKey={[field.fieldKey, "description"]}
                        key={[field.fieldKey, "description"]}
                      >
                        <Input.TextArea placeholder="Module Description" />
                      </Form.Item>
                      <Button
                        key={`delete-${index}`}
                        type="default"
                        size="small"
                        icon={<DeleteOutlined />}
                        onClick={() => remove(field.name)}
                        danger
                        block
                      >
                        Remove Entry
                      </Button>
                    </div>
                    <Divider />
                  </>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    Add Modules Entry
                  </Button>
                </Form.Item>
              </>
            )}
          </Form.List>
        </div>
      </Form>
    </Modal>
  );
};
