import React from "react";
import { Modal, Form, Input, Button, Select, Radio } from "antd";
import { FormRules } from "../../../dist/functions/formRules";
import { ArrowLeftOutlined, SaveOutlined } from "@ant-design/icons";

const { Option } = Select;

export const PropertyCreationFormComponent = (props) => {
  const {
    visible,
    form,
    onSubmit,
    onCancel,
    propertyCategoryClassifications = [],
  } = props;
  const {
    REQUIRED_PROPERTY_CATEGORY_NAME,
    REQUIRED_PROPERTY_CATEGORY_TYPE,
    REQUIRED_PROPERTY_CATEGORY_LABEL,
    REQUIRED_PROPERTY_CATEGORY_STATUS,
    REQUIRED_PROPERTY_CLASSIFICATION,
  } = FormRules;

  const footerButtons = [
    <Button key="back" onClick={onCancel} icon={<ArrowLeftOutlined />}>
      Return
    </Button>,
    <Button
      key="submit"
      type="primary"
      onClick={onSubmit}
      icon={<SaveOutlined />}
    >
      Create Property Category
    </Button>,
  ];

  return (
    <>
      <Modal
        title="Create Property Category"
        visible={visible}
        onOk={onSubmit}
        onCancel={onCancel}
        footer={[footerButtons]}
        forceRender={true}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Property Category Name"
            name="property_category_name"
            rules={[REQUIRED_PROPERTY_CATEGORY_NAME]}
          >
            <Input placeholder="Input Property Category Name" />
          </Form.Item>
          <Form.Item
            label="Property Category Label"
            name="property_category_label"
            rules={[REQUIRED_PROPERTY_CATEGORY_LABEL]}
          >
            <Input placeholder="Input Property Category Label" />
          </Form.Item>
          <Form.Item
            label="Property Category Type"
            name="property_category_type"
            rules={[REQUIRED_PROPERTY_CATEGORY_TYPE]}
          >
            <Select placeholder="Select Property Category Type">
              <Option key="main-1" value={"main"}>
                Main Category
              </Option>
              <Option key="sub-type-1" value={"sub_type"}>
                Sub Category
              </Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Property Classification"
            name="property_category_classification_id"
            rules={[REQUIRED_PROPERTY_CLASSIFICATION]}
          >
            <Select placeholder="Select Property Category Classification">
              {propertyCategoryClassifications.map((item, index) => (
                <Option
                  key={index}
                  value={item.property_category_classification_id}
                >
                  {item.property_category_classification_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Property Category Status"
            name="is_active"
            rules={[REQUIRED_PROPERTY_CATEGORY_STATUS]}
          >
            <Radio.Group>
              <Radio value={true}>Enabled</Radio>
              <Radio value={false}>Disabled</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
