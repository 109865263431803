import React from "react";
import { Table } from "antd";
import { ActivityLogListMetaComponent } from "./ActivityLogListMetaComponent";
import useColumnFilter from "../../../hooks/useColumnFilter";

const { Column } = Table;
export const ActivityLogsListComponent = (props) => {
  const {
    data = { data: [], total: 0 },
    onPaginationChange,
    currentPagination,
    onSortChange,
    columnFilter,
    setColumnFilter,
  } = props;

  const { getColumnFilterProps } = useColumnFilter(
    columnFilter,
    setColumnFilter
  );

  return (
    <Table
      dataSource={data.data}
      pagination={{
        current: currentPagination,
        onChange: onPaginationChange,
        total: data.total,
      }}
      onChange={(pagination, filters, sorter, action) => {
        if (action.action === "sort") {
          onSortChange(sorter.field, sorter.order);
        }
      }}
      size="small"
      bordered={true}
      expandable={{
        expandedRowRender: (record) => (
          <ActivityLogListMetaComponent
            meta_information={record.meta_information}
          />
        ),
      }}
    >
      <Column
        name="id"
        dataIndex="id"
        title="Id"
        sorter={true}
        {...getColumnFilterProps("id")}
      />
      <Column
        name={["user", "users_email"]}
        dataIndex={["user", "users_email"]}
        title="User Email"
        sorter={true}
        {...getColumnFilterProps(["user", "users_email"])}
      />
      <Column
        name="activity_name"
        dataIndex="activity_name"
        title="Activity Name"
        sorter={true}
        {...getColumnFilterProps("activity_name")}
      />
      <Column
        name="created_at"
        dataIndex="created_at"
        title="Date Created"
        sorter={true}
        {...getColumnFilterProps("created_at")}
      />
    </Table>
  );
};
