import React from "react";
import { Modal, Input, Select, Form, Button, DatePicker } from "antd";
import { SaveOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { FormRules } from "../../../dist/functions/formRules";
import { SelectOptionFooterExtraButtonComponent } from "../../SelectOptionFooterExtraButtonComponent";

const { Option } = Select;
export const IslandGroupsFormModalComponent = (props) => {
  const {
    form = null,
    isVisible,
    onClickSubmit,
    onClickCancel,
    isUpdate,
  } = props;

  /**
   * Form Rules
   *
   */

  return (
    <Modal
      title={isUpdate ? "Update Island Groups" : "Create Island Groups"}
      visible={isVisible}
      onOk={onClickSubmit}
      onCancel={onClickCancel}
      footer={[
        <Button key="back" onClick={onClickCancel} icon={<ArrowLeftOutlined />}>
          Return
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onClickSubmit}
          icon={<SaveOutlined />}
        >
          Submit
        </Button>,
      ]}
    >
      <Form form={form} name="island_groups" layout="vertical">
        <Form.Item name="island_group_name" label="Island Group Name">
          <Input />
        </Form.Item>

        <Form.Item name="alternative_name" label="Alternative Name">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
