import React, { useEffect, useState } from "react";
import { Form, Input, Upload, Select, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";

const { Dragger } = Upload;

const { Option } = Select;
export const AgentsCustomFieldInformation = React.memo(
  ({ fields, isHidden, currentAgentPhoto }) => {
    const [currentThumbnail, setCurrentThumbnail] = useState(currentAgentPhoto);

    return (
      <>
        <div style={{ display: isHidden ? "none" : null }}>
          {fields.map((item, index) =>
            item.agent_custom_field_name === "agent_photo" ? (
              <Form.Item
                key={index}
                name={item.agent_custom_field_name}
                fieldKey={item.agent_custom_field_name}
                label={item.agent_custom_field_label}
                shouldUpdate={false}
              >
                {currentThumbnail &&
                currentThumbnail[0] &&
                currentThumbnail[0].url ? (
                  <Dragger
                    defaultFileList={currentThumbnail}
                    listType="picture"
                    name="user_photo"
                    multiple={false}
                    headers={{ session_id: localStorage.getItem("session_id") }}
                    action={`${process.env.REACT_APP_API_URL}/users/profile`}
                    onChange={(info) => {
                      let currentFile = info.file;

                      if (currentFile) {
                        const { status, response } = currentFile;

                        if (status !== "uploading") {
                        }

                        if (status === "done") {
                          message.success(
                            `${currentFile.name} file uploaded successfully.`
                          );
                        }

                        if (status === "error") {
                          message.error(
                            `${currentFile.name} file upload failed.`
                          );
                          message.error(`${response.status}`);
                        }
                      }
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                  </Dragger>
                ) : (
                  <Dragger
                    listType="picture"
                    name="user_photo"
                    multiple={false}
                    headers={{ session_id: localStorage.getItem("session_id") }}
                    action={`${process.env.REACT_APP_API_URL}/users/profile`}
                    onChange={(info) => {
                      setCurrentThumbnail([]);
                      let currentFile = info.file;

                      if (currentFile) {
                        const { status, response } = currentFile;

                        if (status !== "uploading") {
                        }

                        if (status === "done") {
                          message.success(
                            `${currentFile.name} file uploaded successfully.`
                          );
                        }

                        if (status === "error") {
                          message.error(
                            `${currentFile.name} file upload failed.`
                          );
                          message.error(`${response.status}`);
                        }
                      }
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag file to this area to upload
                    </p>
                  </Dragger>
                )}
              </Form.Item>
            ) : (
              <Form.Item
                key={index}
                name={item.agent_custom_field_name}
                fieldKey={item.agent_custom_field_name}
                label={item.agent_custom_field_label}
                shouldUpdate={false}
              >
                <Input />
              </Form.Item>
            )
          )}
        </div>
      </>
    );
  }
);
