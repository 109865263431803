import { message } from "antd";

export const useAPIResponse = async (response) => {
  if (response.status === 200 || response.status === 201) {
    await message.success(response.data.message, 2);
    return true;
  }

  const parsedResponse = JSON.parse(response.response);

  if (!parsedResponse.message) {
    for (const errorObj in parsedResponse) {
      if (errorObj === "status") {
        continue;
      }

      const errors = parsedResponse[errorObj];

      if (Array.isArray(errors)) {
        for (const error of errors) {
          await message.error(error, 5);
        }
      } else {
        await message.error(errors, 5);
      }
    }

    return false;
  }

  await message.error(parsedResponse.message, 2);

  return false;
};

export const useAPIResponseID = async (response) => {
  if (response.status === 200 || response.status === 201) {
    await message.success(response.data.message, 2);

    return response.data.data.response.id;
  }

  const parsedResponse = JSON.parse(response.response);
  await message.error(parsedResponse.message, 2);

  return false;
};
