import { useFetch } from "../hooks/useFetch";
import { useCreate } from "../hooks/useCreate";
import { useUpdate } from "../hooks/useUpdate";
import {
  FETCH_PARTNER_TYPE_URL,
  CREATE_PARTNER_TYPE_URL,
  UPDATE_PARTNER_TYPE_URL,
  DELETE_PARTNER_TYPE_URL,
} from "../api/routes/partner_types";
import { useDelete } from "../hooks/useDelete";

export const FetchPartnerTypes = async (query) => {
  try {
    return await useFetch(FETCH_PARTNER_TYPE_URL, query);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const CreatePartnerType = async (payload) => {
  try {
    return await useCreate(CREATE_PARTNER_TYPE_URL, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const UpdatePartnerType = async (partner_type_id, payload) => {
  try {
    return await useUpdate(
      `${UPDATE_PARTNER_TYPE_URL}/${partner_type_id}`,
      payload
    );
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const DeletePartnerType = async (partner_type_id, payload) => {
  try {
    return await useDelete(`${DELETE_PARTNER_TYPE_URL}/${partner_type_id}`);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
