import React from "react";
import { Button, Divider, Space } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import extractPermission from "../../../dist/functions/extractPermission";

export const BulkUploaderFunctionalityComponent = ({
  onClickUploadData,
  uploadDataButtonStatus,
  permissions = [],
}) => {
  const isHideUploading = extractPermission(permissions, "UPSERT_DATA");

  return (
    <div style={{ textAlign: "right" }}>
      <Space size={"small"}>
        <Button
          type="primary"
          icon={<CloudUploadOutlined />}
          onClick={onClickUploadData}
          disabled={uploadDataButtonStatus}
          hidden={isHideUploading}
        >
          Upload Data
        </Button>
      </Space>
      <Divider />
    </div>
  );
};
