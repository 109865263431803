import React from "react";
import { Tag, Button, Typography, Divider } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import useIsMobile from "../hooks/useIsMobile";

const { Title } = Typography;

export const ModulesSelectAndColumnTagsComponent = (props) => {
  const {
    onClearColumnFilters,
    onClearSearcheAndSelection = () => {},
    isShowColumnFilterTags,
    columnFilterTags = [],
    tags = [],
    isShowSelection,
  } = props;
  const isMobile = useIsMobile();

  const clearAll = () => {
    onClearColumnFilters();
    onClearSearcheAndSelection();
  };

  return (
    <>
      {isShowColumnFilterTags || isShowSelection ? (
        <div
          style={{ marginTop: "2%", textAlign: isMobile ? "center" : "left" }}
        >
          <Title level={5}>Filters & Selections:</Title>
          {columnFilterTags.map((item, index) => (
            <Tag
              key={index}
              color={item.color}
              visible={!item.value ? false : true}
              closable={item.closable ? item.closable : false}
              onClose={item.closable ? () => item.onClose(item.id) : null}
            >
              {`${item.selection_label}: ${!item.value ? "none" : item.value}`}
            </Tag>
          ))}

          {tags.map((item, index) => (
            <Tag
              key={index}
              color={item.color}
              visible={!item.value ? false : true}
              closable={item.closable ? item.closable : false}
              onClose={item.closable ? item.onClose : null}
            >
              {`${item.selection_label}: ${!item.value ? "none" : item.value}`}
            </Tag>
          ))}

          <Button
            type="link"
            icon={<CloseOutlined />}
            size="small"
            onClick={clearAll}
          >
            Clear Column Filters
          </Button>
          <Divider />
        </div>
      ) : null}
    </>
  );
};
