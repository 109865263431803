import React, { useState, useEffect } from "react";
import { message } from "antd";
import {
  FetchCustomFields,
  CreateCustomField,
  UpdateCustomField,
  FetchCustomFieldByQuery,
} from "../../api/CustomField";
import { useAPIResponse } from "../useAPIResponse";

export const useFetchCustomFields = async (
  isForTable = true,
  query = { page: 1, limit: 10, filter: null }
) => {
  if (isForTable) {
    const data = await FetchCustomFields(query);

    for (const item of data.data.data) {
      item["key"] = item.asset_custom_field_id;
    }

    return data.data;
  }

  const data = await FetchCustomFields({
    page: 1,
    limit: null,
    filter: "",
  });

  for (const item of data.data.data) {
    item["key"] = item.asset_custom_field_id;
  }

  return data.data.data;
};

export const useCreateCustomField = async (data) => {
  await message.loading("Creating Custom Field..", 2);
  const response = await CreateCustomField(data);

  return useAPIResponse(response);
};

export const useUpdateCustomField = async (custom_field_id, payload) => {
  await message.loading("Updating Custom Field..", 2);
  const response = await UpdateCustomField(custom_field_id, payload);

  return useAPIResponse(response);
};

export const useQueryCustomField = async (query) => {
  const data = await FetchCustomFieldByQuery(query);

  for (const item of data.data) {
    item["key"] = item.asset_custom_field_id;
  }

  return data.data;
};
