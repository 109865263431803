import { Input } from "antd";

export const APlusMembersFunctionalityComponent = ({ onChangeSearchInput }) => {
  return (
    <div style={{ marginBottom: "2%", textAlign: "right" }}>
      <Input
        placeholder="Search A+ member"
        allowClear
        onChange={onChangeSearchInput}
        style={{ width: "25%" }}
      />
    </div>
  );
};
