import React from "react";

import { AssetInquiriesFormModalComponent } from "./AssetInquiriesFormModalComponent";

export const AssetInquiriesFormsModalFunctionalityComponent = (props) => {
  const {
    onSubmitAssetInquiriesCreation,
    onCancelAssetInquiriesCreation,
    assetInquiriesModalVisibility,
    assetInquiriesCreationForm,
    onSubmitAssetInquiriesUpdate,
    onCancelAssetInquiriesUpdate,
    assetInquiriesUpdatedModalVisibility,
    assetInquiriesUpdateForm,
    assets = { data: [], total: 0 },
    onClickShowMoreAsset,
    users = { data: [], total: 0 },
    onClickShowMoreUser,
  } = props;

  return (
    <>
      <AssetInquiriesFormModalComponent
        form={assetInquiriesCreationForm}
        isVisible={assetInquiriesModalVisibility}
        onClickSubmit={onSubmitAssetInquiriesCreation}
        onClickCancel={onCancelAssetInquiriesCreation}
        assets={assets}
        onClickShowMoreAsset={onClickShowMoreAsset}
        users={users}
        onClickShowMoreUser={onClickShowMoreUser}
      />
      <AssetInquiriesFormModalComponent
        form={assetInquiriesUpdateForm}
        isVisible={assetInquiriesUpdatedModalVisibility}
        onClickSubmit={onSubmitAssetInquiriesUpdate}
        onClickCancel={onCancelAssetInquiriesUpdate}
        assets={assets}
        onClickShowMoreAsset={onClickShowMoreAsset}
        users={users}
        onClickShowMoreUser={onClickShowMoreUser}
        isUpdate={true}
      />
    </>
  );
};
