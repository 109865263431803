import React from "react";
import { Form, Input, Modal, Select, Divider } from "antd";

const { Option } = Select;

export const BulkCityCreationModalComponent = (props) => {
  const {
    visible,
    provinces = [],
    form,
    onSubmitCityCreation,
    onCancel,
  } = props;

  return (
    <Modal
      visible={visible}
      title="Create City"
      onOk={onSubmitCityCreation}
      onCancel={onCancel}
    >
      <Form form={form} name="bulk_city_creation" layout="vertical">
        <Form.List name={"cities"}>
          {(fields) => (
            <>
              {fields.map((field, index) => (
                <>
                  <Form.Item
                    key={[field.key, "city_name"]}
                    name={[field.key, "city_name"]}
                    label="City Name"
                  >
                    <Input
                      key={[field.key, "city_name_input"]}
                      disabled={true}
                    />
                  </Form.Item>
                  <Form.Item
                    key={[field.key, "province_id"]}
                    name={[field.key, "province_id"]}
                    label="Province"
                  >
                    <Select
                      placeholder="Select Province"
                      key={[field.key, "select_province"]}
                    >
                      {provinces.map((province, index) => (
                        <Option
                          key={[field.key, index, province.province_name]}
                          value={province.province_id}
                        >
                          {province.province_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {index === fields.length ? null : <Divider />}
                </>
              ))}
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};
