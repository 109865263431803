import React from "react";
import { Form, Input, Modal, Select, Divider } from "antd";
import { SaveOutlined, ArrowLeftOutlined } from "@ant-design/icons";

const { Option } = Select;

export const BulkBarangayCreationModalComponent = (props) => {
  const { visible, onSubmit, onCancel, form, cities = [] } = props;

  return (
    <Modal
      visible={visible}
      title="Create Barangay"
      onOk={onSubmit}
      onCancel={onCancel}
      okText={"Create Barangay(s)"}
      okButtonProps={{ icon: <SaveOutlined /> }}
      cancelButtonProps={{ icon: <ArrowLeftOutlined /> }}
    >
      <div
        style={{
          overflowY: "auto",
          overflowX: "hidden",
          height: "530px",
          paddingRight: "2%",
        }}
      >
        <Form form={form} name="bulk_barangay_creation" layout="vertical">
          <Form.List name={"barangays"}>
            {(fields) => (
              <>
                {fields.map((field, index) => (
                  <>
                    <Form.Item
                      key={[field.key, "barangay_name"]}
                      name={[field.key, "barangay_name"]}
                      label="Barangay Name"
                    >
                      <Input
                        key={[field.key, "barangay_input"]}
                        disabled={true}
                      />
                    </Form.Item>
                    <Form.Item
                      key={[field.key, "city_id"]}
                      name={[field.key, "city_id"]}
                      label="City"
                    >
                      <Select
                        placeholder="Select City"
                        key={[field.key, "select_city"]}
                      >
                        {cities.map((city, index) => (
                          <Option
                            key={[field.key, index, city.city_name]}
                            value={city.id}
                          >
                            {city.city_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    {index === fields.length ? null : <Divider />}
                  </>
                ))}
              </>
            )}
          </Form.List>
        </Form>
      </div>
    </Modal>
  );
};
