import React, { Fragment, useState, useEffect } from "react";
import { Modal, Form, Divider, message } from "antd";
import { StepsComponent } from "../../StepsComponent";
import { GeneralInformationStepFormComponent } from "./GeneralInformationStepFormComponent";
import { AddressAndContactStepFormComponent } from "./AddressAndContactStepFormComponent";
import { UserAccessRolesStepFormComponent } from "./UserAccessRolesStepFormComponent";
import { UserPermissionsFormComponent } from "./UserPermissionsFormComponent";
import { UserCreationModalFooterComponent } from "./UserCreationModalFooterComponent";
import { CreateUser } from "../../../api/admin/CreateUser";
import useGetRolesWithPermissions from "../../../hooks/roles/useGetRoleswithPermissions";
import { createUserDAO } from "../../../dist/dao/user";
import useCreateUser from "../../../hooks/users/useCreateUser";
import { AgentsCustomFieldInformation } from "./AgentsCustomFieldInformation";
import {
  useCreateAgentMetaField,
  useUpdateAgentMetaField,
} from "../../../hooks/agent_meta_field/useAgentMetaFieldAPI";
import { useFetchAgentCustomFields } from "../../../hooks/agent_custom_field/useAgentCustomFieldAPI";
import { useComponentDidUpdate } from "../../../hooks/useComponentDidUpdate";

const steps = [
  {
    title: "General Information",
    description: "personal information",
    icon: "",
  },
  {
    title: "Address & Contact Information",
    description: "user address/contact",
    icon: "",
  },
  {
    title: "User Information",
    description: "user roles, and access",
    icon: "",
  },
  {
    title: "Permissions",
    description: "permission options",
  },
];

const stepsAgents = [
  {
    title: "General Information",
    description: "personal information",
    icon: "",
  },
  {
    title: "Address & Contact Information",
    description: "user address/contact",
    icon: "",
  },
  {
    title: "User Information",
    description: "user roles, and access",
    icon: "",
  },
  {
    title: "Permissions",
    description: "permission options",
  },
  {
    title: "Agent Custom Field",
    description: "agent custom and meta fields",
  },
];

export const MultiStepFormComponent = (props) => {
  const {
    isModalVisible,
    handleModalVisibleOnChange,
    GetUserList,
    resetSelections,
    currentAgentPhoto,
  } = props;
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedRole, setSelectedRole] = useState([]);
  const [selectedRolePermissions, setSelectedRolePermissions] = useState(null);
  const [isDefaultChecked, setIsDefaultChecked] = useState(true);

  const [isAgent, setIsAgent] = useState(false);

  const [fields, setFields] = useState([]);

  const OnFinish = async (values) => {
    const {
      address_line_1,
      address_line_2,
      city,
      state_province_region,
      user_attached_roles,
    } = values;

    const uar = [];
    //uar = abbreviation of user attached roles
    if (typeof user_attached_roles !== "undefined") {
      for (const roles of user_attached_roles) {
        const obj = {
          roles_id: roles,
        };
        uar.push(obj);
      }
    }

    values.user_attached_roles = uar;
    const payload = {
      ...values,
      address: `${address_line_1} ${address_line_2} ${city} ${state_province_region}`,
    };

    const resp = await CreateUser(payload);
    if (resp.status === 201) {
      message.success(`Successfuly Created User id: ${resp.data.data}`);
      return true;
    } else if (resp.status === 400) {
      message.error("Email Already Exists, please choose different email", 5);
      setCurrentStep(1);
      return false;
    }
  };

  const OnFinishFailed = (error) => {
    const firstStepFields = [
      "users_first_name",
      "users_middle_name",
      "users_last_name",
      "users_gender",
    ];

    const secondStepFields = [
      "address_line_1",
      "address_line_2",
      "city",
      "state_province_region",
      "users_email",
    ];

    const thirdStepFields = [
      "users_username",
      "password",
      "user_attached_roles",
    ];

    for (const errFields of error.errorFields) {
      for (const errItem of errFields.errors) {
        message.error(errItem, 3);

        if (firstStepFields.includes(errFields.name[0])) {
          setCurrentStep(0);
          return;
        }

        if (secondStepFields.includes(errFields.name[0])) {
          setCurrentStep(1);
          return;
        }

        if (thirdStepFields.includes(errFields.name[0])) {
          setCurrentStep(2);
          return;
        }
      }
    }
  };

  useEffect(() => {
    const executeFetch = async () => {
      if (selectedRole.length > 0) {
        const query = { role_id: selectedRole[0] };
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const permissions = await useGetRolesWithPermissions(
          "for_creation",
          query
        );

        if (permissions) {
          setSelectedRolePermissions(permissions.data);
          return;
        }
      }

      setSelectedRolePermissions(null);
    };

    executeFetch();
  }, [selectedRole]);

  useComponentDidUpdate(() => {
    const selectedRoleObj = props.roles.find((e) => e.key == selectedRole[0]);

    if (
      selectedRoleObj &&
      (selectedRoleObj.title == "Agent" || selectedRoleObj.title == "agent")
    ) {
      setIsAgent(true);
    } else {
      setIsAgent(false);
    }
  }, [selectedRole]);

  useEffect(() => {
    FetchAgentCustomFields();
  }, []);

  const UpdateAgentMetaField = async (agent_meta_field_id, payload) => {
    useUpdateAgentMetaField(agent_meta_field_id, payload);
  };

  const CreateAgentMetaField = async (payload) => {
    useCreateAgentMetaField(payload);
  };

  const FetchAgentCustomFields = async () => {
    const query = { page: 1, limit: "ALL", filter: null };
    const data = await useFetchAgentCustomFields(false, query);
    setFields(data);
  };

  const HandleModaOkClicked = async () => {
    try {
      const values = await form.validateFields();

      values.is_default = isDefaultChecked;
      const payload = createUserDAO(values);

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const response = await useCreateUser(payload);

      if (response.success) {
        if (isAgent) {
          for (const custom_field of fields) {
            // const { agent_meta_field } = custom_field;

            // if (agent_meta_field) {
            //   const { agent_meta_field_id, agent_meta_field_name } =
            //     agent_meta_field;
            //   const AgentMetaFieldPayload = {};

            //   if (isNaN(values[agent_meta_field_name])) {
            //     AgentMetaFieldPayload["agent_meta_field_text_value"] =
            //       values[agent_meta_field_name];
            //   } else {
            //     AgentMetaFieldPayload["agent_meta_field_num_value"] =
            //       values[agent_meta_field_name];
            //   }
            //   UpdateAgentMetaField(agent_meta_field_id, AgentMetaFieldPayload);
            // }

            let metaFieldVal = values[custom_field["agent_custom_field_name"]];

            if (custom_field["agent_custom_field_name"] === "agent_photo") {
              let currentFile =
                metaFieldVal.fileList[metaFieldVal.fileList.length - 1];
              if (currentFile !== undefined) {
                if (currentFile.status === "removed") {
                  metaFieldVal = null;
                } else if (currentFile.response !== undefined) {
                  metaFieldVal =
                    currentFile.response === "undefined"
                      ? null
                      : currentFile.response.data.path;
                } else {
                  metaFieldVal = currentFile.url;
                }
              } else {
                if (
                  !metaFieldVal ||
                  (metaFieldVal.fileList && !metaFieldVal.fileList.length)
                ) {
                  metaFieldVal = null;
                }
              }
            }

            if (metaFieldVal !== undefined) {
              const AgentMetaFieldPayload = {
                agent_meta_field_name: custom_field["agent_custom_field_name"],
                agent_custom_field_id: custom_field["agent_custom_field_id"],
                agent_meta_field_type: custom_field["agent_custom_field_type"],
                agent_id: response.data.data.general_information.id,
              };

              if (isNaN(metaFieldVal)) {
                AgentMetaFieldPayload["agent_meta_field_text_value"] =
                  metaFieldVal;
              } else {
                AgentMetaFieldPayload["agent_meta_field_text_value"] =
                  metaFieldVal;

                AgentMetaFieldPayload["agent_meta_field_num_value"] =
                  metaFieldVal;
              }

              await CreateAgentMetaField(AgentMetaFieldPayload);
            }
          }
        }

        form.resetFields();
        handleModalVisibleOnChange();
        GetUserList();
        resetSelections();
        setCurrentStep(0);
        return;
      }

      if (!response.success) {
        setCurrentStep(response.step);
      }
    } catch (err) {
      OnFinishFailed(err);
    }
  };

  const onChangeDefaultPermission = (e) => {
    setIsDefaultChecked(e.target.checked);
  };

  return (
    <Fragment>
      {/* Modal For User Creation */}
      <Modal
        visible={isModalVisible}
        title="User Creation"
        onOk={HandleModaOkClicked}
        onCancel={handleModalVisibleOnChange}
        centered={true}
        footer={
          <UserCreationModalFooterComponent
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            handleModaOkClicked={HandleModaOkClicked}
            selectedRole={selectedRole}
            isAgent={isAgent}
          />
        }
        destroyOnClose={true}
        confirmLoading={true}
        maskClosable={false}
        keyboard={true}
        width={700}
      >
        <div className="user-creation-parent-div">
          <div className="user-creation-child-div">
            <StepsComponent
              steps={isAgent ? stepsAgents : steps}
              size={"small"}
              currentStep={currentStep}
            />
            <Divider type="horizontal" />
          </div>
          <Form
            form={form}
            layout={"vertical"}
            name="user_creation"
            onFinish={OnFinish}
          >
            {currentStep === 0 ? (
              <ConsolidateStepsForm hideGeneral={false} _props={props} />
            ) : currentStep === 1 ? (
              <ConsolidateStepsForm hideAddressContact={false} _props={props} />
            ) : currentStep === 2 ? (
              <ConsolidateStepsForm
                hideUserAccess={false}
                _props={props}
                setSelectedRole={setSelectedRole}
              />
            ) : currentStep === 3 ? (
              <ConsolidateStepsForm
                hidePermissions={false}
                _props={props}
                roles={props.permissionRoles}
                selectedRole={selectedRole}
                selectedRolePermissions={selectedRolePermissions}
                isDefaultChecked={isDefaultChecked}
                onChangeDefaultPermission={onChangeDefaultPermission}
              />
            ) : (
              <ConsolidateStepsForm
                hideCustomField={false}
                fields={fields}
                _props={props}
                currentAgentPhoto={currentAgentPhoto}
              />
            )}
          </Form>
        </div>
      </Modal>
    </Fragment>
  );
};

const ConsolidateStepsForm = (props) => {
  const {
    hideGeneral = true,
    hideAddressContact = true,
    hideUserAccess = true,
    hidePermissions = true,
    hideCustomField = true,
    fields = [],
    _props = null,
    selectedRolePermissions = [],
    isDefaultChecked = true,
    setSelectedRole,
    selectedRole,
    onChangeDefaultPermission,
    currentAgentPhoto,
  } = props;

  return (
    <div>
      <GeneralInformationStepFormComponent isHidden={hideGeneral} />
      <AddressAndContactStepFormComponent isHidden={hideAddressContact} />
      <UserAccessRolesStepFormComponent
        isHidden={hideUserAccess}
        setSelectedRole={setSelectedRole}
        childProps={_props}
      />
      <UserPermissionsFormComponent
        isHidden={hidePermissions}
        roles={selectedRolePermissions}
        selectedRole={selectedRole}
        isDefaultChecked={isDefaultChecked}
        onChangeDefaultPermission={onChangeDefaultPermission}
      />
      <AgentsCustomFieldInformation
        isHidden={hideCustomField}
        fields={fields}
        currentAgentPhoto={currentAgentPhoto}
      />
    </div>
  );
};
