import { useFetch } from "../hooks/useFetch";
import { useCreate } from "../hooks/useCreate";
import { useUpdate } from "../hooks/useUpdate";
import { useDelete } from "../hooks/useDelete";
import {
  fetch_property_categories_url,
  create_property_categories_url,
  update_property_categories_url,
  query_property_categories_url,
  delete_property_categories_url,
} from "./routes/property_sub_category";

export const FetchPropertySubCategory = async (query) => {
  try {
    return await useFetch(fetch_property_categories_url, query);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const CreatePropertySubCategory = async (payload) => {
  try {
    return await useCreate(create_property_categories_url, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const UpdatePropertySubCategory = async (
  property_sub_category_id,
  payload
) => {
  try {
    return await useUpdate(
      `${update_property_categories_url}/${property_sub_category_id}`,
      payload
    );
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const QueryPropertySubCategory = async (query) => {
  try {
    return await useUpdate(`${query_property_categories_url}`, query);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const DeletePropertySubCategory = async (propert_sub_category_id) => {
  try {
    return await useDelete(
      `${delete_property_categories_url}/${propert_sub_category_id}`
    );
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
