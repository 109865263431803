import React, { useState, useEffect } from "react";
import { Drawer, Col, Row, Divider, Empty } from "antd";
import { useFetch } from "../hooks/useFetch";

const DescriptionItem = ({ title, content }) => (
  <div className="site-description-item-profile-wrapper">
    <p className="site-description-item-profile-p-label">{title}:</p>
    {content}
  </div>
);

const initialUserInfo = {
  id: null,
  first_name: null,
  middle_name: null,
  last_name: null,
  email: null,
  mobile: null,
  user_type: null,
  created_at: null,
};

export const ProfileDrawerComponent = (props) => {
  const { onClose, visible } = props;
  const [userInfo, setUserInfo] = useState(initialUserInfo);
  const {
    first_name,
    middle_name,
    last_name,
    email,
    mobile,
    user_type,
    created_at,
  } = userInfo;

  const FetchUser = async () => {
    return await useFetch(
      `${
        process.env.REACT_APP_API_URL
      }/authentications/tokens/${localStorage.getItem("token")}`
    );
  };

  useEffect(() => {
    FetchUser().then((data) => {
      if (data.data.length > 0) {
        setUserInfo(data.data[0].user);
      }
    });
  }, []);

  return (
    <>
      <Drawer
        width={640}
        placement="right"
        closable={true}
        onClose={onClose}
        visible={visible}
      >
        {userInfo ? (
          <>
            {" "}
            <p
              className="site-description-item-profile-p"
              style={{ marginBottom: 24 }}
            >
              User Profile
            </p>
            <p className="site-description-item-profile-p">Personal</p>
            <Row>
              <Col span={12}>
                <DescriptionItem
                  title="Full Name"
                  content={`${first_name} ${middle_name} ${last_name}`}
                />
              </Col>
            </Row>
            <Divider />
            <p className="site-description-item-profile-p">Company</p>
            <Row>
              <Col span={12}>
                <DescriptionItem title="Position" content={user_type} />
              </Col>
              <Col span={12}>
                <DescriptionItem title="Date Created" content={created_at} />
              </Col>
            </Row>
            <Divider />
            <p className="site-description-item-profile-p">Contacts</p>
            <Row>
              <Col span={12}>
                <DescriptionItem title="Email" content={email} />
              </Col>
              <Col span={12}>
                <DescriptionItem title="Mobile Number" content={mobile} />
              </Col>
            </Row>
          </>
        ) : (
          <Empty
            description={
              "No Information Regarding this User, try re-logging in"
            }
          />
        )}
      </Drawer>
    </>
  );
};
