import { useFetch } from "../hooks/useFetch";
import { useCreate } from "../hooks/useCreate";
import { useUpdate } from "../hooks/useUpdate";
import {
  FETCH_LISTING_URL,
  CREATE_LISTING_URL,
  UPDATE_LISTING_URL,
  DELETE_LISTING_URL,
  UPDATE_LISTING_ASSET_LISTING_URL,
} from "../api/routes/listings";
import { useDelete } from "../hooks/useDelete";

export const FetchListings = async (query) => {
  try {
    return await useFetch(FETCH_LISTING_URL, query);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const CreateListing = async (payload) => {
  try {
    return await useCreate(CREATE_LISTING_URL, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const UpdateListing = async (listing_id, payload) => {
  try {
    return await useUpdate(`${UPDATE_LISTING_URL}/${listing_id}`, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const DeleteListing = async (listing_id) => {
  try {
    return await useDelete(`${DELETE_LISTING_URL}/${listing_id}`);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const UpdateListingAssetListing = async (listing_id, payload) => {
  try {
    const data = await useUpdate(
      `${UPDATE_LISTING_ASSET_LISTING_URL}/${listing_id}`,
      payload
    );
    return data;
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
