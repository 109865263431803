import { useEffect, useState } from "react";

const assetInquiriesColumns = [
  {
    title: "Id",
    dataIndex: "asset_inquiry_id",
    key: "asset_inquiry_id",
    fixed: "left",
    width: 90,
  },
  {
    title: "Asset/Property Title",
    dataIndex: ["assets", "property_title"],
    key: ["assets", "property_title"],
    fixed: "left",
    width: 200,
  },
  {
    title: "Inquiry Message",
    dataIndex: "inquiry_message",
    key: "inquiry_message",
    fixed: "left",
    width: 300,
  },
  {
    title: "User Email",
    dataIndex: ["users", "users_email"],
    key: ["users", "users_email"],
  },
  {
    title: "User Mobile",
    dataIndex: ["users", "users_mobile"],
    key: ["users", "users_mobile"],
  },
  {
    title: "Alternate Number",
    dataIndex: "alternate_number",
    key: "alternate_number",
  },
  {
    title: "Preferred Time To Call",
    dataIndex: "preferred_time_to_call",
    key: "preferred_time_to_call",
    fixed: "right",
    width: 200,
  },

  {
    title: "Inquiry Date",
    dataIndex: "created_at",
    key: "created_at",
    fixed: "right",
    width: 200,
  },
];

export default function useGetAssetInquiriesColumns() {
  const [tableColumns, setColumns] = useState(assetInquiriesColumns);

  useEffect(() => {
    setColumns(assetInquiriesColumns);
  }, []);

  return tableColumns;
}
