import React from "react";

import { ProvincesFormModalComponent } from "./ProvincesFormModalComponent";

export const ProvincesFormsModalFunctionalityComponent = (props) => {
  const {
    onSubmitProvincesCreation,
    onCancelProvincesCreation,
    provincesModalVisibility,
    provincesCreationForm,
    onSubmitProvincesUpdate,
    onCancelProvincesUpdate,
    provincesUpdatedModalVisibility,
    provincesUpdateForm,
    regions = { data: [], total: 0 },
    onClickShowMoreRegion,
  } = props;

  return (
    <>
      <ProvincesFormModalComponent
        form={provincesCreationForm}
        isVisible={provincesModalVisibility}
        onClickSubmit={onSubmitProvincesCreation}
        onClickCancel={onCancelProvincesCreation}
        regions={regions}
        onClickShowMoreRegion={onClickShowMoreRegion}
      />
      <ProvincesFormModalComponent
        form={provincesUpdateForm}
        isVisible={provincesUpdatedModalVisibility}
        onClickSubmit={onSubmitProvincesUpdate}
        onClickCancel={onCancelProvincesUpdate}
        regions={regions}
        onClickShowMoreRegion={onClickShowMoreRegion}
        isUpdate={true}
      />
    </>
  );
};
