import React from "react";
import { ModulesFunctionalitiesComponent } from "../../ModulesFunctionalitiesComponent";
import { DeleteOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";

export const PartnersFunctionalityComponent = ({
  onClickCreatePartnerButton,
  onClickUpdatePartnerButton,
  onClickDeletePartnerButton,
  onClickArchivePartnerButton,
  selectedRowKey,
  selectedRow,
}) => {
  const isDeleteButtonDisabled = () => {
    if (!selectedRow) {
      return true;
    }

    return selectedRow.partner_status === "0" ? true : false;
  };

  const functionalitiesComponent = [
    {
      component_type: "button",
      type: "primary",
      icon: <EditOutlined />,
      _onClick: onClickCreatePartnerButton,
      label: "Create Partner",
      size: "default",
    },
    {
      component_type: "button",
      type: "default",
      icon: <SaveOutlined />,
      _onClick: onClickUpdatePartnerButton,
      label: "Update Partner",
      size: "default",
      is_hidden: selectedRowKey ? false : true,
    },
    {
      component_type: "button",
      type: "default",
      icon: <DeleteOutlined />,
      _onClick: onClickDeletePartnerButton,
      label: "Delete Partner",
      size: "default",
      is_hidden: selectedRowKey ? false : true,
      danger: true,
    },
    {
      component_type: "button",
      type: "default",
      icon: <SaveOutlined />,
      _onClick: onClickArchivePartnerButton,
      label: "Archive Partner",
      size: "default",
      is_hidden: selectedRowKey ? isDeleteButtonDisabled() : true,
      danger: true,
    },
  ];

  return (
    <>
      <ModulesFunctionalitiesComponent
        functionalitiesComponent={functionalitiesComponent}
        alignment="right"
        spaceSize="small"
      />
    </>
  );
};
