import { useEffect, useState } from "react";

const columnCallback = (handleClickViewPostHTML) => {
  return [
    {
      title: "Id",
      key: "barangay_id",
      dataIndex: "barangay_id",
    },
    {
      title: "Barangay Name",
      dataIndex: "barangay_name",
      key: "barangay_name",
    },
    {
      title: "City",
      dataIndex: ["city", "city_name"],
      key: ["city", "city_name"],
    },
    {
      title: "Date Creation",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Last Modified Date",
      dataIndex: "updated_at",
      key: "updated_at",
    },
  ];
};

export default function useGetBarangayColumns(handleClickViewPostHTML) {
  let columns = columnCallback(handleClickViewPostHTML);
  const [tableColumns, setColumns] = useState(columns);

  useEffect(() => {
    setColumns(columns);
  }, []);

  return tableColumns;
}
