import React from "react";
import { Modal, Input, Select, Form, Button, DatePicker } from "antd";
import { SaveOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { FormRules } from "../../../dist/functions/formRules";
import { SelectOptionFooterExtraButtonComponent } from "../../SelectOptionFooterExtraButtonComponent";

const { Option } = Select;
export const ProvincesFormModalComponent = (props) => {
  const {
    form = null,
    isVisible,
    onClickSubmit,
    onClickCancel,
    isUpdate,
    regions,
    onClickShowMoreRegion,
  } = props;

  const dateFormat = "YYYY-MM-DD hh:mm:ss";

  /**
   * Form Rules
   *
   */

  return (
    <Modal
      title={isUpdate ? "Update Provinces" : "Create Provinces"}
      visible={isVisible}
      onOk={onClickSubmit}
      onCancel={onClickCancel}
      footer={[
        <Button key="back" onClick={onClickCancel} icon={<ArrowLeftOutlined />}>
          Return
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onClickSubmit}
          icon={<SaveOutlined />}
        >
          Submit
        </Button>,
      ]}
    >
      <Form form={form} name="provinces" layout="vertical">
        <Form.Item
          label="Region"
          name="region_id"
          fieldKey="region_id"
          shouldUpdate={true}
        >
          <Select
            placeholder="Select Region"
            virtual={true}
            dropdownRender={(menu) => (
              <SelectOptionFooterExtraButtonComponent
                key="show_more"
                menu={menu}
                onClickShowMoreList={onClickShowMoreRegion}
                isClickShowMoreDisabled={
                  regions.data.length < regions.total ? false : true
                }
              />
            )}
          >
            {regions.data.map((item) => (
              <Option
                id={item.region_id}
                key={item.region_id}
                value={item.region_id}
              >
                {item.region_name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="province_name" label="Province Name">
          <Input />
        </Form.Item>

        <Form.Item name="alternative_name" label="Alternative Name">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
