import { useUpdate } from "../../hooks/useUpdate";

export const UpdateAsset = async (assetId, data) => {
  try {
    return await useUpdate(
      `${process.env.REACT_APP_API_URL}/assets/${assetId}`,
      data
    );
  } catch (err) {
    console.log("ERROR: ", err.stack);
  }
};
