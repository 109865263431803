import React, { useState, useEffect } from "react";
import { Divider, Form, PageHeader, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { BarangayListCompomentManagement } from "../sub_components/barangay/BarangayListCompomentManagement";
import { BarangayFunctionalityComponent } from "../sub_components/barangay/BarangayFunctionalityComponent";
import { BarangayCreationModalComponent } from "../sub_components/barangay/BarangayCreationModalComponent";
import { BarangayUpdatingModalComponent } from "../sub_components/barangay/BarangayUpdatingModalComponent";
import { useFetchCitiesAsOptions } from "../../hooks/cities/useCityAPI";
import {
  useFetchBarangays,
  useCreateBarangay,
  useUpdateBarangay,
  useDeleteBarangay,
} from "../../hooks/barangay/useBarangaysAPI";
import useFetchPermissionSets from "../../hooks/useFetchPermissions";
import { ModulesFilterAndSelectionComponent } from "../ModulesFilterAndSelectionComponent";

const { confirm } = Modal;
const initialQueryFilters = { page: 1, limit: 10 || "ALL", search_value: null };
const permissionSet = ["CREATE_BARANGAY", "UPDATE_BARANGAY", "DELETE_BARANGAY"];
const initialRolesRestriction = {
  CREATE_BARANGAY: 0,
  UPDATE_BARANGAY: 0,
  DELETE_BARANGAY: 0,
};

export const BarangayManagementComponent = () => {
  /**
   * Variable Declarations
   *
   */

  const [barangayCreationForm] = Form.useForm();
  const [barangayUpdatingForm] = Form.useForm();

  /**
   * Management State
   *
   *
   */

  const [filters, setFilters] = useState(initialQueryFilters);
  const [cities, setCities] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [filterTags, setFilterTags] = useState([]);
  const [showSelection, setShowSelection] = useState(false);
  const [barangayCreationModalStatus, setBarangayCreationModalStatus] =
    useState(false);
  const [barangayUpdatingModalStatus, setBarangayUpdatingModalStatus] =
    useState(false);

  useEffect(() => {
    FetchCities();
  }, []);

  /***
   *
   * Filter Tags hook
   * Automatic tagging to show selection filter components if filters tags has a data
   *
   *
   */

  useEffect(() => {
    if (filters.search_value) {
      setFilterTags((prevState) => {
        const tag = {
          color: "blue",
          value: filters.search_value,
          closable: false,
          selection_label: "Includes",
        };
        return [...prevState, tag];
      });
    }
  }, [filters.search_value]);

  useEffect(() => {
    if (filterTags.length > 0) {
      setShowSelection(true);
      return;
    }

    setShowSelection(false);
  }, [filterTags]);

  useEffect(() => {
    if (selectedRowKey) {
      const { barangay_name, city } = selectedRow;
      barangayUpdatingForm.setFieldsValue({
        barangay_name: barangay_name,
        city_id: city?.id | 0,
      });
    }
  }, [barangayUpdatingForm, selectedRow, selectedRowKey]);

  useEffect(() => {
    if (selectedRow) {
      const { barangay_name, city } = selectedRow;
      var displayName = barangay_name;
      displayName += city?.city_name ? `, ${city?.city_name}` : "";

      setFilterTags((prevState) => {
        const selectedSearchTagIndex = prevState.findIndex(
          (item) => item.selection_label === "Selected"
        );

        const tag = {
          color: "blue",
          value: displayName,
          closable: false,
          selection_label: "Selected",
        };

        if (selectedSearchTagIndex < 0) {
          return [...prevState, tag];
        }

        return prevState.splice(selectedSearchTagIndex, 1, tag);
      });
    }
  }, [selectedRow]);

  /**
   * Custom Hooks
   *
   */

  const barangays = useFetchBarangays(filters, true);
  const rolesRestriction = useFetchPermissionSets(
    permissionSet,
    initialRolesRestriction
  );

  /**
   * Custom functions
   *
   */

  const onPaginationChange = (page, limit) => {
    setFilters((prevState) => {
      return { ...prevState, page: page, limit: limit };
    });
  };

  const onSearch = (searchValue) => {
    setFilters((prevState) => {
      return { ...prevState, search_value: searchValue };
    });
  };

  const onSelection = (selectedRowKey, selectedRow) => {
    setSelectedRow(selectedRow[0]);
    setSelectedRowKey(selectedRowKey[0]);
  };

  const onClickBarangayCreation = () => {
    setBarangayCreationModalStatus(!barangayCreationModalStatus);
  };

  const onClickBarangayUpdating = () => {
    setBarangayUpdatingModalStatus(!barangayUpdatingModalStatus);
  };

  const FetchCities = async () => {
    const data = await useFetchCitiesAsOptions(false, {
      page: 1,
      limit: "ALL",
    });
    setCities(data);
  };

  const OnSubmitBarangayCreation = async () => {
    const values = await barangayCreationForm.validateFields();
    const isSuccess = await useCreateBarangay(values);
    if (isSuccess) {
      barangayCreationForm.resetFields();
      setFilters({ ...initialQueryFilters });
      onClickBarangayCreation();
    }
  };

  const OnSubmitBarangayUpdating = async () => {
    const values = await barangayUpdatingForm.validateFields();
    const isSuccess = await useUpdateBarangay(selectedRowKey, values);
    if (isSuccess) {
      barangayUpdatingForm.resetFields();
      setFilters({ ...initialQueryFilters });
      onClickBarangayUpdating();
      resetSelection();
    }
  };

  const OnSubmitBarangayDeletion = async () => {
    const isSuccess = await useDeleteBarangay(selectedRowKey);
    if (isSuccess) {
      barangayUpdatingForm.resetFields();
      setFilters({ ...initialQueryFilters });
      resetSelection();
    }
  };

  const onClickBarangayDeletion = async () => {
    confirm({
      title: "Do you Want to delete these barangay?",
      icon: <ExclamationCircleOutlined />,
      content: `By clicking OK, the current barangay: ${selectedRow.barangay_name} will be deleted`,
      onOk() {
        OnSubmitBarangayDeletion();
      },
      onCancel() {
        return;
      },
    });
  };

  const resetSelection = () => {
    setSelectedRowKey(null);
    setSelectedRow(null);
    setFilterTags([]);
    onSearch(null);
  };

  return (
    <>
      <PageHeader
        title="Barangay"
        subTitle="Barangay Management"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["Geolocation", "Barangay"]} />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <BarangayFunctionalityComponent
          onSearch={onSearch}
          onClickCreate={onClickBarangayCreation}
          onClickUpdate={onClickBarangayUpdating}
          onClickDelete={onClickBarangayDeletion}
          rolesRestriction={rolesRestriction}
          selectedRowKey={selectedRowKey}
        />
        <ModulesFilterAndSelectionComponent
          isShowSelection={showSelection}
          onClearSearcheAndSelection={resetSelection}
          tags={filterTags}
        />
        <BarangayListCompomentManagement
          data={barangays}
          onPaginationChange={onPaginationChange}
          onSelection={onSelection}
          selectedRowKey={selectedRowKey}
        />
      </div>
      <BarangayCreationModalComponent
        form={barangayCreationForm}
        cities={cities}
        onSubmit={OnSubmitBarangayCreation}
        visible={barangayCreationModalStatus}
        onCancel={onClickBarangayCreation}
      />
      <BarangayUpdatingModalComponent
        visible={barangayUpdatingModalStatus}
        form={barangayUpdatingForm}
        cities={cities}
        onCancel={onClickBarangayUpdating}
        onSubmit={OnSubmitBarangayUpdating}
      />
    </>
  );
};
