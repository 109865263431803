import { useDelete } from "../hooks/useDelete";
import { message } from "antd";
import { useUpdate } from "../hooks/useUpdate";

export const DeleteProjectAdvisorRelation = async (id) => {
  try {
    const response = await useDelete(
      `${process.env.REACT_APP_API_URL}/project_advisor_relation/${id}`
    );

    const { status } = response;

    if (status === 200) {
      await message.success(response.data.message, 4);
      return true;
    }
  } catch (err) {
    await message.error(err.message, 4);
    return false;
  }
};

export const UpdateProjectAdvisorRelation = async (id, payload) => {
  try {
    const response = await useUpdate(
      `${process.env.REACT_APP_API_URL}/project_advisor_relation/${id}`,
      payload
    );

    const { status } = response;

    if (status === 200) {
      await message.success(response.data.message, 4);
      return true;
    }
  } catch (err) {
    await message.error(err.message, 4);
    return false;
  }
};
