import React, { useEffect, useState } from "react";
import { PageHeader, Form, Modal, message } from "antd";
import { BuildOutlined } from "@ant-design/icons";

import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { PropertyDeveloperListComponent } from "../sub_components/property_developers/PropertyDeveloperListComponent";
import { PropertyDevelopersFunctionalityComponent } from "../sub_components/property_developers/PropertyDevelopersFunctionalityComponent";
import { PropertyDeveloperFormsModalFunctionalityComponent } from "../sub_components/property_developers/PropertyDeveloperFormsModalFunctionalityComponent";
import {
  useArchiveDeveloper,
  useCreatePropertyDeveloper,
  useDeletePropertyDeveloper,
  useFetchPropertyDevelopers,
  useRestoreDeveloper,
  useUpdatePropertyDeveloper,
  useUpdatePropertyDevelopersPivot,
  useUploadProjectImage,
  useGetAllDevelopers,
} from "../../hooks/property_developers/usePropertyDeveloperAPI";
import { useQueryFilterReducer } from "../../hooks/useQueryFilterReducer";
import { ModulesSelectAndColumnTagsComponent } from "../ModulesSelectAndColumnTagsComponent";
import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";
import { PropertyDeveloperModalContentComponent } from "../sub_components/property_developers/PropertyDeveloperModalContentComponent";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { AddNewPropertyDevelopersPivotComponent } from "../sub_components/property_developers/AddNewPropertyDevelopersPivotComponent";
import { useFetchPropertyProjects } from "../../hooks/property_projects/usePropertyProjectAPI";
import "../../dist/style/developers.css";
import { UpdatePartner } from "../../api/Partner";

const { confirm } = Modal;
const initialPropertyDevelopersData = {
  data: [],
  total: 0,
};

export const PropertyDevelopersComponent = ({ permissions }) => {
  const [createPropertyDeveloperForm] = Form.useForm();
  const [updatePropertyDeveloperForm] = Form.useForm();
  const [addPropertyDevelopersPivotForm] = Form.useForm();
  const [developerSequencePositionForm] = Form.useForm();

  const [
    propertyDeveloperCreationModalStatus,
    setPropertyDeveloperCreationModalStatus,
  ] = useState(false);
  const [
    propertyDeveloperUpdateModalStatus,
    setPropertyDeveloperUpdateModalStatus,
  ] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const [propertyDevelopersData, setPropertyDevelopersData] = useState(
    initialPropertyDevelopersData
  );

  const [postHTMLcontent, setPostHTMLcontent] = useState(null);
  const [isShowPreviewModalPost, setIsShowPreviewModalPost] = useState(false);
  const [showDeveloperSequenceModal, setShowDeveloperSequenceModal] =
    useState(false);

  const [projects, setProjects] = useState({ data: [], total: 0 });

  const [
    addNewPropertyDevelopersPivotButtonLoading,
    setAddNewPropertyDevelopersPivotButtonLoading,
  ] = useState(false);

  const [showAddPropertyDevelopersPivot, setShowAddPropertyDevelopersPivot] =
    useState(false);

  const [
    showDeveloperSequencePositionModal,
    setShowDeveloperSequencePositionModal,
  ] = useState(false);

  const [featuredDeveloperSequenceList, setFeaturedDeveloperSequenceList] =
    useState([]);

  const onDrop = (info) => {
    const dropKey = info.node.key;
    const dragKey = info.dragNode.key;
    const dropPos = info.node.pos.split("-");
    const dropPosition =
      info.dropPosition - Number(dropPos[dropPos.length - 1]);
    const loop = (data, key, callback) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === key) {
          return callback(data[i], i, data);
        }
        if (data[i].children) {
          loop(data[i].children, key, callback);
        }
      }
    };
    const data = [...featuredDeveloperSequenceList];

    // Find dragObject
    let dragObj;
    loop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1);
      dragObj = item;
    });
    if (!info.dropToGap) {
      // Drop on the content
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
      });
    } else if (
      (info.node.props.children || []).length > 0 &&
      // Has children
      info.node.props.expanded &&
      // Is expanded
      dropPosition === 1 // On the bottom gap
    ) {
      loop(data, dropKey, (item) => {
        item.children = item.children || [];
        // where to insert 示例添加到头部，可以是随意位置
        item.children.unshift(dragObj);
        // in previous version, we use item.children.push(dragObj) to insert the
        // item to the tail of the children
      });
    } else {
      let ar = [];
      let i;
      loop(data, dropKey, (_item, index, arr) => {
        ar = arr;
        i = index;
      });
      if (dropPosition === -1) {
        ar.splice(i, 0, dragObj);
      } else {
        ar.splice(i + 1, 0, dragObj);
      }
    }

    setFeaturedDeveloperSequenceList(data);
  };

  const {
    queryFilters,
    actionsQueryFilters,
    onPaginationChange,
    onSortChange,
    columnFilterTags,
    onClearColumnFilters,
    onLoadColumnFilterTags,
  } = useQueryFilterReducer();

  /**
   * Functionalities
   *
   */

  /**
   *
   * Create propertyDeveloper function for fetch reusability
   *
   */
  const FetchPropertyDevelopers = async (
    page = 1,
    limit = 10,
    columnFilter,
    sortColumn,
    sortType
  ) => {
    /**
     *
     * Construct Query parameters for fetching
     *
     */
    setTableLoading(true);
    const query = {
      page: page,
      limit: limit,
      sortColumn: sortColumn,
      sortType: sortType,
      columnFilter: columnFilter,
    };

    const data = await useFetchPropertyDevelopers(true, query);

    /**
     *
     * Create delay on updating of state for better transition and visualization on Frontend
     *
     */

    setTimeout(() => {
      setPropertyDevelopersData(data);
      setTableLoading(false);
    }, 800);
  };

  const FetchProjects = async (page = 1, limit = 10) => {
    const query = { page: page, limit: limit };
    const data = await useFetchPropertyProjects(true, query);

    setProjects(data);
    return data;
  };

  const FetchAllFeaturedDevelopers = async () => {
    // const queryParams = { is_featured: 1 };
    const developers = await useGetAllDevelopers();

    const formattedDeveloperSeqList = [];

    for (const developer of developers) {
      formattedDeveloperSeqList.push({
        key: developer.partner_id,
        title: developer.partner_name,
        icon: <BuildOutlined />,
        partnerTypeId: developer.partner_type_id,
      });
    }

    setFeaturedDeveloperSequenceList(formattedDeveloperSeqList);
  };

  /**
   *
   * Initial PropertyDeveloper Fetch
   *    - Fetch with 1 page, limit 10 with no filter as query parameters
   */

  useEffect(() => {
    FetchPropertyDevelopers();
    FetchAllFeaturedDevelopers();
    FetchProjects();
  }, []);

  useComponentDidUpdate(() => {
    const { page, limit, columnFilter, sortColumn, sortType } = queryFilters;

    onLoadColumnFilterTags();
    FetchPropertyDevelopers(page, limit, columnFilter, sortColumn, sortType);
  }, [queryFilters]);

  /**
   *
   * Use effect hooks if any of the data is selected
   * Selected row will populate the form for updating
   *
   */
  useEffect(() => {
    if (selectedRow && propertyDeveloperUpdateModalStatus === true) {
      updatePropertyDeveloperForm.setFieldsValue({
        ...selectedRow,
        partner_landline: selectedRow.partner_landline.toString(),
      });
    }
  }, [
    selectedRow,
    propertyDeveloperUpdateModalStatus,
    updatePropertyDeveloperForm,
  ]);

  useEffect(() => {
    if (
      selectedRow &&
      (propertyDeveloperUpdateModalStatus === true ||
        showAddPropertyDevelopersPivot)
    ) {
      const { property_developer_projects_pivots } = selectedRow;

      addPropertyDevelopersPivotForm.setFieldsValue({
        property_developer_projects_pivots,
      });
    }
  }, [
    selectedRow,
    addPropertyDevelopersPivotForm,
    showAddPropertyDevelopersPivot,
  ]);

  /**
   *
   * On every selection change, update the row selection state to trigger the use effect hook with row selection dependency
   * It will re-trigger fetch function with the updated row selection and populate forms for updating
   *
   */
  const onSelectionChange = (keys, selectedRows) => {
    setSelectedRow(selectedRows[0]);
    setSelectedRowKey(keys[0]);
  };

  /**
   *
   * Create PropertyDeveloper function with success validation, connected to useCreatePropertyDeveloper hook
   *
   */

  const CreatePropertyDeveloper = async () => {
    // const payload = await createPropertyDeveloperForm.validateFields();
    const payload = await createPropertyDeveloperForm.validateFields();
    const isSuccess = await useCreatePropertyDeveloper(payload);
    if (isSuccess) {
      createPropertyDeveloperForm.resetFields();
      const { page, limit, columnFilter, sortColumn, sortType } = queryFilters;
      FetchPropertyDevelopers(page, limit, columnFilter, sortColumn, sortType);

      onClickCreatePropertyDeveloperButton();
      resetSelections();
    }
  };

  /**
   *
   * Update PropertyDeveloper function with success validation, connected to useUpdatePropertyDeveloper hook
   *
   */

  const UpdatePropertyDeveloper = async () => {
    // const payload = await updatePropertyDeveloperForm.validateFields();
    const payload = await updatePropertyDeveloperForm.validateFields();

    const isSuccess = await useUpdatePropertyDeveloper(selectedRowKey, payload);
    if (isSuccess) {
      updatePropertyDeveloperForm.resetFields();

      const { page, limit, columnFilter, sortColumn, sortType } = queryFilters;
      FetchPropertyDevelopers(page, limit, columnFilter, sortColumn, sortType);
      onClickUpdatePropertyDeveloperButton();
      resetSelections();
    }
  };

  const UpdatePropertyDevelopersPivot = async () => {
    setAddNewPropertyDevelopersPivotButtonLoading(true);

    const { property_developer_projects_pivots } =
      await addPropertyDevelopersPivotForm.validateFields();

    const isSuccess = await useUpdatePropertyDevelopersPivot(selectedRowKey, {
      property_developer_projects_pivots,
    });
    setAddNewPropertyDevelopersPivotButtonLoading(false);
    if (isSuccess) {
      showaddPropertyDevelopersPivotModal();
      resetSelections();
      const { page, limit, columnFilter, sortColumn, sortType } = queryFilters;
      FetchPropertyDevelopers(page, limit, columnFilter, sortColumn, sortType);
    }
  };

  const handleIsShowPreviewModalStatusPost = () => {
    setIsShowPreviewModalPost(!isShowPreviewModalPost);
  };

  const handleClickViewPostHTML = (html_content) => {
    setPostHTMLcontent(html_content);
    setIsShowPreviewModalPost(true);
  };

  const DeletePropertyDeveloper = async () => {
    const isSuccess = await useDeletePropertyDeveloper(selectedRowKey);
    if (isSuccess) {
      resetSelections();
      const { page, limit, columnFilter, sortColumn, sortType } = queryFilters;
      FetchPropertyDevelopers(page, limit, columnFilter, sortColumn, sortType);
    }
  };

  /**
   *
   * Restore Developer function with success validation, connected to useUpdateDeveloper hook
   * Redefine as restore function, uses the useRestoreDeveloper hook function
   *
   */

  const RestoreDeveloper = async (id) => {
    const isSuccess = await useRestoreDeveloper(id);
    if (isSuccess) {
      const { page, limit, columnFilter, sortColumn, sortType } = queryFilters;
      FetchPropertyDevelopers(page, limit, columnFilter, sortColumn, sortType);
      resetSelections();
    }
  };

  /**
   *
   * Archive Developer function with success validation, connected to useArchiveDeveloper hook
   * Redefine as archive function, uses the useArchiveDeveloper hook function
   *
   */

  const ArchiveDeveloper = async () => {
    const isSuccess = await useArchiveDeveloper(selectedRowKey);
    if (isSuccess) {
      const { page, limit, columnFilter, sortColumn, sortType } = queryFilters;
      FetchPropertyDevelopers(page, limit, columnFilter, sortColumn, sortType);
      resetSelections();
    }
  };

  /**
   *
   * Helper functions:
   *
   * - Functions that will reset all selections, pagination and restore initial index/fetch
   *
   */

  const OnClickDeletePropertyDeveloper = async () => {
    confirm({
      title: `Are you sure, you want to remove this propertyDeveloper?`,
      icon: <ExclamationCircleOutlined />,
      content: `All information attached to this propertyDeveloper will be removed`,
      onOk() {
        DeletePropertyDeveloper();
      },
    });
  };

  const showaddPropertyDevelopersPivotModal = () => {
    const status = !showAddPropertyDevelopersPivot;
    setShowAddPropertyDevelopersPivot(status);

    if (status) {
      const { property_developer_projects_pivots } = selectedRow;

      addPropertyDevelopersPivotForm.setFieldsValue({
        property_developer_projects_pivots,
      });
    }
  };

  const onClickShowMoreProject = async () => {
    const { current_page, last_page } = projects;
    if (current_page < last_page) {
      const query = { page: parseInt(current_page) + 1, limit: 10 };
      const data = await FetchProjects(query);

      const oldProjects = projects.data;
      for (const item of data.data) {
        oldProjects.push(item);
      }

      const newState = {
        ...data,
        data: oldProjects,
      };

      setProjects(newState);
      return;
    }
  };

  /**
   *
   * Helper functions:
   *
   * - Functions that will reset all selections, pagination and restore initial index/fetch
   *
   */

  const resetSelections = () => {
    setSelectedRowKey(null);
    setSelectedRow(null);
  };

  const onClickCreatePropertyDeveloperButton = () => {
    setPropertyDeveloperCreationModalStatus(
      !propertyDeveloperCreationModalStatus
    );
  };

  const onClickUpdatePropertyDeveloperButton = () => {
    setPropertyDeveloperUpdateModalStatus(!propertyDeveloperUpdateModalStatus);
  };

  const developerImageUploaderProps = {
    name: "images",
    multiple: false,
    accept: ".webp",
    action: null,
    progress: true,
  };

  const HandleProjectImagesUpload = async (e, projectRow, subType) => {
    const { onSuccess, onError, file } = e;

    const { partner_id, partner_name } = projectRow;

    const formData = new FormData();

    formData.append("image", file);
    formData.append("partner_id", partner_id);
    formData.append("partner_name", partner_name);
    formData.append("sub_type", subType);

    const uploadResponse = await useUploadProjectImage(formData);

    const { status } = uploadResponse;

    if (status === "error") {
      onError(uploadResponse);
      await message.error(uploadResponse.message, 4);
      return;
    }

    onSuccess(uploadResponse);
    await message.success(uploadResponse.message, 4);

    const { page, limit, columnFilter, sortColumn, sortType } = queryFilters;
    FetchPropertyDevelopers(page, limit, columnFilter, sortColumn, sortType);
  };

  const handleShowDeveloperSequenceModal = () => {
    setShowDeveloperSequenceModal(!showDeveloperSequenceModal);
  };

  const HandleOnSaveDeveloperSequenceOrder = async () => {
    await Promise.all(
      featuredDeveloperSequenceList.map(async (item, index) => {
        const payload = {
          sequence_no: index,
          partner_type_id: item.partnerTypeId,
        };

        await UpdatePartner(item.key, payload);
      })
    );

    await message.success("Successfuly Updated Developers Sequence", 4);
    handleShowDeveloperSequenceModal();
  };

  const handleShowDeveloperSequencePositionModal = () => {
    setShowDeveloperSequencePositionModal(!showDeveloperSequencePositionModal);
  };

  const onClickTree = (key) => {
    const developerIndex = featuredDeveloperSequenceList.findIndex(
      (obj) => obj.key === key[0]
    );

    developerSequencePositionForm.setFieldsValue({
      currentData: JSON.stringify(
        featuredDeveloperSequenceList[developerIndex]
      ),
      sequencePosition: developerIndex + 1,
    });

    handleShowDeveloperSequencePositionModal();
  };

  const onHandleSubmitMovingDevelopersSequencePosition = async () => {
    const values = await developerSequencePositionForm.validateFields();

    const { sequencePosition, currentData } = values;
    const developerObject = JSON.parse(currentData);

    if (sequencePosition === 0) {
      await message.error("Invalid Developer Sequence Position", 2);
      return;
    }

    if (sequencePosition > featuredDeveloperSequenceList.length + 1) {
      await message.error("Invalid Developer Sequence Position", 2);
      return;
    }

    setFeaturedDeveloperSequenceList((data) => {
      const indexToMove = data.findIndex(
        (obj) => obj.key === developerObject.key
      );

      if (indexToMove !== -1) {
        const [objectToMove] = data.splice(indexToMove, 1); // Remove the object
        data.splice(sequencePosition - 1, 0, objectToMove); // Insert the object at the new index
        return [...data];
      }
    });

    developerSequencePositionForm.resetFields();
    handleShowDeveloperSequencePositionModal();
  };

  return (
    <>
      <PageHeader
        title="Property Developer"
        subTitle="Property Developer Management"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["Property Developer"]} />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <PropertyDevelopersFunctionalityComponent
          onClickCreatePropertyDeveloperButton={
            onClickCreatePropertyDeveloperButton
          }
          onClickUpdatePropertyDeveloperButton={
            onClickUpdatePropertyDeveloperButton
          }
          onClickUpdatePropertyDevelopersPivotButton={
            showaddPropertyDevelopersPivotModal
          }
          selectedRowKey={selectedRowKey}
          selectedRow={selectedRow}
          onClickDeletePropertyDeveloperButton={OnClickDeletePropertyDeveloper}
          permissions={permissions}
          onClickArchiveDeveloperButton={ArchiveDeveloper}
          handleShowDeveloperSequenceModal={handleShowDeveloperSequenceModal}
        />

        <ModulesSelectAndColumnTagsComponent
          onClearColumnFilters={onClearColumnFilters}
          isShowColumnFilterTags={columnFilterTags.length}
          columnFilterTags={columnFilterTags}
          isShowSelection={!!selectedRow}
          onClearSearcheAndSelection={resetSelections}
          tags={[
            {
              color: "processing",
              selection_label: "Selected Property Developer with name",
              value: selectedRow ? `${selectedRow.partner_name}` : null,
            },
          ]}
        />
        <PropertyDeveloperListComponent
          handleClickViewPostHTML={handleClickViewPostHTML}
          data={propertyDevelopersData}
          tableLoading={tableLoading}
          onPaginationChange={onPaginationChange}
          selectedRowKey={selectedRowKey}
          onSelection={onSelectionChange}
          currentPagination={queryFilters.page}
          onSortChange={onSortChange}
          setColumnFilter={actionsQueryFilters.changeColumnFilter}
          columnFilter={queryFilters.columnFilter}
          onClickRestoreDeveloper={RestoreDeveloper}
          developerImageUploaderProps={developerImageUploaderProps}
          HandleProjectImagesUpload={HandleProjectImagesUpload}
        />
      </div>
      <PropertyDeveloperFormsModalFunctionalityComponent
        onSubmitPropertyDeveloperCreation={CreatePropertyDeveloper}
        onCancelPropertyDeveloperCreation={onClickCreatePropertyDeveloperButton}
        propertyDeveloperModalVisibility={propertyDeveloperCreationModalStatus}
        propertyDeveloperCreationForm={createPropertyDeveloperForm}
        onSubmitPropertyDeveloperUpdate={UpdatePropertyDeveloper}
        onCancelPropertyDeveloperUpdate={onClickUpdatePropertyDeveloperButton}
        propertyDeveloperUpdatedModalVisibility={
          propertyDeveloperUpdateModalStatus
        }
        propertyDeveloperUpdateForm={updatePropertyDeveloperForm}
        showDeveloperSequenceModal={showDeveloperSequenceModal}
        HandleOnSaveDeveloperSequenceOrder={HandleOnSaveDeveloperSequenceOrder}
        handleShowDeveloperSequenceModal={handleShowDeveloperSequenceModal}
        onDrop={onDrop}
        featuredDeveloperSequenceList={featuredDeveloperSequenceList}
        onClickTree={onClickTree}
        showDeveloperSequencePositionModal={showDeveloperSequencePositionModal}
        handleShowDeveloperSequencePositionModal={
          handleShowDeveloperSequencePositionModal
        }
        developerSequencePositionForm={developerSequencePositionForm}
        onHandleSubmitMovingDevelopersSequencePosition={
          onHandleSubmitMovingDevelopersSequencePosition
        }
      />

      {selectedRow !== null ? (
        <>
          <AddNewPropertyDevelopersPivotComponent
            onClickShowMoreProject={onClickShowMoreProject}
            isVisible={showAddPropertyDevelopersPivot}
            handleOk={UpdatePropertyDevelopersPivot}
            handleCancel={showaddPropertyDevelopersPivotModal}
            loading={addNewPropertyDevelopersPivotButtonLoading}
            form={addPropertyDevelopersPivotForm}
            projects={projects}
            selectedRowKeys={selectedRowKey}
          />
        </>
      ) : null}
      <PropertyDeveloperModalContentComponent
        visible={isShowPreviewModalPost}
        onOk={handleIsShowPreviewModalStatusPost}
        content={postHTMLcontent}
      />
    </>
  );
};
