import React, { useState, useEffect } from "react";
import { message } from "antd";

import { useAPIResponse } from "../useAPIResponse";
import {
  FetchUsers,
  CreateUser,
  UpdateUser,
  DeleteUser,
} from "../../api/Users";

export const useFetchUsers = async (
  isForTable = true,
  query = { page: 1, limit: 10, columnFilter: [] }
) => {
  if (isForTable) {
    const data = await FetchUsers(query);

    for (const item of data.data.data) {
      item["key"] = item.users_id;
    }

    return data.data;
  }

  const data = await FetchUsers({
    page: 1,
    limit: null,
    columnFilter: [],
  });

  for (const item of data.data.data) {
    item["key"] = item.users_id;
  }

  return data.data.data;
};

export const useCreateUser = async (data) => {
  await message.loading("Creating User..", 2);
  const response = await CreateUser(data);

  return useAPIResponse(response);
};

export const useUpdateUser = async (user_id, payload) => {
  await message.loading("Updating User..", 2);
  const response = await UpdateUser(user_id, payload);

  return useAPIResponse(response);
};

export const useDeleteUser = async (user_id) => {
  await message.loading("Updating User..", 2);
  const response = await DeleteUser(user_id);

  return useAPIResponse(response);
};
