/* eslint-disable react-hooks/rules-of-hooks */
import { useFetch } from "../hooks/useFetch";

export const GetSessionDetails = async () => {
  try {
    const sessionId = localStorage.getItem("session_id");
    const url = `${process.env.REACT_APP_API_URL}/sessions`;
    const response = await useFetch(url, {
      session_id: sessionId,
    });
    return response;
  } catch (err) {
    return null;
  }
};
