import React from "react";
import { ModulesFunctionalitiesComponent } from "../../ModulesFunctionalitiesComponent";
import { EditOutlined, SaveOutlined } from "@ant-design/icons";

export const CitiesFunctionalityComponent = (props) => {
  const {
    onClickCreateCityButton,
    onClickUpdateCityButton,
    selectedRowKey,
    onSearch,
  } = props;

  const functionalitiesComponent = [
    {
      component_type: "search_input",
      place_holder: "Search City",
      _onSearch: onSearch,
    },
    {
      component_type: "button",
      type: "primary",
      icon: <EditOutlined />,
      _onClick: onClickCreateCityButton,
      label: "Create City",
      size: "default",
    },
    {
      component_type: "button",
      type: "default",
      icon: <SaveOutlined />,
      _onClick: onClickUpdateCityButton,
      label: "Update City",
      size: "default",
      is_hidden: selectedRowKey ? false : true,
    },
  ];

  return (
    <>
      <ModulesFunctionalitiesComponent
        functionalitiesComponent={functionalitiesComponent}
        alignment="right"
        spaceSize="small"
      />
    </>
  );
};
