import React from "react";
import { Form, Input, Select, InputNumber } from "antd";

const { TextArea } = Input;

const { Option } = Select;
export const AssetsCustomFieldInformation = React.memo(
  ({ fields, propertyCategories }) => {
    console.log({fields,propertyCategories})
    return (
      <>
        {fields.map((item, index) => (
          <Form.Item
            key={index}
            name={item.asset_custom_field_name}
            fieldKey={item.asset_custom_field_name}
            label={item.asset_custom_field_label}
            shouldUpdate={false}
          >
            {item.asset_custom_field_name === "property_sub_category" ? (
              <Select placeholder="Select Property Category">
                {propertyCategories?.map((item) => (
                  <Option
                    id={item.property_category_name}
                    key={item.property_category_name}
                    value={item.property_category_name}
                  >
                    {item.property_category_name}
                  </Option>
                ))}
              </Select>
            ) : item.asset_custom_field_name === "development_status" ? (
              <Select placeholder="Select Development Status">
                <Option
                  id="Ready For Occupancy"
                  key="Ready For Occupancy"
                  value="Ready For Occupancy"
                >
                  Ready For Occupancy
                </Option>

                <Option id="Preselling" key="Preselling" value="Preselling">
                  Preselling
                </Option>
              </Select>
            ) : item.asset_custom_field_name === "property_amenities" ||
              item.asset_custom_field_name === "other_property_details" ||
              item.asset_custom_field_name === "location_notes" ? (
              <TextArea rows={5} />
            ) : item.asset_custom_field_type === "number" ? (
              <InputNumber />
            ) : (
              <Input />
            )}
          </Form.Item>
        ))}
      </>
    );
  }
);
