import React, { useState } from "react";
import { Form, Modal, Input, Divider } from "antd";
import { AgentsCustomFieldInformation } from "./AgentsCustomFieldInformation";
import { UserUpdateGeneralInformation } from "./UserUpdateGeneralInformation";
import { UserUpdateModalFooterComponent } from "./UserUpdateModalFooterComponent";
import { useFetchAgentCustomFields } from "../../../hooks/agent_custom_field/useAgentCustomFieldAPI";
import { UpdateAgentMetaFieldsByAgentCustom } from "../../../api/AgentMetaField";
import { StepsComponent } from "../../StepsComponent";

const { TextArea } = Input;
const steps = [
  {
    title: "User Information",
    description: "user information",
    icon: "",
  },
  {
    title: "Agent Custom Field",
    description: "agent custom and meta fields",
  },
];

export const UpdateUserInformationComponent = (props) => {
  const {
    form,
    onCancel,
    visible,
    onSubmit,
    selectedUserInfo,
    fields,
    currentAgentPhoto,
  } = props;
  const [currentStep, setCurrentStep] = useState(0);

  const onSubmitUpdate = () => {
    onSubmit();
    setCurrentStep(0);
  };
  return (
    <>
      {selectedUserInfo["user_attached_roles,roles,name"] === "Agent" ||
      selectedUserInfo["user_attached_roles,roles,name"] === "agent" ? (
        <Modal
          title="Update User"
          visible={visible}
          onOk={onSubmitUpdate}
          onCancel={onCancel}
          footer={
            <UserUpdateModalFooterComponent
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              handleModaOkClicked={onSubmit}
            />
          }
        >
          <div className="user-creation-parent-div">
            <div className="user-creation-child-div">
              <StepsComponent
                steps={steps}
                size={"small"}
                currentStep={currentStep}
              />
              <Divider type="horizontal" />
            </div>
            <Form form={form} layout="vertical">
              {currentStep === 0 ? (
                <>
                  <UserUpdateGeneralInformation isHidden={false} />
                  <AgentsCustomFieldInformation
                    currentAgentPhoto={currentAgentPhoto}
                    fields={fields}
                    isHidden={true}
                  />
                </>
              ) : (
                <>
                  <UserUpdateGeneralInformation isHidden={true} />
                  <AgentsCustomFieldInformation
                    currentAgentPhoto={currentAgentPhoto}
                    fields={fields}
                    isHidden={false}
                  />
                </>
              )}
            </Form>
          </div>
        </Modal>
      ) : (
        <Modal
          title="Update User"
          visible={visible}
          onOk={onSubmitUpdate}
          onCancel={onCancel}
        >
          <Form form={form} layout="vertical">
            <UserUpdateGeneralInformation />
          </Form>
        </Modal>
      )}
    </>
  );
};
