const permissionsList = [
  "CREATE_PLATFORM",
  "UPDATE_PLATFORM",
  "DELETE_PLATFORM",
  "CREATE_MODULES",
  "UPDATE_MODULES",
  "DELETE_MODULES",
  "ADD_PERMISSION_INDEX",
  "UPDATE_PERMISSION_INDEX",
  "CREATE_ROLES_ACCESS",
  "UPDATE_ROLES_ACCESS",
  "CREATE_USER",
  "UPDATE_USER",
  "ADD_UPDATE_USER_ROLE",
  "UPDATE_USER",
  "UPDATE_STATUS",
  "UPDATE_DEPOSIT_STATUS",
  "GENERATE_USER_REPORT",
  "CREATE_LISTING",
  "UPDATE_LISTING",
  "CREATE_ASSET_LISTING",
  "UPDATE_ASSET_LISTING",
  "DELETE_ASSET_LISTING",
  "CREATE_ASSETS",
  "UPDATE_ASSETS",
  "VALIDATE_DATA",
  "UPSERT_DATA",
  "CREATE_CUSTOM_FIELD",
  "UPDATE_CUSTOM_FIELD",
  "ARCHIVE_CUSTOM_FIELD",
  "RESTORE_CUSTOM_FIELD",
  "CREATE_PROPERTY_CATEGORY",
  "UPDATE_PROPERTY_CATEGORY",
  "DELETE_PROPERTY_CATEGORY",
  "ARCHIVE_PROPERTY_CATEGORY",
  "RESTORE_PROPERTY_CATEGORY",
  "CREATE_CITY",
  "UPDATE_CITY",
  "CREATE_PARTNERS",
  "UPDATE_PARTNERS",
  "DELETE_PARTNERS",
  "ARCHIVE_PARTNERS",
  "CREATE_PARTNER_TYPES",
  "UPDATE_PARTNER_TYPES",
  "DELETE_PARTNER_TYPES",
  "UPDATE_META_FIELD",
  "CREATE_PROPERTY_OWNER",
  "UPDATE_PROPERTY_OWNER",
  "DELETE_PROPERTY_OWNER",
  "UPDATE_PROPERTY_OWNER_PROPERTY_OWNERS_PIVOT",
  "UPDATE_PROPERTY_OWNERS_PIVOT",
  "CREATE_PROPERTY_OWNER_PIVOT",
  "UPDATE_PROPERTY_OWNER_PIVOT",
  "DELETE_PROPERTY_OWNER_PIVOT",
  "CREATE_PROPERTY_PROJECT",
  "UPDATE_PROPERTY_PROJECT",
  "DELETE_PROPERTY_PROJECT",
  "UPDATE_PROPERTY_PROJECTS_PROPERTY_DEVELOPER_PROJECTS_PIVOT",
  "CREATE_PROPERTY_DEVELOPERS",
  "UPDATE_PROPERTY_DEVELOPERS",
  "DELETE_PROPERTY_DEVELOPERS",
  "UPDATE_PROPERTY_DEVELOPER_PROPERTY_DEVELOPER_PROJECTS_PIVOT",
  "CREATE_PROPERTY_DEVELOPER_PROJECTS_PIVOT",
  "UPDATE_PROPERTY_DEVELOPER_PROJECTS_PIVOT",
  "DELETE_PROPERTY_DEVELOPER_PROJECTS_PIVOT",
  "CREATE_ASSET_INQUIRIES",
  "UPDATE_ASSET_INQUIRIES",
  "DELETE_ASSET_INQUIRIES",
  "CREATE_PROVINCES",
  "UPDATE_PROVINCES",
  "DELETE_PROVINCES",
  "CREATE_ISLAND_GROUPS",
  "UPDATE_ISLAND_GROUPS",
  "DELETE_ISLAND_GROUPS",
  "CREATE_AGENT_META_FIELDS",
  "UPDATE_AGENT_META_FIELDS",
  "DELETE_AGENT_META_FIELDS",
  "CREATE_AGENT_CUSTOM_FIELDS",
  "UPDATE_AGENT_CUSTOM_FIELDS",
  "DELETE_AGENT_CUSTOM_FIELDS",
  "CREATE_SETTINGS",
  "UPDATE_SETTINGS",
  "CREATE_BARANGAY",
  "UPDATE_BARANGAY",
  "DELETE_BARANGAY",
  "CREATE_REGIONS",
  "UPDATE_REGIONS",
  "DELETE_REGIONS",
];

const createDefaultPermissionSet = () => {
  const permissions = {};
  for (const item of permissionsList) {
    permissions[item] = 0;
  }

  return permissions;
};

export { permissionsList, createDefaultPermissionSet };
