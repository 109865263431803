import { useFetch } from "../hooks/useFetch";
import { useCreate } from "../hooks/useCreate";
import { useUpdate } from "../hooks/useUpdate";
import {
  FETCH_CUSTOM_FIELD_URL,
  CREATE_CUSTOM_FIELD_URL,
  UPDATE_CUSTOM_FIELD_URL,
  QUERY_CUSTOMF_FIELD_URL,
} from "../api/routes/custom_field";

export const FetchCustomFields = async (query) => {
  try {
    return await useFetch(FETCH_CUSTOM_FIELD_URL, query);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const CreateCustomField = async (payload) => {
  try {
    return await useCreate(CREATE_CUSTOM_FIELD_URL, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const UpdateCustomField = async (custom_field_id, payload) => {
  try {
    return await useUpdate(
      `${UPDATE_CUSTOM_FIELD_URL}/${custom_field_id}`,
      payload
    );
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const FetchCustomFieldByQuery = async (query) => {
  try {
    return await useFetch(QUERY_CUSTOMF_FIELD_URL, query);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
