import { useEffect, useState } from "react";
import { FetchAPlusMembers } from "../../api/Users";

const useFethAPlusMembers = () => {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({ page: 1, limit: 10 });
  const [queryParameters, setQueryParameters] = useState({});
  const [meta, setMeta] = useState({
    total: 0,
    perPage: 0,
    currentPage: 0,
    lastPage: 0,
    firstPage: 0,
  });

  useEffect(() => {
    const FetchData = async () => {
      setIsloading(true);

      try {
        const response = await FetchAPlusMembers({
          ...pagination,
          ...queryParameters,
        });

        const { data, ...meta } = response.data;

        const mappedDatawithKeys = data.map((item) => {
          return { ...item, key: item.users_id };
        });

        setIsError(false);
        setData(mappedDatawithKeys);
        setMeta(meta);
        setIsloading(false);
      } catch (err) {
        setErrorMessage(err.message);
        setIsError(true);
      }
    };

    FetchData();
  }, [pagination, queryParameters]);

  return {
    isError,
    errorMessage,
    data,
    pagination,
    setQueryParameters,
    queryParameters,
    setPagination,
    meta,
    isLoading,
  };
};

export default useFethAPlusMembers;
