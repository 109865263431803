import { useEffect, useState } from "react";
import { Tag } from "antd";

const columns = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "City/Municipality Name",
    dataIndex: "city_name",
    key: "city_name",
  },
  {
    title: "Alternative Name",
    dataIndex: "alternative_name",
    key: "alternative_name",
  },
  {
    title: "Is Municipality (?)",
    dataIndex: "is_municipality",
    key: "is_municipality",
    render: (is_municipality) =>
      is_municipality === 1 ? (
        <Tag color="blue">True</Tag>
      ) : (
        <Tag color="yellow">False</Tag>
      ),
  },
  {
    title: "Province Name",
    dataIndex: ["provinces", "province_name"],
    key: ["provinces", "province_name"],
  },
  {
    title: "Date Created",
    dataIndex: "created_at",
    key: "created_at",
  },
  {
    title: "Last Modified Date",
    dataIndex: "updated_at",
    key: "updated_at",
  },
];

export default function useGetCityColumns() {
  const [tableColumns, setColumns] = useState(columns);

  useEffect(() => {
    setColumns(setColumns);
  }, []);

  return tableColumns;
}
