import React, { useEffect, useState } from "react";
import { Divider, PageHeader, Form, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { CustomFieldListComponent } from "../sub_components/custom_field/CustomFieldListComponent";
import { CustomFieldsFunctionalityComponent } from "../sub_components/custom_field/CustomFieldsFunctionalityComponent";
import { FormsModalFunctionalityComponent } from "../sub_components/custom_field/FormsModalFunctionalityComponent";
import useFetchPermissionSets from "../../hooks/useFetchPermissions";
import {
  useCreateCustomField,
  useFetchCustomFields,
  useUpdateCustomField,
} from "../../hooks/custom_field/useCustomFieldAPI";
import { useQueryFilterReducer } from "../../hooks/useQueryFilterReducer";
import { ModulesSelectAndColumnTagsComponent } from "../ModulesSelectAndColumnTagsComponent";

const { confirm } = Modal;
const initialCustomFieldsData = { page: 1, total: 0, data: [] };
const initialQueryFilters = { page: 1, limit: 10, filter: null };
const permissionSet = [
  "CREATE_CUSTOM_FIELD",
  "UPDATE_CUSTOM_FIELD",
  "ARCHIVE_CUSTOM_FIELD",
  "RESTORE_CUSTOM_FIELD",
];

const initialRolesRestriction = {
  CREATE_CUSTOM_FIELD: 0,
  UPDATE_CUSTOM_FIELD: 0,
  ARCHIVE_CUSTOM_FIELD: 0,
  RESTORE_CUSTOM_FIELD: 0,
};

export const CustomFieldManagementComponent = () => {
  const [createCustomFieldForm] = Form.useForm();
  const [updateCustomFieldForm] = Form.useForm();
  const [customFieldCreationModalStatus, setCustomFieldCreationModalStatus] =
    useState(false);
  const [customFieldUpdateModalStatus, setCustomFieldUpdateModalStatus] =
    useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const [customFieldsData, setCustomFieldsData] = useState(
    initialCustomFieldsData
  );
  // Query restriction using hook
  // Then hook, to get local storage
  const rolesRestriction = useFetchPermissionSets(
    permissionSet,
    initialRolesRestriction
  );

  const {
    queryFilters,
    actionsQueryFilters,
    onPaginationChange,
    onSortChange,
    resetQueryFilters,
    columnFilterTags,
    onClearColumnFilters,
    onLoadColumnFilterTags,
  } = useQueryFilterReducer();

  /**
   * Functionalities
   *
   */

  /**
   *
   * Create custom function for fetch reusability
   *
   */
  const FetchCustomFields = async (
    page = 1,
    limit = 10,
    columnFilter,
    sortColumn,
    sortType
  ) => {
    /**
     *
     * Construct Query parameters for fetching
     *
     */
    setTableLoading(true);
    const query = {
      page: page,
      limit: limit,
      sortColumn: sortColumn,
      sortType: sortType,
      columnFilter: columnFilter,
    };
    const data = await useFetchCustomFields(true, query);
    /**
     *
     * Create delay on updating of state for better transition and visualization on Frontend
     *
     */
    setTimeout(() => {
      setCustomFieldsData(data);
      setTableLoading(false);
    }, 1300);
  };

  /**
   *
   * Initial Custom Field Fetch
   *    - Fetch with 1 page, limit 10 with no filter as query parameters
   */

  useEffect(() => {
    FetchCustomFields();
  }, []);

  useEffect(() => {
    const { page, limit, columnFilter, sortColumn, sortType } = queryFilters;
    onLoadColumnFilterTags();
    FetchCustomFields(page, limit, columnFilter, sortColumn, sortType);
  }, [queryFilters]);

  /**
   *
   * Use effect hooks if any of the data is selected
   * Selected row will populate the form for updating
   *
   */
  useEffect(() => {
    if (selectedRow && customFieldUpdateModalStatus === true) {
      updateCustomFieldForm.setFieldsValue({ ...selectedRow });
    }
  }, [selectedRow, customFieldUpdateModalStatus, updateCustomFieldForm]);

  /**
   *
   * On every selection change, update the row selection state to trigger the use effect hook with row selection dependency
   * It will re-trigger fetch function with the updated row selection and populate forms for updating
   *
   */
  const onSelectionChange = (keys, selectedRows) => {
    setSelectedRow(selectedRows[0]);
    setSelectedRowKey(keys[0]);
  };

  /**
   *
   * Create Custom Field function with success validation, connected to useCReateCustomfield hook
   *
   */

  const CreateCustomField = async () => {
    const payload = await createCustomFieldForm.validateFields();
    const isSuccess = await useCreateCustomField(payload);
    if (isSuccess) {
      createCustomFieldForm.resetFields();
      FetchCustomFields();
      onClickCreateCustomFieldButton();
      resetSelections();
      resetQueryFilters();
    }
  };

  /**
   *
   * Update Custom Field function with success validation, connected to useUpdateCustomfield hook
   *
   */

  const UpdateCustomField = async () => {
    const payload = await updateCustomFieldForm.validateFields();
    const isSuccess = await useUpdateCustomField(selectedRowKey, payload);
    if (isSuccess) {
      updateCustomFieldForm.resetFields();
      FetchCustomFields();
      onClickUpdateCustomFieldButton();
      resetSelections();
      resetQueryFilters();
    }
  };

  /**
   *
   * Update Custom Field function with success validation, connected to useUpdateCustomfield hook
   * Redefine as restore function, but using the same useUpdate hook function
   *
   */

  const RestoreCustomField = async (id) => {
    const payload = { is_active: true };
    const isSuccess = await useUpdateCustomField(id, payload);
    if (isSuccess) {
      FetchCustomFields();
      onClickUpdateCustomFieldButton();
      resetSelections();
      resetQueryFilters();
    }
  };

  /**
   *
   * Update Custom Field function with success validation, connected to useUpdateCustomfield hook
   * Redefine as archive function, but using the same useUpdate hook function
   *
   */

  const ArchiveCustomField = async () => {
    const payload = { is_active: false };
    const isSuccess = await useUpdateCustomField(selectedRowKey, payload);
    if (isSuccess) {
      FetchCustomFields();
      resetSelections();
      resetQueryFilters();
    }
  };

  /**
   *
   * Helper functions:
   *
   * - Functions that will reset all selections, pagination and restore initial index/fetch
   *
   */

  const resetSelections = () => {
    setSelectedRowKey(null);
    setSelectedRow(null);
  };

  const onClickCreateCustomFieldButton = () => {
    setCustomFieldCreationModalStatus(!customFieldCreationModalStatus);
  };

  const onClickUpdateCustomFieldButton = () => {
    setCustomFieldUpdateModalStatus(!customFieldUpdateModalStatus);
  };

  const onClickArchiveCustomFieldButton = () => {
    confirm({
      title: "Do you Want to archive these items?",
      icon: <ExclamationCircleOutlined />,
      content:
        "Archiving this custom field, will affect all the assets involved",
      onOk() {
        ArchiveCustomField();
      },
      onCancel() {
        return;
      },
    });
  };

  return (
    <>
      <PageHeader
        title="Custom Field"
        subTitle="Asset(s) Custom Field Settings Management"
        breadcrumbRender={() => (
          <BreadCrumbsComponent
            breadcrumbsItem={["Settings", "Custom Field"]}
          />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <CustomFieldsFunctionalityComponent
          onClickCreateCustomFieldButton={onClickCreateCustomFieldButton}
          onClickUpdateCustomFieldButton={onClickUpdateCustomFieldButton}
          onClickArchiveCustomFieldButton={onClickArchiveCustomFieldButton}
          selectedRowKey={selectedRowKey}
          selectedRow={selectedRow}
          rolesRestriction={rolesRestriction}
        />
        <ModulesSelectAndColumnTagsComponent
          onClearColumnFilters={onClearColumnFilters}
          isShowColumnFilterTags={columnFilterTags.length}
          columnFilterTags={columnFilterTags}
          isShowSelection={!!selectedRow}
          onClearSearcheAndSelection={resetSelections}
          tags={[
            {
              color: "processing",
              selection_label: "Selected Custom Field",
              value: selectedRow
                ? `${selectedRow.asset_custom_field_name}`
                : null,
            },
          ]}
        />
        <CustomFieldListComponent
          data={customFieldsData}
          tableLoading={tableLoading}
          onPaginationChange={onPaginationChange}
          selectedRowKey={selectedRowKey}
          onSelection={onSelectionChange}
          currentPagination={queryFilters.page}
          onClickRestoreCustomField={RestoreCustomField}
          onSortChange={onSortChange}
          setColumnFilter={actionsQueryFilters.changeColumnFilter}
          columnFilter={queryFilters.columnFilter}
        />
      </div>
      <FormsModalFunctionalityComponent
        onSubmitCustomFieldCreation={CreateCustomField}
        onCancelCustomFieldCreation={onClickCreateCustomFieldButton}
        customFieldModalVisibility={customFieldCreationModalStatus}
        customFieldCreationForm={createCustomFieldForm}
        onSubmitCustomFieldUpdate={UpdateCustomField}
        onCancelCustomFieldUpdate={onClickUpdateCustomFieldButton}
        customFielUpdatedModalVisibility={customFieldUpdateModalStatus}
        customFieldUpdateForm={updateCustomFieldForm}
      />
    </>
  );
};
