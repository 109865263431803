import React from "react";
import { ModulesTableComponent } from "../../ModulesTableComponent";
import useColumnFilter from "../../../hooks/useColumnFilter";
import useGetListingContentsColumns from "../../../hooks/listings/useGetListingContentsColumns";
import { Table } from "antd";
import useGetListingColumns from "../../../hooks/listings/useGetListingColumns";
import useFilteredColumns from "../../../hooks/useFilteredColumns";

export const ListingListComponent = (props) => {
  const {
    data = { data: [], total: 0 },
    onPaginationChange,
    currentPagination,
    onSelection,
    selectedRowKey,
    onSortChange,
    columnFilter,
    setColumnFilter,
    tableLoading,
    handleClickViewPostHTML,
  } = props;

  // const listingColumns = useGetListingColumns();

  // for sub table of asset listing
  const listingContentColumns = useGetListingContentsColumns(
    handleClickViewPostHTML
  );

  const { getColumnFilterProps } = useColumnFilter(
    columnFilter,
    setColumnFilter
  );

  const listingColumns = useGetListingColumns();

  const disableAssetCountFilter = (col) => {
    return col.title !== "Asset Count";
  };

  let newCols = useFilteredColumns(
    listingColumns,
    getColumnFilterProps,
    disableAssetCountFilter
  );

  return (
    <>
      <ModulesTableComponent
        expandable={{
          expandedRowRender: (record) => (
            <div style={{ padding: "3%" }}>
              <Table
                size="small"
                bordered={true}
                dataSource={record.asset_listing}
                columns={listingContentColumns || []}
              ></Table>
            </div>
          ),
        }}
        data={data}
        onPaginationChange={onPaginationChange}
        onSelection={onSelection}
        selectedRowKey={selectedRowKey}
        tableColumns={newCols || []}
        currentPagination={currentPagination}
        columnFilter={columnFilter}
        setColumnFilter={setColumnFilter}
        onSortChange={onSortChange}
        tableLoading={tableLoading}
      />
    </>
  );
};
