import { useEffect, useState } from "react";

const columns = [
  {
    title: "Meta Field Name",
    dataIndex: ["asset_custom_field", "asset_custom_field_label"],
    key: ["asset_custom_field", "asset_custom_field_label"],
  },
  {
    title: "Meta Field Value (Text)",
    dataIndex: "asset_meta_field_text_value",
    key: "asset_meta_field_text_value",
  },
  {
    title: "Meta Field Value (Number)",
    dataIndex: "asset_meta_field_num_value",
    key: "asset_meta_field_num_value",
  },
  {
    title: "Date Created",
    dataIndex: "created_at",
    key: "created_at",
  },
  {
    title: "Last Modified Date",
    dataIndex: "updated_at",
    key: "updated_at",
  },
];

export default function useGetAssetMetaFieldColumns() {
  const [tableColumns, setColumns] = useState(columns);

  useEffect(() => {
    setColumns(columns);
  }, []);

  return tableColumns;
}
