import React, { useState } from "react";
import { Modal, Input, Form, Button, Steps, Divider } from "antd";
import { SaveOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { FormRules } from "../../../dist/functions/formRules";
import { useComponentDidUpdate } from "../../../hooks/useComponentDidUpdate";

const { Step } = Steps;

export const PropertyDeveloperFormModalComponent = (props) => {
  const {
    form = null,
    isVisible,
    onClickSubmit,
    onClickCancel,
    isUpdate,
  } = props;

  const [currentStep, setCurrentStep] = useState(1);

  const stepsNavigator = (direction) => {
    setCurrentStep((prevState) => {
      return direction === "forward" ? prevState + 1 : prevState - 1;
    });
  };

  useComponentDidUpdate(() => {
    setCurrentStep(1);
  }, [isVisible]);

  const {
    REQUIRED_PARTNER_NAME,
    REQUIRED_PARTNER_PERSON_IN_CHARGE,
    REQUIRED_PARTNER_MOBILE,
    REQUIRED_PARTNER_LANDLINE,
    REQUIRED_PARTNER_EMAIL,
  } = FormRules;

  const returnModalFooterButtons = [
    <Button
      key="cancel_button"
      type="default"
      size="medium"
      onClick={onClickCancel}
      hidden={currentStep === 1 ? false : true}
      icon={<LeftOutlined />}
    >
      Cancel
    </Button>,
    <Button
      key="back_button"
      type="default"
      size="medium"
      onClick={() => stepsNavigator("backward")}
      hidden={currentStep > 1 ? false : true}
      icon={<LeftOutlined />}
    >
      Back
    </Button>,
    <Button
      key="next_button"
      type="default"
      size="medium"
      onClick={() => stepsNavigator("forward")}
      hidden={currentStep === 2}
      icon={<RightOutlined />}
    >
      Next
    </Button>,
    <Button
      key="submit_button"
      type="primary"
      size="medium"
      hidden={currentStep === 2 ? false : true}
      onClick={onClickSubmit}
      icon={<SaveOutlined />}
    >
      Submit
    </Button>,
  ];

  /**
   * Form Rules
   *
   */

  return (
    <Modal
      title={
        isUpdate ? "Update Property Developer" : "Create Property Developer"
      }
      visible={isVisible}
      onOk={onClickSubmit}
      onCancel={onClickCancel}
      footer={returnModalFooterButtons}
    >
      <div style={{ padding: "4%" }}>
        <Steps size="small" current={currentStep - 1}>
          <Step title="General Information 1" />
          <Step title="General Information 2" />
        </Steps>
        <Divider />
      </div>
      <Form form={form} layout="vertical" name="property_developers">
        <div hidden={currentStep === 1 ? false : true}>
          <Form.Item name="partner_code" label="Partner Code">
            <Input />
          </Form.Item>
          <Form.Item name="partner_notes" label="Partner Notes">
            <Input />
          </Form.Item>

          <Form.Item
            name="partner_name"
            label="Partner Name"
            rules={[REQUIRED_PARTNER_NAME]}
          >
            <Input />
          </Form.Item>
        </div>
        <div hidden={currentStep === 2 ? false : true}>
          <Form.Item
            name="partner_person_in_charge"
            label="Partner Person In Charge"
            rules={[REQUIRED_PARTNER_PERSON_IN_CHARGE]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="partner_mobile"
            label="Partner Mobile"
            rules={[
              REQUIRED_PARTNER_MOBILE,
              FormRules.CONTACT_CHARACTER_COUNT,
              FormRules.NUMBER_FORMAT_ONLY,
            ]}
          >
            <Input prefix="+639" maxLength={9} />
          </Form.Item>
          <Form.Item
            name="partner_landline"
            label="Partner Landline"
            rules={[
              REQUIRED_PARTNER_LANDLINE,
              FormRules.PARTNERS_LANDLINE_CHARACTER_COUNT,
              FormRules.NUMBER_FORMAT_ONLY,
            ]}
          >
            <Input prefix={"(+632)"} maxLength={8} />
          </Form.Item>
          <Form.Item
            name="partner_email"
            label="Partner Email"
            rules={[REQUIRED_PARTNER_EMAIL, FormRules.EMAIL_FORMAT]}
          >
            <Input />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};
