import { message } from "antd";

import { useAPIResponse } from "../useAPIResponse";
import {
  FetchPropertyDevelopers,
  CreatePropertyDeveloper,
  UpdatePropertyDeveloper,
  DeletePropertyDeveloper,
  UpdatePropertyDeveloperProjectsPivot,
  RestoreDeveloper,
  ArchiveDeveloper,
  GetAllDevelopers,
  UploadDeveloperImages,
} from "../../api/PropertyDevelopers";

export const useFetchPropertyDevelopers = async (
  isForTable = true,
  query = { page: 1, limit: 10, columnFilter: [] }
) => {
  if (isForTable) {
    const data = await FetchPropertyDevelopers(query);

    for (const item of data.data.data) {
      item["key"] = item.partner_id;
    }

    return data.data;
  }

  const data = await FetchPropertyDevelopers({
    page: 1,
    limit: null,
    columnFilter: [],
  });

  for (const item of data.data.data) {
    item["key"] = item.partner_id;
  }

  return data.data.data;
};

export const useGetAllDevelopers = async (query = null) => {
  const response = await GetAllDevelopers(query);
  return response;
};

export const useCreatePropertyDeveloper = async (data) => {
  await message.loading("Creating Property Developer..", 2);
  const response = await CreatePropertyDeveloper(data);

  return useAPIResponse(response);
};

export const useUpdatePropertyDeveloper = async (developer_id, payload) => {
  await message.loading("Updating Property Developer..", 2);
  const response = await UpdatePropertyDeveloper(developer_id, payload);

  return useAPIResponse(response);
};

export const useUpdatePropertyDevelopersPivot = async (
  developer_id,
  payload
) => {
  await message.loading("Updating Property Developer Pivot..", 2);
  const response = await UpdatePropertyDeveloperProjectsPivot(
    developer_id,
    payload
  );

  return useAPIResponse(response);
};

export const useDeletePropertyDeveloper = async (developer_id) => {
  await message.loading("Updating Property Developer..", 2);
  const response = await DeletePropertyDeveloper(developer_id);

  return useAPIResponse(response);
};

export const useRestoreDeveloper = async (developer_id, payload) => {
  await message.loading("Restoring Developer..", 2);
  const response = await RestoreDeveloper(developer_id, payload);

  return useAPIResponse(response);
};

export const useArchiveDeveloper = async (developer_id, payload) => {
  await message.loading("Archiving Developer..", 2);
  const response = await ArchiveDeveloper(developer_id, payload);

  return useAPIResponse(response);
};

export const useUploadProjectImage = async (payload) => {
  const resp = await UploadDeveloperImages(payload);

  const { status } = resp;

  if (status !== 201) {
    return JSON.parse(resp.response);
  }

  return resp.data;
};
