import React, { useRef, useState } from 'react';
import {
  Form,
  Button,
  Input,
  Divider,
  InputNumber,
  Select,
  Radio,
  message,
} from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { SelectOptionFooterExtraButtonComponent } from '../../SelectOptionFooterExtraButtonComponent';
import { FormRules } from '../../../dist/functions/formRules';
const { Option } = Select;

export const AddNewAssetListingFormComponent = (props) => {
  const {
    assets,
    onClickShowMoreAsset,
    selectedRowKeys = 0,
    asset_listing = [],
    form,
    isUpdate = false,
  } = props;

  const isPriceError = useRef(false);

  // const [isPriceError, setPriceError] = useState(false);

  const checkFieldIfExists = (key) => {
    return !!asset_listing[key]?.asset_listing_id || false;
  };

  const setAssetListingPrice = (assetPriceMin, assetPriceMax, fieldKey) => {
    const value = form.getFieldValue('asset_listing');
    Object.assign(value[fieldKey], {
      asset_listing_price_min: assetPriceMin,
      asset_listing_price_max: assetPriceMax,
    });

    form.setFieldsValue({ value });
  };

  const handlePrice = async (value) => {
    const re = /^[0-9\b]+$/;

    if (!re.test(value) && !isPriceError.current) {
      isPriceError.current = true;

      await message.error('Please only enter number', 2, () => {
        isPriceError.current = false;
      });
    }
  };

  const { REQUIRED_STATUS, REQUIRED_ASSET } = FormRules;

  return (
    <>
      <div
        style={{
          overflowX: 'hidden',
          overflowY: 'auto',
          height: '400px',
        }}
      >
        <Form.List name='asset_listing'>
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <React.Fragment key={[field.fieldKey, 'frag']}>
                  <div style={{ marginTop: '2%' }}>
                    <Form.Item
                      label='Asset'
                      {...field}
                      name={[field.name, 'asset_id']}
                      key={[field.fieldKey, 'asset_id']}
                      fieldKey={[field.fieldKey, 'asset_id']}
                      rules={[REQUIRED_ASSET]}
                    >
                      <Select
                        placeholder='Select Asset'
                        disabled={checkFieldIfExists(field.key)}
                        onChange={(value, option) => {
                          setAssetListingPrice(
                            assets.data[option.key].price_min,
                            assets.data[option.key].price_max,
                            field.name
                          );
                        }}
                        dropdownRender={(menu) => (
                          <SelectOptionFooterExtraButtonComponent
                            key='show_more'
                            menu={menu}
                            onClickShowMoreList={onClickShowMoreAsset}
                            isClickShowMoreDisabled={
                              assets.data.length < assets.total ? false : true
                            }
                          />
                        )}
                      >
                        {assets.data.map((item, index) => (
                          <Option key={index} value={item.asset_id}>
                            {item.property_title
                              ? item.property_title
                              : item.asset_code}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      hidden={true}
                      {...field}
                      name={[field.name, 'asset_listing_id']}
                      key={[field.fieldKey, 'asset_listing_id']}
                      fieldKey={[field.fieldKey, 'asset_listing_id']}
                    ></Form.Item>
                    <Form.Item
                      hidden={true}
                      {...field}
                      name={[field.name, 'listing_id']}
                      key={[field.fieldKey, 'listing_id']}
                      fieldKey={[field.fieldKey, 'listing_id']}
                      initialValue={selectedRowKeys}
                    ></Form.Item>
                    <Form.Item
                      label='Listing Method'
                      {...field}
                      name={[field.name, 'listing_method']}
                      key={[field.fieldKey, 'listing_method']}
                      fieldKey={[field.fieldKey, 'listing_method']}
                    >
                      <Input
                        placeholder='Listing Method'
                        disabled={true}
                        // disabled={checkFieldIfExists(field.key)}
                      />
                    </Form.Item>

                    <Form.Item
                      label='Status'
                      {...field}
                      name={[field.name, 'status']}
                      fieldKey={[field.fieldKey, 'status']}
                      key={[field.fieldKey, 'status']}
                      rules={[REQUIRED_STATUS]}
                      initialValue={1}
                    >
                      <Radio.Group>
                        <Radio value={1}>Active</Radio>
                        <Radio value={0}>Not Active</Radio>
                      </Radio.Group>
                    </Form.Item>

                    {!checkFieldIfExists(field.key) && isUpdate && (
                      <>
                        <Form.Item
                          {...field}
                          label='Asset Listing Price Min'
                          name={[field.name, 'asset_listing_price_min']}
                          fieldKey={[field.fieldKey, 'asset_listing_price_min']}
                          key={[field.fieldKey, 'asset_listing_price_min']}
                        >
                          <InputNumber
                            onInput={(e) => handlePrice(e)}
                            onBlur={(e) => (isPriceError.current = false)}
                            style={{ width: '100%' }}
                            placeholder='Asset Listing Price Min'
                            max={9999999999}
                          />
                        </Form.Item>

                        <Form.Item
                          {...field}
                          label='Asset Listing Price Max'
                          name={[field.name, 'asset_listing_price_max']}
                          fieldKey={[field.fieldKey, 'asset_listing_price_max']}
                          key={[field.fieldKey, 'asset_listing_price_max']}
                        >
                          <InputNumber
                            onInput={(e) => handlePrice(e)}
                            onBlur={(e) => (isPriceError.current = false)}
                            style={{ width: '100%' }}
                            placeholder='Asset Listing Price Max'
                            max={9999999999}
                          />
                        </Form.Item>
                      </>
                    )}

                    <Button
                      key={`delete-${index}`}
                      type='default'
                      size='small'
                      icon={<DeleteOutlined />}
                      onClick={() => remove(field.name)}
                      danger
                      block
                    >
                      Remove Entry
                    </Button>
                  </div>
                  <Divider />
                </React.Fragment>
              ))}
              <Form.Item>
                <Button
                  type='dashed'
                  onClick={() => add({ listing_method: 'manual' })}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Asset Listing Entry
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
    </>
  );
};
