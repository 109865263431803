import axios from "axios";

export const useDelete = async (url, payload = {}) => {
  // const [status, setStatus] = useState('Loading');
  // const [data, setData] = useState(null);
  const header = {
    headers: { session: localStorage.getItem("session_id") },
  };

  return await axios.delete(url, payload, header);
};
