import React from "react";
import { PageHeader } from "antd";
import {} from "@ant-design/icons";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";

export const DashboardComponent = () => {
  return (
    <>
      <PageHeader
        title="DASHBOARD"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["Dashboard"]} />
        )}
      />
    </>
  );
};
