import React, { useEffect, useState } from "react";
import { Divider, PageHeader, Form, Modal, message } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { ListingListComponent } from "../sub_components/listings/ListingListComponent";
import { ListingsFunctionalityComponent } from "../sub_components/listings/ListingsFunctionalityComponent";
import { ListingFormsModalFunctionalityComponent } from "../sub_components/listings/ListingFormsModalFunctionalityComponent";
import { ModulesSelectAndColumnTagsComponent } from "../ModulesSelectAndColumnTagsComponent";
import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";
import { ListingModalContentComponent } from "../sub_components/listings/ListingModalContentComponent";
import { AddNewAssetListingComponent } from "../sub_components/listings/AddNewAssetListingComponent";
import {
  useFetchPartners,
  useFetchPartnersListingNull,
} from "../../hooks/partners/usePartnerAPI";
import {
  useCreateListing,
  useDeleteListing,
  useFetchListings,
  useUpdateListing,
  UseUpdateListingAssetListing,
} from "../../hooks/listings/useListingAPI";
import { useQueryFilterReducer } from "../../hooks/useQueryFilterReducer";
import { useFetchAssets } from "../../hooks/assets/useAssetAPI";
import { useFetchUsers } from "../../hooks/users/useUserAPI";

const { confirm } = Modal;

export const ListingsManagementComponent = ({ permissions }) => {
  const [createListingForm] = Form.useForm();
  const [updateListingForm] = Form.useForm();
  const [addAssetListingForm] = Form.useForm();
  const [listingCreationModalStatus, setListingCreationModalStatus] =
    useState(false);
  const [listingUpdateModalStatus, setListingUpdateModalStatus] =
    useState(false);

  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [postHTMLcontent, setPostHTMLcontent] = useState(null);
  const [isShowPreviewModalPost, setIsShowPreviewModalPost] = useState(false);
  const [assets, setAssets] = useState({ data: [], total: 0 });
  const [users, setUsers] = useState({ data: [], total: 0 });
  const [addNewAssetListingButtonLoading, setAddNewAssetListingButtonLoading] =
    useState(false);
  const [showAddAssetListing, setShowAddAssetListing] = useState(false);
  const [partnersQuery, setPartnersQuery] = useState({
    page: 1,
    limit: 10,
  });

  const [
    partners,
    isLoadingPartners,
    persistentPartners,
    setShouldPersistPartner,
    shouldResetPersist,
  ] = useFetchPartnersListingNull(true, partnersQuery);

  const {
    queryFilters,
    actionsQueryFilters,
    onPaginationChange,
    onSortChange,
    columnFilterTags,
    onClearColumnFilters,
    onLoadColumnFilterTags,
    resetQueryFilters,
  } = useQueryFilterReducer({
    page: 1,
    limit: 10,
    columnFilter: [],
    sortColumn: "listing_started_at",
    sortType: "desc",
    search_value: "",
  });

  const [listingsData, isLoadingListings] = useFetchListings(
    true,
    queryFilters
  );

  /**
   * Functionalities
   *
   */

  const FetchUsers = async (page = 1, limit = 10) => {
    const query = { page: page, limit: limit };
    const data = await useFetchUsers(true, query);

    setUsers(data);
    return data;
  };

  const FetchAssets = async (page = 1, limit = 10, colFilter) => {
    const query = { page: page, limit: limit, columnFilter: colFilter };
    const data = await useFetchAssets(true, query);

    setAssets(data);
    return data;
  };

  /**
   *
   * Initial Listing Fetch
   *    - Fetch with 1 page, limit 10 with no filter as query parameters
   */

  useEffect(() => {
    FetchAssets();
    FetchUsers();
  }, []);

  useComponentDidUpdate(() => {
    onLoadColumnFilterTags();
  }, [queryFilters]);

  /**
   *
   * Use effect hooks if any of the data is selected
   * Selected row will populate the form for updating
   *
   */
  useEffect(() => {
    if (selectedRow && listingUpdateModalStatus === true) {
      const {
        partner_id,
        listing_status,
        listing_started_at,
        listing_ended_at,
        listing_closed_at,
        listing_closed_type,
        listing_closed_by,
      } = selectedRow;

      updateListingForm.setFieldsValue({
        partner_id,
        listing_status,
        listing_started_at: listing_started_at
          ? moment(listing_started_at)
          : undefined,
        listing_ended_at: listing_ended_at
          ? moment(listing_ended_at)
          : undefined,
        listing_closed_at: listing_closed_at
          ? moment(listing_closed_at)
          : undefined,
        listing_closed_type,
        listing_closed_by,
      });
    }
  }, [selectedRow, listingUpdateModalStatus, updateListingForm]);

  /**
   *
   * On every selection change, update the row selection state to trigger the use effect hook with row selection dependency
   * It will re-trigger fetch function with the updated row selection and populate forms for updating
   *
   */
  const onSelectionChange = (keys, selectedRows) => {
    setSelectedRow(selectedRows[0]);
    setSelectedRowKey(keys[0]);
  };

  const onChangePartner = (partner_id) => {
    // change the render of asset when partner changed
    FetchAssets(1, 10, [
      { id: "partner_id", value: partner_id.toString() },
      { id: "asset_status", value: "active" },
    ]);
  };
  /**
   *
   * Create Listing function with success validation, connected to useCreateListing hook
   *
   */

  const CreateListing = async () => {
    // const payload = await createListingForm.validateFields();
    const {
      partner_id,
      listing_status,
      listing_started_at,
      listing_ended_at,

      listing_closed_by,
      asset_listing,
    } = await createListingForm.validateFields();

    const listing_started_at_ = listing_started_at.format(
      "YYYY-MM-DD hh:mm:ss"
    );
    const listing_ended_at_ = listing_ended_at.format("YYYY-MM-DD hh:mm:ss");

    const payload = {
      partner_id,
      listing_status,
      listing_started_at: listing_started_at_,
      listing_ended_at: listing_ended_at_,
      listing_closed_by,
    };

    const isSuccess = await useCreateListing(payload);

    if (isSuccess !== false) {
      if (asset_listing) {
        let _asset_listing = asset_listing.map((e) => {
          return { ...e, listing_id: isSuccess };
        });
        await UseUpdateListingAssetListing(isSuccess, {
          asset_listing: _asset_listing,
        });
      }

      createListingForm.resetFields();
      resetQueryFilters();
      resetPartnerQuery();
      onClickCreateListingButton();
      resetSelections();
    }
  };

  /**
   *
   * Update Listing function with success validation, connected to useUpdateListing hook
   *
   */

  const UpdateListing = async () => {
    // const payload = await updateListingForm.validateFields();
    const {
      listing_status,
      listing_ended_at,
      listing_closed_at,
      listing_closed_type,
      listing_closed_by,
    } = await updateListingForm.validateFields();

    const listing_ended_at_ = listing_ended_at.format("YYYY-MM-DD hh:mm:ss");
    const listing_closed_at_ = listing_closed_at.format("YYYY-MM-DD hh:mm:ss");

    const payload = {
      listing_status,
      listing_ended_at: listing_ended_at_,
      listing_closed_at: listing_closed_at_,
      listing_closed_type,
      listing_closed_by,
    };

    const isSuccess = await useUpdateListing(selectedRowKey, payload);
    if (isSuccess) {
      updateListingForm.resetFields();
      resetQueryFilters();
      resetPartnerQuery();
      onClickUpdateListingButton();
      resetSelections();
    }
  };

  const UpdateAssetListing = async () => {
    try {
      const values = await addAssetListingForm.validateFields();

      setAddNewAssetListingButtonLoading(true);
      const isSuccess = await UseUpdateListingAssetListing(
        selectedRowKey,
        values
      );
      setAddNewAssetListingButtonLoading(false);
      if (isSuccess) {
        showaddAssetListingModal();
        resetSelections();
        resetQueryFilters();
      }
    } catch (errorInfo) {
      for (const errorField of errorInfo.errorFields) {
        let errMessage = "";
        for (const err of errorField.errors) {
          errMessage += err + " ";
        }

        message.error(errMessage);
      }
    }
  };

  const handleIsShowPreviewModalStatusPost = () => {
    setIsShowPreviewModalPost(!isShowPreviewModalPost);
  };

  const handleClickViewPostHTML = (html_content) => {
    setPostHTMLcontent(html_content);
    setIsShowPreviewModalPost(true);
  };

  const DeleteListing = async () => {
    const isSuccess = await useDeleteListing(selectedRowKey);
    if (isSuccess) {
      resetSelections();
      resetQueryFilters();
      resetPartnerQuery();
    }
  };

  /**
   *
   * Helper functions:
   *
   * - Functions that will reset all selections, pagination and restore initial index/fetch
   *
   */

  const OnClickDeleteListing = async () => {
    confirm({
      title: `Are you sure, you want to remove this listing?`,
      icon: <ExclamationCircleOutlined />,
      content: `All information attached to this listing will be removed`,
      onOk() {
        DeleteListing();
      },
    });
  };

  const showaddAssetListingModal = () => {
    const status = !showAddAssetListing;
    setShowAddAssetListing(status);
    if (status) {
      const { asset_listing } = selectedRow;

      addAssetListingForm.setFieldsValue({
        asset_listing,
      });
    }
  };

  const onClickShowMorePartner = async () => {
    const { current_page, last_page } = partners;
    if (current_page < last_page) {
      const query = { page: parseInt(current_page) + 1, limit: 10 };
      setShouldPersistPartner(true);
      setPartnersQuery(query);

      return;
    }
  };

  const onClickShowMoreUser = async () => {
    const { current_page, last_page } = users;
    if (current_page < last_page) {
      const query = { page: parseInt(current_page) + 1, limit: 10 };
      const data = await FetchUsers(query);

      const oldUsers = users.data;
      for (const item of data.data) {
        oldUsers.push(item);
      }

      const newState = {
        ...data,
        data: oldUsers,
      };

      setUsers(newState);
      return;
    }
  };

  const onClickShowMoreAsset = async () => {
    const { current_page, last_page } = assets;
    if (current_page < last_page) {
      const query = parseInt(current_page) + 1;
      const data = await FetchAssets(query);

      const oldAssets = assets.data;
      for (const item of data.data) {
        oldAssets.push(item);
      }

      const newState = {
        ...data,
        data: oldAssets,
      };

      setAssets(newState);
      return;
    }
  };

  /**
   *
   * Helper functions:
   *
   * - Functions that will reset all selections, pagination and restore initial index/fetch
   *
   */

  const resetSelections = () => {
    setSelectedRowKey(null);
    setSelectedRow(null);
  };

  const resetPartnerQuery = () => {
    shouldResetPersist(true);
    setPartnersQuery({ ...{ page: 1, limit: 10 } });
  };

  const onClickCreateListingButton = () => {
    setListingCreationModalStatus(!listingCreationModalStatus);

    FetchAssets(1, 10, [
      { id: "partner_id", value: "0" },
      { id: "asset_status", value: "active" },
    ]);
  };

  const onClickUpdateListingButton = () => {
    setListingUpdateModalStatus(!listingUpdateModalStatus);
  };

  return (
    <>
      <PageHeader
        title="Listing"
        subTitle="Listing Management"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["Listing"]} />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <ListingsFunctionalityComponent
          onClickCreateListingButton={onClickCreateListingButton}
          onClickUpdateListingButton={onClickUpdateListingButton}
          onClickUpdateAssetListingButton={showaddAssetListingModal}
          selectedRowKey={selectedRowKey}
          selectedRow={selectedRow}
          onClickDeleteListingButton={OnClickDeleteListing}
          permissions={permissions}
        />
        <ModulesSelectAndColumnTagsComponent
          onClearColumnFilters={onClearColumnFilters}
          isShowColumnFilterTags={columnFilterTags.length}
          columnFilterTags={columnFilterTags}
          isShowSelection={!!selectedRow}
          onClearSearcheAndSelection={resetSelections}
          tags={[
            {
              color: "processing",
              selection_label: "Selected Listing with Partner",
              value:
                selectedRow && selectedRow.partners
                  ? `${selectedRow.partners.partner_name}`
                  : null,
            },
          ]}
        />
        <ListingListComponent
          handleClickViewPostHTML={handleClickViewPostHTML}
          data={listingsData}
          tableLoading={isLoadingListings}
          onPaginationChange={onPaginationChange}
          selectedRowKey={selectedRowKey}
          onSelection={onSelectionChange}
          currentPagination={queryFilters.page}
          onSortChange={onSortChange}
          setColumnFilter={actionsQueryFilters.changeColumnFilter}
          columnFilter={queryFilters.columnFilter}
        />
      </div>
      <ListingFormsModalFunctionalityComponent
        onSubmitListingCreation={CreateListing}
        onCancelListingCreation={onClickCreateListingButton}
        listingModalVisibility={listingCreationModalStatus}
        listingCreationForm={createListingForm}
        onSubmitListingUpdate={UpdateListing}
        onCancelListingUpdate={onClickUpdateListingButton}
        listingUpdatedModalVisibility={listingUpdateModalStatus}
        listingUpdateForm={updateListingForm}
        onClickShowMorePartner={onClickShowMorePartner}
        partners={persistentPartners}
        onClickShowMoreUser={onClickShowMoreUser}
        users={users}
        selectedRow={selectedRow}
        onClickShowMoreAsset={onClickShowMoreAsset}
        assets={assets}
        onChangePartner={onChangePartner}
      />

      {selectedRow !== null ? (
        <>
          <AddNewAssetListingComponent
            onClickShowMoreAsset={onClickShowMoreAsset}
            isVisible={showAddAssetListing}
            handleOk={UpdateAssetListing}
            handleCancel={showaddAssetListingModal}
            loading={addNewAssetListingButtonLoading}
            form={addAssetListingForm}
            assets={assets}
            selectedRowKeys={selectedRowKey}
            selectedRow={selectedRow}
          />
        </>
      ) : null}
      <ListingModalContentComponent
        visible={isShowPreviewModalPost}
        onOk={handleIsShowPreviewModalStatusPost}
        content={postHTMLcontent}
      />
    </>
  );
};
