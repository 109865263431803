import React, { useEffect, useState } from "react";
import { Divider, PageHeader, Form } from "antd";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { CityListComponent } from "../sub_components/cities/CityListComponent";
import { CitiesFunctionalityComponent } from "../sub_components/cities/CitiesFunctionalityComponent";
import { CityFormsModalFunctionalityComponent } from "../sub_components/cities/CityFormsModalFunctionalityComponent";
import { useFetchCities, useUpdateCity } from "../../hooks/cities/useCityAPI";
import { useQueryFilterReducer } from "../../hooks/useQueryFilterReducer";
import { ModulesSelectAndColumnTagsComponent } from "../ModulesSelectAndColumnTagsComponent";
import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";
import { useFetchProvincesFunction } from "../../hooks/provinces/useProvinceAPI";
import useCreateCity from "../../hooks/cities/useCreateCity";
import useFetchUrbans from "../../hooks/urbans/useFetchUrbans";

export const CityComponent = () => {
  const [createCityForm] = Form.useForm();
  const [updateCityForm] = Form.useForm();
  const [cityCreationModalStatus, setCityCreationModalStatus] = useState(false);
  const [cityUpdateModalStatus, setCityUpdateModalStatus] = useState(false);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [provincesQuery, setProvincesQuery] = useState({
    page: 1,
    limit: 10,
    selectedProvinceId: selectedRow?.province_id,
  });

  const [provinceVariables, setProvinceVariables] = useState([]);
  const [provinces, persistentProvinces, setShouldPersistProvince] =
    provinceVariables;

  const [fetchProvincesFunction, isLoadingProvinces] =
    useFetchProvincesFunction();

  const {
    queryFilters,
    actionsQueryFilters,
    onSearchValueChange,
    onPaginationChange,
    onSortChange,
    columnFilterTags,
    onClearColumnFilters,
    onLoadColumnFilterTags,
    resetQueryFilters,
  } = useQueryFilterReducer();
  const { CreateCity } = useCreateCity();
  const { urbans } = useFetchUrbans();

  const [citiesData, isLoadingCities] = useFetchCities(true, queryFilters);

  /**
   * Functionalities
   *
   */

  useComponentDidUpdate(() => {
    onLoadColumnFilterTags();
  }, [queryFilters]);

  useEffect(() => {
    if (provincesQuery.selectedProvinceId || cityCreationModalStatus) {
      const getPVariables = async () => {
        const pVariables = await fetchProvincesFunction(true, provincesQuery);
        setProvinceVariables(pVariables);
      };

      getPVariables();
    }
  }, [provincesQuery.selectedProvinceId, cityCreationModalStatus]);

  useEffect(() => {
    const query = {
      page: 1,
      limit: 10,
      selectedProvinceId: selectedRow?.province_id,
    };
    setProvincesQuery(query);
  }, [selectedRow]);

  /**
   *
   * Use effect hooks if any of the data is selected
   * Selected row will populate the form for updating
   *
   */
  useEffect(() => {
    if (selectedRow && cityUpdateModalStatus === true) {
      updateCityForm.setFieldsValue({ ...selectedRow });
    }
  }, [selectedRow, cityUpdateModalStatus, updateCityForm]);

  /**
   *
   * On every selection change, update the row selection state to trigger the use effect hook with row selection dependency
   * It will re-trigger fetch function with the updated row selection and populate forms for updating
   *
   */
  const onSelectionChange = (keys, selectedRows) => {
    setSelectedRow(selectedRows[0]);
    setSelectedRowKey(keys[0]);
  };

  /**
   *
   * Create City function with success validation, connected to useCreateCity hook
   *
   */
  const handleSubmitCityCreation = async () => {
    try {
      const payload = await createCityForm.validateFields();
      const isCreationSuccess = await CreateCity(payload);
      if (isCreationSuccess) {
        createCityForm.resetFields();
        resetQueryFilters();
        onClickCreateCityButton();
        resetSelections();
      }
    } catch (err) {
      console.log(err, "error");
    }
  };

  /**
   *
   * Update City function with success validation, connected to useUpdateCity hook
   *
   */

  const UpdateCity = async () => {
    const payload = await updateCityForm.validateFields();

    const isSuccess = await useUpdateCity(selectedRowKey, payload);
    if (isSuccess) {
      updateCityForm.resetFields();
      resetQueryFilters();
      onClickUpdateCityButton();
      resetSelections();
    }
  };

  const onClickShowMoreProvince = async () => {
    const { current_page, last_page } = persistentProvinces;
    if (current_page < last_page) {
      setShouldPersistProvince(true);
      setProvincesQuery({
        page: parseInt(current_page) + 1,
        limit: 10,
        selectedProvinceId: selectedRow?.province_id,
      });
    }
  };

  /**
   *
   * Helper functions:
   *
   * - Functions that will reset all selections, pagination and restore initial index/fetch
   *
   */

  const resetSelections = () => {
    setSelectedRowKey(null);
    setSelectedRow(null);
  };

  const onClickCreateCityButton = () => {
    setCityCreationModalStatus(!cityCreationModalStatus);
  };

  const onClickUpdateCityButton = () => {
    setCityUpdateModalStatus(!cityUpdateModalStatus);
  };

  return (
    <>
      <PageHeader
        title="City"
        subTitle="City Management"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["City"]} />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <CitiesFunctionalityComponent
          onSearch={onSearchValueChange}
          onClickCreateCityButton={onClickCreateCityButton}
          onClickUpdateCityButton={onClickUpdateCityButton}
          selectedRowKey={selectedRowKey}
          selectedRow={selectedRow}
        />
        <ModulesSelectAndColumnTagsComponent
          onClearColumnFilters={onClearColumnFilters}
          isShowColumnFilterTags={columnFilterTags.length}
          columnFilterTags={columnFilterTags}
          isShowSelection={!!selectedRow}
          onClearSearcheAndSelection={resetSelections}
          tags={[
            {
              color: "processing",
              selection_label: "Selected City",
              value: selectedRow ? `${selectedRow.city_name}` : null,
            },
          ]}
        />
        <CityListComponent
          data={citiesData}
          tableLoading={isLoadingCities}
          onPaginationChange={onPaginationChange}
          selectedRowKey={selectedRowKey}
          onSelection={onSelectionChange}
          currentPagination={queryFilters.page}
          onSortChange={onSortChange}
          setColumnFilter={actionsQueryFilters.changeColumnFilter}
          columnFilter={queryFilters.columnFilter}
        />
      </div>
      <CityFormsModalFunctionalityComponent
        onSubmitCityCreation={handleSubmitCityCreation}
        onCancelCityCreation={onClickCreateCityButton}
        cityModalVisibility={cityCreationModalStatus}
        cityCreationForm={createCityForm}
        onSubmitCityUpdate={UpdateCity}
        onCancelCityUpdate={onClickUpdateCityButton}
        cityUpdatedModalVisibility={cityUpdateModalStatus}
        cityUpdateForm={updateCityForm}
        onClickShowMoreProvince={onClickShowMoreProvince}
        provinces={persistentProvinces}
        isLoadingProvinces={isLoadingProvinces}
        urbans={urbans}
      />
    </>
  );
};
