// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* .ant-tree .ant-tree-treenode {
  display: flex !important;
  align-items: center !important;
  padding: 8px 0px 8px 0 !important;
  outline: auto !important;
  outline-style: solid !important;
  outline-color: whitesmoke !important;
  margin-bottom: 2.8% !important;
  border: cornsilk !important;
  font-size: 16px !important;
  justify-content: center !important;
} */
`, "",{"version":3,"sources":["webpack://./src/dist/style/developers.css"],"names":[],"mappings":"AAAA;;;;;;;;;;;GAWG","sourcesContent":["/* .ant-tree .ant-tree-treenode {\n  display: flex !important;\n  align-items: center !important;\n  padding: 8px 0px 8px 0 !important;\n  outline: auto !important;\n  outline-style: solid !important;\n  outline-color: whitesmoke !important;\n  margin-bottom: 2.8% !important;\n  border: cornsilk !important;\n  font-size: 16px !important;\n  justify-content: center !important;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
