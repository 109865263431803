import React from "react";

import { IslandGroupsFormModalComponent } from "./IslandGroupsFormModalComponent";

export const IslandGroupsFormsModalFunctionalityComponent = (props) => {
  const {
    onSubmitIslandGroupsCreation,
    onCancelIslandGroupsCreation,
    island_groupsModalVisibility,
    island_groupsCreationForm,
    onSubmitIslandGroupsUpdate,
    onCancelIslandGroupsUpdate,
    island_groupsUpdatedModalVisibility,
    island_groupsUpdateForm,
  } = props;

  return (
    <>
      <IslandGroupsFormModalComponent
        form={island_groupsCreationForm}
        isVisible={island_groupsModalVisibility}
        onClickSubmit={onSubmitIslandGroupsCreation}
        onClickCancel={onCancelIslandGroupsCreation}
      />
      <IslandGroupsFormModalComponent
        form={island_groupsUpdateForm}
        isVisible={island_groupsUpdatedModalVisibility}
        onClickSubmit={onSubmitIslandGroupsUpdate}
        onClickCancel={onCancelIslandGroupsUpdate}
        isUpdate={true}
      />
    </>
  );
};
