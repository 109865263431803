import { message } from "antd";
import {
  FetchPartnerTypes,
  CreatePartnerType,
  UpdatePartnerType,
  DeletePartnerType,
} from "../../api/PartnerType";
import { useAPIResponse } from "../useAPIResponse";
import { useGeneralFetch } from "../useGeneralFetch";

export const useFetchPartnerTypes = async (
  isForTable = true,
  query = { page: 1, limit: 10, columnFilter: [] }
) => {
  if (isForTable) {
    const data = await FetchPartnerTypes(query);

    for (const item of data.data.data) {
      item["key"] = item.partner_type_id;
    }

    return data.data;
  }

  const data = await FetchPartnerTypes({
    page: 1,
    limit: null,
    columnFilter: [],
  });

  for (const item of data.data.data) {
    item["key"] = item.partner_type_id;
  }

  return data.data.data;
};

export const useFetchPartnerTypesSelect = (
  isForTable = true,
  query = { page: 1, limit: 10, columnFilter: [] }
) => {
  return useGeneralFetch(
    isForTable,
    query,
    FetchPartnerTypes,
    "partner_type_id"
  );
};

export const useCreatePartnerType = async (data) => {
  await message.loading("Creating PartnerType..", 2);
  const response = await CreatePartnerType(data);

  return useAPIResponse(response);
};

export const useUpdatePartnerType = async (partner_type_id, payload) => {
  await message.loading("Updating PartnerType..", 2);
  const response = await UpdatePartnerType(partner_type_id, payload);

  return useAPIResponse(response);
};

export const useDeletePartnerType = async (partner_type_id) => {
  await message.loading("Updating PartnerType..", 2);
  const response = await DeletePartnerType(partner_type_id);

  return useAPIResponse(response);
};
