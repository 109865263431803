import { useFetch } from "../hooks/useFetch";
import { useCreate } from "../hooks/useCreate";
import { useUpdate } from "../hooks/useUpdate";
import {
  FETCH_PROPERTY_DEVELOPER_URL,
  CREATE_PROPERTY_DEVELOPER_URL,
  UPDATE_PROPERTY_DEVELOPER_URL,
  DELETE_PROPERTY_DEVELOPER_URL,
  UPDATE_PROPERTY_DEVELOPERS_PIVOT_URL,
  ARCHIVE_DEVELOPER_URL,
  RESTORE_DEVELOPER_URL,
  GET_ALL_DEVELOPERS,
} from "../api/routes/property_developers";
import { useDelete } from "../hooks/useDelete";

export const FetchPropertyDevelopers = async (query) => {
  try {
    return await useFetch(FETCH_PROPERTY_DEVELOPER_URL, query);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const GetAllDevelopers = async (query) => {
  try {
    return await useFetch(GET_ALL_DEVELOPERS, query);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const CreatePropertyDeveloper = async (payload) => {
  try {
    return await useCreate(CREATE_PROPERTY_DEVELOPER_URL, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const UpdatePropertyDeveloper = async (partner_id, payload) => {
  try {
    return await useUpdate(
      `${UPDATE_PROPERTY_DEVELOPER_URL}/${partner_id}`,
      payload
    );
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const DeletePropertyDeveloper = async (partner_id) => {
  try {
    return await useDelete(`${DELETE_PROPERTY_DEVELOPER_URL}/${partner_id}`);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const UpdatePropertyDeveloperProjectsPivot = async (
  partner_id,
  payload
) => {
  try {
    const data = await useUpdate(
      `${UPDATE_PROPERTY_DEVELOPERS_PIVOT_URL}/${partner_id}`,
      payload
    );
    return data;
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const ArchiveDeveloper = async (developer_id, payload) => {
  try {
    return await useUpdate(`${ARCHIVE_DEVELOPER_URL}/${developer_id}`, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const RestoreDeveloper = async (developer_id, payload) => {
  try {
    return await useUpdate(`${RESTORE_DEVELOPER_URL}/${developer_id}`, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const UploadDeveloperImages = async (payload) => {
  try {
    const data = await useCreate(
      `${process.env.REACT_APP_API_URL}/property_developers/upload`,
      payload
    );
    return data;
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
