import React from "react";
import { ModulesFunctionalitiesComponent } from "../../ModulesFunctionalitiesComponent";
import { DeleteOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import extractPermission from "../../../dist/functions/extractPermission";

export const PropertyProjectsFunctionalityComponent = ({
  onClickCreatePropertyProjectButton,
  onClickUpdatePropertyProjectButton,
  onClickDeletePropertyProjectButton,
  selectedRowKey,
  permissions,
}) => {
  const isHideProjectCreate = extractPermission(
    permissions,
    "CREATE_PROPERTY_PROJECT"
  );

  const isHideProjectUpdate = extractPermission(
    permissions,
    "UPDATE_PROPERTY_PROJECT"
  );

  const isHideProjectDelete = extractPermission(
    permissions,
    "DELETE_PROPERTY_PROJECT"
  );

  const functionalitiesComponent = [
    {
      component_type: "button",
      type: "primary",
      icon: <EditOutlined />,
      _onClick: onClickCreatePropertyProjectButton,
      hidden: isHideProjectCreate,
      label: "Create Property Project",
      size: "default",
    },
    {
      component_type: "button",
      type: "default",
      icon: <SaveOutlined />,
      _onClick: onClickUpdatePropertyProjectButton,
      hidden: isHideProjectUpdate,
      label: "Update Property Project",
      size: "default",
      is_hidden: selectedRowKey ? false : true,
    },
    {
      component_type: "button",
      type: "default",
      icon: <DeleteOutlined />,
      _onClick: onClickDeletePropertyProjectButton,
      hidden: isHideProjectDelete,
      label: "Delete Property Project",
      size: "default",
      is_hidden: selectedRowKey ? false : true,
      danger: true,
    },
  ];

  return (
    <>
      <ModulesFunctionalitiesComponent
        functionalitiesComponent={functionalitiesComponent}
        alignment="right"
        spaceSize="small"
      />
    </>
  );
};
