import React, { useEffect, useState } from "react";
import { Divider, PageHeader, Form, Modal } from "antd";

import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { IslandGroupsListComponent } from "../sub_components/island_groups/IslandGroupsListComponent";
import { IslandGroupsFunctionalityComponent } from "../sub_components/island_groups/IslandGroupsFunctionalityComponent";
import { IslandGroupsFormsModalFunctionalityComponent } from "../sub_components/island_groups/IslandGroupsFormsModalFunctionalityComponent";
import {
  useCreateIslandGroup,
  useDeleteIslandGroup,
  useFetchIslandGroups,
  useUpdateIslandGroup,
} from "../../hooks/island_groups/useIslandGroupAPI";

import { useQueryFilterReducer } from "../../hooks/useQueryFilterReducer";
import { ModulesSelectAndColumnTagsComponent } from "../ModulesSelectAndColumnTagsComponent";
import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";

import { ExclamationCircleOutlined } from "@ant-design/icons";

const { confirm } = Modal;
const initialIslandGroupsData = { page: 1, total: 0, data: [] };

export const IslandGroupsComponent = () => {
  const [createIslandGroupsForm] = Form.useForm();
  const [updateIslandGroupsForm] = Form.useForm();
  const [island_groupsCreationModalStatus, setIslandGroupsCreationModalStatus] =
    useState(false);
  const [island_groupsUpdateModalStatus, setIslandGroupsUpdateModalStatus] =
    useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const {
    queryFilters,
    actionsQueryFilters,
    onPaginationChange,
    onSortChange,
    columnFilterTags,
    onClearColumnFilters,
    onLoadColumnFilterTags,
    resetQueryFilters,
  } = useQueryFilterReducer();

  const [island_groupsData, isLoadingIslandGroups] = useFetchIslandGroups(
    true,
    queryFilters
  );

  /**
   * Functionalities
   *
   */

  const DeleteIslandGroups = async () => {
    const isSuccess = await useDeleteIslandGroup(selectedRowKey);
    if (isSuccess) {
      resetSelections();
      resetQueryFilters();
    }
  };

  useComponentDidUpdate(() => {
    onLoadColumnFilterTags();
  }, [queryFilters]);

  /**
   *
   * Use effect hooks if any of the data is selected
   * Selected row will populate the form for updating
   *
   */
  useEffect(() => {
    if (selectedRow && island_groupsUpdateModalStatus === true) {
      updateIslandGroupsForm.setFieldsValue(selectedRow);
    }
  }, [selectedRow, island_groupsUpdateModalStatus, updateIslandGroupsForm]);

  /**
   *
   * On every selection change, update the row selection state to trigger the use effect hook with row selection dependency
   * It will re-trigger fetch function with the updated row selection and populate forms for updating
   *
   */
  const onSelectionChange = (keys, selectedRows) => {
    setSelectedRow(selectedRows[0]);
    setSelectedRowKey(keys[0]);
  };

  /**
   *
   * Create IslandGroups function with success validation, connected to useCreateIslandGroup hook
   *
   */

  const CreateIslandGroups = async () => {
    const payload = await createIslandGroupsForm.validateFields();

    const isSuccess = await useCreateIslandGroup(payload);
    if (isSuccess) {
      createIslandGroupsForm.resetFields();
      resetQueryFilters();
      onClickCreateIslandGroupsButton();
      resetSelections();
    }
  };

  /**
   *
   * Update IslandGroups function with success validation, connected to useUpdateIslandGroup hook
   *
   */

  const UpdateIslandGroups = async () => {
    const payload = await updateIslandGroupsForm.validateFields();

    const isSuccess = await useUpdateIslandGroup(selectedRowKey, payload);

    if (isSuccess) {
      updateIslandGroupsForm.resetFields();
      resetQueryFilters();
      onClickUpdateIslandGroupsButton();
      resetSelections();
    }
  };

  const OnClickDeleteIslandGroups = async () => {
    confirm({
      title: `Are you sure, you want to remove this island_groups?`,
      icon: <ExclamationCircleOutlined />,
      content: `All information attached to this island_groups will be removed`,
      onOk() {
        DeleteIslandGroups();
      },
    });
  };

  /**
   *
   * Helper functions:
   *
   * - Functions that will reset all selections, pagination and restore initial index/fetch
   *
   */

  const resetSelections = () => {
    setSelectedRowKey(null);
    setSelectedRow(null);
  };

  const onClickCreateIslandGroupsButton = () => {
    setIslandGroupsCreationModalStatus(!island_groupsCreationModalStatus);
  };

  const onClickUpdateIslandGroupsButton = () => {
    setIslandGroupsUpdateModalStatus(!island_groupsUpdateModalStatus);
  };

  return (
    <>
      <PageHeader
        title="Island Groups"
        subTitle="Island Groups Management"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["Island Groups"]} />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <IslandGroupsFunctionalityComponent
          onClickCreateIslandGroupsButton={onClickCreateIslandGroupsButton}
          onClickUpdateIslandGroupsButton={onClickUpdateIslandGroupsButton}
          onClickDeleteIslandGroupsButton={OnClickDeleteIslandGroups}
          selectedRowKey={selectedRowKey}
          selectedRow={selectedRow}
        />

        <ModulesSelectAndColumnTagsComponent
          onClearColumnFilters={onClearColumnFilters}
          isShowColumnFilterTags={columnFilterTags.length}
          columnFilterTags={columnFilterTags}
          isShowSelection={!!selectedRow}
          onClearSearcheAndSelection={resetSelections}
          tags={[
            {
              color: "processing",
              selection_label: "Selected Island Group Name",
              value: selectedRow ? `${selectedRow.island_group_name}` : null,
            },
          ]}
        />
        <IslandGroupsListComponent
          data={island_groupsData}
          tableLoading={isLoadingIslandGroups}
          onPaginationChange={onPaginationChange}
          selectedRowKey={selectedRowKey}
          onSelection={onSelectionChange}
          currentPagination={queryFilters.page}
          onSortChange={onSortChange}
          setColumnFilter={actionsQueryFilters.changeColumnFilter}
          columnFilter={queryFilters.columnFilter}
        />
      </div>
      <IslandGroupsFormsModalFunctionalityComponent
        onSubmitIslandGroupsCreation={CreateIslandGroups}
        onCancelIslandGroupsCreation={onClickCreateIslandGroupsButton}
        island_groupsModalVisibility={island_groupsCreationModalStatus}
        island_groupsCreationForm={createIslandGroupsForm}
        onSubmitIslandGroupsUpdate={UpdateIslandGroups}
        onCancelIslandGroupsUpdate={onClickUpdateIslandGroupsButton}
        island_groupsUpdatedModalVisibility={island_groupsUpdateModalStatus}
        island_groupsUpdateForm={updateIslandGroupsForm}
      />
    </>
  );
};
