import React from "react";
import { Space, Button, Input } from "antd";
import { FormOutlined, DeleteOutlined } from "@ant-design/icons";
import useIsMobile from "../../../hooks/useIsMobile";
import useIsTablet from "../../../hooks/useIsTablet";

export const AssetFunctionComponent = ({
  selectedAssets,
  handleUpdateModalVisibility,
  OnClickDeleteAsset,
  onSearch,
  permissions = [],
}) => {
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const { Search } = Input;

  return (
    <>
      <div
        style={{
          textAlign: isMobile || isTablet ? "center" : "right",
          width: "100%",
          marginBottom: "2%",
        }}
      >
        <Space
          size={"small"}
          direction={isMobile || isTablet ? "vertical" : "horizontal"}
        >
          <Search
            placeholder="Search Asset Properties"
            onSearch={onSearch}
            enterButton
          />

          {selectedAssets ? (
            <>
              <Button
                hidden={false}
                type="default"
                onClick={handleUpdateModalVisibility}
                icon={<FormOutlined />}
                block={isMobile ? true : false}
              >
                Update Property
              </Button>

              <Button
                hidden={false}
                type="default"
                onClick={OnClickDeleteAsset}
                icon={<DeleteOutlined />}
                block={isMobile ? true : false}
                danger
              >
                Delete Property
              </Button>
            </>
          ) : null}
        </Space>
      </div>
    </>
  );
};
