const createUserTransformer = (payload) => {
  return {
    users_uuid: payload.users_uuid,
    users_first_name: payload.users_first_name,
    users_middle_name: payload.users_middle_name,
    users_password: payload.users_password,
    users_last_name: payload.users_last_name,
    users_username: payload.users_username,
    users_email: payload.users_email,
    users_gender: payload.users_gender,
    users_referrer: payload.users_referrer,
    users_mobile: payload.users_mobile,
    users_status: payload.users_status,
    users_is_verified: payload.users_is_verified,
    users_address: `${payload.address_line_1} ${payload.address_line_2} ${payload.city} ${payload.state_province_region}`,
    city: payload.city,

    user_attached_role: payload.user_attached_role,
  };
};

export { createUserTransformer };
