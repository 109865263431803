import { useEffect, useRef } from "react";

export const useComponentDidUpdate = (callback, dependencies) => {
  const hasMounted = useRef(false);

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
    } else {
      callback();
    }
  }, dependencies);
};
