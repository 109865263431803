import { Tag } from "antd";
import { useEffect, useState } from "react";

const columns = [
  {
    title: "Id",
    dataIndex: "listing_id",
    key: "listing_id",
    fixed: "left",
    // width: 100,
  },
  {
    title: "Listing Name",
    dataIndex: ["partners", "partner_name"],
    key: ["partners", "partner_name"],
    fixed: "left",
  },
  {
    title: "Status",
    dataIndex: "listing_status",
    key: "listing_status",
    // width: 100,
    fixed: "left",
    render: (listing_status) => {
      return listing_status === 1 ? (
        <Tag color={"green"}>Active</Tag>
      ) : (
        <Tag color={"red"}>Not Active</Tag>
      );
    },
  },
  {
    title: "Item Count",
    dataIndex: "asset_listing",
    render: (asset_listing) => {
      return asset_listing.length;
    },
  },
  {
    title: "Listing Started At",
    dataIndex: "listing_started_at",
    key: "listing_started_at",
  },
];

export default function useGetListingColumns() {
  const [tableColumns, setColumns] = useState(columns);

  useEffect(() => {
    setColumns(columns);
  }, []);

  return tableColumns;
}
