import React from "react";
import { Form, Modal, Input, Select } from "antd";
import { FormRules } from "../../../dist/functions/formRules";

const { Option } = Select;
const { REQUIRED_OPTION_GROUP, REQUIRED_OPTION_VALUE } = FormRules;

export const OptionUpdateModalComponent = (props) => {
  const { showModal, onCancel, OnFinish, form, selectedRow } = props;
  return (
    <>
      {selectedRow ? (
        <Modal
          title="Update Option"
          visible={showModal}
          onOk={OnFinish}
          destroyOnClose={true}
          onCancel={onCancel}
        >
          <Form form={form} layout="vertical">
            <Form.Item
              name="option_value"
              label="Option Value"
              key="option_value"
              rules={[REQUIRED_OPTION_VALUE]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
            <Form.Item
              name={"option_group"}
              label="Option Group"
              rules={[REQUIRED_OPTION_GROUP]}
            >
              <Select placeholder="Select Option Group">
                <Option value={"admin"}>Admin</Option>
                <Option value={"marketing"}>Marketing</Option>
                <Option value={"developer"}>Developer</Option>
              </Select>
            </Form.Item>
          </Form>
        </Modal>
      ) : null}
    </>
  );
};
