import React, { useState, useEffect } from "react";
import { Divider, PageHeader, Form, message } from "antd";
import { useCreate } from "../../hooks/useCreate";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { CreatingRolesFormComponent } from "../sub_components/roles_access/CreatingRolesFormComponent";
import { RolesAccessFunctionalitiesComponent } from "../sub_components/roles_access/RolesAccessFunctionalitiesComponent";
import { PermissionRolesResultsComponent } from "../sub_components/roles_access/PermissionRolesResultsComponent";
import { EditRolesPermissionComponent } from "../sub_components/roles_access/EditRolesPermissionComponent";
import { AddUserToRoleComponent } from "../sub_components/roles_access/AddUserToRoleComponent";
import useGetRolesWithPermissions from "../../hooks/roles/useGetRoleswithPermissions";
import useGetRolesWithPermissionsIndex from "../../hooks/roles/useGetRolesWithPermissionsIndex";
import useGetUpdateRolesPermissions from "../../hooks/roles/useGetUpdateRolesPermissions";
import useGetPlatforms from "../../hooks/platforms/useGetPlatforms";

export const RolesAccessComponent = (props) => {
  const [form] = Form.useForm();
  const [editPermissionForm] = Form.useForm();
  const [addUserToRole] = Form.useForm();
  const [rolesSearchForm] = Form.useForm();
  const [platforms, setPlatforms] = useState([]);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [queriedModules, setCurrentModules] = useState([]);
  const [roles, setRoles] = useState([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);
  const [editPermissionsModal, setEditPermissionsModal] = useState(false);
  const [addUserToRoleModal, setAddUserToRoleModal] = useState(false);
  const [validatedEmailList, setValidatedEmailList] = useState([]);

  const handlePlatformSelectChange = (values) => {
    setSelectedPlatform(values);
  };

  const GetPlatformList = async () => {
    const query = {
      page: 1,
      limit: null,
    };

    const data = await useGetPlatforms(query, false);
    setPlatforms(data);
  };

  const GetRolesList = async () => {
    setTableLoading(true);
    const data = await useGetRolesWithPermissionsIndex();
    setRoles(data.data);
    setTableLoading(false);
  };

  const handleEditModalVisibleOnClick = () => {
    setEditPermissionsModal(false);
  };

  const handleModalVisibleOnClick = () => {
    setModalVisible(!isModalVisible);
  };

  const OnFinish = async () => {
    const values = await form.validateFields();
    const permissions = [];
    for (const value in values) {
      if (value.includes("platform_name")) {
        const permission_idx_id = value.split("/")[0];
        const permission = {
          permission_index_id: parseInt(permission_idx_id),
          has_access:
            typeof values[value] === "undefined" ? false : values[value],
        };
        permissions.push(permission);
      }
    }

    const payload = {
      name: values.name,
      description: values.description,
      permissions: permissions,
    };

    const data = await CreateRolesAccessByPlatform(payload);
    message
      .loading("Creating Roles with Attached Permissions..", 2)
      .then(() => {
        if (data.status === 201) {
          message.success("Successfuly Created Roles and Permissions");
          form.resetFields();
          GetPlatformList();
          GetRolesList();
          handleModalVisibleOnClick();
          setCurrentModules([]);
          setSelectedPlatform(null);
          return;
        }

        message.error(
          "Error Creating Roles with Permissions, kindly seek help from support team"
        );
      });
  };

  const CreateRolesAccessByPlatform = async (payload) => {
    const data = await useCreate(
      `${process.env.REACT_APP_API_URL}/roles/${selectedPlatform}`,
      payload
    );
    return data;
  };

  useEffect(() => {
    GetPlatformList();
  }, []);

  useEffect(() => {
    if (selectedPlatform === null) return;
    const currentModules = platforms.filter(
      (platform) => selectedPlatform === platform.id
    );

    setCurrentModules(currentModules[0].modules);
  }, [selectedPlatform]);

  const onClickSubmitSearch = async () => {
    const values = await rolesSearchForm.validateFields();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const permissions = await useGetRolesWithPermissions(
      "for_creation",
      values
    );

    if (permissions) {
      setPermissions(permissions.data);
      return;
    }

    setPermissions([]);
  };

  const OnClickEditRolePermission = (value) => {
    setSelectedRole(value);
    setEditPermissionsModal(true);
    editPermissionForm.resetFields();
  };

  const OnClickAddUserOnRole = (value) => {
    console.log(value, "value for adding user on the role");
    setSelectedRole(value);
    setAddUserToRoleModal(true);
  };

  const OnClickSubmitAddUserToRole = async () => {
    const values = await addUserToRole.validateFields();
    const { email_list } = values;

    var email_pattern = /\S+@\S+\.\S+/;
    const email_list_arr = email_list.split(",");

    await message.loading("validating email addresses");

    for (const email of email_list_arr) {
      const valid_email = email_pattern.test(email);
      if (!valid_email) {
        await message.warning(
          `validation error, found incorrect email address format : "${email}"`,
          6
        );

        await message.warning(
          "please input right format of email address, and submit again to validate",
          2
        );
        return;
      }
    }

    const email_list_arr_with_validations = [];

    for (const email of email_list_arr) {
      const email_with_validation = {
        email: email,
        format_validation: "success",
        existing_role_warning: "pending",
      };

      email_list_arr_with_validations.push(email_with_validation);
    }

    setValidatedEmailList(email_list_arr_with_validations);
    await message.info(
      "validation complete, all email address is in right format",
      2
    );
  };

  const OnClickSubmitEditPermissions = async () => {
    const values = await editPermissionForm.validateFields();

    const permissions = [];
    for (const key in values) {
      const objectPermission = {
        module_id: key.split("-")[2],
        permission_index_id: key.split("-")[1],
        has_access: values[key],
      };

      permissions.push(objectPermission);
    }

    const payload = {
      roles_id: selectedRole.id,
      permissions,
    };

    const success = await useGetUpdateRolesPermissions(payload);
    if (success) {
      setSelectedRole(null);
      setEditPermissionsModal(false);
      editPermissionForm.resetFields();
      GetRolesList();
    }
  };

  return (
    <>
      <PageHeader
        className="site-page-header"
        title="ROLES"
        subTitle="USER ROLES MANAGEMENT MODULE"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["Administration", "Roles"]} />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <RolesAccessFunctionalitiesComponent
          handleModalVisibleOnClick={handleModalVisibleOnClick}
          rolesSearchForm={rolesSearchForm}
          roles={roles}
          onClickSubmitSearch={onClickSubmitSearch}
          GetRolesList={GetRolesList}
          loading={tableLoading}
          permissions={props.permissions}
        />
        <PermissionRolesResultsComponent
          roles_modules_and_permissions={permissions}
          roles={roles}
          onClickEditRolePermission={OnClickEditRolePermission}
          OnClickAddUserOnRole={OnClickAddUserOnRole}
          tableLoading={tableLoading}
        />
      </div>
      <CreatingRolesFormComponent
        isModalVisible={isModalVisible}
        OnFinish={OnFinish}
        handlePlatformSelectChange={handlePlatformSelectChange}
        platforms={platforms}
        queriedModules={queriedModules}
        handleModalVisibleOnClick={handleModalVisibleOnClick}
        form={form}
      />
      <EditRolesPermissionComponent
        data={selectedRole}
        visible={editPermissionsModal}
        form={editPermissionForm}
        onSubmit={OnClickSubmitEditPermissions}
        onCancel={handleEditModalVisibleOnClick}
      />
      <AddUserToRoleComponent
        visible={addUserToRoleModal}
        onClickFinish={OnClickSubmitAddUserToRole}
        data={selectedRole}
        form={addUserToRole}
        validatedEmailList={validatedEmailList}
      />
    </>
  );
};
