import { /*useEffect,*/ useRef, useState } from "react";
// import { useComponentDidUpdate } from "./useComponentDidUpdate";

const initialTableState = { data: [], total: 0 };


export const useGeneralFetchFunction = ()=> {
  
  // const [data, setData] = useState(initialTableState);
  const [loading, setLoading] = useState(false);

  const rawData = useRef(initialTableState)
  // used for options select/dropdown
  const persistentData = useRef(initialTableState);
  const shouldPersist = useRef(true);
  const shouldResetPersist = useRef(false);

  const setShouldPersist = (param) => {
    shouldPersist.current = param;
  };

  const setShouldResetPersist = (param) => {
    shouldResetPersist.current = param;
  };

  const setRawData = (param) => {
    rawData.current = param;
  };

  const setPersistentData = (param) => {
    persistentData.current = param;
  };
  
  const generalFetchFunction = async(isForTable, query, fetcherFunction, idKey) => {

    setLoading(true);

    const mainData = await fetcherFunction(query);
    const { data, ...metaData } = mainData.data;
    // console.log("MAIN DATA IS: ", mainData)

    // add "key" property
    const dataWithKeys = data.map((item) => {
      item["key"] = item[idKey];
      return item;
    });

    setRawData(isForTable
      ? { ...metaData, data: dataWithKeys }
      : dataWithKeys);

    // reset the persistent data to default rawData whenever the page is 1
    // if(rawData.current?.current_page === 1) setPersistentData(rawData.current)

    // get the selectedProvinceData
    persistentData.current.selectedProvinceData = rawData.current?.selectedProvinceData


    setTimeout(() => {
      setLoading(false);
    }, 800);

    // console.log("PDATA= " , persistentData.current)
    if (isForTable && shouldPersist.current && rawData.current?.data?.length && rawData.current?.current_page !== persistentData?.current?.current_page) {
      // console.log("Persisting data..")
      setShouldPersist(false);
      setPersistentData({
        ...rawData.current,
        data: [...persistentData.current.data, ...rawData.current?.data],
      });
    }

    if (shouldResetPersist.current) {
      setPersistentData(data);
      setShouldResetPersist(false);
    }

    // console.log("TO RETURN DATA: ", rawData)
    return [
      rawData.current,
      persistentData.current,
      setShouldPersist,
      setShouldResetPersist,
    ];
  };

  return [generalFetchFunction,loading]
}