import React from "react";
import { ModulesTableComponent } from "../../ModulesTableComponent";
import useGetPropertCategoriesColumn from "../../../hooks/property_categories/useGetPropertCategoriesColumn";
import useColumnFilter from "../../../hooks/useColumnFilter";
import useFilteredColumns from "../../../hooks/useFilteredColumns";

export const PropertyCategoryListComponent = ({
  data = { data: [], total: 0 },
  onPaginationChange,
  currentPagination,
  onSelection,
  selectedRowKey,
  onSortChange,
  columnFilter,
  setColumnFilter,
  tableLoading,
  onClickRestore,
  permissions = [],
}) => {
  const reviewsColumn = useGetPropertCategoriesColumn(
    onClickRestore,
    permissions
  );

  const { getColumnFilterProps } = useColumnFilter(
    columnFilter,
    setColumnFilter
  );

  let newCols = useFilteredColumns(reviewsColumn, getColumnFilterProps);

  return (
    <ModulesTableComponent
      data={data}
      onPaginationChange={onPaginationChange}
      onSelection={onSelection}
      selectedRowKey={selectedRowKey}
      tableColumns={newCols || []}
      currentPagination={currentPagination}
      columnFilter={columnFilter}
      setColumnFilter={setColumnFilter}
      onSortChange={onSortChange}
      tableLoading={tableLoading}
    />
  );
};
