import { useState, useEffect } from "react";
import {
  FetchBarangays,
  CreateBarangay,
  UpdateBarangay,
  DeleteBarangay,
} from "../../api/Barangays";
import { message } from "antd";
import { useGeneralFetch } from "../useGeneralFetch";

const initialTableState = { data: [], total: 0 };

export const useFetchBarangays = (query, isForTable = false) => {
  const [data, setData] = useState(initialTableState);

  useEffect(() => {
    const ExecuteFetchBarangaysAPI = async () => {
      const barangays = await FetchBarangays(query);
      const { data, ...metaData } = barangays.data;
      const barangaysResponseArray = barangays.data.data.map((item) => {
        item["key"] = item.barangay_id;
        return item;
      });

      setData(() => {
        return isForTable
          ? { ...metaData, data: barangaysResponseArray }
          : barangaysResponseArray;
      });
    };

    ExecuteFetchBarangaysAPI();
  }, [query, isForTable]);

  return data;
};

export const useFetchBarangaysHookOption = (
  isForTable = true,
  query = { page: 1, limit: null, columnFilter: [] }
) => {
  return useGeneralFetch(isForTable, query, FetchBarangays, "barangay_id");
};

export const useCreateBarangay = async (payload) => {
  await message.loading("Creating Barangay", 2);
  const response = await CreateBarangay(payload);

  if (response.status !== 201) {
    await message.error("Error Creating Barangay", 2);
    await message.info("Seek help from the dev team for the assistance");
    return false;
  }

  await message.success(response.data.message, 2);
  return true;
};

export const useUpdateBarangay = async (id, payload) => {
  await message.loading("Updating Barangay", 2);
  const response = await UpdateBarangay(id, payload);

  if (response.status !== 200) {
    await message.error("Error Updating Barangay", 2);
    await message.info("Seek help from the dev team for the assistance");
    return false;
  }

  await message.success(response.data.message, 2);
  return true;
};
export const useDeleteBarangay = async (id) => {
  await message.loading("Deleting Barangay", 2);
  const response = await DeleteBarangay(id);

  if (response.status !== 200) {
    await message.error("Error Deleting Barangay", 2);
    await message.info("Seek help from the dev team for the assistance");
    return false;
  }

  await message.success("Successfuly Deleted Barangay", 2);
  return true;
};
