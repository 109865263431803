import React from "react";
import { Table } from "antd";

const { Column } = Table;

export const AgentMetaFieldListDynamicComponent = ({ data = {} }) => {
  const _data = [];
  for (const i in data) {
    let dataObj = { key: i, value: data[i] };
    _data.push(dataObj);
  }

  return (
    <div style={{ padding: "2%" }}>
      <Table
        width="80%"
        dataSource={_data}
        size="small"
        pagination={false}
        bordered={true}
      >
        <Column name="key" dataIndex="key" title="Key" />
        <Column name="value" dataIndex="value" title="Value" />
      </Table>
    </div>
  );
};
