export default function GetAssetsChildColumns(assets) {
  return {
    UUID: assets.asset_uuid,
    "Asset Status": assets.asset_status,
    Street: assets.street,
    Barangay: assets.barangay,
    Subdivision: assets.subdivision,
    City: assets.cities ? assets.cities.city_name : null,
    Developer: assets.developers ? assets.developers.partner_name : null,
    Partner: assets.partners ? assets.partners.partner_name : null,
    Project: assets.property_projects
      ? assets.property_projects.property_projects_name
      : null,
    "Date of Creation": assets.created_at,
    "Last Modified Date": assets.updated_at,
  };
}
