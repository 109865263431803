import React, { useState, useEffect } from "react";
import { Modal, Form, message, Steps, Button, Divider } from "antd";
import { RightOutlined, LeftOutlined, SaveOutlined } from "@ant-design/icons";
import { AssetGenralInformationFormComponent } from "./AssetGenralInformationFormComponent";
import { AssetsCustomFieldInformation } from "./AssetsCustomFieldInformation";
import { AssetAddressInformationComponent } from "./AssetAddressInformationComponent";
import { useFetchCustomFields } from "../../../hooks/custom_field/useCustomFieldAPI";
import { useFetchPropertySubCategory } from "../../../hooks/property_categories/usePropertySubCategoriesAPI";
import { useUpdateAssetMetaField } from "../../../hooks/asset_meta_field/useAssetMetaFieldAPI";
import { UpdateAsset } from "../../../api/assets/UpdateAsset";
import { UpdateAssetMetaFieldsByAssetCustom } from "../../../api/AssetMetaField";

const { Step } = Steps;
export const AssetUpdateComponent = (props) => {
  const [fields, setFields] = useState([]);
  const [propertyCategories, setPropertyCategories] = useState([]);

  const {
    assetId,
    updateModalVisibility,
    handleUpdateModalVisibility,
    GetAssetsList,
    setCurrentPagination,
    onClearSelection,
    form,
    partners = { data: [], total: 0 },
    agents = { data: [], total: 0 },
    cities = { data: [], total: 0 },
    developers = { data: [], total: 0 },
    projects = { data: [], total: 0 },
    provinces = { data: [], total: 0 },
    barangays = { data: [], total: 0 },
    onClickShowMoreAgent,
    onClickShowMoreCity,
    onClickShowMorePartner,
    onClickShowMoreDeveloper,
    onClickShowMoreProject,
    onClickShowMoreProvince,
    onClickShowMoreBarangay,
    setShouldResetPersistCities,
    setShouldResetPersistBarangay,
    setBarangaysQuery,
    setProvincesQuery,
    setCitiesQuery,
    currentStep,
    setCurrentStep,
    stepsNavigator,
    selectedAssets,
  } = props;

  const FetchCustomFields = async () => {
    const query = { page: 1, limit: "ALL", filter: null };
    const data = await useFetchCustomFields(false, query);
    // console.log(data)
    setFields(data);
  };

  const FetchPropertyCategories = async () => {
    const query = { page: 1, limit: "ALL", filter: null };
    const data = await useFetchPropertySubCategory(query, false);
    setPropertyCategories(data);
  };

  useEffect(() => {
    FetchCustomFields();
    FetchPropertyCategories();
  }, []);

  const UpdateAssetMetaField = async (asset_meta_field_id, payload) => {
    useUpdateAssetMetaField(asset_meta_field_id, payload);
  };

  const HandleUpdateAsset = async () => {
    try {
      const values = await form.validateFields();

      /**
       * Update Asset Meta Fields first
       * TODO - Refractor
       *
       * Add validation
       * - Check if the field is touched/change to update
       *
       */
      for (const custom_field of fields) {
        const metaFieldVal = values[custom_field["asset_custom_field_name"]];

        if (metaFieldVal !== undefined) {
          const AssetMetaFieldPayload = {
            asset_meta_field_name: custom_field["asset_custom_field_name"],
          };

          if (isNaN(metaFieldVal)) {
            AssetMetaFieldPayload["asset_meta_field_text_value"] = metaFieldVal;
            AssetMetaFieldPayload["asset_meta_field_num_value"] = null;
          } else {
            AssetMetaFieldPayload["asset_meta_field_text_value"] =
              metaFieldVal + "";

            AssetMetaFieldPayload["asset_meta_field_num_value"] = metaFieldVal;
          }

          await UpdateAssetMetaFieldsByAssetCustom(
            selectedAssets.asset_id,
            custom_field["asset_custom_field_id"],
            AssetMetaFieldPayload
          );
        }
      }

      const {
        asset_uuid,
        agent_id,
        street,
        subdivision,
        barangay_id,
        city_id,
        province_id,
        developer_id,
        project_id,
        partner_id,
        asset_status,
        longitude,
        latitude,
        price_min,
        price_max,
        assets_condition,
        asset_property_category_id,
        is_featured,
      } = values;

      const data = {
        asset_uuid,
        agent_id,
        street,
        subdivision,
        barangay_id,
        city_id,
        province_id,
        developer_id,
        project_id,
        partner_id,
        asset_status,
        longitude,
        latitude,
        price_min,
        price_max,
        assets_condition,
        asset_property_category_id,
        is_featured,
      };

      const resp = await UpdateAsset(assetId, data);

      if (resp.status === 200) {
        form.resetFields();
        message.success("Successfuly Updated Property General Information");
        handleUpdateModalVisibility();
        GetAssetsList(1, 10);
        setCurrentPagination(1);
        onClearSelection();
        setCurrentStep(1);
        return;
      }
    } catch (errorInfo) {
      for (const errorField of errorInfo.errorFields) {
        let errMessage = "";
        for (const err of errorField.errors) {
          errMessage += err + " ";
        }

        message.error(errMessage);
      }
    }
  };

  const returnModalFooterButtons = () => {
    const footerButtons = [
      <Button
        key="submit_button-cancel"
        type="default"
        size="medium"
        onClick={handleUpdateModalVisibility}
        hidden={currentStep === 1 ? false : true}
        icon={<LeftOutlined />}
      >
        Cancel
      </Button>,
      <Button
        key="submit_button-back"
        type="default"
        size="medium"
        onClick={() => stepsNavigator("backward")}
        hidden={currentStep > 1 ? false : true}
        icon={<LeftOutlined />}
      >
        Back
      </Button>,
      <Button
        key="submit_button-next"
        type="default"
        size="medium"
        onClick={() => stepsNavigator("forward")}
        hidden={currentStep !== 3 && currentStep > 0 ? false : true}
        icon={<RightOutlined />}
      >
        Next
      </Button>,
      <Button
        key="submit_button-submit"
        type="primary"
        size="medium"
        onClick={HandleUpdateAsset}
        hidden={currentStep === 3 ? false : true}
        icon={<SaveOutlined />}
      >
        Submit
      </Button>,
    ];

    return footerButtons;
  };

  return (
    <>
      <Modal
        visible={updateModalVisibility}
        title="Update Property"
        okText="Update"
        cancelText="Cancel"
        onCancel={handleUpdateModalVisibility}
        centered={true}
        width={700}
        destroyOnClose={true}
        footer={returnModalFooterButtons()}
      >
        <div style={{ padding: "4%" }}>
          <Steps size="small" current={currentStep - 1}>
            <Step title="General Information" />
            <Step title="Address Information" />
            <Step title="Custom Fields" />
          </Steps>
          <Divider />
        </div>
        <Form form={form} layout="vertical" name="assets">
          <div hidden={currentStep === 1 ? false : true}>
            <AssetGenralInformationFormComponent
              partners={partners}
              agents={agents}
              developers={developers}
              projects={projects}
              onClickShowMorePartner={onClickShowMorePartner}
              onClickShowMoreAgent={onClickShowMoreAgent}
              onClickShowMoreDeveloper={onClickShowMoreDeveloper}
              onClickShowMoreProject={onClickShowMoreProject}
              selectedAssets={selectedAssets}
              propertyCategories={propertyCategories}
            />
          </div>
          <div hidden={currentStep === 2 ? false : true}>
            <AssetAddressInformationComponent
              form={form}
              cities={cities}
              provinces={provinces}
              barangays={barangays}
              onClickShowMoreCity={onClickShowMoreCity}
              onClickShowMoreProvince={onClickShowMoreProvince}
              onClickShowMoreBarangay={onClickShowMoreBarangay}
              setBarangaysQuery={setBarangaysQuery}
              setProvincesQuery={setProvincesQuery}
              setCitiesQuery={setCitiesQuery}
              setShouldResetPersistCities={setShouldResetPersistCities}
              setShouldResetPersistBarangay={setShouldResetPersistBarangay}
            />
          </div>
          <div
            hidden={currentStep === 3 ? false : true}
            style={{ overflow: "auto", height: "600px" }}
          >
            <AssetsCustomFieldInformation fields={fields} />
          </div>
        </Form>
      </Modal>
    </>
  );
};
