import React from "react";
import { Result, Typography, Button, Space } from "antd";
import { CloseCircleOutlined, UserAddOutlined } from "@ant-design/icons";

const { Paragraph, Text } = Typography;
export const ErrorValidationComponent = (props) => {
  const {
    validationRemarks = [],
    agentListForCreation = [],
    partnerListForCreation = [],
    provinceListForCreation = [],
    cityListForCreation = [],
    barangayListForCreation = [],
    onClickAgentCreation,
    onClickPartnerCreation,
    onClickCityCreation,
    onClickProvinceCreation,
    onClickBarangayCreation,
  } = props;
  return (
    <Result
      status="error"
      title="Validation Failed"
      subTitle="Please check and modify the following information before resubmitting."
    >
      <div>
        <Space>
          {agentListForCreation.length > 0 ? (
            <Button
              type="primary"
              size="default"
              icon={<UserAddOutlined />}
              onClick={onClickAgentCreation}
            >
              Create Agent ({agentListForCreation.length})
            </Button>
          ) : null}
          {partnerListForCreation.length > 0 ? (
            <Button
              type="primary"
              size="default"
              icon={<UserAddOutlined />}
              onClick={onClickPartnerCreation}
            >
              Create Partner ({partnerListForCreation.length})
            </Button>
          ) : null}
          {cityListForCreation.length > 0 ? (
            <Button
              type="primary"
              size="default"
              icon={<UserAddOutlined />}
              onClick={onClickCityCreation}
            >
              Create City ({cityListForCreation.length})
            </Button>
          ) : null}
          {provinceListForCreation.length > 0 ? (
            <Button
              type="primary"
              size="default"
              icon={<UserAddOutlined />}
              onClick={onClickProvinceCreation}
            >
              Create Province ({provinceListForCreation.length})
            </Button>
          ) : null}
          {barangayListForCreation.length > 0 ? (
            <Button
              type="primary"
              size="default"
              icon={<UserAddOutlined />}
              onClick={onClickBarangayCreation}
            >
              Create Barangay ({barangayListForCreation.length})
            </Button>
          ) : null}
        </Space>
      </div>
      <br />
      <div className="desc">
        <Paragraph>
          <Text
            strong
            style={{
              fontSize: 16,
            }}
          >
            The Bulk Uploader Validation encountered the following error(s):
          </Text>
          <br />
        </Paragraph>
        <div style={{ overflowY: "auto", height: "400px" }}>
          {validationRemarks.map((rowGroup) => {
            return rowGroup.map((itemRemarks, index) => (
              <Paragraph key={index}>
                <CloseCircleOutlined className="site-result-demo-error-icon" />{" "}
                {itemRemarks}
              </Paragraph>
            ));
          })}
        </div>
      </div>
    </Result>
  );
};
