import React from "react";
import { Modal, Form } from "antd";
import { AddNewPropertyDeveloperProjectsPivotFormComponent } from "./AddNewPropertyDeveloperProjectsPivotFormComponent";
import { ReturnModalFooterComponents } from "../modules/ReturnModalFooterComponents";

export const AddNewPropertyDeveloperProjectsPivotComponent = (props) => {
  const {
    form,
    isVisible,
    handleOk,
    handleCancel,
    loading,
    developers = [],
    onClickShowMoreDeveloper,
    selectedRowKeys,
  } = props;

  return (
    <>
      <Modal
        visible={isVisible}
        title="Add Property Developer Projects Pivot"
        onOk={handleOk}
        onCancel={handleCancel}
        width="60%"
        footer={
          <ReturnModalFooterComponents
            handleCancel={handleCancel}
            handleOk={handleOk}
            loading={loading}
          />
        }
        destroyOnClose={true}
      >
        <Form form={form} layout="vertical">
          <AddNewPropertyDeveloperProjectsPivotFormComponent
            developers={developers}
            onClickShowMoreDeveloper={onClickShowMoreDeveloper}
            selectedRowKeys={selectedRowKeys}
          />
        </Form>
      </Modal>
    </>
  );
};
