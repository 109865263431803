import { useFetch } from "../hooks/useFetch";
import { useCreate } from "../hooks/useCreate";
import { useUpdate } from "../hooks/useUpdate";
import {
  FETCH_MODULE_URL,
  CREATE_MODULE_URL,
  UPDATE_MODULE_URL,
  DELETE_MODULE_URL,
  CREATE_BULK_MODULE_URL,
} from "../api/routes/modules";
import { useDelete } from "../hooks/useDelete";

export const FetchModules = async (query) => {
  try {
    return await useFetch(FETCH_MODULE_URL, query);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const CreateModule = async (payload) => {
  try {
    return await useCreate(CREATE_MODULE_URL, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const CreateBulkModule = async (platform_id, payload) => {
  try {
    return await useCreate(`${CREATE_BULK_MODULE_URL}/${platform_id}`, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const UpdateModule = async (module_id, payload) => {
  try {
    return await useUpdate(`${UPDATE_MODULE_URL}/${module_id}`, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const DeleteModule = async (module_id, payload) => {
  try {
    return await useDelete(`${DELETE_MODULE_URL}/${module_id}`);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
