import { useEffect, useState } from "react";
import { Tag, Button } from "antd";
import { RedoOutlined } from "@ant-design/icons";

const columns = [
  {
    title: "Partner Type ID",
    dataIndex: "partner_type_id",
    key: "partner_type_id",
  },
  {
    title: "Partner Type Name",
    dataIndex: "partner_type_name",
    key: "partner_type_name",
  },
  {
    title: "Partner Type Label",
    dataIndex: "partner_type_label",
    key: "partner_type_label",
  },
  {
    title: "Date Created",
    dataIndex: "created_at",
    key: "created_at",
  },
  {
    title: "Last Modified Date",
    dataIndex: "updated_at",
    key: "updated_at",
  },
];

export default function useGetPartnerTypeColumns() {
  const [tableColumns, setColumns] = useState(columns);

  useEffect(() => {
    setColumns(setColumns);
  }, []);

  return tableColumns;
}
