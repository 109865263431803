import React from "react";
import { Table, Button, Divider } from "antd";
import { InfoCircleFilled } from "@ant-design/icons";

export const UploadedItemListComponent = (props) => {
  const {
    columns = [],
    data = [],
    loading = false,
    onClickSyncingLogs,
  } = props;

  return (
    <>
      {data.length > 0 ? (
        <>
          <Divider />
          <Button
            type="link"
            icon={<InfoCircleFilled />}
            onClick={onClickSyncingLogs}
          >
            Check Syncing Logs
          </Button>
          <br />
          <br />
          <Table
            loading={loading}
            columns={columns}
            dataSource={data}
            size="small"
            bordered={true}
            scroll={{ x: 1500 }}
          />
        </>
      ) : null}
    </>
  );
};
