import { ModulesFilterAndSelectionComponent } from "../../ModulesFilterAndSelectionComponent";

export const PropertyFiltersComponent = (props) => {
  const {
    onClearSearchAndSelection,
    isShowSelection = false,
    tags = [],
  } = props;

  return (
    <ModulesFilterAndSelectionComponent
      onClearSearcheAndSelection={onClearSearchAndSelection}
      isShowSelection={isShowSelection}
      tags={tags}
    />
  );
};
