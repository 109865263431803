import React from "react";
import { EditOutlined, SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import { ModulesFunctionalitiesComponent } from "../../ModulesFunctionalitiesComponent";

export const BarangayFunctionalityComponent = (props) => {
  const {
    onClickCreate,
    onClickUpdate,
    onClickDelete,
    onSearch,
    rolesRestriction,
    selectedRowKey,
  } = props;

  const { DELETE_BARANGAY } = rolesRestriction;

  const functionalitiesComponent = [
    {
      component_type: "search_input",
      place_holder: "Search Barangay",
      _onSearch: onSearch,
    },
    {
      component_type: "button",
      type: "primary",
      icon: <EditOutlined />,
      _onClick: onClickCreate,
      label: "Create Barangay",
      size: "default",
    },
    {
      component_type: "button",
      type: "default",
      icon: <SaveOutlined />,
      _onClick: onClickUpdate,
      label: "Update Barangay",
      size: "default",
      is_hidden: selectedRowKey ? false : true,
    },
    {
      component_type: "button",
      type: "default",
      icon: <DeleteOutlined />,
      _onClick: onClickDelete,
      label: "Delete Barangay",
      size: "default",
      danger: true,
      is_hidden: DELETE_BARANGAY === 0 ? true : selectedRowKey ? false : true,
    },
  ];

  return (
    <>
      <ModulesFunctionalitiesComponent
        functionalitiesComponent={functionalitiesComponent}
        alignment="right"
        spaceSize="small"
      />
    </>
  );
};
