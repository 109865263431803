import React from "react";
import {
  Card,
  Row,
  Col,
  Descriptions,
  Upload,
  Typography,
  Divider,
  Image,
  Button,
  List,
  Avatar,
} from "antd";
import {
  InboxOutlined,
  InfoCircleOutlined,
  StarFilled,
  StarOutlined,
  DeleteOutlined,
  InfoCircleFilled,
} from "@ant-design/icons";
import { ModulesTableComponent } from "../../ModulesTableComponent";
import useColumnFilter from "../../../hooks/useColumnFilter";
import useGetPropertyProjectColumns from "../../../hooks/property_projects/useGetPropertyProjectColumns";
import useFilteredColumns from "../../../hooks/useFilteredColumns";

export const PropertyProjectListComponent = (props) => {
  const {
    data = { data: [], total: 0 },
    onPaginationChange,
    currentPagination,
    onSelection,
    selectedRowKey,
    onSortChange,
    columnFilter,
    setColumnFilter,
    tableLoading,
    UpdatePropertyProjectTag,
    projectImageUploaderProps,
    handleProjectImagesUpload,
    deleteMedia,
    updateMedia,
    deleteProjectAdvisorRelation,
    updateProjectAdvisorRelation,
  } = props;

  const { Paragraph, Title } = Typography;
  const { Dragger } = Upload;

  const { getColumnFilterProps } = useColumnFilter(
    columnFilter,
    setColumnFilter
  );

  const propertyProjectColumns = useGetPropertyProjectColumns(
    UpdatePropertyProjectTag
  );

  let newCols = useFilteredColumns(
    propertyProjectColumns,
    getColumnFilterProps
  );

  const searchAdvisorMetaByValue = (list, fieldName) => {
    if (!list || list.length === 0 || !fieldName) {
      return null;
    }

    const data = list.find((meta) => meta.agent_meta_field_name === fieldName);

    if (!data) {
      return null;
    }

    return data.agent_meta_field_text_value;
  };

  return (
    <>
      <ModulesTableComponent
        expandable={{
          expandedRowRender: (record) => {
            return (
              <>
                <Card style={{ padding: "1%" }}>
                  <Title level={4}>Other Details: </Title>
                  <Divider />
                  <Row gutter={[48, 8]}>
                    <Col span={15}>
                      <div>
                        <Descriptions
                          size="small"
                          column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                          layout="vertical"
                        >
                          <Descriptions.Item
                            label={<Title level={5}>Subtitle</Title>}
                            span={4}
                          >
                            {record.project_user_title}
                          </Descriptions.Item>
                          <Descriptions.Item
                            label={<Title level={5}>Description</Title>}
                            span={4}
                          >
                            {
                              <Paragraph
                                ellipsis={{
                                  expandable: true,
                                  rows: 3,
                                  symbol: "read more",
                                }}
                              >
                                {record.project_user_description}
                              </Paragraph>
                            }
                          </Descriptions.Item>
                        </Descriptions>
                        <br />
                        <Title level={5}>Advisor(s)</Title>
                        <br />
                        <List
                          itemLayout="horizontal"
                          dataSource={record.advisors}
                          renderItem={(item) => (
                            <List.Item
                              actions={[
                                <Button
                                  type="text"
                                  onClick={() =>
                                    updateProjectAdvisorRelation(item.id, {
                                      is_default: !item.is_default,
                                    })
                                  }
                                >
                                  {item.is_default ? (
                                    <InfoCircleFilled />
                                  ) : (
                                    <InfoCircleOutlined />
                                  )}
                                </Button>,
                                <Button
                                  type="text"
                                  danger={true}
                                  onClick={() =>
                                    deleteProjectAdvisorRelation(item.id)
                                  }
                                >
                                  <DeleteOutlined />
                                </Button>,
                              ]}
                            >
                              <List.Item.Meta
                                avatar={
                                  <Avatar
                                    className="adviser-photo-avatar"
                                    src={searchAdvisorMetaByValue(
                                      item.adviser.agent_meta_field,
                                      "agent_photo"
                                    )}
                                  />
                                }
                                description={
                                  <ul>
                                    <li>
                                      {searchAdvisorMetaByValue(
                                        item.adviser.agent_meta_field,
                                        "agent_in_charge"
                                      )}
                                    </li>
                                    <li>{item.adviser.users_email}</li>
                                    <li>
                                      {searchAdvisorMetaByValue(
                                        item.adviser.agent_meta_field,
                                        "agent_mobile_globe"
                                      )}
                                    </li>
                                    <li>
                                      {searchAdvisorMetaByValue(
                                        item.adviser.agent_meta_field,
                                        "agent_mobile_smart"
                                      )}
                                    </li>
                                    <li>
                                      {searchAdvisorMetaByValue(
                                        item.adviser.agent_meta_field,
                                        "agent_landline"
                                      )}
                                    </li>
                                  </ul>
                                }
                              />
                            </List.Item>
                          )}
                        />
                      </div>
                    </Col>
                    <Col span={9}>
                      <div className="projectsMetaFieldsInformationContainer">
                        <Descriptions
                          size="small"
                          column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
                          layout="vertical"
                        >
                          {record.meta_fields.map((item) => (
                            <Descriptions.Item
                              label={
                                <Title level={5}>
                                  {
                                    item.asset_custom_field
                                      .asset_custom_field_label
                                  }
                                </Title>
                              }
                              span={4}
                            >
                              <Paragraph>
                                {item.asset_meta_field_text_value ? (
                                  <ul>
                                    {item.asset_meta_field_text_value
                                      .split(",")
                                      .map((item) => (
                                        <li>
                                          <span>{item.trim()}</span>
                                        </li>
                                      ))}
                                  </ul>
                                ) : null}
                              </Paragraph>
                            </Descriptions.Item>
                          ))}
                        </Descriptions>
                      </div>
                    </Col>
                  </Row>
                </Card>
                <br />
                <Card style={{ padding: "1%" }}>
                  <Title level={4}>Images: </Title>
                  <Divider />
                  <Row>
                    <Col span={10}>
                      <div>
                        <Dragger
                          {...projectImageUploaderProps}
                          customRequest={(e) =>
                            handleProjectImagesUpload(e, record)
                          }
                        >
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p className="ant-upload-text">
                            Click or drag file to this area to upload
                          </p>
                          <p className="ant-upload-hint">
                            Support for a single or bulk upload.
                          </p>
                        </Dragger>
                      </div>
                    </Col>
                    <Col span={1}>
                      <Divider />
                    </Col>
                    <Col span={13}>
                      <div className="projectsImageContainer">
                        <Row gutter={[4, 2]}>
                          {record.medias.map((media) => (
                            <Col
                              key={media.id}
                              xs={24}
                              sm={12}
                              md={8}
                              lg={8}
                              xl={8}
                            >
                              <Card
                                style={{ textAlign: "center" }}
                                size="small"
                                cover={<Image width={"100%"} src={media.url} />}
                                actions={[
                                  <Button
                                    type="text"
                                    onClick={async () => {
                                      await updateMedia(
                                        media.id,
                                        record.property_projects_id,
                                        !media.is_feature
                                      );
                                    }}
                                  >
                                    {media.is_feature ? (
                                      <StarFilled />
                                    ) : (
                                      <StarOutlined />
                                    )}
                                  </Button>,
                                  <Button
                                    type="text"
                                    danger={true}
                                    onClick={() => deleteMedia(media.id)}
                                  >
                                    <DeleteOutlined />
                                  </Button>,
                                ]}
                              />
                            </Col>
                          ))}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </>
            );
          },
        }}
        data={data}
        onPaginationChange={onPaginationChange}
        onSelection={onSelection}
        selectedRowKey={selectedRowKey}
        tableColumns={newCols || []}
        currentPagination={currentPagination}
        columnFilter={columnFilter}
        setColumnFilter={setColumnFilter}
        onSortChange={onSortChange}
        scrollSize={700}
        tableLoading={tableLoading}
      />
    </>
  );
};
