import { message } from "antd";
import {
  FetchPartners,
  CreatePartner,
  UpdatePartner,
  RestorePartner,
  ArchivePartner,
  DeletePartner,
  FetchPartnersListingNull,
} from "../../api/Partner";
import { useAPIResponse } from "../useAPIResponse";
import { useGeneralFetch } from "../useGeneralFetch";

export const useFetchPartners = (
  isForTable = true,
  query = { page: 1, limit: 10, columnFilter: [] }
) => {
  return useGeneralFetch(isForTable, query, FetchPartners, "partner_id");
};

export const useFetchPartnersListingNull = (
  isForTable = true,
  query = { page: 1, limit: 10, columnFilter: [] }
) => {
  return useGeneralFetch(
    isForTable,
    query,
    FetchPartnersListingNull,
    "partner_id"
  );
};

export const useCreatePartner = async (data) => {
  await message.loading("Creating Partner..", 2);
  const response = await CreatePartner(data);

  return useAPIResponse(response);
};

export const useUpdatePartner = async (partner_id, payload) => {
  await message.loading("Updating Partner..", 2);
  const response = await UpdatePartner(partner_id, payload);

  return useAPIResponse(response);
};

export const useRestorePartner = async (partner_id, payload) => {
  await message.loading("Restoring Partner..", 2);
  const response = await RestorePartner(partner_id, payload);

  return useAPIResponse(response);
};

export const useArchivePartner = async (partner_id, payload) => {
  await message.loading("Archiving Partner..", 2);
  const response = await ArchivePartner(partner_id, payload);

  return useAPIResponse(response);
};

export const useDeletePartner = async (partner_id, payload) => {
  await message.loading("Deleting Partner..", 2);
  const response = await DeletePartner(partner_id, payload);

  return useAPIResponse(response);
};
