import React, { useState } from "react";
import moment from "moment";

import {
  Modal,
  Input,
  Select,
  Form,
  Button,
  InputNumber,
  DatePicker,
  Radio,
  message,
} from "antd";
import { SaveOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { FormRules } from "../../../dist/functions/formRules";
import { SelectOptionFooterExtraButtonComponent } from "../../SelectOptionFooterExtraButtonComponent";
import { AddNewAssetListingFormComponent } from "./AddNewAssetListingFormComponent";

const { Option } = Select;
export const ListingFormModalComponent = (props) => {
  const {
    form = null,
    isVisible,
    onClickSubmit,
    onClickCancel,
    isUpdate,
    partners,
    onClickShowMorePartner,
    users,
    onClickShowMoreUser,
    selectedRow,
    assets = [],
    onClickShowMoreAsset,
    onChangePartner,
  } = props;

  let listing_started_at =
    isUpdate && selectedRow && selectedRow.listing_started_at
      ? moment(selectedRow.listing_started_at)
      : null;

  let listing_ended_at =
    isUpdate && selectedRow && selectedRow.listing_ended_at
      ? moment(selectedRow.listing_ended_at)
      : null;

  let listing_closed_at =
    isUpdate && selectedRow && selectedRow.listing_closed_at
      ? moment(selectedRow.listing_closed_at)
      : null;

  const dateFormat = "YYYY-MM-DD HH:mm:ss";

  /**
   * Form Rules
   *
   */

  const {
    REQUIRED_LISTING_STATUS,
    REQUIRED_LISTING_ENDED_AT,
    REQUIRED_LISTING_CLOSED_AT,
    REQUIRED_PARTNER_ID,
    REQUIRED_LISTING_STARTED_AT,
  } = FormRules;

  function fixMinRange(currDate, minDate) {
    let newMinDate = moment(minDate.format(dateFormat));
    let subtractedMinDate = moment(
      moment(newMinDate).subtract(1, "days").format(dateFormat)
    );

    let dateCurrDate = moment(currDate.format("YYYY-MM-DD"));
    let dateSubtractedMinDate = moment(subtractedMinDate.format("YYYY-MM-DD"));

    return (
      subtractedMinDate.valueOf() > currDate.valueOf() || // prevents the exact min date from disabling
      dateCurrDate.valueOf() === dateSubtractedMinDate.valueOf() // to disable date before the min date
    );
  }

  function fixMaxRange(currDate, maxDate) {
    let newMaxDate = moment(maxDate.format(dateFormat));
    let addedMaxDate = moment(
      moment(newMaxDate).add(1, "days").format(dateFormat)
    );

    let dateCurrDate = moment(currDate.format("YYYY-MM-DD"));
    let dateAddedMaxDate = moment(addedMaxDate.format("YYYY-MM-DD"));

    return (
      addedMaxDate.valueOf() < currDate.valueOf() || // prevents the exact max date from disabling
      dateCurrDate.valueOf() === dateAddedMaxDate.valueOf() // to disable date before the max date
    );
  }

  function disableEndedAt(selectedDate) {
    return (
      (listing_started_at &&
        selectedDate &&
        fixMinRange(selectedDate, listing_started_at)) ||
      (isUpdate &&
        listing_closed_at &&
        selectedDate &&
        fixMinRange(selectedDate, listing_closed_at))
    );
  }

  function disableEndedAtTime(strDate) {
    if (strDate && listing_started_at) {
      let newStartedAt = moment(listing_started_at.format(dateFormat));

      let selectedDate = moment(strDate);

      if (isUpdate && listing_closed_at) {
        let newClosedAt = moment(listing_closed_at.format(dateFormat));

        // ended at must be greater than started at and closed at
        if (
          selectedDate.valueOf() <= newStartedAt.valueOf() ||
          selectedDate.valueOf() <= newClosedAt.valueOf()
        ) {
          const fields = form.validateFields();

          message.error(
            "Listing Ended At Date must be greater than Listing Started At and Listing Closed At",
            5
          );

          form.setFieldsValue({
            listing_ended_at: listing_ended_at,
            ...fields,
          });

          return false;
        }
      } else {
        // ended at must be greater than started at
        if (selectedDate.valueOf() <= newStartedAt.valueOf()) {
          const fields = form.validateFields();

          message.error(
            "Listing Ended At Date must be greater than Listing Started At",
            5
          );

          form.setFieldsValue({
            listing_ended_at: listing_ended_at,
            ...fields,
          });

          return false;
        }
      }

      listing_ended_at = selectedDate;
      return true;
    }
  }

  function disableClosedAt(selectedDate) {
    if (!isUpdate) {
      return false;
    } else {
      return (
        (listing_started_at &&
          selectedDate &&
          fixMinRange(selectedDate, listing_started_at)) ||
        (listing_ended_at &&
          selectedDate &&
          fixMaxRange(selectedDate, listing_ended_at))
      );
    }
  }

  function disableClosedAtTime(strDate) {
    if (strDate && listing_started_at) {
      let newStartedAt = moment(listing_started_at.format(dateFormat));

      let selectedDate = moment(strDate);

      if (isUpdate && listing_ended_at) {
        let newEndedAt = moment(listing_ended_at.format(dateFormat));

        // closed at must be greater than started at and less than ended at
        if (
          selectedDate.valueOf() < newStartedAt.valueOf() ||
          selectedDate.valueOf() > newEndedAt.valueOf()
        ) {
          const fields = form.validateFields();

          message.error(
            "Listing Closed At Date must be greater than Listing Started At and less than Listing Ended At",
            5
          );

          form.setFieldsValue({
            listing_closed_at: listing_closed_at,
            ...fields,
          });

          return false;
        }
      }

      listing_closed_at = selectedDate;
      return true;
    }
  }

  const onChangeClosedAt = (value) => {
    if (disableClosedAtTime(value)) {
      const fields = form.validateFields();
      const userId = parseInt(localStorage.getItem("user_id"));

      form.setFieldsValue({
        listing_closed_type: "manual",
        listing_closed_by: userId,
        ...fields,
      });
    }
  };

  function disableStartedAt(current) {
    return current && fixMaxRange(current, moment());
  }

  function disableStartedAtTime(strDate) {
    if (strDate) {
      let maxDate = moment();

      let selectedDate = moment(strDate);

      if (selectedDate.valueOf() > maxDate.valueOf()) {
        const fields = form.validateFields();

        message.error(
          "Listing Started At Date must be not later than today's date and time",
          5
        );

        form.setFieldsValue({
          listing_started_at: listing_started_at,
          ...fields,
        });

        return false;
      }

      listing_started_at = selectedDate;
      return true;
    }
  }

  const resetEndedAt = () => {
    const fields = form.validateFields();
    form.setFieldsValue({ listing_ended_at: null, ...fields });
  };

  const resetClosedAt = () => {
    const fields = form.validateFields();
    form.setFieldsValue({ listing_closed_at: null, ...fields });
  };

  return (
    <Modal
      title={isUpdate ? "Update Listing" : "Create Listing"}
      visible={isVisible}
      onOk={onClickSubmit}
      onCancel={onClickCancel}
      footer={[
        <Button key="back" onClick={onClickCancel} icon={<ArrowLeftOutlined />}>
          Return
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onClickSubmit}
          icon={<SaveOutlined />}
        >
          Submit
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" name="listings">
        <Form.Item
          name="listing_status"
          fieldKey="listing_status"
          label="Listing Status"
          initialValue={1}
          rules={[REQUIRED_LISTING_STATUS]}
        >
          <Radio.Group>
            <Radio value={1}>Active</Radio>
            <Radio value={0}>Not Active</Radio>
          </Radio.Group>
        </Form.Item>

        {!isUpdate && (
          <Form.Item
            label="Partner"
            name="partner_id"
            fieldKey="partner_id"
            shouldUpdate={true}
            rules={[REQUIRED_PARTNER_ID]}
          >
            <Select
              placeholder="Select Partner"
              virtual={true}
              onChange={onChangePartner}
              dropdownRender={(menu) => (
                <SelectOptionFooterExtraButtonComponent
                  key="show_more"
                  menu={menu}
                  onClickShowMoreList={onClickShowMorePartner}
                  isClickShowMoreDisabled={
                    partners.data.length < partners.total ? false : true
                  }
                />
              )}
            >
              {partners.data.map((item) => (
                <Option
                  id={item.partner_id}
                  key={item.partner_id}
                  value={item.partner_id}
                >
                  {item.partner_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        )}

        {!isUpdate && (
          <Form.Item
            name="listing_started_at"
            fieldKey="listing_started_at"
            label="Listing Started At"
            rules={[REQUIRED_LISTING_STARTED_AT]}
          >
            <DatePicker
              showTime
              format={dateFormat}
              onChange={(date, strDate) => {
                disableStartedAtTime(strDate);
                resetEndedAt();
              }}
              disabledDate={(currDate) => disableStartedAt(currDate)}
            />
          </Form.Item>
        )}

        <Form.Item
          name="listing_ended_at"
          fieldKey="listing_ended_at"
          label="Listing Ended At"
          rules={[REQUIRED_LISTING_ENDED_AT]}
        >
          <DatePicker
            format={dateFormat}
            onChange={(date, strDate) => {
              disableEndedAtTime(strDate);

              if (isUpdate) {
                resetClosedAt();
              }
            }}
            disabledDate={(currDate) =>
              listing_started_at ? disableEndedAt(currDate) : true
            }
            // disabledTime={disableEndedAtTime}
            showTime={{
              hideDisabledOptions: true,
            }}
          />
        </Form.Item>

        {isUpdate && (
          <Form.Item
            name="listing_closed_at"
            fieldKey="listing_closed_at"
            label="Listing Closed At"
            rules={[REQUIRED_LISTING_CLOSED_AT]}
          >
            <DatePicker
              showTime
              format={dateFormat}
              onChange={(date, strDate) => onChangeClosedAt(strDate)}
              disabledDate={(currDate) => disableClosedAt(currDate)}
            />
          </Form.Item>
        )}

        {isUpdate && (
          <Form.Item
            name="listing_closed_type"
            fieldKey="listing_closed_type"
            label="Listing Closed Type"

            // rules={[REQUIRED_CLOSED_TYPE]}
          >
            <Input disabled={true} />
          </Form.Item>
        )}
        {/* 
        <Form.Item
          name="listing_closed_by"
          fieldKey="listing_closed_by"
          label="Listing Closed By"
          // rules={[REQUIRED_CLOSED_BY]}
        >
          <InputNumber style={{ width: "100%" }} />
        </Form.Item> */}

        <Form.Item
          label="Listing Closed By"
          name="listing_closed_by"
          fieldKey="listing_closed_by"
          shouldUpdate={true}
        >
          <Select
            placeholder="Select Listing Closed By"
            virtual={true}
            disabled={isUpdate}
            dropdownRender={(menu) => (
              <SelectOptionFooterExtraButtonComponent
                key="show_more"
                menu={menu}
                onClickShowMoreList={onClickShowMoreUser}
                isClickShowMoreDisabled={
                  users.data.length < users.total ? false : true
                }
              />
            )}
          >
            {users.data.map((item) => (
              <Option
                id={item.users_id}
                key={item.users_id}
                value={item.users_id}
              >
                {item.users_email}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {!isUpdate && (
          <AddNewAssetListingFormComponent
            assets={assets}
            onClickShowMoreAsset={onClickShowMoreAsset}
            form={form}
          />
        )}
      </Form>
    </Modal>
  );
};
