import React from "react";
import { Tag, Button, Typography, Divider } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import useIsMobile from "../../../hooks/useIsMobile";

const { Title } = Typography;
export const AssetFiltersAndSelectionComponent = (props) => {
  const {
    onClearSearcheAndSelection,
    selectedAssets,
    onClearColumnFilters,
    isShowColumnFilterTags,
    columnFilterTags = [],
  } = props;

  const isMobile = useIsMobile();
  return (
    <>
      {selectedAssets || isShowColumnFilterTags ? (
        <div
          style={{ marginTop: "2%", textAlign: isMobile ? "center" : "left" }}
        >
          <Divider />
          <Title level={5}>Filters & Selections:</Title>
          <Tag
            color="processing"
            visible={
              selectedAssets
                ? selectedAssets.property_title
                  ? true
                  : false
                : false
            }
          >
            {`Selected Properties: ${
              selectedAssets?.property_title
                ? selectedAssets.property_title
                : "none"
            }`}
          </Tag>

          {columnFilterTags.length
            ? columnFilterTags.map((item, index) => (
                <Tag
                  key={index}
                  color="processing"
                  visible={!item.value ? false : true}
                  closable={item.closable ? item.closable : false}
                  onClose={item.closable ? () => item.onClose(item.id) : null}
                >
                  {`${item.selection_label}: ${
                    !item.value ? "none" : item.value
                  }`}
                </Tag>
              ))
            : null}
          <Button
            type="link"
            icon={<CloseOutlined />}
            size="small"
            onClick={() => {
              onClearSearcheAndSelection();
              onClearColumnFilters();
            }}
          >
            Clear Search/Filter
          </Button>
          <Divider />
        </div>
      ) : null}
    </>
  );
};
