import React from "react";
import { Modal, Input, Select, Form, Button, InputNumber } from "antd";
import { SaveOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { FormRules } from "../../../dist/functions/formRules";

const { Option } = Select;
export const PartnerTypeFormModalComponent = (props) => {
  const {
    form = null,
    isVisible,
    onClickSubmit,
    onClickCancel,
    states,
    isUpdate,
  } = props;

  /**
   * Form Rules
   *
   */

  // const {
  //   REQUIRED_ASSET_CUSTOM_FIELD_NAME,
  //   REQUIRED_ASSET_CUSTOM_FIELD_LABEL,
  //   REQUIRED_ASSET_CUSTOM_FIELD_TYPE,
  // } = FormRules;

  return (
    <Modal
      title={isUpdate ? "Update Partner Type" : "Create Partner Type"}
      visible={isVisible}
      onOk={onClickSubmit}
      onCancel={onClickCancel}
      footer={[
        <Button key="back" onClick={onClickCancel} icon={<ArrowLeftOutlined />}>
          Return
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onClickSubmit}
          icon={<SaveOutlined />}
        >
          Submit
        </Button>,
      ]}
    >
      <Form form={form} name="partner_types" layout="vertical">
        <Form.Item name="partner_type_name" label="Partner Type Name">
          <Input />
        </Form.Item>
        <Form.Item name="partner_type_label" label="Partner Type Label">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};
