import React from "react";
import { Button } from "antd";

export const SelectOptionFooterExtraButtonComponent = (props) => {
  const { menu, onClickShowMoreList, isClickShowMoreDisabled, loading } = props;
  return (
    <div>
      {menu}
      <div style={{ textAlign: "center", margin: "1.5%" }}>
        <Button
          loading={loading}
          type="link"
          size="small"
          onClick={onClickShowMoreList}
          disabled={isClickShowMoreDisabled}
        >
          {loading ? "Please wait..." : isClickShowMoreDisabled ? "End of list" : "Show More"}
        </Button>
      </div>
    </div>
  );
};
