import { useUpdate } from "../../hooks/useUpdate";
import { message } from "antd";

// for simulating fetch purpose only
// function simulateFetch() {
//     return new Promise((resolve, reject) => {
//         setTimeout(() => {
//             resolve();
//         }, 1000);
//     });
// }
export const UpdateAssetFeature = async (payload) => {
  try {
    const data = await useUpdate(
      `${process.env.REACT_APP_API_URL}/medias`,
      payload
    );
    if (data.status === 200) {
      message.success("Successfully updated default image.", 2);
    } else {
      message.error("Failed to update default image, please try again.", 3);
    }
    return data.status;
  } catch (err) {
    message.error("Failed to update default image, please try again.", 3);
    console.log("ERROR: ", err.stack);
    return null;
  }
};
