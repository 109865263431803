import { Card, Button, Popconfirm, Modal, Image as ImageAnt, Spin } from "antd";
import {
  DeleteOutlined,
  LoadingOutlined,
  StarOutlined,
  StarFilled,
  ExclamationCircleOutlined,
  CloudUploadOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import { useState, useEffect, useRef } from "react";
import { UpdateAssetFeature } from "../../../api/assets/UpdateAssetFeature";
import { DeleteAssetImage } from "../../../api/assets/DeleteAssetImage";
import { AssetImageBatchUpload } from "../../../api/assets/AssetImageBatchUpload";
import MetaDataFields from "./AssetMetaDataFields";

//custom hook
import useClassName from "../../../hooks/useClassName";

//for entries without image url, use placeholder
const noImage = "/no_image_available.svg";

//confirmation window
function confirm(onOk, content) {
  Modal.confirm({
    title: "Confirm",
    icon: <ExclamationCircleOutlined />,
    content,
    onOk,
  });
}

//helpers
const generateMediaApiOnSearch = (toEditData, setMediaApi) => {
  console.log(toEditData, "asd");

  const initialMediaApi = toEditData.media.map((d) => {
    //test if filename is old or new format
    //if new, just return it
    //if old, reconstruct it to new
    const splitMediaName = d.media_name.split("-");
    const file_name =
      splitMediaName.length > 2
        ? d.media_name
        : `used-${toEditData.asset_uuid}-${d.media_name}`;

    return {
      id: d.id,
      asset_uuid: toEditData.asset_uuid,
      preview: d.url || noImage,
      file_name,
      transaction_type: null,
      is_feature: d.is_feature || "0",
      metadata:
        d.meta !== "No Metadata"
          ? Object.entries(d.meta.Metadata).map((x) => ({
              name: x[0],
              value: x[1],
            }))
          : [],
      cloudState: {
        preview: d.url,
      },
    };
  });
  setMediaApi(() => initialMediaApi);
};

const getBase64Array = (files) => {
  //convert files to base64
  const promises = files.map((file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = (error) => reject(error);
    });
  });
  //resolve all and return
  return Promise.all(promises);
};

function PopConfirmDynamic({ children, title, onConfirm, disabled }) {
  return (
    <Popconfirm
      title={title}
      onConfirm={onConfirm}
      okText="Yes"
      cancelText="No"
      disabled={disabled}
    >
      {children}
    </Popconfirm>
  );
}
export default function AssetImageData({
  toEditData,
  mediaApi,
  setMediaApi,
  loadingList,
  GetAssetsList,
}) {
  //react hooks___________________________________
  const [toUpdateImageIndex, setToUpdateImageIndex] = useState();
  const [progressInfo, setProgressInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const inputFileSingle = useRef(null);
  const inputFileMultiple = useRef(null);

  useEffect(() => {
    console.log("New Data searched, resetting the image values...");

    //guard clause || return if no data found | error handling
    if (!toEditData) return console.log("No data found for toEditData");

    setMediaApi(() => []);
    toEditData &&
      toEditData.media &&
      generateMediaApiOnSearch(toEditData, setMediaApi);
  }, [toEditData]);

  //custom hooks
  const getClassName = useClassName();

  //guard clause || default return if no data found
  if (!toEditData)
    return (
      <>
        <h2>Images Information Section</h2>
        <p>Asset's media images will show here.</p>
      </>
    );

  const ProgressDialog = () => {
    const checkIfAllDone = () => progressInfo.every((x) => x.done);
    const successCount = progressInfo.filter(
      (x) => x.status === "success"
    ).length;
    const failedCount = progressInfo.filter(
      (x) => x.status === "failed"
    ).length;
    const textColors = {
      success: "green",
      processing: "yellow",
      failed: "red",
      pending: "blue",
    };

    return (
      <div className="progress-dialog-background">
        <div className="progress-dialog-foreground">
          <div>
            <div
              style={{
                color: successCount > 0 ? textColors.success : "black",
              }}
            >
              Success: {successCount} / {progressInfo.length}
            </div>
            <div
              style={{
                color: failedCount > 0 ? textColors.failed : textColors.success,
              }}
            >
              Failed: {failedCount}
            </div>
          </div>
          <div className="progress-items-container">
            {progressInfo.map(({ base64Preview, percent, status }, i) => {
              return (
                <div
                  className={getClassName("progress-item")}
                  key={i}
                  style={{ color: textColors[status] }}
                >
                  <img src={base64Preview} />
                  {status === "success"
                    ? "Success"
                    : status === "processing"
                    ? "Processing.."
                    : status === "failed"
                    ? "Failed"
                    : "Pending"}
                </div>
              );
            })}
          </div>
          <Button
            disabled={!checkIfAllDone()}
            block
            onClick={() => {
              setProgressInfo([]);
            }}
          >
            Done
          </Button>
        </div>
      </div>
    );
  };

  //action calls
  const handleSetDefault = async (id, toUpdateDefaultItemIndex) => {
    setLoading(true);
    //if this item is not FOR_NEW_UPLOADING..
    if (
      mediaApi[toUpdateDefaultItemIndex].transaction_type !==
      "FOR_NEW_UPLOADING"
    ) {
      //get same id from mediaApi and clone it using spread
      const newDataForFeature = {
        ...mediaApi.filter((x) => x.id === id)[0],
        is_feature: "1",
        asset_id: toEditData.asset_id,
        transaction_type: "FOR_UPDATE_FEATURE",
      };

      const status = await UpdateAssetFeature(newDataForFeature);

      if (status === 200) {
        setMediaApi((prevState) =>
          prevState.map((m) => {
            //change is_feature to "1" if same id
            if (m.id === id) {
              const newDataPreviousTransactionType = {
                ...m,
                is_feature: "1",
              };
              return newDataPreviousTransactionType;
            }
            //default: return with is_feature set to 0
            return { ...m, is_feature: "0" };
          })
        );
      }
    } else {
      setMediaApi((prevState) => {
        const newState = [...prevState];
        newState.map((entry, i) => {
          entry.is_feature = "0";
        });
        newState[toUpdateDefaultItemIndex].is_feature = "1";

        return newState;
      });
    }

    setLoading(false);
  };

  const handleDelete = async (index) => {
    setLoading(true);
    const { transaction_type } = mediaApi[index];
    if (transaction_type === "FOR_NEW_UPLOADING") {
      //if for new uploading, just delete the entry
      setMediaApi((prevState) => {
        return prevState.filter((m, i) => i !== index);
      });
    } else {
      const { id, asset_uuid, file_name } = mediaApi[index];
      const payload = {
        id,
        asset_uuid,
        file_name,
        transaction_type: "FOR_DELETE",
        is_feature: "0",
      };
      const status = await DeleteAssetImage(payload);
      //check here first if delete api is successful
      if (status === 200) {
        setMediaApi((prevState) => {
          //avoid mutation
          //remove the indexed item
          const newState = [...prevState].filter((x, i) => i != index);

          return newState;
        });
      }
    }

    setLoading(false);
  };

  const handleSelectedImage = async (e, isNew) => {
    const files = [...e.target.files];

    const arrayOfBase64 = await getBase64Array(files);

    setMediaApi((prevState) => {
      const newState = [...prevState];
      //get all empty slots from newState
      const emptySlotsIndices = [];
      newState.map((x, i) => {
        x.preview === noImage && emptySlotsIndices.push(i);
      });

      if (isNew) {
        //construct api from array of files
        const newMediaApis = files.map((file, i) => {
          //if current index is inside the length of empty slots
          //then format as for_updating
          if (i < emptySlotsIndices.length) {
            return {
              ...newState[emptySlotsIndices[i]],
              file,
              preview: arrayOfBase64[i],
              transaction_type: "FOR_UPDATING",
              metadata: [
                {
                  name: "property-view",
                  value: "exterior/interior",
                },
              ],
            };
          } else {
            //else, format as for_new_uploading
            return {
              asset_uuid: toEditData.asset_uuid,
              file,
              file_name: `used-${toEditData.asset_uuid}`,
              preview: arrayOfBase64[i],
              transaction_type: "FOR_NEW_UPLOADING",
              is_feature: "0",
              metadata: [
                {
                  name: "property-view",
                  value: "exterior/interior",
                },
              ],
              cloudState: {},
            };
          }
        });

        newMediaApis.map((newMediaApi, i) => {
          if (i < emptySlotsIndices.length) {
            //for_updating
            newState[emptySlotsIndices[i]] = newMediaApi;
          } else {
            //new entries or for_new_uploading
            newState.push(newMediaApi);
          }
        });
        return newState;
      }
      //if not for_new_uploading then set it to for_updating, else leave it as is
      newState[toUpdateImageIndex].transaction_type =
        newState[toUpdateImageIndex].transaction_type !== "FOR_NEW_UPLOADING"
          ? "FOR_UPDATING"
          : newState[toUpdateImageIndex].transaction_type;

      newState[toUpdateImageIndex].file = files[0];
      newState[toUpdateImageIndex].preview = arrayOfBase64[0];
      newState[toUpdateImageIndex].metadata = [
        { name: "property-view", value: "exterior/interior" },
      ];

      return newState;
    });

    /*  BUG FIX: reset the value so that the onChange
            will trigger upon new search
            when the same image is selected */
    e.target.value = "";
  };

  //TODO: show updates summary before batch uploading
  // const UpdatesSummary = ()=>{

  // }
  const handleBatchUpload = async () => {
    //for processing metadata
    const forms = [...document.querySelectorAll(".metadata-forms")];
    let metadataArray = [];

    const toMetadataArray = (form) => {
      const formData = new FormData(form);
      const inputValueArray = [];
      const returnObj = {};
      for (const pair of formData.entries()) {
        inputValueArray.push(pair[1]);
      }
      inputValueArray.map((v, i) => {
        if (i % 2 === 0) {
          returnObj[v] = inputValueArray[i + 1];
        }
      });
      metadataArray.push(returnObj);
    };
    //filter the cards
    const filteredMediaApi = mediaApi.filter((x, i) => {
      if (
        x.transaction_type === "FOR_NEW_UPLOADING" ||
        x.transaction_type === "FOR_UPDATING"
      ) {
        toMetadataArray(forms[i]);
        return true;
      }
    });

    //convert mediaApi to FormData
    const toFormData = filteredMediaApi.map((m, i) => {
      let formData = new FormData();
      formData.append("file", m.file);
      m.transaction_type === "FOR_UPDATING" && formData.append("id", m.id);
      formData.append("asset_uuid", m.asset_uuid);
      formData.append("file_name", m.file_name);
      formData.append("transaction_type", m.transaction_type);
      formData.append("is_feature", m.is_feature);

      formData.append("metadata", JSON.stringify(metadataArray[i]));

      return formData;
    });
    //show progress dialog
    setProgressInfo(
      filteredMediaApi.map((x) => ({
        base64Preview: x.preview,
        percent: 0,
        status: "pending",
        done: false,
      }))
    );
    AssetImageBatchUpload(toFormData, setProgressInfo, () => GetAssetsList());
  };

  //check if mediaApi has entries that are pending for upload
  const isUploadButtonEnabled = mediaApi.some(
    (m) =>
      m.transaction_type === "FOR_UPDATING" ||
      m.transaction_type === "FOR_NEW_UPLOADING"
  );

  const makeCards = () => {
    return (
      mediaApi
        // .filter((x) => x.preview != noImage)
        .map((m, i) => (
          <Card.Grid
            key={i}
            className={`${getClassName("card-grid")}${
              m.transaction_type === "FOR_NEW_UPLOADING"
                ? " card-grid-new"
                : m.transaction_type === "FOR_UPDATING"
                ? " card-grid-update"
                : ""
            }`}
            // style={{display: m.preview === noImage && "none"}}
          >
            <div className={getClassName("left-side-card")}>
              <div
                className="card-img-container"
                onClick={() => {
                  setToUpdateImageIndex(() => i);
                  inputFileSingle.current.click();
                }}
              >
                <ImageAnt
                  className="card-img"
                  src={m.preview}
                  preview={false}
                  placeholder={
                    <Spin
                      size="large"
                      style={{
                        background: "white",
                        width: "100%",
                        height: "100%",
                      }}
                    />
                  }
                />
                {/* <img src={m.preview} className="card-img" /> */}
              </div>
              <div className="controls-style">
                {loading ? (
                  <LoadingOutlined className="input-style" />
                ) : m.is_feature === "1" || m.is_feature === 1 ? (
                  <StarFilled
                    className="input-style"
                    style={{ color: "green" }}
                  />
                ) : (
                  <StarOutlined
                    className="input-style"
                    onClick={() => {
                      handleSetDefault(m.id, i);
                    }}
                  />
                )}
                <PopConfirmDynamic
                  onConfirm={() => handleDelete(i)}
                  title="Delete this image?"
                  disabled={m.cloudState.preview === null}
                >
                  {loading ? (
                    <LoadingOutlined className="input-style" />
                  ) : (
                    <DeleteOutlined
                      style={{
                        color: m.cloudState.preview === null ? "#ddd" : "red",
                      }}
                      className="input-style"
                    />
                  )}
                </PopConfirmDynamic>
              </div>
            </div>
            <div className="right-side-card">
              <MetaDataFields
                metadata={m.metadata}
                cardIndex={i}
                setMediaApi={setMediaApi}
              />
            </div>
          </Card.Grid>
        ))
    );
  };
  const LoadingCards = () => (
    <>
      <Card loading={true} className="card-loading" />
      <Card loading={true} className="card-loading" />
    </>
  );

  return (
    <>
      <h2>Asset Code: {toEditData.asset_uuid}</h2>
      <hr />
      <div className="image-data-cards-container">
        <input
          type="file"
          id="file-single"
          ref={inputFileSingle}
          accept="image/*"
          style={{ display: "none" }}
          onChange={(e) => handleSelectedImage(e)}
        />
        <input
          type="file"
          id="file-multiple"
          multiple
          ref={inputFileMultiple}
          accept="image/*"
          style={{ display: "none" }}
          onChange={(e) => handleSelectedImage(e, true)}
        />
        {loadingList ? <LoadingCards /> : makeCards()}
        {loadingList ? (
          <LoadingCards />
        ) : (
          mediaApi && (
            <div className={getClassName("image-upload-button")}>
              <div>
                <Button
                  type="dashed"
                  className="upload-new-image"
                  onClick={() => {
                    inputFileMultiple.current.click();
                  }}
                >
                  <FileAddOutlined />
                </Button>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "10px",
                  }}
                >
                  Add
                  <br />
                  Images
                </p>
              </div>
              <div>
                <Button
                  disabled={!isUploadButtonEnabled}
                  type="dashed"
                  className="upload-new-image"
                  onClick={() =>
                    confirm(handleBatchUpload, "Start uploading the updates?")
                  }
                >
                  <CloudUploadOutlined />
                </Button>
                <p
                  style={{
                    textAlign: "center",
                    fontSize: "10px",
                  }}
                >
                  Upload
                  <br />
                  Now
                </p>
              </div>
            </div>
          )
        )}
      </div>
      {progressInfo.length > 0 && <ProgressDialog />}
    </>
  );
}
