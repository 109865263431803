import { useFetch } from "../hooks/useFetch";
import { FETCH_ASSET_COLUMNS_URL, FETCH_ASSET_LIST } from "./routes/assets";

export const FetchAssetsColumns = async () => {
  try {
    return await useFetch(FETCH_ASSET_COLUMNS_URL);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const FetchAssetsList = async (query) => {
  try {
    return await useFetch(FETCH_ASSET_LIST, query);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
