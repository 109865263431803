import { useFetch } from "../../hooks/useFetch";

export const FetchListingsList = async (query = {}) => {
  try {
    const data = await useFetch(
      `${process.env.REACT_APP_API_URL}/listings`,
      query
    );
    return data.data;
  } catch (err) {
    return null;
  }
};
