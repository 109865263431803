import React from "react";
import { Table } from "antd";

export const ModulesTableComponent = ({
  data = { data: [], total: 0 },
  onPaginationChange,
  onSelection,
  selectedRowKey,
  tableColumns = [],
  size = "middle",
  selectionType = "radio",
  currentPagination,
  onSortChange,
  scrollSize = 1500,
  hasSelection = true,
  tableLoading = false,
  expandable = null,
}) => {
  return (
    <Table
      loading={tableLoading}
      columns={tableColumns}
      dataSource={data.data}
      size={size}
      scroll={{ x: scrollSize }}
      onChange={(pagination, filters, sorter, action) => {
        if (action.action === "sort") {
          console.log("SORTER FIELD", sorter.field);
          onSortChange(sorter.field, sorter.order);
        }
      }}
      rowSelection={
        hasSelection
          ? {
              type: selectionType,
              selectedRowKeys:
                selectionType === "radio" ? [selectedRowKey] : selectedRowKey,
              onChange: onSelection,
            }
          : null
      }
      expandable={expandable}
      pagination={{
        total: data.total,
        onChange: onPaginationChange,
        current: currentPagination,
      }}
      bordered={true}
    />
  );
};
