import React from "react";
import { Modal, Form, Select, Divider } from "antd";

import { AddNewAssetListingFormComponent } from "./AddNewAssetListingFormComponent";
import { ReturnModalFooterComponents } from "../modules/ReturnModalFooterComponents";

export const AddNewAssetListingComponent = (props) => {
  const {
    form,
    isVisible,
    handleOk,
    handleCancel,
    loading,
    assets = [],
    onClickShowMoreAsset,
    selectedRowKeys,
    selectedRow,
  } = props;

  return (
    <>
      <Modal
        visible={isVisible}
        title="Add Asset Listing"
        onOk={handleOk}
        onCancel={handleCancel}
        width="60%"
        footer={
          <ReturnModalFooterComponents
            handleCancel={handleCancel}
            handleOk={handleOk}
            loading={loading}
          />
        }
        destroyOnClose={true}
      >
        <Form form={form} layout="vertical">
          <AddNewAssetListingFormComponent
            assets={assets}
            onClickShowMoreAsset={onClickShowMoreAsset}
            selectedRowKeys={selectedRowKeys}
            asset_listing={selectedRow.asset_listing}
            form={form}
            isUpdate={true}
          />
        </Form>
      </Modal>
    </>
  );
};
