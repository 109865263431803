import React from "react";
import { Form, Modal, Input, Select, Row, Col } from "antd";
import { FormRules } from "../../../dist/functions/formRules";

const { Option } = Select;
const { TextArea } = Input;
const {
  REQUIRED_PARTNER_EMAIL,
  REQUIRED_PARTNER_NAME,
  REQUIRED_PARTNER_LANDLINE,
  REQUIRED_PARTNER_MOBILE,
  REQUIRED_PARTNER_PERSON_IN_CHARGE,
  REQUIRED_PARTNER_TYPE,
  NUMBER_FORMAT_ONLY,
  LANDLINE_CHARACTER_COUNT,
  CONTACT_CHARACTER_COUNT,
  EMAIL_FORMAT,
} = FormRules;

export const BulkPartnerCreationModalComponent = (props) => {
  const { form, visible = true, onSubmit, onCancel, partnerTypes = [] } = props;

  return (
    <Modal
      visible={visible}
      title="Create Partner"
      onOk={onSubmit}
      onCancel={onCancel}
    >
      <Form form={form} name="bulk_partner_creation" layout="vertical">
        <Form.List name={"partners"}>
          {(fields) => (
            <>
              {fields.map((field) => (
                <>
                  <Form.Item
                    key={[field.key, "partner_name"]}
                    name={[field.key, "partner_name"]}
                    rules={[REQUIRED_PARTNER_NAME]}
                    label="Partner Name"
                  >
                    <Input
                      key={[field.key, "partner_name_input"]}
                      disabled={true}
                    />
                  </Form.Item>
                  <Form.Item
                    key={[field.key, "partner_code"]}
                    name={[field.key, "partner_code"]}
                    label="Partner Code"
                  >
                    <Input key={[field.key, "partner_code_input"]} />
                  </Form.Item>
                  <Form.Item
                    key={[field.key, "partner_person_in_charge"]}
                    name={[field.key, "partner_person_in_charge"]}
                    rules={[REQUIRED_PARTNER_PERSON_IN_CHARGE]}
                    label="Person In Charge"
                  >
                    <Input
                      key={[field.key, "partner_person_in_charge_input"]}
                    />
                  </Form.Item>
                  <Row gutter={6}>
                    <Col span={12}>
                      <Form.Item
                        key={[field.key, "partner_mobile"]}
                        name={[field.key, "partner_mobile"]}
                        rules={[
                          REQUIRED_PARTNER_MOBILE,
                          NUMBER_FORMAT_ONLY,
                          CONTACT_CHARACTER_COUNT,
                        ]}
                        label="Mobile"
                      >
                        <Input
                          key={[field.key, "partner_mobile_input"]}
                          prefix="+639"
                          maxLength={9}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        key={[field.key, "partner_landline"]}
                        name={[field.key, "partner_landline"]}
                        rules={[
                          REQUIRED_PARTNER_LANDLINE,
                          NUMBER_FORMAT_ONLY,
                          LANDLINE_CHARACTER_COUNT,
                        ]}
                        label="Landline"
                      >
                        {/* <Input.Group compact>
                          <Input
                            maxLength={12}
                            style={{ width: "30%" }}
                            key={[field.key, "partner_landline_input_prefix"]}
                          />
                          <Input
                            maxLength={12}
                            style={{ width: "70%" }}
                            key={[field.key, "partner_landline_input_value"]}
                          />
                        </Input.Group> */}
                        <Input
                          maxLength={12}
                          //   style={{ width: "70%" }}
                          //   key={[field.key, "partner_landline_input_value"]}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Form.Item
                    key={[field.key, "partner_email"]}
                    name={[field.key, "partner_email"]}
                    rules={[REQUIRED_PARTNER_EMAIL, EMAIL_FORMAT]}
                    label="Email"
                  >
                    <Input key={[field.key, "partner_email_input"]} />
                  </Form.Item>
                  <Form.Item
                    key={[field.key, "partner_type_id"]}
                    name={[field.key, "partner_type_id"]}
                    rules={[REQUIRED_PARTNER_TYPE]}
                    label="Partner Type"
                  >
                    <Select
                      placeholder="Select Partner Type"
                      key={[field.key, "select_Partner_type"]}
                    >
                      {partnerTypes.map((partnerType, index) => (
                        <Option
                          key={[
                            field.key,
                            index,
                            partnerType.partner_type_name,
                          ]}
                          value={partnerType.partner_type_id}
                        >
                          {partnerType.partner_type_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item
                    key={[field.key, "partner_notes"]}
                    name={[field.key, "partner_notes"]}
                    label="Partner Notes"
                  >
                    <TextArea
                      key={[field.key, "partner_notes_input"]}
                      rows={3}
                    />
                  </Form.Item>
                </>
              ))}
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};
