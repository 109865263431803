import React from "react";
import { Result, Table, Typography } from "antd";
import useGetAPlusMemberColumn from "../../../hooks/a_plus_network/useGetAPlusMemberColumn";

export const APlusMembersTableManagementComponent = ({
  data,
  onPaginationChange,
  onSelection,
  selectedRowKey,
  tableLoading,
  isError,
  meta,
}) => {
  const aPlusMemberColumns = useGetAPlusMemberColumn();
  const { Title } = Typography;
  const subTableColumns = [
    {
      title: "Firstname",
      dataIndex: ["referral_info", "users_first_name"],
      key: ["referral_info", "users_first_name"],
    },
    {
      title: "Lastname",
      dataIndex: ["referral_info", "users_last_name"],
      key: ["referral_info", "users_last_name"],
    },
    {
      title: "Middlename",
      dataIndex: ["referral_info", "users_middle_name"],
      key: ["referral_info", "users_middle_name"],
    },
    {
      title: "Email",
      dataIndex: ["referral_info", "users_email"],
      key: ["referral_info", "users_email"],
    },
    {
      title: "Referrer Code",
      dataIndex: ["referral_info", "referrer_code"],
      key: ["referral_info", "referrer_code"],
    },
  ];

  return (
    <>
      {isError ? (
        <Result
          status="warning"
          title="Try refreshing this page, or relogin."
        />
      ) : (
        <Table
          loading={tableLoading}
          columns={aPlusMemberColumns}
          dataSource={data}
          size="middle"
          scroll={{ x: "1200" }}
          expandable={{
            expandedRowRender: (record) => (
              <>
                <div style={{ padding: "2%" }}>
                  <Title level={5}>Downline</Title>
                  <br />
                  <Table
                    size="small"
                    bordered
                    columns={subTableColumns}
                    dataSource={record.referrals}
                    pagination={false}
                  />
                </div>
              </>
            ),
            rowExpandable: (record) => record.referrals.length > 0,
          }}
          bordered
          rowSelection={{
            type: "radio",
            selectedRowKeys: [selectedRowKey],
            onChange: onSelection,
          }}
          pagination={{
            total: meta.total,
            current: meta.currentPage,
            onChange: onPaginationChange,
          }}
        />
      )}
    </>
  );
};
