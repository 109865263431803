import React, { useEffect, useState } from "react";
import { PageHeader, Form, Modal, message } from "antd";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { PropertyProjectListComponent } from "../sub_components/property_projects/PropertyProjectListComponent";
import { PropertyProjectsFunctionalityComponent } from "../sub_components/property_projects/PropertyProjectsFunctionalityComponent";
import { PropertyProjectFormsModalFunctionalityComponent } from "../sub_components/property_projects/PropertyProjectFormsModalFunctionalityComponent";
import { useQueryFilterReducer } from "../../hooks/useQueryFilterReducer";
import { ModulesSelectAndColumnTagsComponent } from "../ModulesSelectAndColumnTagsComponent";
import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";
import { PropertyProjectModalContentComponent } from "../sub_components/property_projects/PropertyProjectModalContentComponent";
import {
  ExclamationCircleOutlined,
  InfoCircleTwoTone,
  DeleteOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { AddNewPropertyDeveloperProjectsPivotComponent } from "../sub_components/property_projects/AddNewPropertyDeveloperProjectsPivotComponent";
import { useGetAllDevelopers } from "../../hooks/property_developers/usePropertyDeveloperAPI";
import useFetchPermissionSets from "../../hooks/useFetchPermissions";
import { useFetchIslandGroupsAsOptions } from "../../hooks/island_groups/useIslandGroupAPI";
import { useFetchProvinceAsOptions } from "../../hooks/provinces/useProvinceAPI";
import { FetchPropertySubCategory } from "../../api/PropertySubCategory";
import { FetchBarangays } from "../../api/Barangays";
import { FetchCities } from "../../api/City";
import { FetchAdvisors } from "../../api/Users";
import {
  useCreatePropertyProject,
  useDeletePropertyProject,
  useFetchPropertyProjects,
  useUpdatePropertyProject,
  useUpdatePropertyProjectsPivot,
  useUpdatePropertyProjectTags,
  useUploadProjectImage,
} from "../../hooks/property_projects/usePropertyProjectAPI";
import { useFetchLocales } from "../../hooks/locales/useLocaleAPI";
import { DeleteMedia, UpdateFeaturedMedia } from "../../api/medias";
import {
  DeleteProjectAdvisorRelation,
  UpdateProjectAdvisorRelation,
} from "../../api/project_advisor_relations";
import { useQueryCustomField } from "../../hooks/custom_field/useCustomFieldAPI";
import "../../dist/style/projects.css";

const { confirm } = Modal;
const initialPropertyProjectsData = {
  data: [],
  total: 0,
};
const permissionSet = [
  "CREATE_PROPERTY_PROJECT",
  "UPDATE_PROPERTY_PROJECT",
  "DELETE_PROPERTY_PROJECT",
];

const initialUserRolesState = {
  CREATE_PROPERTY_PROJECT: 0,
  UPDATE_PROPERTY_PROJECT: 0,
  DELETE_PROPERTY_PROJECT: 0,
  UPDATE_PROPERTY_PROJECTS_PROPERTY_DEVELOPER_PROJECTS_PIVOT: 0,
};

export const PropertyProjectsComponent = ({ permissions }) => {
  const [createPropertyProjectForm] = Form.useForm();
  const [updatePropertyProjectForm] = Form.useForm();
  const [addPropertyProjectsPivotForm] = Form.useForm();

  const [
    propertyProjectCreationModalStatus,
    setPropertyProjectCreationModalStatus,
  ] = useState(false);
  const [
    propertyProjectUpdateModalStatus,
    setPropertyProjectUpdateModalStatus,
  ] = useState(false);
  const [propertyCategory, setPropertyCategory] = useState([]);
  const [projectName, setProjectName] = useState([]);
  const [provinces, SetProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [islandGroup, setIslandGroup] = useState([]);
  const [barangay, setBarangays] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [propertyProjectsData, setPropertyProjectsData] = useState(
    initialPropertyProjectsData
  );
  const [postHTMLcontent, setPostHTMLcontent] = useState(null);
  const [isShowPreviewModalPost, setIsShowPreviewModalPost] = useState(false);
  const [developers, setDevelopers] = useState([]);
  const [
    addNewPropertyProjectsPivotButtonLoading,
    setAddNewPropertyProjectsPivotButtonLoading,
  ] = useState(false);
  const [showAddPropertyProjectsPivot, setShowAddPropertyProjectsPivot] =
    useState(false);
  const [projectCustomFields, setProjectCustomFields] = useState([]);
  const [advisors, setAdvisors] = useState([]);
  const [locales, setLocales] = useState([]);

  useEffect(() => {
    fetchCities();
  }, [selectedProvince]);

  useEffect(() => {
    FetchBarangay();
    // if the selected city gets poppulated
    // this hook wil trigger as per the business rules
    // dropdown of barangays, should be dependent on the city selected
    // so it will not fetch all the barangays on the selection
  }, [selectedCity]);

  useEffect(() => {
    FetchPropertyCategory();
    GetProjectCustomFields();
    FetchPropertyProjectName();
    FetchIslandGroup();
    FetchProvinces();
    handleFetchAdvisors();
    FetchLocales();
  }, []);

  const handleFetchAdvisors = async () => {
    const data = await FetchAdvisors();
    setAdvisors(data);
  };

  const {
    queryFilters,
    actionsQueryFilters,
    onPaginationChange,
    onSortChange,
    columnFilterTags,
    onClearColumnFilters,
    onLoadColumnFilterTags,
  } = useQueryFilterReducer();

  /**
   * Functionalities
   *
   */

  const fetchCities = async () => {
    if (!selectedProvince) {
      setCities([]);
      return;
    }

    // if the code reach this far, meaning there is a selected city on the form
    // so get the city id from the state and put it in the query object

    const query = { page: 1, limit: "ALL", province_id: selectedProvince };
    const response = await FetchCities(query);
    const { data } = response;
    setCities(data.data);
  };

  const FetchIslandGroup = async () => {
    const data = await useFetchIslandGroupsAsOptions(false, {});
    setIslandGroup(data);
  };

  const FetchProvinces = async () => {
    const data = await useFetchProvinceAsOptions(false, {});
    SetProvinces(data);
  };

  const FetchLocales = async () => {
    const data = await useFetchLocales();
    setLocales(data);
  };

  const FetchBarangay = async () => {
    /**
     * Validate the selected city if there is a data
     *
     * - if null, just set barangays state to empty array
     * - if there is data query barangays by selcted city
     *
     */

    if (!selectedCity) {
      setBarangays([]);
      return;
      // call return function so the bottom code will not be executed after setting barangays as empty array
    }

    // if the code reach this far, meaning there is a selected city on the form
    // so get the city id from the state and put it in the query object

    const query = { page: 1, limit: "ALL", city_id: selectedCity };
    const response = await FetchBarangays(query);
    const { data } = response;
    setBarangays(data.data);
  };

  const FetchPropertyProjectName = async () => {
    const data = await useFetchPropertyProjects(false, {});
    setProjectName(data);
  };

  const FetchPropertyCategory = async () => {
    const response = await FetchPropertySubCategory(false, {});
    const { data } = response;
    setPropertyCategory(data.data);
  };

  const GetProjectCustomFields = async () => {
    const customFieldToQueryList = [
      "project_highlights",
      "neighborhood_feature_tags",
      "landmarks_poi",
      "property_promo",
    ];

    const projectCustomFieldList = [];

    for (const customFieldName of customFieldToQueryList) {
      const query = {
        asset_custom_field_name: customFieldName,
        is_active: 1,
      };

      const customField = await QueryCustomField(query);
      projectCustomFieldList.push(...customField);
    }

    const modifiedProjetcCustomField = projectCustomFieldList.map((item) => {
      const {
        asset_custom_field_name,
        asset_custom_field_label,
        asset_custom_field_id,
      } = item;
      return {
        id: asset_custom_field_id,
        fieldKey: asset_custom_field_name,
        name: asset_custom_field_name,
        label: asset_custom_field_label,
      };
    });

    setProjectCustomFields(modifiedProjetcCustomField);
  };

  const QueryCustomField = async (query) => {
    return await useQueryCustomField(query);
  };

  /**
   *
   * Create propertyProject function for fetch reusability
   *
   */
  const FetchPropertyProjects = async (
    page = 1,
    limit = 10,
    columnFilter,
    sortColumn,
    sortType
  ) => {
    /**
     *
     * Construct Query parameters for fetching
     *
     */
    setTableLoading(true);
    const query = {
      page: page,
      limit: limit,
      sortColumn: sortColumn,
      sortType: sortType,
      columnFilter: columnFilter,
    };
    const data = await useFetchPropertyProjects(true, query);
    /**
     *
     * Create delay on updating of state for better transition and visualization on Frontend
     *
     */
    setTimeout(() => {
      setPropertyProjectsData(data);
      setTableLoading(false);
    }, 800);
  };

  const FetchDevelopers = async () => {
    const data = await useGetAllDevelopers();
    setDevelopers(data);
  };

  /**
   *
   * Initial PropertyProject Fetch
   *    - Fetch with 1 page, limit 10 with no filter as query parameters
   */

  useEffect(() => {
    FetchPropertyProjects();
    FetchDevelopers();
  }, []);

  useComponentDidUpdate(() => {
    const { page, limit, columnFilter, sortColumn, sortType } = queryFilters;

    onLoadColumnFilterTags();
    FetchPropertyProjects(page, limit, columnFilter, sortColumn, sortType);
  }, [queryFilters, DeleteMedia]);

  /**
   *
   * Use effect hooks if any of the data is selected
   * Selected row will populate the form for updating
   *
   */

  useEffect(() => {
    if (selectedRow && propertyProjectUpdateModalStatus === true) {
      const {
        property_projects_name,
        barangay_id,
        city_id,
        locale_id,
        island_group_id,
        province_id,
        property_category_id,
        meta_fields,
        project_user_description,
        project_user_title,
        property_developer_projects_pivots,
        advisors,
      } = selectedRow;

      setSelectedCity(city_id);
      setSelectedProvince(province_id);

      const advisorIds = advisors.map((item) => {
        return item.adviser_id;
      });

      const formFields = {
        property_projects_name,
        address: {
          barangay_id,
          city_id,
          island_group_id,
          province_id,
          locale_id,
        },
        property_category_id,
        project_user_description,
        project_user_title,
        developer_id: property_developer_projects_pivots[0]?.partner_id || null,
        advisors: advisorIds,
      };

      for (const meta of meta_fields) {
        const {
          asset_custom_field_id,
          asset_meta_field_name,
          asset_meta_field_text_value,
        } = meta;

        formFields[`${asset_custom_field_id}:${asset_meta_field_name}`] =
          asset_meta_field_text_value;
      }

      updatePropertyProjectForm.setFieldsValue({ ...formFields });
    }
  }, [
    selectedRow,
    propertyProjectUpdateModalStatus,
    updatePropertyProjectForm,
  ]);

  useEffect(() => {
    if (
      selectedRow &&
      (propertyProjectUpdateModalStatus === true ||
        showAddPropertyProjectsPivot)
    ) {
      const { property_developer_projects_pivots } = selectedRow;
      addPropertyProjectsPivotForm.setFieldsValue({
        property_developer_projects_pivots,
      });
    }
  }, [selectedRow, addPropertyProjectsPivotForm, showAddPropertyProjectsPivot]);

  /**
   *
   * On every selection change, update the row selection state to trigger the use effect hook with row selection dependency
   * It will re-trigger fetch function with the updated row selection and populate forms for updating
   *
   */
  const onSelectionChange = (keys, selectedRows) => {
    setSelectedRow(selectedRows[0]);
    setSelectedRowKey(keys[0]);
  };

  /**
   *
   * Create PropertyProject function with success validation, connected to useCreatePropertyProject hook
   *
   */

  const CreatePropertyProject = async () => {
    const {
      property_projects_name,
      property_category_id,
      developer_id,
      address,
      pseudo_project,
      is_featured,
      project_user_title,
      project_user_description,
      advisors,
      ...custom_fields
    } = await createPropertyProjectForm.validateFields();

    // console.log(advisors, "advisors");

    const project_meta_fields = [];

    for (const field in custom_fields) {
      const custom_field_id = field.split(":")[0];
      const asset_meta_field_name = field.split(":")[1];

      const itemMetaValue = {
        asset_custom_field_id: custom_field_id,
        asset_meta_field_name: asset_meta_field_name,
        asset_meta_field_text_value: custom_fields[field],
      };

      project_meta_fields.push(itemMetaValue);
    }

    const { barangay_id, city_id, island_group_id, province_id, locale_id } =
      address;

    const payload = {
      property_projects_name,
      barangay_id,
      city_id,
      island_group_id,
      province_id,
      locale_id,
      property_category_id,
      developer_id,
      project_meta_fields,
      pseudo_project,
      is_featured,
      project_user_title,
      project_user_description,
      advisors,
    };

    const isSuccess = await useCreatePropertyProject(payload);

    if (isSuccess) {
      createPropertyProjectForm.resetFields();
      const { page, limit, columnFilter, sortColumn, sortType } = queryFilters;
      FetchPropertyProjects(page, limit, columnFilter, sortColumn, sortType);
      onClickCreatePropertyProjectButton();
      resetSelections();
    }
  };

  /**
   *
   * Update PropertyProject function with success validation, connected to useUpdatePropertyProject hook
   *
   */

  const UpdatePropertyProject = async () => {
    const {
      property_projects_name,
      property_category_id,
      developer_id,
      address,
      pseudo_project,
      is_featured,
      project_user_title,
      project_user_description,
      advisors,
      ...custom_fields
    } = await updatePropertyProjectForm.validateFields();

    const project_meta_fields = [];

    for (const field in custom_fields) {
      const custom_field_id = field.split(":")[0];
      const asset_meta_field_name = field.split(":")[1];

      const itemMetaValue = {
        asset_custom_field_id: custom_field_id,
        asset_meta_field_name: asset_meta_field_name,
        asset_meta_field_text_value: custom_fields[field],
      };

      project_meta_fields.push(itemMetaValue);
    }

    const { barangay_id, city_id, island_group_id, province_id, locale_id } =
      address;

    const payload = {
      property_projects_name,
      project_user_title,
      project_user_description,
      barangay_id,
      city_id,
      island_group_id,
      province_id,
      locale_id,
      property_category_id,
      developer_id,
      project_meta_fields,
      pseudo_project,
      is_featured,
      advisors,
    };

    // console.log(payload, "payload");

    const isSuccess = await useUpdatePropertyProject(selectedRowKey, payload);

    if (isSuccess) {
      updatePropertyProjectForm.resetFields();
      const { page, limit, columnFilter, sortColumn, sortType } = queryFilters;
      FetchPropertyProjects(page, limit, columnFilter, sortColumn, sortType);
      onClickUpdatePropertyProjectButton();
      resetSelections();
    }
  };

  const UpdatePropertyProjectTag = (property_project_id, payload) => {
    const Update = (id, payload) => {
      useUpdatePropertyProjectTags(id, payload);
    };

    confirm({
      title: `Are you sure, you want to update this Project?`,
      icon: <InfoCircleTwoTone />,
      okText: "Yes, Update Project",
      okButtonProps: {
        type: "primary",
        danger: false,
      },
      async onOk() {
        Update(property_project_id, payload);
      },
    });
  };

  const UpdatePropertyProjectsPivot = async () => {
    setAddNewPropertyProjectsPivotButtonLoading(true);

    const { property_developer_projects_pivots } =
      await addPropertyProjectsPivotForm.validateFields();

    const isSuccess = await useUpdatePropertyProjectsPivot(selectedRowKey, {
      property_developer_projects_pivots,
    });

    setAddNewPropertyProjectsPivotButtonLoading(false);

    if (isSuccess) {
      showaddPropertyProjectsPivotModal();
      resetSelections();
      const { page, limit, columnFilter, sortColumn, sortType } = queryFilters;
      FetchPropertyProjects(page, limit, columnFilter, sortColumn, sortType);
    }
  };

  const handleIsShowPreviewModalStatusPost = () => {
    setIsShowPreviewModalPost(!isShowPreviewModalPost);
  };

  const handleClickViewPostHTML = (html_content) => {
    setPostHTMLcontent(html_content);
    setIsShowPreviewModalPost(true);
  };

  const DeletePropertyProject = async () => {
    const isSuccess = await useDeletePropertyProject(selectedRowKey);
    if (isSuccess) {
      resetSelections();
      const { page, limit, columnFilter, sortColumn, sortType } = queryFilters;
      FetchPropertyProjects(page, limit, columnFilter, sortColumn, sortType);
    }
  };

  const rolesRestriction = useFetchPermissionSets(
    permissionSet,
    initialUserRolesState
  );

  /**
   *
   * Helper functions:
   *
   * - Functions that will reset all selections, pagination and restore initial index/fetch
   *
   */

  const OnClickDeletePropertyProject = async () => {
    confirm({
      title: `Are you sure, you want to remove this Project?`,
      icon: <ExclamationCircleOutlined />,
      content: `All information attached to this propertyProject will be removed`,
      okText: "Yes, Delete Project",
      okButtonProps: {
        type: "primary",
        danger: true,
      },
      onOk() {
        DeletePropertyProject();
      },
    });
  };

  const showaddPropertyProjectsPivotModal = () => {
    const status = !showAddPropertyProjectsPivot;
    setShowAddPropertyProjectsPivot(status);

    if (status) {
      const { property_developer_projects_pivots } = selectedRow;

      addPropertyProjectsPivotForm.setFieldsValue({
        property_developer_projects_pivots,
      });
    }
  };

  const onClickShowMoreDeveloper = async () => {
    const { current_page, last_page } = developers;
    if (current_page < last_page) {
      const query = { page: parseInt(current_page) + 1, limit: 10 };
      const data = await FetchDevelopers(query);

      const oldDevelopers = developers.data;
      for (const item of data.data) {
        oldDevelopers.push(item);
      }

      const newState = {
        ...data,
        data: oldDevelopers,
      };

      setDevelopers(newState);
      return;
    }
  };

  /**
   *
   * Helper functions:
   *
   * - Functions that will reset all selections, pagination and restore initial index/fetch
   *
   */

  const resetSelections = () => {
    setSelectedRowKey(null);
    setSelectedRow(null);
  };

  const onClickCreatePropertyProjectButton = () => {
    setPropertyProjectCreationModalStatus(!propertyProjectCreationModalStatus);
  };

  const onClickUpdatePropertyProjectButton = () => {
    setPropertyProjectUpdateModalStatus(!propertyProjectUpdateModalStatus);
  };

  const onChangeCities = (city_id) => {
    // functionality triggers when select option of cities changes
    // selected city will be trasnfer to some state
    setSelectedCity(city_id);
  };

  const onChangeProvince = (province_id) => {
    // functionality triggers when select option of cities changes
    // selected city will be trasnfer to some state
    setSelectedProvince(province_id);
  };

  const projectImageUploaderProps = {
    name: "images",
    multiple: true,
    accept: ".webp",
    action: null,
  };

  const HandleProjectImagesUpload = async (e, projectRow) => {
    const { onSuccess, onError, file } = e;

    const { property_projects_id, property_projects_name } = projectRow;

    const formData = new FormData();

    formData.append("image", file);
    formData.append("property_projects_id", property_projects_id);
    formData.append("property_projects_name", property_projects_name);

    const uploadResponse = await useUploadProjectImage(formData);

    const { status } = uploadResponse;

    if (status === "error") {
      onError(uploadResponse);
      await message.error(uploadResponse.message, 4);
      return;
    }

    onSuccess(uploadResponse);
    await message.success(uploadResponse.message, 4);

    const { page, limit, columnFilter, sortColumn, sortType } = queryFilters;
    FetchPropertyProjects(page, limit, columnFilter, sortColumn, sortType);
  };

  const handleDeletionOfMedia = async (id) => {
    confirm({
      title: "Are you sure, you want to delete this image?",
      type: "warning",
      okText: "Yes, Delete image",
      okButtonProps: {
        type: "primary",
        danger: true,
        icon: <DeleteOutlined />,
      },
      onOk: async () => {
        const resp = await DeleteMedia(id);
        if (resp) {
          const { page, limit, columnFilter, sortColumn, sortType } =
            queryFilters;
          FetchPropertyProjects(
            page,
            limit,
            columnFilter,
            sortColumn,
            sortType
          );
        }
      },
    });
  };

  const handleTaggingImageAsFeature = async (
    id,
    property_project_id,
    is_feature
  ) => {
    confirm({
      title: "Are you sure, you want to update this image?",
      type: "info",
      okText: "Yes, Submit",
      okButtonProps: {
        type: "primary",
        icon: <SaveOutlined />,
      },
      onOk: async () => {
        const resp = await UpdateFeaturedMedia(
          id,
          { property_project_id },
          is_feature
        );

        if (resp) {
          const { page, limit, columnFilter, sortColumn, sortType } =
            queryFilters;
          FetchPropertyProjects(
            page,
            limit,
            columnFilter,
            sortColumn,
            sortType
          );
        }
      },
    });
  };

  const handleDeletionOfProjecAdvisortRelation = async (id) => {
    confirm({
      title: "Are you sure, you want to delete this project advisor relation?",
      type: "warning",
      okText: "Yes, Delete Advisor Relation",
      okButtonProps: {
        type: "primary",
        danger: true,
        icon: <DeleteOutlined />,
      },
      onOk: async () => {
        const resp = await DeleteProjectAdvisorRelation(id);
        if (resp) {
          const { page, limit, columnFilter, sortColumn, sortType } =
            queryFilters;
          FetchPropertyProjects(
            page,
            limit,
            columnFilter,
            sortColumn,
            sortType
          );
        }
      },
    });
  };

  const handleUpdatingOfProjectAdvisorRelation = async (id, payload) => {
    confirm({
      title: "Are you sure, you want to update this project advisor relation?",
      type: "info",
      okText: "Yes, Submit",
      okButtonProps: {
        type: "primary",
        icon: <SaveOutlined />,
      },
      onOk: async () => {
        const resp = await UpdateProjectAdvisorRelation(id, payload);

        if (resp) {
          const { page, limit, columnFilter, sortColumn, sortType } =
            queryFilters;
          FetchPropertyProjects(
            page,
            limit,
            columnFilter,
            sortColumn,
            sortType
          );
        }
      },
    });
  };

  return (
    <>
      <PageHeader
        title="Property Project"
        subTitle="Property Project Management"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["Property Project"]} />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <PropertyProjectsFunctionalityComponent
          onClickCreatePropertyProjectButton={
            onClickCreatePropertyProjectButton
          }
          onClickUpdatePropertyProjectButton={
            onClickUpdatePropertyProjectButton
          }
          onClickUpdatePropertyProjectsPivotButton={
            showaddPropertyProjectsPivotModal
          }
          selectedRowKey={selectedRowKey}
          selectedRow={selectedRow}
          onClickDeletePropertyProjectButton={OnClickDeletePropertyProject}
          permissions={permissions}
          rolesRestriction={rolesRestriction}
          cities={cities}
        />

        <ModulesSelectAndColumnTagsComponent
          onClearColumnFilters={onClearColumnFilters}
          isShowColumnFilterTags={columnFilterTags.length}
          columnFilterTags={columnFilterTags}
          isShowSelection={!!selectedRow}
          onClearSearcheAndSelection={resetSelections}
          tags={[
            {
              color: "processing",
              selection_label: "Selected Property Project with name",
              value: selectedRow
                ? `${selectedRow.property_projects_name}`
                : null,
            },
          ]}
        />
        <PropertyProjectListComponent
          handleClickViewPostHTML={handleClickViewPostHTML}
          data={propertyProjectsData}
          tableLoading={tableLoading}
          onPaginationChange={onPaginationChange}
          selectedRowKey={selectedRowKey}
          onSelection={onSelectionChange}
          currentPagination={queryFilters.page}
          onSortChange={onSortChange}
          setColumnFilter={actionsQueryFilters.changeColumnFilter}
          columnFilter={queryFilters.columnFilter}
          UpdatePropertyProjectTag={UpdatePropertyProjectTag}
          projectImageUploaderProps={projectImageUploaderProps}
          handleProjectImagesUpload={HandleProjectImagesUpload}
          deleteMedia={handleDeletionOfMedia}
          updateMedia={handleTaggingImageAsFeature}
          deleteProjectAdvisorRelation={handleDeletionOfProjecAdvisortRelation}
          updateProjectAdvisorRelation={handleUpdatingOfProjectAdvisorRelation}
        />
      </div>
      <PropertyProjectFormsModalFunctionalityComponent
        onSubmitPropertyProjectCreation={CreatePropertyProject}
        onCancelPropertyProjectCreation={onClickCreatePropertyProjectButton}
        propertyProjectModalVisibility={propertyProjectCreationModalStatus}
        propertyProjectCreationForm={createPropertyProjectForm}
        onSubmitPropertyProjectUpdate={UpdatePropertyProject}
        onCancelPropertyProjectUpdate={onClickUpdatePropertyProjectButton}
        propertyProjectUpdatedModalVisibility={propertyProjectUpdateModalStatus}
        propertyProjectUpdateForm={updatePropertyProjectForm}
        cities={cities}
        islandGroup={islandGroup}
        provinces={provinces}
        barangay={barangay}
        locales={locales}
        projectName={projectName}
        propertyCategory={propertyCategory}
        onClickShowMoreDeveloper={onClickShowMoreDeveloper}
        onChangeCities={onChangeCities}
        onChangeProvince={onChangeProvince}
        developers={developers}
        projectCustomFields={projectCustomFields}
        advisors={advisors}
      />
      {selectedRow !== null ? (
        <>
          <AddNewPropertyDeveloperProjectsPivotComponent
            onClickShowMoreDeveloper={onClickShowMoreDeveloper}
            isVisible={showAddPropertyProjectsPivot}
            handleOk={UpdatePropertyProjectsPivot}
            handleCancel={showaddPropertyProjectsPivotModal}
            loading={addNewPropertyProjectsPivotButtonLoading}
            form={addPropertyProjectsPivotForm}
            developers={developers}
            selectedRowKeys={selectedRowKey}
          />
        </>
      ) : null}
      <PropertyProjectModalContentComponent
        visible={isShowPreviewModalPost}
        onOk={handleIsShowPreviewModalStatusPost}
        content={postHTMLcontent}
      />
    </>
  );
};
