import { EyeOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { ListingListAssetComponent } from "../../components/sub_components/listings/ListingListAssetComponent";
import { Button, Tag } from "antd";

const columnCallback = (handleClickViewPostHTML) => {
  return [
    {
      title: "Asset Listing ID",
      dataIndex: "asset_listing_id",
      key: "asset_listing_id",
    },
    {
      title: "Assets",
      key: "actions",
      render: (row) => (
        <Button
          type="primary"
          size="small"
          icon={<EyeOutlined />}
          onClick={() =>
            handleClickViewPostHTML(
              <ListingListAssetComponent
                asset={row.asset}
                asset_listing_id={row.asset_listing_id}
              />
            )
          }
        >
          View Asset
        </Button>
      ),
    },
    {
      title: "Asset Listing Price Min",
      dataIndex: "asset_listing_price_min",
      key: "asset_listing_price_min",
    },
    {
      title: "Asset Listing Price Max",
      dataIndex: "asset_listing_price_max",
      key: "asset_listing_price_max",
    },
    {
      title: "Asset Listing Price Sqm",
      dataIndex: "asset_listing_price_sqm",
      key: "asset_listing_price_sqm",
    },
    {
      title: "Listing Method",
      dataIndex: "listing_method",
      key: "listing_method",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) =>
        status ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },
  ];
};

export default function useGetListingContentsColumns(handleClickViewPostHTML) {
  let columns = columnCallback(handleClickViewPostHTML);
  const [tableColumns, setColumns] = useState(columns);

  useEffect(() => {
    setColumns(columns);
  }, []);

  return tableColumns;
}
