import React from "react";
import { ModulesTableComponent } from "../../ModulesTableComponent";
import useColumnFilter from "../../../hooks/useColumnFilter";
import useGetProvinceColumns from "../../../hooks/provinces/useGetProvinceColumns";
import useFilteredColumns from "../../../hooks/useFilteredColumns";

export const ProvincesListComponent = (props) => {
  const {
    data = { data: [], total: 0 },
    onPaginationChange,
    currentPagination,
    onSelection,
    selectedRowKey,
    onSortChange,
    columnFilter,
    setColumnFilter,
    tableLoading,
  } = props;

  const { getColumnFilterProps } = useColumnFilter(
    columnFilter,
    setColumnFilter
  );

  const provincesColumns = useGetProvinceColumns();

  let newCols = useFilteredColumns(provincesColumns, getColumnFilterProps);

  return (
    <>
      <ModulesTableComponent
        data={data}
        onPaginationChange={onPaginationChange}
        onSelection={onSelection}
        selectedRowKey={selectedRowKey}
        tableColumns={newCols || []}
        currentPagination={currentPagination}
        columnFilter={columnFilter}
        setColumnFilter={setColumnFilter}
        onSortChange={onSortChange}
        tableLoading={tableLoading}
      />
    </>
  );
};
