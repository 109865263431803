import React from "react";
import { ModulesTableComponent } from "../../ModulesTableComponent";
import useColumnFilter from "../../../hooks/useColumnFilter";
import useGetBarangayColumns from "../../../hooks/barangay/useGetBarangayColumns";
import useFilteredColumns from "../../../hooks/useFilteredColumns";

export const BarangayListCompomentManagement = (props) => {
  const {
    data = { data: [], total: 0 },
    onPaginationChange,
    currentPagination,
    onSelection,
    selectedRowKey,
    onSortChange,
    columnFilter,
    setColumnFilter,
    tableLoading,
  } = props;

  const reviewsColumn = useGetBarangayColumns();

  const { getColumnFilterProps } = useColumnFilter(
    columnFilter,
    setColumnFilter
  );

  let newCols = useFilteredColumns(reviewsColumn, getColumnFilterProps);

  return (
    <ModulesTableComponent
      data={data}
      onPaginationChange={onPaginationChange}
      onSelection={onSelection}
      selectedRowKey={selectedRowKey}
      tableColumns={newCols || []}
      currentPagination={currentPagination}
      columnFilter={columnFilter}
      setColumnFilter={setColumnFilter}
      onSortChange={onSortChange}
      tableLoading={tableLoading}
    />
  );
};
