import React from "react";

import { CityFormModalComponent } from "./CityFormModalComponent";

export const CityFormsModalFunctionalityComponent = ({
  onSubmitCityCreation,
  onCancelCityCreation,
  cityModalVisibility,
  cityCreationForm,
  onSubmitCityUpdate,
  onCancelCityUpdate,
  cityUpdatedModalVisibility,
  cityUpdateForm,
  provinces = { data: [], total: 0 },
  onClickShowMoreProvince,
  isLoadingProvinces,
  urbans,
}) => {
  return (
    <>
      <CityFormModalComponent
        form={cityCreationForm}
        isVisible={cityModalVisibility}
        onClickSubmit={onSubmitCityCreation}
        onClickCancel={onCancelCityCreation}
        provinces={provinces}
        onClickShowMoreProvince={onClickShowMoreProvince}
        isLoadingProvinces={isLoadingProvinces}
        urbans={urbans}
      />
      <CityFormModalComponent
        form={cityUpdateForm}
        isVisible={cityUpdatedModalVisibility}
        onClickSubmit={onSubmitCityUpdate}
        onClickCancel={onCancelCityUpdate}
        provinces={provinces}
        onClickShowMoreProvince={onClickShowMoreProvince}
        isUpdate={true}
        isLoadingProvinces={isLoadingProvinces}
        urbans={urbans}
      />
    </>
  );
};
