import { message } from "antd";
import {
  FetchPropertySubCategory,
  CreatePropertySubCategory,
  UpdatePropertySubCategory,
  QueryPropertySubCategory,
  DeletePropertySubCategory,
} from "../../api/PropertySubCategory";

export const useFetchPropertySubCategory = async (query, isForTable) => {
  const _query = {
    page: 1,
    limit: "ALL",
    search_value: null,
  };

  const _finalQuery = isForTable ? query : _query;

  const data = await FetchPropertySubCategory(_finalQuery);

  for (const item of data.data.data) {
    item["key"] = item.property_category_id;
  }

  return isForTable ? data.data : data.data.data;
};

export const useCreatePropertySubCategory = async (payload) => {
  await message.loading("Creating Property Category", 2);

  const response = await CreatePropertySubCategory(payload);

  if (response.status === 201) {
    await message.success(response.data.message, 3);
    return true;
  }

  const parsedResponse = JSON.parse(response.response);
  await message.error(parsedResponse.message, 3);
  return false;
};

export const useUpdatePropertySubCategory = async (property_id, payload) => {
  await message.loading("Updating Property Category", 2);
  const response = await UpdatePropertySubCategory(property_id, payload);
  if (response.status === 200) {
    await message.success(response.data.message, 3);
    return true;
  }

  const parsedResponse = JSON.parse(response.response);
  await message.error(parsedResponse.message, 3);
};

export const useQueryPropertySubCategory = async (query) => {
  const data = await QueryPropertySubCategory(query);
  for (const item of data.data.data) {
    item["key"] = item.property_category_id;
  }
  return data.data;
};

export const useDeletePropertySubCategory = async (property_id) => {
  await message.loading("Deleting Property Category", 2);
  const response = await DeletePropertySubCategory(property_id);

  if (response.status === 200) {
    await message.success(response.data.message, 3);
    return true;
  }

  const parsedResponse = JSON.parse(response.response);
  await message.error(parsedResponse.message, 3);
};
