import { useState } from "react";
import { useCreate } from "../useCreate";
import { CREATE_CITY_URL } from "../../api/routes/cities";
import { message } from "antd";
import { useAPIResponse } from "../useAPIResponse";

const useCreateCity = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(null);

  const CreateCity = async (payload) => {
    setIsLoading(true);
    await message.loading("Processing Creation of City", 3);
    const response = await useCreate(CREATE_CITY_URL, payload);
    const isCreationSuccess = useAPIResponse(response);

    if (!isCreationSuccess) {
      setIsError(true);
      setIsLoading(false);
      return isCreationSuccess;
    }

    setIsError(false);
    setIsLoading(false);
    return isCreationSuccess;
  };

  return {
    CreateCity,
    isError,
    isLoading,
  };
};

export default useCreateCity;
