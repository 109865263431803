import React, { useEffect, useState } from "react";
import { Divider, PageHeader, Form, Modal } from "antd";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { AssetInquiriesListComponent } from "../sub_components/asset_inquiries/AssetInquiriesListComponent";
import { AssetInquiriesFormsModalFunctionalityComponent } from "../sub_components/asset_inquiries/AssetInquiriesFormsModalFunctionalityComponent";
import {
  useCreateAssetInquiries,
  useDeleteAssetInquiries,
  useFetchAssetInquiries,
  useUpdateAssetInquiries,
} from "../../hooks/asset_inquiries/useAssetInquiriesAPI";
import { useQueryFilterReducer } from "../../hooks/useQueryFilterReducer";
import { ModulesSelectAndColumnTagsComponent } from "../ModulesSelectAndColumnTagsComponent";
import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";
import { AssetInquiriesModalContentComponent } from "../sub_components/asset_inquiries/AssetInquiriesModalContentComponent";
import { useFetchAssets } from "../../hooks/assets/useAssetAPI";
import { useFetchUsers } from "../../hooks/users/useUserAPI";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { confirm } = Modal;
const initialAssetInquiriesData = { page: 1, total: 0, data: [] };

export const AssetInquiriesComponent = () => {
  const [createAssetInquiriesForm] = Form.useForm();
  const [updateAssetInquiriesForm] = Form.useForm();
  const [
    assetInquiriesCreationModalStatus,
    setAssetInquiriesCreationModalStatus,
  ] = useState(false);
  const [assetInquiriesUpdateModalStatus, setAssetInquiriesUpdateModalStatus] =
    useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const [postHTMLcontent, setPostHTMLcontent] = useState(null);
  const [modalTitle, setModalTitle] = useState(null);
  const [isShowPreviewModalPost, setIsShowPreviewModalPost] = useState(false);

  const [users, setUsers] = useState({ data: [], total: 0 });
  const [assets, setAssets] = useState({ data: [], total: 0 });

  const [asset_inquiriesData, setAssetInquiriesData] = useState(
    initialAssetInquiriesData
  );

  const {
    queryFilters,
    actionsQueryFilters,
    onPaginationChange,
    onSortChange,
    columnFilterTags,
    onClearColumnFilters,
    onLoadColumnFilterTags,
  } = useQueryFilterReducer();

  /**
   * Functionalities
   *
   */

  const handleIsShowPreviewModalStatusPost = () => {
    setIsShowPreviewModalPost(!isShowPreviewModalPost);
  };

  const handleClickViewPostHTML = (html_content) => {
    setPostHTMLcontent(html_content);
    setIsShowPreviewModalPost(true);
  };

  const DeleteAssetInquiries = async () => {
    const isSuccess = await useDeleteAssetInquiries(selectedRowKey);
    if (isSuccess) {
      resetSelections();
      FetchAssetInquiries();
    }
  };

  /**
   *
   * Create assetInquiries function for fetch reusability
   *
   */
  const FetchAssetInquiries = async (
    page = 1,
    limit = 10,
    columnFilter,
    sortColumn,
    sortType
  ) => {
    /**
     *
     * Construct Query parameters for fetching
     *
     */
    setTableLoading(true);
    const query = {
      page: page,
      limit: limit,
      sortColumn: sortColumn,
      sortType: sortType,
      columnFilter: columnFilter,
    };
    const data = await useFetchAssetInquiries(true, query);
    /**
     *
     * Create delay on updating of state for better transition and visualization on Frontend
     *
     */
    setTimeout(() => {
      setAssetInquiriesData(data);
      setTableLoading(false);
    }, 800);
  };

  const FetchUsers = async (page = 1, limit = 10) => {
    const query = { page: page, limit: limit };
    const data = await useFetchUsers(true, query);

    setUsers(data);
    return data;
  };

  const FetchAssets = async (page = 1, limit = 10) => {
    const query = { page: page, limit: limit };
    const data = await useFetchAssets(true, query);

    setAssets(data);
    return data;
  };

  /**
   *
   * Initial AssetInquiries Fetch
   *    - Fetch with 1 page, limit 10 with no filter as query parameters
   */

  useEffect(() => {
    FetchAssetInquiries();
    FetchUsers();
    FetchAssets();
  }, []);

  useComponentDidUpdate(() => {
    const { page, limit, columnFilter, sortColumn, sortType } = queryFilters;

    onLoadColumnFilterTags();
    FetchAssetInquiries(page, limit, columnFilter, sortColumn, sortType);
  }, [queryFilters]);

  /**
   *
   * Use effect hooks if any of the data is selected
   * Selected row will populate the form for updating
   *
   */
  useEffect(() => {
    if (selectedRow && assetInquiriesUpdateModalStatus === true) {
      updateAssetInquiriesForm.setFieldsValue(selectedRow);
    }
  }, [selectedRow, assetInquiriesUpdateModalStatus, updateAssetInquiriesForm]);

  /**
   *
   * On every selection change, update the row selection state to trigger the use effect hook with row selection dependency
   * It will re-trigger fetch function with the updated row selection and populate forms for updating
   *
   */
  const onSelectionChange = (keys, selectedRows) => {
    setSelectedRow(selectedRows[0]);
    setSelectedRowKey(keys[0]);
  };

  /**
   *
   * Create AssetInquiries function with success validation, connected to useCreateAssetInquiries hook
   *
   */

  const CreateAssetInquiries = async () => {
    const payload = await createAssetInquiriesForm.validateFields();

    const isSuccess = await useCreateAssetInquiries(payload);
    if (isSuccess) {
      createAssetInquiriesForm.resetFields();
      const { page, limit, columnFilter, sortColumn, sortType } = queryFilters;
      FetchAssetInquiries(page, limit, columnFilter, sortColumn, sortType);
      onClickCreateAssetInquiriesButton();
      resetSelections();
    }
  };

  /**
   *
   * Update AssetInquiries function with success validation, connected to useUpdateAssetInquiries hook
   *
   */

  const UpdateAssetInquiries = async () => {
    const payload = await updateAssetInquiriesForm.validateFields();

    const isSuccess = await useUpdateAssetInquiries(selectedRowKey, payload);

    if (isSuccess) {
      updateAssetInquiriesForm.resetFields();
      const { page, limit, columnFilter, sortColumn, sortType } = queryFilters;
      FetchAssetInquiries(page, limit, columnFilter, sortColumn, sortType);
      onClickUpdateAssetInquiriesButton();
      resetSelections();
    }
  };

  const OnClickDeleteAssetInquiries = async () => {
    confirm({
      title: `Are you sure, you want to remove this asset inquiries?`,
      icon: <ExclamationCircleOutlined />,
      content: `All information attached to this asset inquiries will be removed`,
      onOk() {
        DeleteAssetInquiries();
      },
    });
  };

  const onClickShowMoreUser = async () => {
    const { current_page, last_page } = users;
    if (current_page < last_page) {
      const query = { page: parseInt(current_page) + 1, limit: 10 };
      const data = await FetchUsers(query);

      const oldUsers = users.data;
      for (const item of data.data) {
        oldUsers.push(item);
      }

      const newState = {
        ...data,
        data: oldUsers,
      };

      setUsers(newState);
      return;
    }
  };

  const onClickShowMoreAsset = async () => {
    const { current_page, last_page } = assets;
    if (current_page < last_page) {
      const query = { page: parseInt(current_page) + 1, limit: 10 };
      const data = await FetchAssets(query);

      const oldAssets = assets.data;
      for (const item of data.data) {
        oldAssets.push(item);
      }

      const newState = {
        ...data,
        data: oldAssets,
      };

      setAssets(newState);
      return;
    }
  };

  /**
   *
   * Helper functions:
   *
   * - Functions that will reset all selections, pagination and restore initial index/fetch
   *
   */

  const resetSelections = () => {
    setSelectedRowKey(null);
    setSelectedRow(null);
  };

  const onClickCreateAssetInquiriesButton = () => {
    setAssetInquiriesCreationModalStatus(!assetInquiriesCreationModalStatus);
  };

  const onClickUpdateAssetInquiriesButton = () => {
    setAssetInquiriesUpdateModalStatus(!assetInquiriesUpdateModalStatus);
  };

  return (
    <>
      <PageHeader
        title="Asset Inquiries"
        subTitle="Asset Inquiries Management"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["Asset Inquiries"]} />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        {/* <AssetInquiriesFunctionalityComponent
          onClickCreateAssetInquiriesButton={onClickCreateAssetInquiriesButton}
          onClickUpdateAssetInquiriesButton={onClickUpdateAssetInquiriesButton}
          onClickDeleteAssetInquiriesButton={OnClickDeleteAssetInquiries}
          selectedRowKey={selectedRowKey}
          selectedRow={selectedRow}
        /> */}

        <ModulesSelectAndColumnTagsComponent
          onClearColumnFilters={onClearColumnFilters}
          isShowColumnFilterTags={columnFilterTags.length}
          columnFilterTags={columnFilterTags}
          isShowSelection={!!selectedRow}
          onClearSearcheAndSelection={resetSelections}
          tags={[
            {
              color: "processing",
              selection_label: "Selected Asset Inquiries Alternate Number",
              value: selectedRow ? `${selectedRow.alternate_number}` : null,
            },
          ]}
        />
        <AssetInquiriesListComponent
          data={asset_inquiriesData}
          tableLoading={tableLoading}
          onPaginationChange={onPaginationChange}
          selectedRowKey={selectedRowKey}
          onSelection={onSelectionChange}
          currentPagination={queryFilters.page}
          onSortChange={onSortChange}
          setColumnFilter={actionsQueryFilters.changeColumnFilter}
          columnFilter={queryFilters.columnFilter}
          handleClickViewPostHTML={handleClickViewPostHTML}
          setModalTitle={setModalTitle}
        />
      </div>
      <AssetInquiriesFormsModalFunctionalityComponent
        onSubmitAssetInquiriesCreation={CreateAssetInquiries}
        onCancelAssetInquiriesCreation={onClickCreateAssetInquiriesButton}
        assetInquiriesModalVisibility={assetInquiriesCreationModalStatus}
        assetInquiriesCreationForm={createAssetInquiriesForm}
        onSubmitAssetInquiriesUpdate={UpdateAssetInquiries}
        onCancelAssetInquiriesUpdate={onClickUpdateAssetInquiriesButton}
        assetInquiriesUpdatedModalVisibility={assetInquiriesUpdateModalStatus}
        assetInquiriesUpdateForm={updateAssetInquiriesForm}
        onClickShowMoreUser={onClickShowMoreUser}
        users={users}
        onClickShowMoreAsset={onClickShowMoreAsset}
        assets={assets}
      />

      <AssetInquiriesModalContentComponent
        visible={isShowPreviewModalPost}
        onOk={handleIsShowPreviewModalStatusPost}
        content={postHTMLcontent}
        title={modalTitle}
      />
    </>
  );
};
