import { useFetch } from "../hooks/useFetch";
import { useCreate } from "../hooks/useCreate";
import { useUpdate } from "../hooks/useUpdate";
import {
  FETCH_PROVINCE_URL,
  CREATE_PROVINCE_URL,
  UPDATE_PROVINCE_URL,
  DELETE_PROVINCE_URL,
} from "../api/routes/provinces";
import { useDelete } from "../hooks/useDelete";

export const FetchProvinces = async (query) => {
  // console.log("fetching... ", query)
  try {
    return await useFetch(FETCH_PROVINCE_URL, query);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const CreateProvince = async (payload) => {
  try {
    return await useCreate(CREATE_PROVINCE_URL, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const UpdateProvince = async (province_id, payload) => {
  try {
    return await useUpdate(`${UPDATE_PROVINCE_URL}/${province_id}`, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const DeleteProvince = async (province_id) => {
  try {
    return await useDelete(`${DELETE_PROVINCE_URL}/${province_id}`);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
