import React from "react";
import { Form, Button, Divider, Select } from "antd";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { SelectOptionFooterExtraButtonComponent } from "../../SelectOptionFooterExtraButtonComponent";
const { Option } = Select;

export const AddNewPropertyDeveloperProjectsPivotFormComponent = (props) => {
  const { developers, onClickShowMoreDeveloper, selectedRowKeys } = props;

  return (
    <>
      <div
        style={{
          overflowX: "hidden",
          overflowY: "auto",
          height: "300px",
        }}
      >
        <Form.List name="property_developer_projects_pivots">
          {(fields, { add, remove }) => (
            <>
              {fields.map((field, index) => (
                <React.Fragment key={[field.fieldKey, "frag"]}>
                  <div style={{ marginTop: "2%" }}>
                    <Form.Item
                      label="Developer"
                      {...field}
                      name={[field.name, "partner_id"]}
                      key={[field.fieldKey, "partner_id"]}
                      fieldKey={[field.fieldKey, "partner_id"]}
                    >
                      <Select
                        placeholder="Select Developer"
                        dropdownRender={(menu) => (
                          <SelectOptionFooterExtraButtonComponent
                            key="show_more"
                            menu={menu}
                            onClickShowMoreList={onClickShowMoreDeveloper}
                            isClickShowMoreDisabled={
                              developers.data.length < developers.total
                                ? false
                                : true
                            }
                          />
                        )}
                      >
                        {developers.data.map((item, index) => (
                          <Option key={index} value={item.partner_id}>
                            {item.partner_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      hidden={true}
                      {...field}
                      name={[
                        field.name,
                        "property_developer_projects_pivot_id",
                      ]}
                      key={[
                        field.fieldKey,
                        "property_developer_projects_pivot_id",
                      ]}
                      fieldKey={[
                        field.fieldKey,
                        "property_developer_projects_pivot_id",
                      ]}
                      initialValue={0}
                    />
                    <Form.Item
                      hidden={true}
                      {...field}
                      name={[field.name, "property_projects_id"]}
                      key={[field.fieldKey, "property_projects_id"]}
                      fieldKey={[field.fieldKey, "property_projects_id"]}
                      initialValue={selectedRowKeys}
                    />
                    <Button
                      key={`delete-${index}`}
                      type="default"
                      size="small"
                      icon={<DeleteOutlined />}
                      onClick={() => remove(field.name)}
                      danger
                      block
                    >
                      Remove Entry
                    </Button>
                  </div>
                  <Divider />
                </React.Fragment>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  block
                  icon={<PlusOutlined />}
                >
                  Add Developer (Entry)
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
      </div>
    </>
  );
};
