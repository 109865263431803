import React from "react";
import { Upload } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import extractPermission from "../../../dist/functions/extractPermission";

const { Dragger } = Upload;
export const FileDraggerComponent = ({
  props,
  validationStatus,
  permissions,
}) => {
  const isHideValidateData = extractPermission(permissions, "VALIDATE_DATA");
  const isHideUploading = extractPermission(permissions, "UPSERT_DATA");

  return (
    <>
      {!isHideValidateData && !isHideUploading ? (
        validationStatus !== "success" ? (
          <Dragger {...props}>
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </Dragger>
        ) : null
      ) : null}
    </>
  );
};
