import React from "react";
import { Form, Input } from "antd";
const { TextArea } = Input;

export const UserUpdateGeneralInformation = ({ isHidden }) => {
  return (
    <div style={{ display: isHidden ? "none" : null }}>
      <Form.Item name="users_first_name" label="Firstname">
        <Input />
      </Form.Item>
      <Form.Item name="users_middle_name" label="Middlename">
        <Input />
      </Form.Item>
      <Form.Item name="users_last_name" label="Lastname">
        <Input />
      </Form.Item>
      <Form.Item name="users_email" label="Email Address">
        <Input />
      </Form.Item>
      <Form.Item name="users_address" label="Address">
        <TextArea rows={4} />
      </Form.Item>
      <Form.Item name="users_mobile" label="Mobile">
        <Input />
      </Form.Item>

      <Form.Item name="users_referrer" label="Referrer">
        <Input />
      </Form.Item>
    </div>
  );
};
