import React from "react";
import { List, Collapse } from "antd";

const { Panel } = Collapse;

export const ItemLogsComponent = (props) => {
  const { forCreationList = [], forUpdateList = [] } = props;

  return (
    <>
      {forCreationList.length > 0 || forUpdateList.length > 0 ? (
        <>
          {"Validation Logs"}
          <br />
          <br />
          <Collapse defaultActiveKey={[]} ghost>
            <Panel
              header={`Assets For Creation (${forCreationList.length})`}
              key="1"
            >
              <List
                size="small"
                dataSource={forCreationList}
                renderItem={(item) => <List.Item>{item.number}</List.Item>}
              />
            </Panel>
            <Panel
              header={`Assets For Update (${forUpdateList.length})`}
              key="2"
            >
              <List
                size="small"
                dataSource={forUpdateList}
                renderItem={(item) => <List.Item>{item.number}</List.Item>}
              />
            </Panel>
          </Collapse>
        </>
      ) : null}
    </>
  );
};
