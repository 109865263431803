import React, { useState, useEffect, useRef, useCallback } from "react";
import { PageHeader, Divider } from "antd";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { ActivityLogsListComponent } from "../sub_components/activity_logs/ActivityLogsListComponent";
import useGetActivityLogs from "../../hooks/activity_logs/useGetActivityLogs";
import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";
import { ModulesFunctionalitiesComponent } from "../ModulesFunctionalitiesComponent";
import { ModulesFilterAndSelectionComponent } from "../ModulesFilterAndSelectionComponent";
import { ModulesSelectAndColumnTagsComponent } from "../ModulesSelectAndColumnTagsComponent";

const initialTableState = {
  data: [],
  total: 0,
};
export const ActivityLogsManagementComponent = () => {
  const [activities, setActivities] = useState(initialTableState);

  const [searchParameters, setSearchParameters] = useState({
    filter: "",
  });

  const [isShowSelection, setIsShowSelection] = useState(false);
  const [searchTags, setSearchTags] = useState([]);

  const [isShowColumnFilter, setIsShowColumnFilter] = useState(false);
  const [columnFilterTags, setColumnFilterTags] = useState([]);

  const [currentPagination, setCurrentPagination] = useState(1);
  const [currentCount, setCurrentCount] = useState(10);
  const [columnFilter, setColumnFilter] = useState([]);
  const sortColumn = useRef(null);
  const sortType = useRef("asc");

  const setSortColumn = (value) => {
    sortColumn.current = value;
  };

  const setSortType = (value) => {
    if (value === "descend") {
      sortType.current = "desc";
    } else if (value === "ascend") {
      sortType.current = "asc";
    } else {
      sortType.current = "none";
    }
  };

  const onSortChange = async (sortCol, sortType) => {
    setSortColumn(sortCol);
    setSortType(sortType);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    await fetchActivities(currentPagination, currentCount);
  };

  const fetchActivities = async (page = 1, limit = 10) => {
    setCurrentPagination(page);
    const query = {
      page: page,
      limit: limit,
      sortColumn: sortColumn.current,
      sortType: sortType.current,
      columnFilter: columnFilter,
      search_value: searchParameters.filter,
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const data = await useGetActivityLogs(query);
    setActivities(data);
  };

  const onPaginationChange = (page, limit) => {
    setCurrentPagination(page);
    setCurrentCount(limit);
    fetchActivities(page, limit);
  };

  ///////////////////////////////
  // for column filter tags
  const onCloseColumnFilterTag = (id) => {
    setColumnFilterTags((prevState) => {
      return prevState.filter((item) => item.id !== id);
    });

    setColumnFilter((prevState) => {
      let newData = prevState.filter((item) => {
        return Array.isArray(item.id)
          ? JSON.stringify(item.id) != id
          : item.id != id;
      });

      return newData;
    });

    if (!columnFilterTags.length) {
      setIsShowColumnFilter(false);
    }
  };

  const onLoadColumnFilterTags = () => {
    if (columnFilter.length) {
      let filterTags = columnFilter.map((e) => {
        let [label, tagId] = Array(2).fill(e.id);

        if (Array.isArray(e.id)) {
          tagId = JSON.stringify(tagId);
          label = "";

          for (const wordLabel of e.id) {
            label += `${wordLabel} `;
          }
        }

        return {
          color: "processing",
          closable: true,
          selection_label: label,
          value: e.value,
          id: tagId,
          onClose: onCloseColumnFilterTag,
        };
      });

      setColumnFilterTags(filterTags);
      setIsShowColumnFilter(true);
    } else {
      // if columnFilter is just blank, then remove the tags
      setColumnFilterTags([]);
      setIsShowColumnFilter(false);
    }
  };

  const onClearColumnFilters = () => {
    setColumnFilterTags([]);
    setIsShowColumnFilter(false);
    setColumnFilter([]);
  };

  /////////////////////////////////////
  // for search button tags
  const onCloseIncludesFilterSearchTag = () => {
    setSearchParameters({ filter: "" });
    // setSearchTags((prevState) => {
    //   return prevState.filter(
    //     (item) => item.selection_label !== "Includes Value"
    //   );
    // });
    // setIsShowSelection(false);
  };

  const onLoadFiltersAndSelection = useCallback((filter) => {
    if (filter) {
      // set tags state for the filters
      setSearchTags(() => {
        return [
          {
            color: "blue",
            closable: true,
            selection_label: "Includes Value",
            value: filter,
            onClose: onCloseIncludesFilterSearchTag,
          },
        ];
      });

      // set is show selection tag
      setIsShowSelection(true);
    } else {
      // if search value is just blank, then remove the tags
      setSearchTags([]);
      setIsShowSelection(false);
    }
  }, []);

  const onClearSearchAndSelection = () => {
    setSearchTags([]);
    setIsShowSelection(false);
    setSearchParameters({ filter: "" });
    fetchActivities();
  };

  const onSearchReviews = (search_value) => {
    setSearchParameters({ filter: search_value });

    // reset the search input field after submittion of search criteria
  };

  const functionalitiesComponent = [
    {
      component_type: "search_input",
      place_holder: "Search Activity Logs",
      _onSearch: onSearchReviews,
    },
  ];

  useComponentDidUpdate(async () => {
    const { filter } = searchParameters;
    await fetchActivities(1, currentCount);
    onLoadFiltersAndSelection(filter);
  }, [searchParameters, onLoadFiltersAndSelection]);

  useEffect(() => {
    fetchActivities();
  }, []);

  useComponentDidUpdate(() => {
    fetchActivities(1, currentCount);

    onLoadColumnFilterTags();
  }, [columnFilter]);

  return (
    <>
      <PageHeader
        title="Activity"
        subTitle="Activity/Logs Management"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["Activity"]} />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <ModulesFunctionalitiesComponent
          functionalitiesComponent={functionalitiesComponent}
          alignment="right"
          spaceSize={0}
        />

        <ModulesSelectAndColumnTagsComponent
          onClearColumnFilters={onClearColumnFilters}
          isShowColumnFilterTags={isShowColumnFilter}
          columnFilterTags={columnFilterTags}
          onClearSearcheAndSelection={onClearSearchAndSelection}
          isShowSelection={isShowSelection}
          tags={searchTags}
        />

        <ActivityLogsListComponent
          data={activities}
          onPaginationChange={onPaginationChange}
          currentPagination={currentPagination}
          columnFilter={columnFilter}
          setColumnFilter={setColumnFilter}
          onSortChange={onSortChange}
        />
      </div>
    </>
  );
};
