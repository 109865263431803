import React from "react";
import { PropertyProjectFormModalComponent } from "./PropertyProjectFormModalComponent";

export const PropertyProjectFormsModalFunctionalityComponent = (props) => {
  const {
    onSubmitPropertyProjectCreation,
    onCancelPropertyProjectCreation,
    propertyProjectModalVisibility,
    propertyProjectCreationForm,
    onSubmitPropertyProjectUpdate,
    onCancelPropertyProjectUpdate,
    propertyProjectUpdatedModalVisibility,
    propertyProjectUpdateForm,
    developers,
    cities,
    islandGroup,
    provinces,
    barangay,
    locales,
    propertyCategory,
    onChangeCities,
    onChangeProvince,
    projectCustomFields,
    advisors = [],
  } = props;

  return (
    <>
      <PropertyProjectFormModalComponent
        form={propertyProjectCreationForm}
        isVisible={propertyProjectModalVisibility}
        onClickSubmit={onSubmitPropertyProjectCreation}
        onClickCancel={onCancelPropertyProjectCreation}
        cities={cities}
        islandGroup={islandGroup}
        provinces={provinces}
        barangay={barangay}
        developers={developers}
        onChangeCities={onChangeCities}
        onChangeProvince={onChangeProvince}
        propertyCategory={propertyCategory}
        projectCustomFields={projectCustomFields}
        locales={locales}
        advisors={advisors}
      />
      <PropertyProjectFormModalComponent
        form={propertyProjectUpdateForm}
        isVisible={propertyProjectUpdatedModalVisibility}
        onClickSubmit={onSubmitPropertyProjectUpdate}
        onClickCancel={onCancelPropertyProjectUpdate}
        isUpdate={true}
        cities={cities}
        islandGroup={islandGroup}
        provinces={provinces}
        barangay={barangay}
        developers={developers}
        propertyCategory={propertyCategory}
        onChangeCities={onChangeCities}
        onChangeProvince={onChangeProvince}
        projectCustomFields={projectCustomFields}
        advisors={advisors}
        locales={locales}
      />
    </>
  );
};
