import { EyeOutlined } from "@ant-design/icons";
import { Button, Tag } from "antd";
import { useEffect, useState } from "react";
import { AgentMetaFieldListDynamicComponent } from "../../components/sub_components/agent_meta_fields/AgentMetaFieldListDynamicComponent";

const columnCallback = (handleClickViewPostHTML, setModalTitle) => {
  return [
    {
      title: "Agent Meta Field ID",
      dataIndex: "agent_meta_field_id",
      key: "agent_meta_field_id",
    },

    {
      title: "Agent Custom Field Label",
      dataIndex: ["agent_custom_fields", "agent_custom_field_label"],
      key: ["agent_custom_fields", "agent_custom_field_label"],
    },
    // {
    //   title: "Users Email",
    //   dataIndex: ["users", "users_email"],
    //   key: ["users", "users_email"],
    // },
    {
      title: "Agent Meta Field Name",
      dataIndex: "agent_meta_field_name",
      key: "agent_meta_field_name",
    },
    {
      title: "Agent Meta Field Text Value",
      dataIndex: "agent_meta_field_text_value",
      key: "agent_meta_field_text_value",
    },
    {
      title: "Agent Meta Field Num Value",
      dataIndex: "agent_meta_field_num_value",
      key: "agent_meta_field_num_value",
    },
    {
      title: "Agent Meta Field Type",
      dataIndex: "agent_meta_field_type",
      key: "agent_meta_field_type",
    },

    {
      title: "Date Creation",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Last Modified Date",
      dataIndex: "updated_at",
      key: "updated_at",
    },
  ];
};

export default function useGetAgentMetaFieldColumns(
  handleClickViewPostHTML,
  setModalTitle
) {
  let columns = columnCallback(handleClickViewPostHTML, setModalTitle);
  const [tableColumns, setColumns] = useState(columns);

  useEffect(() => {
    setColumns(columns);
  }, []);

  return tableColumns;
}
