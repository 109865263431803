import { useFetch } from "../hooks/useFetch";
import { useCreate } from "../hooks/useCreate";
import { useUpdate } from "../hooks/useUpdate";
import {
  FETCH_REGION_URL,
  CREATE_REGION_URL,
  UPDATE_REGION_URL,
  DELETE_REGION_URL,
} from "../api/routes/regions";
import { useDelete } from "../hooks/useDelete";

export const FetchRegions = async (query) => {
  try {
    return await useFetch(FETCH_REGION_URL, query);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const CreateRegion = async (payload) => {
  try {
    return await useCreate(CREATE_REGION_URL, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const UpdateRegion = async (region_id, payload) => {
  try {
    return await useUpdate(`${UPDATE_REGION_URL}/${region_id}`, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const DeleteRegion = async (region_id) => {
  try {
    return await useDelete(`${DELETE_REGION_URL}/${region_id}`);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
