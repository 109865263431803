import { useFetch } from "../hooks/useFetch";
import { useCreate } from "../hooks/useCreate";
import { useUpdate } from "../hooks/useUpdate";
import { useDelete } from "../hooks/useDelete";
import {
  FETCH_ASSET_INQUIRIES_URL,
  CREATE_ASSET_INQUIRIES_URL,
  UPDATE_ASSET_INQUIRIES_URL,
  DELETE_ASSET_INQUIRIES_URL,
} from "../api/routes/asset_inquiries";

export const FetchAssetInquiries = async (query) => {
  try {
    return await useFetch(FETCH_ASSET_INQUIRIES_URL, query);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const CreateAssetInquiries = async (payload) => {
  try {
    return await useCreate(CREATE_ASSET_INQUIRIES_URL, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const UpdateAssetInquiries = async (asset_inquiry_id, payload) => {
  try {
    return await useUpdate(
      `${UPDATE_ASSET_INQUIRIES_URL}/${asset_inquiry_id}`,
      payload
    );
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const DeleteAssetInquiries = async (listing_id) => {
  try {
    return await useDelete(`${DELETE_ASSET_INQUIRIES_URL}/${listing_id}`);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
