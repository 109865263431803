import React from "react";
import { Modal, Form, Input, Select } from "antd";
import { SaveOutlined, ArrowLeftOutlined } from "@ant-design/icons";

const { Option } = Select;

export const BarangayUpdatingModalComponent = (props) => {
  const { visible = true, onSubmit, onCancel, form, cities = [] } = props;

  return (
    <>
      <Modal
        visible={visible}
        title="Update Barangay"
        onOk={onSubmit}
        onCancel={onCancel}
        okText={"Update Barangay"}
        okButtonProps={{ icon: <SaveOutlined /> }}
        cancelButtonProps={{ icon: <ArrowLeftOutlined /> }}
      >
        <Form form={form} name="barangays" layout="vertical">
          <Form.Item name={"barangay_name"} label="Barangay Name">
            <Input />
          </Form.Item>
          <Form.Item name={"city_id"} label="City/Municipality">
            <Select placeholder="Select City/Municipality">
              {cities.map((item) => (
                <Option value={item.id} key={item.id}>{item.city_name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
