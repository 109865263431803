import React from "react";
import { CustomCreationFormModalComponent } from "./CustomCreationFormModalComponent";
import { CustomUpdateFormModalComponent } from "./CustomUpdateFormModalComponent";

export const FormsModalFunctionalityComponent = (props) => {
  const {
    onSubmitCustomFieldCreation,
    onCancelCustomFieldCreation,
    customFieldModalVisibility,
    customFieldCreationForm,
    onSubmitCustomFieldUpdate,
    onCancelCustomFieldUpdate,
    customFielUpdatedModalVisibility,
    customFieldUpdateForm,
  } = props;

  return (
    <>
      <CustomCreationFormModalComponent
        form={customFieldCreationForm}
        isVisible={customFieldModalVisibility}
        onClickSubmit={onSubmitCustomFieldCreation}
        onClickCancel={onCancelCustomFieldCreation}
      />
      <CustomUpdateFormModalComponent
        form={customFieldUpdateForm}
        isVisible={customFielUpdatedModalVisibility}
        onClickSubmit={onSubmitCustomFieldUpdate}
        onClickCancel={onCancelCustomFieldUpdate}
      />
    </>
  );
};
