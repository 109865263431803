import {
  CreateAgentMetaFields,
  DeleteAgentMetaFields,
  UpdateAgentMetaFields,
  FetchAgentMetaFields,
  UpdateAgentMetaFieldsByAgentCustom,
} from "../../api/AgentMetaField";
import { message } from "antd";
import { useAPIResponse } from "../useAPIResponse";

export const useFetchAgentMetaFields = async (
  isForTable = true,
  query = { page: 1, limit: 10, columnFilter: [] }
) => {
  if (isForTable) {
    const data = await FetchAgentMetaFields(query);

    for (const item of data.data.data) {
      item["key"] = item.agent_meta_field_id;
    }

    return data.data;
  }

  const data = await FetchAgentMetaFields({
    page: 1,
    limit: null,
    columnFilter: [],
  });

  for (const item of data.data.data) {
    item["key"] = item.agent_meta_field_id;
  }

  return data.data.data;
};

export const useCreateAgentMetaField = async (payload) => {
  await message.loading("Creating Agent Meta Field", 2);
  const response = await CreateAgentMetaFields(payload);

  return useAPIResponse(response);
};

export const useUpdateAgentMetaField = async (agent_meta_field_id, payload) => {
  // await message.loading("Updating Agent Meta Field", 2);
  const response = await UpdateAgentMetaFields(agent_meta_field_id, payload);

  return useAPIResponse(response);
};

export const UseUpdateAgentMetaFieldByAgentCustom = async (
  agent_id,
  agent_custom_field_id,
  payload
) => {
  // await message.loading("Updating Agent Meta Field", 2);
  const response = await UpdateAgentMetaFieldsByAgentCustom(
    agent_id,
    agent_custom_field_id,
    payload
  );

  return useAPIResponse(response);
};

export const useDeleteAgentMetaField = async (agent_meta_field_id) => {
  await message.loading("Deleting Agent Meta Field", 2);

  const response = await DeleteAgentMetaFields(agent_meta_field_id);

  return useAPIResponse(response);
};
