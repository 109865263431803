import React from "react";
import { Form, Input, Upload, Select, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { FormRules } from "../../../dist/functions/formRules";

const { Dragger } = Upload;
const { Option } = Select;
export const GeneralInformationStepFormComponent = ({
  isHidden,
  isForEdit = false,
}) => {
  return (
    <div style={{ display: isHidden ? "none" : null }}>
      <Form.Item
        name="users_first_name"
        label="Firstname"
        rules={[FormRules.REQUIRED_FIRSTNAME]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="users_middle_name"
        label="Middlename"
        rules={[FormRules.REQUIRED_MIDDLENAME]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="users_last_name"
        label="Lastname"
        rules={[FormRules.REQUIRED_LASTNAME]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="users_gender"
        label="Gender"
        rules={[FormRules.REQUIRED_GENDER]}
      >
        <Select defaultValue="Select Gender">
          <Option value="male">Male</Option>
          <Option value="female">Female</Option>
          <Option value="Other">Other</Option>
        </Select>
      </Form.Item>
      {/* <Form.Item name="interest" label="Interest">
        <Select defaultValue="Select Interest">
          <Option value="motorycle">Motorcycle</Option>
          <Option value="car">Car</Option>
        </Select>
      </Form.Item> */}
      {/* <Form.Item name="image" label="Photo" hidden={isForEdit}>
        <Dragger
          name="user_photo"
          multiple={false}
          action={`${process.env.REACT_APP_API_URL}/uploads/user/profile`}
          onChange={(info) => {
            const { status, response } = info.file;
            if (status !== "uploading") {
            }

            if (status === "done") {
              message.success(`${info.file.name} file uploaded successfully.`);
            }

            if (status === "error") {
              message.error(`${info.file.name} file upload failed.`);
              message.error(`${response.status}`);
            }
          }}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
        </Dragger>
      </Form.Item> */}
    </div>
  );
};
