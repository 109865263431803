import axios from "axios";

export const useFetch = async (url, params = {}) => {
  const header = {
    headers: { session: localStorage.getItem("session_id") },
  };

  const data = await axios.get(url, { params, headers: header.headers });
  return data.data;
};
