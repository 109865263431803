import { useFetch } from "../hooks/useFetch";
import { useCreate } from "../hooks/useCreate";
import { useUpdate } from "../hooks/useUpdate";
import { useDelete } from "../hooks/useDelete";
import {
  CREATE_BARANGAY,
  DELETE_BARANGAY,
  FETCH_BARANGAYS,
  UPDATE_BARANGAY,
} from "./routes/barangays";

export const FetchBarangays = async (query) => {
  try {
    return await useFetch(FETCH_BARANGAYS, query);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const CreateBarangay = async (payload) => {
  try {
    return await useCreate(CREATE_BARANGAY, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const UpdateBarangay = async (id, payload) => {
  try {
    return await useUpdate(`${UPDATE_BARANGAY}/${id}`, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const DeleteBarangay = async (id) => {
  try {
    return await useDelete(`${DELETE_BARANGAY}/${id}`);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
