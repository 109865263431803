import React from "react";
import { ModulesFunctionalitiesComponent } from "../../ModulesFunctionalitiesComponent";
import { DeleteOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";

export const PartnerTypesFunctionalityComponent = (props) => {
  const {
    onClickCreatePartnerTypeButton,
    onClickUpdatePartnerTypeButton,
    onClickDeletePartnerTypeButton,
    selectedRowKey,
    selectedRow,
  } = props;

  const functionalitiesComponent = [
    {
      component_type: "button",
      type: "primary",
      icon: <EditOutlined />,
      _onClick: onClickCreatePartnerTypeButton,
      label: "Create Partner Type",
      size: "default",
    },
    {
      component_type: "button",
      type: "default",
      icon: <SaveOutlined />,
      _onClick: onClickUpdatePartnerTypeButton,
      label: "Update Partner Type",
      size: "default",
      is_hidden: selectedRowKey ? false : true,
    },
    {
      component_type: "button",
      type: "default",
      icon: <DeleteOutlined />,
      _onClick: onClickDeletePartnerTypeButton,
      label: "Delete Partner Type",
      size: "default",
      is_hidden: selectedRowKey ? false : true,
    },
  ];

  return (
    <>
      <ModulesFunctionalitiesComponent
        functionalitiesComponent={functionalitiesComponent}
        alignment="right"
        spaceSize="small"
      />
    </>
  );
};
