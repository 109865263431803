import React from "react";
import { Modal, Form, Input, Select, Spin } from "antd";
import { SaveOutlined, ArrowLeftOutlined } from "@ant-design/icons";

const { Option } = Select;

export const BarangayCreationModalComponent = (props) => {
  const { visible = true, onSubmit, onCancel, form, cities = [] } = props;

  return (
    <>
      <Modal
        visible={visible}
        title="Create Barangay"
        onOk={onSubmit}
        onCancel={onCancel}
        okText={"Create Barangay"}
        okButtonProps={{ icon: <SaveOutlined /> }}
        cancelButtonProps={{ icon: <ArrowLeftOutlined /> }}
      >
        <Form form={form} name="barangays" layout="vertical">
          <Form.Item name={"barangay_name"} label="Barangay Name">
            <Input />
          </Form.Item>
          <Form.Item name={"city_id"} label="City/Municipality">
            <Select
              placeholder="Select City/Municipality"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {cities.map((item) => (
                <Option value={item.id} key={item.id}>{item.city_name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
