export default function GetPartnersSecondaryColumns(partners) {
  return {
    partner_code: partners.partner_code,
    partner_notes: partners.partner_notes,
    partner_type_name: partners.partner_types
      ? partners.partner_types.partner_type_name
      : null,
    partner_person_in_charge: partners.partner_person_in_charge,
  };
}
