import React, { useEffect, useState } from "react";
import { Divider, PageHeader, Form, Modal } from "antd";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { ProvincesListComponent } from "../sub_components/provinces/ProvincesListComponent";
import { ProvincesFunctionalityComponent } from "../sub_components/provinces/ProvincesFunctionalityComponent";
import { ProvincesFormsModalFunctionalityComponent } from "../sub_components/provinces/ProvincesFormsModalFunctionalityComponent";
import {
  useCreateProvince,
  useDeleteProvince,
  useFetchProvinces,
  useUpdateProvince,
} from "../../hooks/provinces/useProvinceAPI";
import { useQueryFilterReducer } from "../../hooks/useQueryFilterReducer";
import { ModulesSelectAndColumnTagsComponent } from "../ModulesSelectAndColumnTagsComponent";
import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useFetchRegions } from "../../hooks/regions/useRegionAPI";

const { confirm } = Modal;
// const initialProvincesData = { page: 1, total: 0, data: [] };
// const initialIslandGroupsData = { page: 1, total: 0, data: [] };
const initialTableState = { page: 1, total: 0, data: [] };

export const ProvincesComponent = () => {
  const [createProvincesForm] = Form.useForm();
  const [updateProvincesForm] = Form.useForm();
  const [provincesCreationModalStatus, setProvincesCreationModalStatus] =
    useState(false);
  const [provincesUpdateModalStatus, setProvincesUpdateModalStatus] =
    useState(false);

  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const [regionsQuery, setRegionsQuery] = useState({ page: 1, limit: 10 });

  const [
    regions,
    isLoadingRegions,
    persistentRegions,
    setShouldPersistRegions,
  ] = useFetchRegions(true, regionsQuery);

  const {
    queryFilters,
    actionsQueryFilters,
    onPaginationChange,
    onSortChange,
    columnFilterTags,
    onClearColumnFilters,
    onLoadColumnFilterTags,
    resetQueryFilters,
  } = useQueryFilterReducer();

  const [provincesData, isLoadingProvinces] = useFetchProvinces(
    true,
    queryFilters
  );

  /**
   * Functionalities
   *
   */

  const DeleteProvinces = async () => {
    const isSuccess = await useDeleteProvince(selectedRowKey);
    if (isSuccess) {
      resetSelections();
      resetQueryFilters();
    }
  };

  useComponentDidUpdate(() => {
    onLoadColumnFilterTags();
  }, [queryFilters]);

  /**
   *
   * Use effect hooks if any of the data is selected
   * Selected row will populate the form for updating
   *
   */
  useEffect(() => {
    if (selectedRow && provincesUpdateModalStatus === true) {
      updateProvincesForm.setFieldsValue(selectedRow);
    }
  }, [selectedRow, provincesUpdateModalStatus, updateProvincesForm]);

  /**
   *
   * On every selection change, update the row selection state to trigger the use effect hook with row selection dependency
   * It will re-trigger fetch function with the updated row selection and populate forms for updating
   *
   */
  const onSelectionChange = (keys, selectedRows) => {
    setSelectedRow(selectedRows[0]);
    setSelectedRowKey(keys[0]);
  };

  /**
   *
   * Create Provinces function with success validation, connected to useCreateProvince hook
   *
   */

  const CreateProvinces = async () => {
    const payload = await createProvincesForm.validateFields();

    const isSuccess = await useCreateProvince(payload);
    if (isSuccess) {
      createProvincesForm.resetFields();
      resetQueryFilters();
      onClickCreateProvincesButton();
      resetSelections();
    }
  };

  /**
   *
   * Update Provinces function with success validation, connected to useUpdateProvince hook
   *
   */

  const UpdateProvinces = async () => {
    const payload = await updateProvincesForm.validateFields();

    const isSuccess = await useUpdateProvince(selectedRowKey, payload);

    if (isSuccess) {
      updateProvincesForm.resetFields();
      resetQueryFilters();
      onClickUpdateProvincesButton();
      resetSelections();
    }
  };

  const OnClickDeleteProvinces = async () => {
    confirm({
      title: `Are you sure, you want to remove this provinces?`,
      icon: <ExclamationCircleOutlined />,
      content: `All information attached to this provinces will be removed`,
      onOk() {
        DeleteProvinces();
      },
    });
  };

  const onClickShowMoreRegion = async () => {
    const { current_page, last_page } = regions;
    if (current_page < last_page) {
      const query = { page: parseInt(current_page) + 1, limit: 10 };
      setShouldPersistRegions(true);
      setRegionsQuery(query);

      return;
    }
  };

  /**
   *
   * Helper functions:
   *
   * - Functions that will reset all selections, pagination and restore initial index/fetch
   *
   */

  const resetSelections = () => {
    setSelectedRowKey(null);
    setSelectedRow(null);
  };

  const onClickCreateProvincesButton = () => {
    setProvincesCreationModalStatus(!provincesCreationModalStatus);
  };

  const onClickUpdateProvincesButton = () => {
    setProvincesUpdateModalStatus(!provincesUpdateModalStatus);
  };

  return (
    <>
      <PageHeader
        title="Provinces"
        subTitle="Provinces Management"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["Provinces"]} />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <ProvincesFunctionalityComponent
          onClickCreateProvincesButton={onClickCreateProvincesButton}
          onClickUpdateProvincesButton={onClickUpdateProvincesButton}
          onClickDeleteProvincesButton={OnClickDeleteProvinces}
          selectedRowKey={selectedRowKey}
          selectedRow={selectedRow}
        />

        <ModulesSelectAndColumnTagsComponent
          onClearColumnFilters={onClearColumnFilters}
          isShowColumnFilterTags={columnFilterTags.length}
          columnFilterTags={columnFilterTags}
          isShowSelection={!!selectedRow}
          onClearSearcheAndSelection={resetSelections}
          tags={[
            {
              color: "processing",
              selection_label: "Selected Province Name",
              value: selectedRow ? `${selectedRow.province_name}` : null,
            },
          ]}
        />
        <ProvincesListComponent
          data={provincesData}
          tableLoading={isLoadingProvinces}
          onPaginationChange={onPaginationChange}
          selectedRowKey={selectedRowKey}
          onSelection={onSelectionChange}
          currentPagination={queryFilters.page}
          onSortChange={onSortChange}
          setColumnFilter={actionsQueryFilters.changeColumnFilter}
          columnFilter={queryFilters.columnFilter}
        />
      </div>
      <ProvincesFormsModalFunctionalityComponent
        onSubmitProvincesCreation={CreateProvinces}
        onCancelProvincesCreation={onClickCreateProvincesButton}
        provincesModalVisibility={provincesCreationModalStatus}
        provincesCreationForm={createProvincesForm}
        onSubmitProvincesUpdate={UpdateProvinces}
        onCancelProvincesUpdate={onClickUpdateProvincesButton}
        provincesUpdatedModalVisibility={provincesUpdateModalStatus}
        provincesUpdateForm={updateProvincesForm}
        onClickShowMoreRegion={onClickShowMoreRegion}
        regions={persistentRegions}
      />
    </>
  );
};
