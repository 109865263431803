import React from "react";
import { Form, Select, Input } from "antd";
import { FormRules } from "../../../dist/functions/formRules";

const { Option } = Select;

export const UserAccessRolesStepFormComponent = (props) => {
  const { isHidden, childProps, setSelectedRole } = props;
  const { roles = [] } = childProps;

  return (
    <div style={{ display: isHidden ? "none" : null }}>
      <Form.Item
        name="users_username"
        label="Username"
        rules={[FormRules.REQUIRED_USERNAME]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="users_password"
        label="Password"
        rules={[FormRules.REQUIRED_PASSWORD]}
      >
        <Input.Password visibilityToggle={true} />
      </Form.Item>
      <Form.Item name="user_attached_roles" label="User Role">
        <Select
          defaultValue="Select Role Name"
          onChange={(role) => {
            setSelectedRole([role]);
          }}
        >
          {roles.map((item, index) => (
            <Option key={index} value={item.key}>
              {item.title}
            </Option>
          ))}
        </Select>
      </Form.Item>
    </div>
  );
};
