import React, { useEffect, useState, useReducer } from "react";
import { Divider, PageHeader, Form, Modal } from "antd";

import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { PartnerTypeListComponent } from "../sub_components/partner_types/PartnerTypeListComponent";
import { PartnerTypesFunctionalityComponent } from "../sub_components/partner_types/PartnerTypesFunctionalityComponent";
import { PartnerTypeFormsModalFunctionalityComponent } from "../sub_components/partner_types/PartnerTypeFormsModalFunctionalityComponent";
import {
  useCreatePartnerType,
  useFetchPartnerTypes,
  useUpdatePartnerType,
  useDeletePartnerType,
} from "../../hooks/partner_types/usePartnerTypeAPI";

import { useQueryFilterReducer } from "../../hooks/useQueryFilterReducer";
import { ModulesSelectAndColumnTagsComponent } from "../ModulesSelectAndColumnTagsComponent";
import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { confirm } = Modal;
const initialPartnerTypesData = { page: 1, total: 0, data: [] };

export const PartnerTypesComponent = () => {
  const [createPartnerTypeForm] = Form.useForm();
  const [updatePartnerTypeForm] = Form.useForm();
  const [partnerTypeCreationModalStatus, setPartnerTypeCreationModalStatus] =
    useState(false);
  const [partnerTypeUpdateModalStatus, setPartnerTypeUpdateModalStatus] =
    useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const [partnerTypesData, setPartnerTypesData] = useState(
    initialPartnerTypesData
  );

  const {
    queryFilters,
    actionsQueryFilters,
    onPaginationChange,
    onSortChange,
    columnFilterTags,
    onClearColumnFilters,
    onLoadColumnFilterTags,
  } = useQueryFilterReducer();

  /**
   * Functionalities
   *
   */

  /**
   *
   * Create partnerType function for fetch reusability
   *
   */
  const FetchPartnerTypes = async (
    page = 1,
    limit = 10,
    columnFilter,
    sortColumn,
    sortType
  ) => {
    /**
     *
     * Construct Query parameters for fetching
     *
     */
    setTableLoading(true);
    const query = {
      page: page,
      limit: limit,
      sortColumn: sortColumn,
      sortType: sortType,
      columnFilter: columnFilter,
    };
    const data = await useFetchPartnerTypes(true, query);
    /**
     *
     * Create delay on updating of state for better transition and visualization on Frontend
     *
     */
    setTimeout(() => {
      setPartnerTypesData(data);
      setTableLoading(false);
    }, 800);
  };

  /**
   *
   * Initial PartnerType Fetch
   *    - Fetch with 1 page, limit 10 with no filter as query parameters
   */

  useEffect(() => {
    FetchPartnerTypes();
  }, []);

  useComponentDidUpdate(() => {
    const { page, limit, columnFilter, sortColumn, sortType } = queryFilters;

    onLoadColumnFilterTags();
    FetchPartnerTypes(page, limit, columnFilter, sortColumn, sortType);
  }, [queryFilters]);

  /**
   *
   * Use effect hooks if any of the data is selected
   * Selected row will populate the form for updating
   *
   */
  useEffect(() => {
    if (selectedRow && partnerTypeUpdateModalStatus === true) {
      updatePartnerTypeForm.setFieldsValue({ ...selectedRow });
    }
  }, [selectedRow, partnerTypeUpdateModalStatus, updatePartnerTypeForm]);

  /**
   *
   * On every selection change, update the row selection state to trigger the use effect hook with row selection dependency
   * It will re-trigger fetch function with the updated row selection and populate forms for updating
   *
   */
  const onSelectionChange = (keys, selectedRows) => {
    setSelectedRow(selectedRows[0]);
    setSelectedRowKey(keys[0]);
  };

  /**
   *
   * Create PartnerType function with success validation, connected to useCreatePartnerType hook
   *
   */

  const CreatePartnerType = async () => {
    const payload = await createPartnerTypeForm.validateFields();
    const isSuccess = await useCreatePartnerType(payload);
    if (isSuccess) {
      createPartnerTypeForm.resetFields();
      const { page, limit, columnFilter, sortColumn, sortType } = queryFilters;
      FetchPartnerTypes(page, limit, columnFilter, sortColumn, sortType);
      onClickCreatePartnerTypeButton();
      resetSelections();
    }
  };

  /**
   *
   * Update PartnerType function with success validation, connected to useUpdatePartnerType hook
   *
   */

  const UpdatePartnerType = async () => {
    const payload = await updatePartnerTypeForm.validateFields();
    const isSuccess = await useUpdatePartnerType(selectedRowKey, payload);
    if (isSuccess) {
      updatePartnerTypeForm.resetFields();
      const { page, limit, columnFilter, sortColumn, sortType } = queryFilters;
      FetchPartnerTypes(page, limit, columnFilter, sortColumn, sortType);
      onClickUpdatePartnerTypeButton();
      resetSelections();
    }
  };

  /**
   *
   * Delete Partner Type function with success validation, connected to useDeletePartnerType hook
   *
   */
  const DeletePartnerType = async () => {
    const isSuccess = await useDeletePartnerType(selectedRowKey);
    if (isSuccess) {
      const { page, limit, columnFilter, sortColumn, sortType } = queryFilters;
      FetchPartnerTypes(page, limit, columnFilter, sortColumn, sortType);
      resetSelections();
    }
  };

  /**
   *
   * Helper functions:
   *
   * - Functions that will reset all selections, pagination and restore initial index/fetch
   *
   */

  const DeleteConfirm = async () => {
    confirm({
      title: "Do you want to delete this partner type?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        DeletePartnerType();
      },
    });
  };

  const resetSelections = () => {
    setSelectedRowKey(null);
    setSelectedRow(null);
  };

  const onClickCreatePartnerTypeButton = () => {
    setPartnerTypeCreationModalStatus(!partnerTypeCreationModalStatus);
  };

  const onClickUpdatePartnerTypeButton = () => {
    setPartnerTypeUpdateModalStatus(!partnerTypeUpdateModalStatus);
  };

  return (
    <>
      <PageHeader
        title="Partner Type"
        subTitle="Partner Type Management"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["PartnerType"]} />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <PartnerTypesFunctionalityComponent
          onClickCreatePartnerTypeButton={onClickCreatePartnerTypeButton}
          onClickUpdatePartnerTypeButton={onClickUpdatePartnerTypeButton}
          onClickDeletePartnerTypeButton={DeleteConfirm}
          selectedRowKey={selectedRowKey}
          selectedRow={selectedRow}
        />

        <ModulesSelectAndColumnTagsComponent
          onClearColumnFilters={onClearColumnFilters}
          isShowColumnFilterTags={columnFilterTags.length}
          columnFilterTags={columnFilterTags}
          isShowSelection={!!selectedRow}
          onClearSearcheAndSelection={resetSelections}
          tags={[
            {
              color: "processing",
              selection_label: "Selected Partner Type",
              value: selectedRow ? `${selectedRow.partner_type_name}` : null,
            },
          ]}
        />
        <PartnerTypeListComponent
          data={partnerTypesData}
          tableLoading={tableLoading}
          onPaginationChange={onPaginationChange}
          selectedRowKey={selectedRowKey}
          onSelection={onSelectionChange}
          currentPagination={queryFilters.page}
          onSortChange={onSortChange}
          setColumnFilter={actionsQueryFilters.changeColumnFilter}
          columnFilter={queryFilters.columnFilter}
        />
      </div>
      <PartnerTypeFormsModalFunctionalityComponent
        onSubmitPartnerTypeCreation={CreatePartnerType}
        onCancelPartnerTypeCreation={onClickCreatePartnerTypeButton}
        partnerTypeModalVisibility={partnerTypeCreationModalStatus}
        partnerTypeCreationForm={createPartnerTypeForm}
        onSubmitPartnerTypeUpdate={UpdatePartnerType}
        onCancelPartnerTypeUpdate={onClickUpdatePartnerTypeButton}
        partnerTypeUpdatedModalVisibility={partnerTypeUpdateModalStatus}
        partnerTypeUpdateForm={updatePartnerTypeForm}
      />
    </>
  );
};
