import React from "react";
import { Modal, Input, Select, Form, Button, Radio } from "antd";
import { SaveOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { SelectOptionFooterExtraButtonComponent } from "../../SelectOptionFooterExtraButtonComponent";

export const CityFormModalComponent = ({
  form = null,
  isVisible,
  onClickSubmit,
  onClickCancel,
  isUpdate,
  provinces,
  onClickShowMoreProvince,
  isLoadingProvinces,
  urbans,
}) => {
  const { Option } = Select;
  return (
    <Modal
      title={isUpdate ? "Update City" : "Create City"}
      visible={isVisible}
      onOk={onClickSubmit}
      onCancel={onClickCancel}
      footer={[
        <Button key="back" onClick={onClickCancel} icon={<ArrowLeftOutlined />}>
          Return
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onClickSubmit}
          icon={<SaveOutlined />}
        >
          Submit
        </Button>,
      ]}
    >
      <Form form={form} name="cities" layout="vertical">
        <Form.Item
          name="city_name"
          label="City Name"
          rules={[
            {
              required: true,
              message: "City name is required",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item name="alternative_name" label="Alternative Name">
          <Input />
        </Form.Item>
        <Form.Item
          name="is_municipality"
          label="Is Municipality (?)"
          rules={[
            {
              required: true,
              message: "Municipality Tag/Checker cannot be blank",
            },
          ]}
        >
          <Radio.Group>
            <Radio key="is-municipality:1" id="is-municipality:1" value={1}>
              Yes
            </Radio>
            <Radio key="is-municipality:0" id="is-municipality:0" value={0}>
              No
            </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label="Province"
          name="province_id"
          fieldKey="province_id"
          shouldUpdate={true}
          rules={[
            {
              required: true,
              message: "Please choose province",
            },
          ]}
        >
          <Select
            placeholder="Select Province"
            virtual={true}
            dropdownRender={(menu) => (
              <SelectOptionFooterExtraButtonComponent
                key="show_more"
                menu={menu}
                onClickShowMoreList={onClickShowMoreProvince}
                loading={isLoadingProvinces}
                isClickShowMoreDisabled={
                  +provinces?.data?.length < provinces?.total ? false : true
                }
              />
            )}
          >
            {[
              ...(provinces?.selectedProvinceData || []),
              ...provinces?.data?.filter(
                (d) =>
                  d.province_id !==
                  provinces?.selectedProvinceData?.[0]?.province_id
              ),
            ].map((item) => (
              <Option
                id={item.province_id}
                key={item.province_id}
                value={item.province_id}
              >
                {item.province_name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="urban_id"
          label="Urban"
          help={
            "leave this field blank if the city is not under an urban location"
          }
        >
          <Select placeholder="Select Urban">
            {urbans.map((item) => (
              <Option value={item.id}>{item.urban_name}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="state_id" label="State ID" hidden={true}>
          <Select placeholder="Select Country">
            <Option value={1}>Philippines</Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
