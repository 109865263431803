import React from "react";
import {
  Modal,
  Form,
  Button,
  Input,
  Select,
  Row,
  Col,
  Empty,
  Checkbox,
} from "antd";
import { SaveOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { FormRules } from "../../../dist/functions/formRules";

export const PropertyProjectFormModalComponent = (props) => {
  const {
    form = null,
    isVisible,
    onClickSubmit,
    onClickCancel,
    isUpdate,
    cities,
    islandGroup,
    provinces,
    barangay,
    locales,
    developers = [],
    propertyCategory,
    onChangeCities,
    onChangeProvince,
    projectCustomFields = [],
    advisors,
  } = props;

  /**
   * Form Rules
   *
   */
  const { Option } = Select;
  const { REQUIRED_PROJECT_NAME } = FormRules;
  const { TextArea } = Input;

  return (
    <Modal
      title={isUpdate ? "Update Project" : "Create New Project"}
      width={1350}
      visible={isVisible}
      onOk={onClickSubmit}
      onCancel={onClickCancel}
      footer={[
        <Button key="back" onClick={onClickCancel} icon={<ArrowLeftOutlined />}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onClickSubmit}
          icon={<SaveOutlined />}
        >
          {isUpdate ? "Update Project" : "Create Project"}
        </Button>,
      ]}
    >
      <div style={{ padding: "2%" }}>
        <Form form={form} layout="vertical" name="property_projects">
          <Row gutter={[30, 8]}>
            <Col span={15}>
              <div className="projectMainFieldsContainer">
                <Form.Item
                  name="property_projects_name"
                  fieldKey="property_projects_name"
                  label="Project Name"
                  rules={[REQUIRED_PROJECT_NAME]}
                >
                  <Input />
                </Form.Item>
                <Form.Item label="Address">
                  <Input.Group compact>
                    <Form.Item
                      name={["address", "island_group_id"]}
                      label="Island Group"
                      style={{ width: "33.3%", textAlign: "left" }}
                      rules={[
                        {
                          required: true,
                          message: "Island Group is Required",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Island Group"
                        style={{ width: "100%", textAlign: "left" }}
                      >
                        {islandGroup.map((item) => (
                          <Option
                            key={item.island_group_id}
                            value={item.island_group_id}
                          >
                            {item.island_group_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={["address", "province_id"]}
                      fieldKey="province_id"
                      label="Province"
                      style={{ width: "33.3%" }}
                      rules={[
                        {
                          required: true,
                          message: "Province is Required",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Province"
                        onChange={onChangeProvince}
                        style={{ width: "100%" }}
                      >
                        {provinces.map((item) => (
                          <Option
                            key={item.province_id}
                            value={item.province_id}
                          >
                            {item.province_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={["address", "city_id"]}
                      fieldKey="city_id"
                      label="City"
                      style={{ width: "33.3%" }}
                      rules={[
                        {
                          required: true,
                          message: "City Group is Required",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Cities"
                        onChange={onChangeCities}
                        style={{ width: "100%" }}
                      >
                        {cities.map((item) => (
                          <Option key={item.id} value={item.id}>
                            {item.city_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={["address", "barangay_id"]}
                      fieldKey="barangay_id"
                      label="Barangay"
                      style={{ width: "33.3%" }}
                      rules={[
                        {
                          required: true,
                          message: "Barangay is Required",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select Barangay"
                        style={{ width: "100%" }}
                      >
                        {barangay.map((item) => (
                          <Option
                            key={item.barangay_id}
                            value={item.barangay_id}
                          >
                            {item.barangay_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name={["address", "locale_id"]}
                      fieldKey="locale_id"
                      label="Locale"
                      style={{ width: "33.3%" }}
                    >
                      <Select
                        placeholder="Select Locale"
                        style={{ width: "100%" }}
                      >
                        {locales.map((item) => (
                          <Option key={item.locale_id} value={item.locale_id}>
                            {item.locale_name}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Input.Group>
                </Form.Item>

                <Form.Item
                  name="project_user_title"
                  fieldKey="project_user_title"
                  label="Project Sub Title"
                >
                  <TextArea />
                </Form.Item>
                <Form.Item
                  name="project_user_description"
                  fieldKey="project_user_description"
                  label="Project Description"
                >
                  <TextArea />
                </Form.Item>
                <Form.Item
                  name="property_category_id"
                  fieldKey="property_category_id"
                  label="Property Type"
                  rules={[
                    {
                      required: true,
                      message: "Property Type is Required",
                    },
                  ]}
                >
                  <Select placeholder="Select Property Type">
                    {propertyCategory.map((item) => (
                      <Option
                        key={item.property_category_id}
                        value={item.property_category_id}
                      >
                        {item.property_category_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  name="developer_id"
                  fieldKey="developer_id"
                  label="Developer"
                  rules={[
                    {
                      required: true,
                      message: "Developer is Required",
                    },
                  ]}
                >
                  <Select placeholder="Select Developer">
                    {developers.map((item) => (
                      <Option key={item.partner_id} value={item.partner_id}>
                        {item.partner_name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                {!isUpdate ? (
                  <Form.Item
                    label="Pseudo Project (?)"
                    name={"pseudo_project"}
                    fieldKey={"pseudo_project"}
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox />
                  </Form.Item>
                ) : null}
                {!isUpdate ? (
                  <Form.Item
                    label="Featured (?)"
                    name={"is_featured"}
                    fieldKey={"pseudo_project"}
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox />
                  </Form.Item>
                ) : null}
              </div>
            </Col>
            <Col span={9}>
              {projectCustomFields.length === 0 ? (
                <div className="projectsCustomFieldEmptyDiv">
                  <Empty
                    description={"No Custom Fields Related To Projects Module"}
                  />
                </div>
              ) : (
                <div className="projectCustomFieldsContainer">
                  {projectCustomFields.map(({ id, fieldKey, label, name }) => {
                    return (
                      <Form.Item
                        key={id}
                        id={id}
                        fieldKey={fieldKey}
                        label={label}
                        name={`${id}:${name}`}
                      >
                        <TextArea />
                      </Form.Item>
                    );
                  })}
                  <Form.Item
                    label="Advisors"
                    name="advisors"
                    fieldKey="advisors"
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select Advisors"
                      onChange={() => {}}
                      style={{ width: "100%" }}
                      options={advisors.map(({ users_id, users_email }) => ({
                        value: users_id,
                        label: users_email,
                      }))}
                    />
                  </Form.Item>
                </div>
              )}
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};
