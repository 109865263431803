import React from "react";
import { Form, Input, Select } from "antd";
import { SelectOptionFooterExtraButtonComponent } from "../../SelectOptionFooterExtraButtonComponent";
import { FormRules } from "../../../dist/functions/formRules";

const { REQUIRED_BARANGAY_ID, REQUIRED_CITY_ID, REQUIRED_PROVINCE_ID } =
  FormRules;

const { Option } = Select;
export const AssetAddressInformationComponent = (props) => {
  const {
    form,
    cities,
    provinces,
    barangays,
    onClickShowMoreCity,
    onClickShowMoreProvince,
    onClickShowMoreBarangay,
    setBarangaysQuery,
    setCitiesQuery,
    setShouldResetPersistCities,
    setShouldResetPersistBarangay,
  } = props;

  const onSelectProvince = (value) => {
    setShouldResetPersistCities(true);
    setCitiesQuery({ columnFilter: [{ id: "province_id", value: value }] });
    setShouldResetPersistBarangay(true);
    setBarangaysQuery({ columnFilter: [{ id: "city_id", value: null }] });

    const fields = form.validateFields();
    form.setFieldsValue({ city_id: null, barangay_id: null, ...fields });
  };

  const onSelectCities = (value) => {
    setShouldResetPersistBarangay(true);
    setBarangaysQuery({ columnFilter: [{ id: "city_id", value: value }] });
    const fields = form.validateFields();
    form.setFieldsValue({ barangay_id: null, ...fields });
  };

  return (
    <>
      <Form.Item
        name="province_id"
        fieldKey="province_id"
        label="Province"
        rules={[REQUIRED_PROVINCE_ID]}
      >
        <Select
          placeholder="Select Province"
          virtual={true}
          onSelect={(value) => onSelectProvince(value)}
          dropdownRender={(menu) => (
            <SelectOptionFooterExtraButtonComponent
              key="show_more"
              menu={menu}
              onClickShowMoreList={onClickShowMoreProvince}
              isClickShowMoreDisabled={
                provinces.data.length < provinces.total ? false : true
              }
            />
          )}
        >
          {provinces.data.map((item) => (
            <Option
              id={item.province_id}
              key={item.province_id}
              value={item.province_id}
            >
              {item.province_name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        label="City"
        name="city_id"
        fieldKey="city_id"
        shouldUpdate={true}
        rules={[REQUIRED_CITY_ID]}
      >
        <Select
          placeholder="Select City"
          virtual={true}
          onSelect={(value) => onSelectCities(value)}
          dropdownRender={(menu) => (
            <SelectOptionFooterExtraButtonComponent
              key="show_more"
              menu={menu}
              onClickShowMoreList={onClickShowMoreCity}
              isClickShowMoreDisabled={
                cities.data.length < cities.total ? false : true
              }
            />
          )}
        >
          {cities.data.map((item) => (
            <Option id={item.id} key={item.id} value={item.id}>
              {item.city_name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="barangay_id"
        fieldKey="barangay_id"
        label="Barangay"
        rules={[REQUIRED_BARANGAY_ID]}
      >
        <Select
          placeholder="Select Barangay"
          virtual={true}
          dropdownRender={(menu) => (
            <SelectOptionFooterExtraButtonComponent
              key="show_more"
              menu={menu}
              onClickShowMoreList={onClickShowMoreBarangay}
              isClickShowMoreDisabled={
                barangays.data.length < barangays.total ? false : true
              }
            />
          )}
        >
          {barangays.data.map((item) => (
            <Option
              id={item.barangay_id}
              key={item.barangay_id}
              value={item.barangay_id}
            >
              {item.barangay_name}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="subdivision"
        fieldKey="subdivision"
        label="Subdivision"
        // rules={[REQUIRED_SUBDIVISION]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="street"
        fieldKey="street"
        label="Street"
        // rules={[REQUIRED_STREET]}
      >
        <Input />
      </Form.Item>
    </>
  );
};
