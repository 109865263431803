import { useCreate } from "../hooks/useCreate";
import { useFetch } from "../hooks/useFetch";
import { useUpdate } from "../hooks/useUpdate";
import { useDelete } from "../hooks/useDelete";
import {
  CREATE_AGENT_META_FIELD_URL,
  DELETE_AGENT_META_FIELD_URL,
  FETCH_AGENT_META_FIELD_URL,
  UPDATE_AGENT_META_FIELD_URL,
  UPDATE_AGENT_META_FIELD_BY_AGENT_CUSTOM_URL,
} from "./routes/agent_meta_field";

export const FetchAgentMetaFields = async (query) => {
  try {
    return await useFetch(FETCH_AGENT_META_FIELD_URL, query);
  } catch (err) {
    if (err.request) return err.request;
    if (err.resonse) return err.response;
    return err.message;
  }
};

export const CreateAgentMetaFields = async (payload) => {
  try {
    return await useCreate(CREATE_AGENT_META_FIELD_URL, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.resonse) return err.response;
    return err.message;
  }
};

export const UpdateAgentMetaFields = async (agent_meta_field_id, payload) => {
  try {
    return await useUpdate(
      `${UPDATE_AGENT_META_FIELD_URL}/${agent_meta_field_id}`,
      payload
    );
  } catch (err) {
    if (err.request) return err.request;
    if (err.resonse) return err.response;
    return err.message;
  }
};

export const UpdateAgentMetaFieldsByAgentCustom = async (
  agent_id,
  agent_custom_field_id,
  payload
) => {
  try {
    return await useUpdate(
      `${UPDATE_AGENT_META_FIELD_BY_AGENT_CUSTOM_URL}/${agent_id}/custom/${agent_custom_field_id}`,
      payload
    );
  } catch (err) {
    if (err.request) return err.request;
    if (err.resonse) return err.response;
    return err.message;
  }
};

export const DeleteAgentMetaFields = async (agent_meta_field_id) => {
  try {
    return await useDelete(
      `${DELETE_AGENT_META_FIELD_URL}/${agent_meta_field_id}`
    );
  } catch (err) {
    if (err.request) return err.request;
    if (err.resonse) return err.response;
    return err.message;
  }
};
