import React, { useState, useEffect, useCallback, useRef } from "react";
import { Form, PageHeader, Divider, message } from "antd";
import { AddNewPermissionIndexComponent } from "../sub_components/permission_index/AddNewPermissionIndexComponent";
import { UpdatePermissionListComponent } from "../sub_components/permission_index/UpdatePermissionListComponent";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { PermissionIndexFunctionalityComponent } from "../sub_components/permission_index/PermissionIndexFunctionalityComponent";
import { UpdatePermissionList } from "../../api/admin/UpdatePermissionList";
import { PermissionIndexListComponent } from "../sub_components/permission_index/PermissionIndexListComponent";
import useGetPermissionIndexList from "../../hooks/permission_index/useGetPermissionIndexList";
import useGetPlatforms from "../../hooks/platforms/useGetPlatforms";
import useGetModulesByQuery from "../../hooks/modules/useGetModulesByQuery";
import useCreatePermissionIndex from "../../hooks/permission_index/useCreatePermissionIndex";
import { createPermissionIndex } from "../../dist/dao/permissionIndex";
import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";
import { ModulesSelectAndColumnTagsComponent } from "../ModulesSelectAndColumnTagsComponent";

const initialTableState = {
  data: [],
  total: 0,
};
export const PermissionIndexComponent = ({ permissions }) => {
  const [permissionForm] = Form.useForm();
  const [updatePermissionForm] = Form.useForm();

  const [platforms, setPlatforms] = useState([]);
  const [modules, setModules] = useState([]);
  const [permissionIndex, setPermissionIndex] = useState(initialTableState);
  const [selectedPlatform, setSelectedPlatform] = useState(1);

  const [currentPagination, setCurrentPagination] = useState(1);
  const [currentCount, setCurrentCount] = useState(10);
  const [selectedRowKeys, setSelectedRowKeys] = useState(null);
  const [selectedRows, setSelectedRows] = useState(null);
  const [showPermissionAddModal, setShowPermissionAddModal] = useState(false);
  const [showUpdatePermissionsModal, setShowUpdatePermissionsModal] =
    useState(false);
  const [selectedPlatformByModule, setSelectedPlatformByModule] =
    useState(null);

  const [isShowColumnFilter, setIsShowColumnFilter] = useState(false);
  const [columnFilterTags, setColumnFilterTags] = useState([]);

  const [columnFilter, setColumnFilter] = useState([]);
  const sortColumn = useRef(null);
  const sortType = useRef("asc");

  const setSortColumn = (value) => {
    sortColumn.current = value;
  };

  const setSortType = (value) => {
    if (value === "descend") {
      sortType.current = "desc";
    } else if (value === "ascend") {
      sortType.current = "asc";
    } else {
      sortType.current = "none";
    }
  };

  const onSortChange = async (sortCol, sortType) => {
    setSortColumn(sortCol);
    setSortType(sortType);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    await fetchPermissionIndex(currentPagination, currentCount);
  };

  const OnFinish = async () => {
    const values = await permissionForm.validateFields();
    const payload = createPermissionIndex(values);
    const module_id = values.module_id;
    const isSuccess = await useCreatePermissionIndex(module_id, payload);

    if (isSuccess) {
      permissionForm.resetFields();
      handleShowModalPermission();
      resetSelection();
      fetchPermissionIndex();
    }
  };

  const OnFinishUpdatePermission = async () => {
    const { id, platform_id } = selectedPlatformByModule;
    const data = await updatePermissionForm.validateFields();
    if (typeof data.permission_indeces === "undefined") {
      return;
    }

    for (const item of data.permission_indeces) {
      item["module_id"] = id;
      item["has_access"] = 0;
      item["action_type"] = "ADD";
    }

    const payload = { data };

    const resp = await UpdatePermissionList(payload, platform_id);
    if (resp.status === 200) {
      message
        .info(
          "All added permissions, will auto reflect on the users and roles connected to this module"
        )
        .then(() => {
          message.success("Successfuly Added Permissions");
        });

      handleShowModalUpdatePermission();
      setSelectedPlatformByModule(null);
    }
  };

  const handleShowModalPermission = () => {
    setShowPermissionAddModal(!showPermissionAddModal);
  };

  const handleShowModalUpdatePermission = () => {
    setShowUpdatePermissionsModal(!showUpdatePermissionsModal);
  };

  const resetSelection = () => {
    setSelectedRowKeys(null);
    setSelectedRows(null);
  };

  const onPaginationChange = (page, limit) => {
    setCurrentPagination(page);
    setCurrentCount(limit);
    fetchPermissionIndex(page, limit);
  };

  const fetchPermissionIndex = async (page = 1, total = 10) => {
    setCurrentPagination(page);
    const query = {
      page: page,
      limit: total,
      sortColumn: sortColumn.current,
      sortType: sortType.current,
      columnFilter: columnFilter,
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const data = await useGetPermissionIndexList(query);
    setPermissionIndex(data);
  };

  const fetchPlatforms = async (page = 1, total = null) => {
    const query = {
      page: page,
      total: total,
    };
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const data = await useGetPlatforms(query, false);
    setPlatforms(data);
  };

  const fetchModules = useCallback(
    async (page = 1, limit = "ALL") => {
      const query = {
        page: page,
        limit: limit,
      };

      // eslint-disable-next-line react-hooks/rules-of-hooks
      const data = await useGetModulesByQuery(selectedPlatform, query, false);
      setModules(data);
    },
    [selectedPlatform]
  );

  const onChangePlatform = async (platform) => {
    setSelectedPlatform(platform);
  };

  ///////////////////////////////
  // for column filter tags
  const onCloseColumnFilterTag = (id) => {
    setColumnFilterTags((prevState) => {
      return prevState.filter((item) => item.id !== id);
    });

    setColumnFilter((prevState) => {
      let newData = prevState.filter((item) => {
        return Array.isArray(item.id)
          ? JSON.stringify(item.id) != id
          : item.id != id;
      });

      return newData;
    });

    if (!columnFilterTags.length) {
      setIsShowColumnFilter(false);
    }
  };

  const onLoadColumnFilterTags = () => {
    if (columnFilter.length) {
      let filterTags = columnFilter.map((e) => {
        let [label, tagId] = Array(2).fill(e.id);

        if (Array.isArray(e.id)) {
          tagId = JSON.stringify(tagId);
          label = "";

          for (const wordLabel of e.id) {
            label += `${wordLabel} `;
          }
        }

        return {
          color: "processing",
          closable: true,
          selection_label: label,
          value: e.value,
          id: tagId,
          onClose: onCloseColumnFilterTag,
        };
      });

      setColumnFilterTags(filterTags);
      setIsShowColumnFilter(true);
    } else {
      // if columnFilter is just blank, then remove the tags
      setColumnFilterTags([]);
      setIsShowColumnFilter(false);
    }
  };

  const onClearColumnFilters = () => {
    setColumnFilterTags([]);
    setIsShowColumnFilter(false);
    setColumnFilter([]);
  };

  useEffect(() => {
    fetchModules();
  }, [fetchModules, selectedPlatform]);

  useEffect(() => {
    fetchPlatforms();
    fetchPermissionIndex();
  }, []);

  useComponentDidUpdate(() => {
    fetchPermissionIndex(1, currentCount);
    onLoadColumnFilterTags();
  }, [columnFilter]);

  return (
    <>
      <PageHeader
        title="PERMISSIONS"
        subTitle="PERMISSION INDEX MANAGEMENT MODULE"
        breadcrumbRender={() => (
          <BreadCrumbsComponent
            breadcrumbsItem={["Administration", "Permission Index"]}
          />
        )}
      />

      <div className="content-pane-transaction-div-style">
        <Divider />
        <PermissionIndexFunctionalityComponent
          handleShowModalPermission={handleShowModalPermission}
          selectedPlatform={selectedRows}
          permissions={permissions}
        />

        <ModulesSelectAndColumnTagsComponent
          onClearColumnFilters={onClearColumnFilters}
          isShowColumnFilterTags={isShowColumnFilter}
          columnFilterTags={columnFilterTags}
          isShowSelection={!!selectedRows}
          onClearSearcheAndSelection={resetSelection}
          tags={[
            {
              color: "processing",
              selection_label: "Selected Platform",
              value: selectedRows ? `${selectedRows.name}` : null,
            },
          ]}
        />
        <PermissionIndexListComponent
          data={permissionIndex}
          onPaginationChange={onPaginationChange}
          currentPagination={currentPagination}
          columnFilter={columnFilter}
          setColumnFilter={setColumnFilter}
          onSortChange={onSortChange}
        />
      </div>
      <AddNewPermissionIndexComponent
        visible={showPermissionAddModal}
        OnOk={OnFinish}
        onCancel={handleShowModalPermission}
        form={permissionForm}
        platforms={platforms}
        modules={modules}
        onChangePlatform={onChangePlatform}
      />
      <UpdatePermissionListComponent
        visible={showUpdatePermissionsModal}
        OnFinish={OnFinishUpdatePermission}
        onCancel={handleShowModalUpdatePermission}
        form={updatePermissionForm}
      />
    </>
  );
};
