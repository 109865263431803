import { useEffect, useState } from "react";

const columns = [
  { title: "id", dataIndex: "users_id", key: "users_id" },
  {
    title: "Firstname",
    dataIndex: "users_first_name",
    key: "users_first_name",
  },
  {
    title: "Lastname",
    dataIndex: "users_last_name",
    key: "users_last_name",
  },
  {
    title: "Middlename",
    dataIndex: "users_middle_name",
    key: "users_middle_name",
  },
  { title: "Email", dataIndex: "users_email", key: "users_email" },
  { title: "Referrer Code", dataIndex: "referrer_code", key: "referrer_code" },
  {
    title: "Referred By",
    dataIndex: ["referrer", "referral_info", "users_email"],
    key: "referrer",
  },
  {
    title: "Registration Date",
    dataIndex: "created_at",
    key: "created_at",
  },
];

export default function useGetAPlusMemberColumn() {
  const [tableColumns, setColumns] = useState(columns);

  useEffect(() => {
    setColumns(setColumns);
  }, []);

  return tableColumns;
}
