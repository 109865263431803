import React from "react";
import { ModulesFunctionalitiesComponent } from "../../ModulesFunctionalitiesComponent";
import { DeleteOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";

export const IslandGroupsFunctionalityComponent = (props) => {
  const {
    onClickCreateIslandGroupsButton,
    onClickUpdateIslandGroupsButton,
    onClickDeleteIslandGroupsButton,
    selectedRowKey,
    selectedRow,
  } = props;

  const functionalitiesComponent = [
    {
      component_type: "button",
      type: "primary",
      icon: <EditOutlined />,
      _onClick: onClickCreateIslandGroupsButton,
      label: "Create Island Groups",
      size: "default",
    },
    {
      component_type: "button",
      type: "default",
      icon: <SaveOutlined />,
      _onClick: onClickUpdateIslandGroupsButton,
      label: "Update Island Groups",
      size: "default",
      is_hidden: selectedRowKey ? false : true,
    },
    {
      component_type: "button",
      type: "default",
      icon: <DeleteOutlined />,
      _onClick: onClickDeleteIslandGroupsButton,
      label: "Delete Island Groups",
      size: "default",
      is_hidden: selectedRowKey ? false : true,
    },
  ];

  return (
    <>
      <ModulesFunctionalitiesComponent
        functionalitiesComponent={functionalitiesComponent}
        alignment="right"
        spaceSize="small"
      />
    </>
  );
};
