export default function useFilteredColumns(
  cols,
  getColumnFilterProps,
  customCondition = (param) => true
) {
  let newCols = [];

  for (const col of cols) {
    if (
      col.dataIndex !== undefined &&
      col.title !== "Actions" &&
      customCondition(cols)
    ) {
      newCols.push({
        ...col,
        sorter: true,
        ...getColumnFilterProps(col.dataIndex),
      });
    } else {
      newCols.push({
        ...col,
      });
    }
  }

  return newCols;
}
