import { PageHeader, Divider } from "antd";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { APlusMembersFunctionalityComponent } from "../sub_components/a_plus_network/APlusMembersFunctionalityComponent";
import { APlusMembersTableManagementComponent } from "../sub_components/a_plus_network/APlusMembersTableManagementComponent";
import useFetchAPlusMembers from "../../hooks/a_plus_network/useFetchAPlusMembers";
import { useState } from "react";

export const APlusMemberManagementComponent = () => {
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedUserInfo, setSelectedUserInfo] = useState(null);
  const {
    data,
    isError,
    meta,
    pagination,
    setPagination,
    setQueryParameters,
    queryParameters,
    isLoading,
  } = useFetchAPlusMembers();

  const onPaginationChange = (page, limit) => {
    setPagination({ page, limit });
  };

  const onRowSelection = (id, row) => {
    setSelectedUserId(id[0]);
    setSelectedUserInfo(row[0]);
  };

  const onChangeSearchInput = (e) => {
    setQueryParameters(() => {
      return { search_value: e.target.value };
    });
  };

  return (
    <>
      <PageHeader
        title="A+ Network"
        subTitle="A+ Network Management"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["A+ Network"]} />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <APlusMembersFunctionalityComponent
          onChangeSearchInput={onChangeSearchInput}
        />
        <APlusMembersTableManagementComponent
          isError={isError}
          data={data}
          selectedRowKey={selectedUserId}
          onPaginationChange={onPaginationChange}
          onSelection={onRowSelection}
          meta={meta}
          pagination={pagination}
          tableLoading={isLoading}
        />
      </div>
    </>
  );
};
