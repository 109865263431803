import React, { createContext, useState } from "react";

const Context = createContext();

const defaultUserProfile = {
  userId: null,
  fullName: "",
  token: null,
  roleName: "",
  emailAddress: "",
  permissions: [],
};

const Provider = ({ children }) => {
  const [userProfile, setUserProfileInfo] = useState(defaultUserProfile);

  return (
    <Context.Provider value={{ userProfile, setUserProfileInfo }}>
      {children}
    </Context.Provider>
  );
};

export { Context, Provider };
