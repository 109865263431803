import React from "react";
import { Button } from "antd";
import { FormOutlined } from "@ant-design/icons";
import extractPermission from "../../../dist/functions/extractPermission";

export const PlatformFunctionalitiesComponent = ({
  createPlatformOnClick,
  permissions = [],
}) => {
  const isHideCreatePlatform = extractPermission(
    permissions,
    "CREATE_PLATFORM"
  );

  return (
    <div className="btn-layer-div">
      <Button
        type="primary"
        size="middle"
        icon={<FormOutlined />}
        onClick={createPlatformOnClick}
        hidden={isHideCreatePlatform}
      >
        Create Platform
      </Button>
    </div>
  );
};
