import { message } from "antd";
import { useEffect, useState } from "react";
import {
  FetchIslandGroups,
  CreateIslandGroup,
  UpdateIslandGroup,
  DeleteIslandGroup,
} from "../../api/IslandGroup";
import { useAPIResponse } from "../useAPIResponse";
import { useGeneralFetch } from "../useGeneralFetch";

const initialTableState = { data: [], total: 0 };

export const useFetchIslandGroups = (
  isForTable = true,
  query = { page: 1, limit: 10, columnFilter: [] }
) => {
  return useGeneralFetch(
    isForTable,
    query,
    FetchIslandGroups,
    "island_group_id"
  );
};

export const useFetchIslandGroupsAsOptions = async (
  isForTable = true,
  query = { page: 1, limit: 10, columnFilter: [] }
) => {
  if (isForTable) {
    const data = await FetchIslandGroups(query);

    for (const item of data.data.data) {
      item["key"] = item.id;
    }

    return data.data;
  }

  const data = await FetchIslandGroups({
    page: 1,
    limit: null,
    columnFilter: [],
  });

  for (const item of data.data.data) {
    item["key"] = item.id;
  }

  return data.data.data;
};

export const useCreateIslandGroup = async (data) => {
  await message.loading("Creating Island Group..", 2);
  const response = await CreateIslandGroup(data);

  return useAPIResponse(response);
};

export const useUpdateIslandGroup = async (island_group_id, payload) => {
  await message.loading("Updating Island Group..", 2);
  const response = await UpdateIslandGroup(island_group_id, payload);

  return useAPIResponse(response);
};

export const useDeleteIslandGroup = async (island_group_id, payload) => {
  await message.loading("Deleting Island Group..", 2);
  const response = await DeleteIslandGroup(island_group_id, payload);

  return useAPIResponse(response);
};
