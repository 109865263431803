import { useEffect, useState } from "react";
import { Tag, Button } from "antd";
import { RedoOutlined } from "@ant-design/icons";

const columns = [
  {
    title: "ID",
    dataIndex: "partner_id",
    key: "partner_id",
  },
  {
    title: "Name",
    dataIndex: "partner_name",
    key: "partner_name",
  },
  {
    title: "Mobile",
    dataIndex: "partner_mobile",
    key: "partner_mobile",
  },
  {
    title: "Landline",
    dataIndex: "partner_landline",
    key: "partner_landline",
  },
  {
    title: "Email",
    dataIndex: "partner_email",
    key: "partner_email",
  },
  {
    title: "Partner Type",
    dataIndex: ["partner_type", "partner_type_label"],
    key: ["partner_type", "partner_type_label"],
  },
  {
    title: "Status",
    dataIndex: "partner_status",
    key: "partner_status",
    render: (partner_status) =>
      partner_status && partner_status !== "0" ? (
        <Tag color="green">Enable</Tag>
      ) : (
        <Tag color="orange">Archived</Tag>
      ),
  },
  {
    title: "Date Created",
    dataIndex: "created_at",
    key: "created_at",
  },
];

export default function useGetPartnerColumns(onClickRestorePartner) {
  const [tableColumns, setColumns] = useState(columns);

  useEffect(() => {
    setColumns([
      ...columns,
      {
        dataIndex: "partner_id",
        title: "Actions",
        render: (id, row) => (
          <Button
            size="small"
            type="link"
            icon={<RedoOutlined />}
            onClick={() => onClickRestorePartner(id)}
            disabled={row.partner_status !== "0" ? true : false}
          />
        ),
      },
    ]);
  }, []);

  return tableColumns;
}
