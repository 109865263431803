import React from "react";
import { Result } from "antd";

export const SuccessfulValidationComponent = () => {
  return (
    <>
      <Result
        status="success"
        title="Validation Success"
        subTitle="You can now proceed with uploading the data"
      />
    </>
  );
};
