import React from "react";
import {
  Form,
  Select,
  Input,
  Radio,
  InputNumber,
  Typography,
  Divider,
  Col,
  Row,
} from "antd";
import { SelectOptionFooterExtraButtonComponent } from "../../SelectOptionFooterExtraButtonComponent";
import { FormRules } from "../../../dist/functions/formRules";

const { REQUIRED_PARTNER_ID, REQUIRED_AGENT_ID, REQUIRED_ASSET_STATUS } =
  FormRules;
const { Option } = Select;
const { Title, Text } = Typography;

export const AssetGenralInformationFormComponent = (props) => {
  const {
    isHidden,
    partners = { data: [], total: 0 },
    agents = { data: [], total: 0 },
    developers = { data: [], total: 0 },
    projects = { data: [], total: 0 },
    propertyCategories,
    onClickShowMorePartner,
    onClickShowMoreAgent,
    onClickShowMoreDeveloper,
    onClickShowMoreProject,
    selectedAssets,
  } = props;

  return (
    <>
      <div
        style={{
          display: isHidden ? "none" : null,
        }}
      >
        <Title level={5}>
          {selectedAssets.property_title
            ? `Property Title: ${selectedAssets.property_title}`
            : ""}
        </Title>
        <Text type="secondary">
          Note: To make changes on the property title, update the ff. fields (
          <Text code>Number of Bedrooms</Text>, <Text code>Property Type</Text>,{" "}
          <Text code>City Name</Text> and <Text code>Province Name</Text>) to
          do. so
        </Text>
        <Divider />
        <Form.Item name="asset_uuid" fieldKey="asset_uuid" label="Asset UUID">
          <Input disabled={true} />
        </Form.Item>
        <Form.Item
          label="Partner"
          name="partner_id"
          fieldKey="partner_id"
          shouldUpdate={true}
          rules={[REQUIRED_PARTNER_ID]}
        >
          <Select
            placeholder="Select Partner"
            virtual={true}
            disabled={true}
            dropdownRender={(menu) => (
              <SelectOptionFooterExtraButtonComponent
                key="show_more"
                menu={menu}
                onClickShowMoreList={onClickShowMorePartner}
                isClickShowMoreDisabled={
                  partners.data.length < partners.total ? false : true
                }
              />
            )}
          >
            {partners.data.map((item) => (
              <Option
                id={item.partner_id}
                key={item.partner_id}
                value={item.partner_id}
              >
                {item.partner_name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="assets_condition"
          fieldKey="assets_condition"
          label="Assets / Property Condition"
        >
          <Input />
        </Form.Item>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              name="asset_status"
              fieldKey="asset_status"
              label="Property Status"
              rules={[REQUIRED_ASSET_STATUS]}
            >
              <Radio.Group>
                <Radio value={"active"}>Active</Radio>
                <Radio value={"not active"}>Not Active</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="is_featured"
              fieldKey="is_featured"
              label="Is Featured?"
            >
              <Radio.Group>
                <Radio value={1}>Yes</Radio>
                <Radio value={0}>No</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="asset_property_category_id"
          fieldKey="asset_property_category_id"
          label="Asset / Property Category"
        >
          <Select placeholder="Select Category" virtual={true}>
            {propertyCategories.map((item) => (
              <Option
                id={item.property_category_id}
                key={item.property_category_id}
                value={item.property_category_id}
              >
                {item.property_category_label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item
              label="Developer"
              name="developer_id"
              fieldKey="developer_id"
              shouldUpdate={true}
            >
              <Select
                placeholder="Select Developer"
                virtual={true}
                dropdownRender={(menu) => (
                  <SelectOptionFooterExtraButtonComponent
                    key="show_more"
                    menu={menu}
                    onClickShowMoreList={onClickShowMoreDeveloper}
                    isClickShowMoreDisabled={
                      developers.data.length < developers.total ? false : true
                    }
                  />
                )}
              >
                {developers.data.map((item) => (
                  <Option
                    id={item.partner_id}
                    key={item.partner_id}
                    value={item.partner_id}
                  >
                    {item.partner_name === 0 || item.partner_name === null
                      ? ""
                      : item.partner_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Project"
              name="project_id"
              fieldKey="project_id"
              shouldUpdate={true}
            >
              <Select
                placeholder="Select Project"
                virtual={true}
                dropdownRender={(menu) => (
                  <SelectOptionFooterExtraButtonComponent
                    key="show_more"
                    menu={menu}
                    onClickShowMoreList={onClickShowMoreProject}
                    isClickShowMoreDisabled={
                      projects.data.length < projects.total ? false : true
                    }
                  />
                )}
              >
                {projects.data.map((item) => (
                  <Option
                    id={item.property_projects_id}
                    key={item.property_projects_id}
                    value={item.property_projects_id}
                  >
                    {item.property_projects_name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label="Agent"
          name="agent_id"
          fieldKey="id"
          shouldUpdate={true}
          rules={[REQUIRED_AGENT_ID]}
        >
          <Select
            placeholder="Select Agent"
            virtual={true}
            dropdownRender={(menu) => (
              <SelectOptionFooterExtraButtonComponent
                key="show_more"
                menu={menu}
                onClickShowMoreList={onClickShowMoreAgent}
                isClickShowMoreDisabled={
                  agents.data.length < agents.total ? false : true
                }
              />
            )}
          >
            {agents.data.map((item) => {
              var agentFullName = "";

              agentFullName += `${item.users_first_name} ` || "";
              agentFullName += `${item.users_middle_name}` || "";
              agentFullName += ` ${item.users_last_name}` || "";

              return (
                <Option
                  id={item.users_id}
                  key={item.users_id}
                  value={item.users_id}
                >
                  {agentFullName}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="price_min" fieldKey="price_min" label="Price Min">
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="price_max" fieldKey="price_max" label="Price Max">
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={8}>
          <Col span={12}>
            <Form.Item name="longitude" fieldKey="longitude" label="Longitude">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="latitude" fieldKey="latitude" label="Latitude">
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </>
  );
};
