import { message } from "antd";
import { FetchCities, CreateCity, UpdateCity } from "../../api/City";
import { useAPIResponse } from "../useAPIResponse";
import { useGeneralFetch } from "../useGeneralFetch";

export const useFetchCities = (
  isForTable = true,
  query = { page: 1, limit: 10, columnFilter: [] }
) => {
  return useGeneralFetch(isForTable, query, FetchCities, "id");
};

export const useFetchCitiesAsOptions = async (
  isForTable = true,
  query = { page: 1, limit: 10, columnFilter: [] }
) => {
  if (isForTable) {
    const data = await FetchCities(query);

    for (const item of data.data.data) {
      item["key"] = item.id;
    }

    return data.data;
  }

  const data = await FetchCities({
    page: 1,
    limit: "ALL",
    columnFilter: [],
  });

  for (const item of data.data.data) {
    item["key"] = item.id;
  }

  return data.data.data;
};

export const useCreateCity = async (data) => {
  await message.loading("Creating City..", 2);
  const response = await CreateCity(data);

  return useAPIResponse(response);
};

export const useUpdateCity = async (city_id, payload) => {
  await message.loading("Updating City..", 2);
  const response = await UpdateCity(city_id, payload);

  return useAPIResponse(response);
};
