import React, { useState, useEffect } from "react";
import { message } from "antd";
import {
  FetchRegions,
  CreateRegion,
  UpdateRegion,
  DeleteRegion,
} from "../../api/Region";
import { useAPIResponse } from "../useAPIResponse";
import { useGeneralFetch } from "../useGeneralFetch";

const initialTableState = { data: [], total: 0 };

export const useFetchRegions = (
  isForTable = true,
  query = { page: 1, limit: 10, columnFilter: [] }
) => {
  return useGeneralFetch(isForTable, query, FetchRegions, "region_id");
};

export const useCreateRegion = async (data) => {
  await message.loading("Creating Region..", 2);
  const response = await CreateRegion(data);

  return useAPIResponse(response);
};

export const useUpdateRegion = async (region_id, payload) => {
  await message.loading("Updating Region..", 2);
  const response = await UpdateRegion(region_id, payload);

  return useAPIResponse(response);
};

export const useDeleteRegion = async (region_id, payload) => {
  await message.loading("Deleting Region..", 2);
  const response = await DeleteRegion(region_id, payload);

  return useAPIResponse(response);
};
