import { useEffect, useState } from "react";
import { useFetch } from "../useFetch";

const useFetchUrbans = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const Fetch = async () => {
      setLoading(true);
      try {
        const data = await useFetch(
          `${process.env.REACT_APP_API_URL}/urbans`,
          {}
        );

        if (data) {
          setData(data);
        }
      } catch (err) {
        console.log("error: ", err.message);
        setLoading(false);
      }
      setLoading(false);
    };

    Fetch();
  }, []);

  return { urbans: data, loading };
};

export default useFetchUrbans;
