import React from "react";
import { Table, Typography } from "antd";

const { Column } = Table;
const { Title } = Typography;
export const ListingListAssetComponent = ({ asset = {}, asset_listing_id }) => {
  const _asset = [];
  for (const i in asset) {
    let assetObj = { key: i, value: asset[i] };
    _asset.push(assetObj);
  }

  return (
    <div style={{ padding: "2%" }}>
      <Table
        width="80%"
        dataSource={_asset}
        size="small"
        pagination={false}
        bordered={true}
      >
        <Column name="key" dataIndex="key" title="Key" />
        <Column name="value" dataIndex="value" title="Value" />
      </Table>
    </div>
  );
};
