import "antd/dist/antd.css";
import "./App.css";
import React from "react";
import { RoutesComponent } from "./components/RoutesComponent";
import { Provider } from "./context/Context";

function App() {
  return (
    <Provider>
      <RoutesComponent />
    </Provider>
  );
}

export default App;
