import { useCreate } from "../hooks/useCreate";
import { useFetch } from "../hooks/useFetch";
import { useUpdate } from "../hooks/useUpdate";
import { useDelete } from "../hooks/useDelete";
import {
  CREATE_ASSET_META_FIELD_URL,
  DELETE_ASSET_META_FIELD_URL,
  FETCH_ASSET_META_FIELD_URL,
  UPDATE_ASSET_META_FIELD_URL,
  UPDATE_ASSET_META_FIELD_BY_ASSET_CUSTOM_URL,
  QUERY_ASSET_META_FIELD_URL,
} from "./routes/asset_meta_field";

export const FetchAssetMetaFields = async (query) => {
  try {
    return await useFetch(FETCH_ASSET_META_FIELD_URL, query);
  } catch (err) {
    if (err.request) return err.request;
    if (err.resonse) return err.response;
    return err.message;
  }
};

export const CreateAssetMetaFields = async (payload) => {
  try {
    return await useCreate(CREATE_ASSET_META_FIELD_URL, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.resonse) return err.response;
    return err.message;
  }
};

export const UpdateAssetMetaFields = async (asset_meta_field_id, payload) => {
  try {
    return await useUpdate(
      `${UPDATE_ASSET_META_FIELD_URL}/${asset_meta_field_id}`,
      payload
    );
  } catch (err) {
    if (err.request) return err.request;
    if (err.resonse) return err.response;
    return err.message;
  }
};

export const UpdateAssetMetaFieldsByAssetCustom = async (
  asset_id,
  asset_custom_field_id,
  payload
) => {
  try {
    return await useUpdate(
      `${UPDATE_ASSET_META_FIELD_BY_ASSET_CUSTOM_URL}/${asset_id}/custom/${asset_custom_field_id}`,
      payload
    );
  } catch (err) {
    if (err.request) return err.request;
    if (err.resonse) return err.response;
    return err.message;
  }
};

export const DeleteAssetMetaFields = async (asset_meta_field_id) => {
  try {
    return await useDelete(
      `${DELETE_ASSET_META_FIELD_URL}/${asset_meta_field_id}`
    );
  } catch (err) {
    if (err.request) return err.request;
    if (err.resonse) return err.response;
    return err.message;
  }
};

export const FetchMetaFieldByQuery = async (query) => {
  try {
    return await useFetch(QUERY_ASSET_META_FIELD_URL, query);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
