import { message } from "antd";

import { useAPIResponse } from "../useAPIResponse";
import {
  FetchPropertyProjects,
  CreatePropertyProject,
  UpdatePropertyProject,
  DeletePropertyProject,
  UpdatePropertyProjectPivot,
  UpdatePropertyProjecTags,
  UploadProjectImages,
} from "../../api/PropertyProjects";

export const useFetchPropertyProjects = async (
  isForTable = true,
  query = { page: 1, limit: 10 || "ALL", columnFilter: [] }
) => {
  if (isForTable) {
    const data = await FetchPropertyProjects(query);

    for (const item of data.data.data) {
      item["key"] = item.property_projects_id;
    }

    return data.data;
  }

  const data = await FetchPropertyProjects({
    page: 1,
    limit: null,
    columnFilter: [],
  });

  for (const item of data.data.data) {
    item["key"] = item.property_projects_id;
  }

  return data.data.data;
};

export const useCreatePropertyProject = async (data) => {
  await message.loading("Creating Property Project..", 2);
  const response = await CreatePropertyProject(data);

  return useAPIResponse(response);
};

export const useUpdatePropertyProject = async (
  property_projects_id,
  payload
) => {
  await message.loading("Updating Property Project..", 2);
  const response = await UpdatePropertyProject(property_projects_id, payload);

  return useAPIResponse(response);
};

export const useUpdatePropertyProjectTags = async (
  property_projects_id,
  payload
) => {
  await message.loading("Updating Property Project..", 2);
  const response = await UpdatePropertyProjecTags(
    property_projects_id,
    payload
  );

  return useAPIResponse(response);
};

export const useUpdatePropertyProjectsPivot = async (
  property_projects_id,
  payload
) => {
  await message.loading("Updating Property Project Pivot..", 2);
  const response = await UpdatePropertyProjectPivot(
    property_projects_id,
    payload
  );

  return useAPIResponse(response);
};

export const useDeletePropertyProject = async (property_projects_id) => {
  await message.loading("Updating Property Project..", 2);
  const response = await DeletePropertyProject(property_projects_id);

  return useAPIResponse(response);
};

export const useUploadProjectImage = async (payload) => {
  const resp = await UploadProjectImages(payload);

  const { status } = resp;

  if (status !== 201) {
    return JSON.parse(resp.response);
  }

  return resp.data;
};
