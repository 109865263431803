import React from "react";

import { RegionsFormModalComponent } from "./RegionsFormModalComponent";

export const RegionsFormsModalFunctionalityComponent = (props) => {
  const {
    onSubmitRegionsCreation,
    onCancelRegionsCreation,
    regionsModalVisibility,
    regionsCreationForm,
    onSubmitRegionsUpdate,
    onCancelRegionsUpdate,
    regionsUpdatedModalVisibility,
    regionsUpdateForm,
    island_groups = { data: [], total: 0 },
    onClickShowMoreIslandGroup,
  } = props;

  return (
    <>
      <RegionsFormModalComponent
        form={regionsCreationForm}
        isVisible={regionsModalVisibility}
        onClickSubmit={onSubmitRegionsCreation}
        onClickCancel={onCancelRegionsCreation}
        island_groups={island_groups}
        onClickShowMoreIslandGroup={onClickShowMoreIslandGroup}
      />
      <RegionsFormModalComponent
        form={regionsUpdateForm}
        isVisible={regionsUpdatedModalVisibility}
        onClickSubmit={onSubmitRegionsUpdate}
        onClickCancel={onCancelRegionsUpdate}
        island_groups={island_groups}
        onClickShowMoreIslandGroup={onClickShowMoreIslandGroup}
        isUpdate={true}
      />
    </>
  );
};
