import { useFetch } from "../hooks/useFetch";
import { useCreate } from "../hooks/useCreate";
import { useUpdate } from "../hooks/useUpdate";
import { useDelete } from "../hooks/useDelete";
import {
  FETCH_PARTNER_URL,
  CREATE_PARTNER_URL,
  UPDATE_PARTNER_URL,
  DELETE_PARTNER_URL,
  ARCHIVE_PARTNER_URL,
  RESTORE_PARTNER_URL,
  FETCH_PARTNER_URL_LISTING_NULL,
} from "../api/routes/partners";

export const FetchPartners = async (query) => {
  try {
    return await useFetch(FETCH_PARTNER_URL, query);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const FetchPartnersListingNull = async (query) => {
  try {
    return await useFetch(FETCH_PARTNER_URL_LISTING_NULL, query);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const CreatePartner = async (payload) => {
  try {
    return await useCreate(CREATE_PARTNER_URL, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const UpdatePartner = async (partner_id, payload) => {
  try {
    return await useUpdate(`${UPDATE_PARTNER_URL}/${partner_id}`, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const ArchivePartner = async (partner_id, payload) => {
  try {
    return await useUpdate(`${ARCHIVE_PARTNER_URL}/${partner_id}`, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const RestorePartner = async (partner_id, payload) => {
  try {
    return await useUpdate(`${RESTORE_PARTNER_URL}/${partner_id}`, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
export const DeletePartner = async (partner_id, payload) => {
  try {
    return await useDelete(`${DELETE_PARTNER_URL}/${partner_id}`);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
