import React, { useState, useEffect } from "react";
import { Form, Divider, PageHeader, Modal } from "antd";
import { ModulesFunctionalityComponent } from "../sub_components/modules/ModulesFunctionalityComponent";

import { BreadCrumbsComponent } from "../BreadCrumbsComponent";

import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";
import { ModulesSelectAndColumnTagsComponent } from "../ModulesSelectAndColumnTagsComponent";

import {
  useCreateBulkModule,
  useCreateModule,
  useDeleteModule,
  useFetchModules,
  useUpdateModule,
} from "../../hooks/modules/useModuleAPI";
import { ModuleFormsModalFunctionalityComponent } from "../sub_components/modules/ModuleFormsModalFunctionalityComponent";
import { ModuleListComponent } from "../sub_components/modules/ModuleListComponent";
import { useQueryFilterReducer } from "../../hooks/useQueryFilterReducer";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useFetchPlatforms } from "../../hooks/platforms/usePlatformAPI";

const { confirm } = Modal;

export const ModulesComponent = ({ permissions }) => {
  const [createModuleForm] = Form.useForm();
  const [createBulkModuleForm] = Form.useForm();
  const [updateModuleForm] = Form.useForm();
  const [bulkModuleCreationModalStatus, setBulkModuleCreationModalStatus] =
    useState(false);
  const [moduleCreationModalStatus, setModuleCreationModalStatus] =
    useState(false);
  const [moduleUpdateModalStatus, setModuleUpdateModalStatus] = useState(false);

  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [platformsQuery, setPlatformsQuery] = useState({ page: 1, limit: 10 });
  const [platforms] = useFetchPlatforms(true, platformsQuery);
  const {
    queryFilters,
    actionsQueryFilters,
    onPaginationChange,
    onSortChange,
    columnFilterTags,
    onClearColumnFilters,
    onLoadColumnFilterTags,
    resetQueryFilters,
  } = useQueryFilterReducer();
  const [modulesData, isLoadingModules] = useFetchModules(true, queryFilters);

  /**
   *
   * Initial Module Fetch
   *    - Fetch with 1 page, limit 10 with no filter as query parameters
   */

  useComponentDidUpdate(() => {
    onLoadColumnFilterTags();
  }, [queryFilters]);

  /**
   *
   * Use effect hooks if any of the data is selected
   * Selected row will populate the form for updating
   *
   */
  useEffect(() => {
    if (selectedRow && moduleUpdateModalStatus === true) {
      updateModuleForm.setFieldsValue({ ...selectedRow });
    }
  }, [selectedRow, moduleUpdateModalStatus, updateModuleForm]);

  /**
   *
   * On every selection change, update the row selection state to trigger the use effect hook with row selection dependency
   * It will re-trigger fetch function with the updated row selection and populate forms for updating
   *
   */
  const onSelectionChange = (keys, selectedRows) => {
    setSelectedRow(selectedRows[0]);
    setSelectedRowKey(keys[0]);
  };

  /**
   *
   * Create Bulk Module function with success validation, connected to useCreateBulkModule hook
   *
   */

  const CreateBulkModule = async () => {
    const payload = await createBulkModuleForm.validateFields();
    const isSuccess = await useCreateBulkModule(payload.platform_id, payload);
    if (isSuccess) {
      createBulkModuleForm.resetFields();
      resetQueryFilters();
      onClickCreateBulkModuleButton();
      resetSelections();
    }
  };

  /**
   *
   * Create Module function with success validation, connected to useCreateModule hook
   *
   */

  const CreateModule = async () => {
    const payload = await createModuleForm.validateFields();
    const isSuccess = await useCreateModule(payload);
    if (isSuccess) {
      createModuleForm.resetFields();
      resetQueryFilters();
      onClickCreateModuleButton();
      resetSelections();
    }
  };

  /**
   *
   * Update Module function with success validation, connected to useUpdateModule hook
   *
   */

  const UpdateModule = async () => {
    const payload = await updateModuleForm.validateFields();
    const isSuccess = await useUpdateModule(selectedRowKey, payload);
    if (isSuccess) {
      updateModuleForm.resetFields();
      resetQueryFilters();
      onClickUpdateModuleButton();
      resetSelections();
    }
  };

  /**
   *
   * Delete Module function with success validation, connected to useDeleteModule hook
   *
   */
  const DeleteModule = async () => {
    const isSuccess = await useDeleteModule(selectedRowKey);
    if (isSuccess) {
      resetQueryFilters();
      resetSelections();
    }
  };

  /**
   *
   * Helper functions:
   *
   * - Functions that will reset all selections, pagination and restore initial index/fetch
   *
   */

  const DeleteConfirm = async () => {
    confirm({
      title: "Do you want to delete this module?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        DeleteModule();
      },
    });
  };

  const resetSelections = () => {
    setSelectedRowKey(null);
    setSelectedRow(null);
  };

  const onClickCreateModuleButton = () => {
    setModuleCreationModalStatus(!moduleCreationModalStatus);
  };

  const onClickCreateBulkModuleButton = () => {
    setBulkModuleCreationModalStatus(!bulkModuleCreationModalStatus);
  };

  const onClickUpdateModuleButton = () => {
    setModuleUpdateModalStatus(!moduleUpdateModalStatus);
  };

  return (
    <>
      <PageHeader
        title="MODULES"
        subTitle="MODULE PER PLATFORM MANAGEMENT"
        breadcrumbRender={() => (
          <BreadCrumbsComponent
            breadcrumbsItem={["Administration", "Modules"]}
          />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <ModulesFunctionalityComponent
          onClickCreateModuleButton={onClickCreateModuleButton}
          onClickUpdateModuleButton={onClickUpdateModuleButton}
          onClickCreateBulkModuleButton={onClickCreateBulkModuleButton}
          onClickDeleteModuleButton={DeleteConfirm}
          permissions={permissions}
          selectedRowKey={selectedRowKey}
          selectedRow={selectedRow}
        />

        <ModulesSelectAndColumnTagsComponent
          onClearColumnFilters={onClearColumnFilters}
          isShowColumnFilterTags={columnFilterTags.length}
          columnFilterTags={columnFilterTags}
          isShowSelection={!!selectedRow}
          onClearSearcheAndSelection={resetSelections}
          tags={[
            {
              color: "processing",
              selection_label: "Selected Module",
              value: selectedRow ? `${selectedRow.name}` : null,
            },
          ]}
        />
        <ModuleListComponent
          data={modulesData}
          tableLoading={isLoadingModules}
          onPaginationChange={onPaginationChange}
          selectedRowKey={selectedRowKey}
          onSelection={onSelectionChange}
          currentPagination={queryFilters.page}
          onSortChange={onSortChange}
          setColumnFilter={actionsQueryFilters.changeColumnFilter}
          columnFilter={queryFilters.columnFilter}
        />
        <ModuleFormsModalFunctionalityComponent
          onSubmitBulkModuleCreation={CreateBulkModule}
          bulkModuleModalVisibility={bulkModuleCreationModalStatus}
          onCancelBulkModuleUpdate={onClickCreateBulkModuleButton}
          bulkModuleCreationForm={createBulkModuleForm}
          onSubmitModuleCreation={CreateModule}
          onCancelModuleCreation={onClickCreateModuleButton}
          moduleModalVisibility={moduleCreationModalStatus}
          moduleCreationForm={createModuleForm}
          onSubmitModuleUpdate={UpdateModule}
          onCancelModuleUpdate={onClickUpdateModuleButton}
          moduleUpdatedModalVisibility={moduleUpdateModalStatus}
          moduleUpdateForm={updateModuleForm}
          platforms={platforms}
        />
      </div>
    </>
  );
};
