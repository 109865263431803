import React from "react";
import { ModulesFunctionalitiesComponent } from "../../ModulesFunctionalitiesComponent";
import { DeleteOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import extractPermission from "../../../dist/functions/extractPermission";

export const ListingsFunctionalityComponent = (props) => {
  const {
    onClickCreateListingButton,
    onClickUpdateListingButton,
    onClickUpdateAssetListingButton,
    onClickDeleteListingButton,
    selectedRowKey,
    permissions,
  } = props;

  const isHideCreateListing = extractPermission(permissions, "CREATE_LISTING");
  const isHideUpdateListing = extractPermission(permissions, "UPDATE_LISTING");
  const isHideDeleteListing = extractPermission(permissions, "DELETE_LISTING");

  const functionalitiesComponent = [
    {
      component_type: "button",
      type: "primary",
      icon: <EditOutlined />,
      _onClick: onClickCreateListingButton,
      hidden: isHideCreateListing,
      label: "Create Listing",
      size: "default",
    },
    {
      component_type: "button",
      type: "default",
      icon: <SaveOutlined />,
      _onClick: onClickUpdateListingButton,
      hidden: isHideUpdateListing,
      label: "Update Listing",
      size: "default",
      is_hidden: selectedRowKey ? false : true,
    },
    {
      component_type: "button",
      type: "default",
      icon: <SaveOutlined />,
      _onClick: onClickUpdateAssetListingButton,
      hidden: isHideUpdateListing,
      label: "Update Asset Listing",
      size: "default",
      is_hidden: selectedRowKey ? false : true,
    },
    {
      component_type: "button",
      type: "default",
      icon: <DeleteOutlined />,
      _onClick: onClickDeleteListingButton,
      hidden: isHideDeleteListing,
      label: "Delete Listing",
      size: "default",
      is_hidden: selectedRowKey ? false : true,
    },
  ];

  return (
    <>
      <ModulesFunctionalitiesComponent
        functionalitiesComponent={functionalitiesComponent}
        alignment="right"
        spaceSize="small"
      />
    </>
  );
};
