import { useCreate } from "../../hooks/useCreate";

export const CreateAsset = async (data) => {
  try {
    const result = useCreate(
      `${process.env.REACT_APP_API_URL}/assets/create`,
      data
    );
    return result;
  } catch (err) {
    return null;
  }
};
