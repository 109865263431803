import React from "react";
import { ModulesTableComponent } from "../../ModulesTableComponent";
import useColumnFilter from "../../../hooks/useColumnFilter";
import useGetPropertyDeveloperContentsColumns from "../../../hooks/property_developers/useGetPropertyDeveloperContentsColumns";
import {
  Divider,
  Card,
  Typography,
  Button,
  Image,
  Row,
  Col,
  Empty,
  Upload,
  Descriptions,
} from "antd";
import { InboxOutlined, DeleteOutlined } from "@ant-design/icons";
import useGetPropertyDeveloperColumns from "../../../hooks/property_developers/useGetPropertyDeveloperColumns";
import useFilteredColumns from "../../../hooks/useFilteredColumns";

export const PropertyDeveloperListComponent = (props) => {
  const {
    data = { data: [], total: 0 },
    onPaginationChange,
    currentPagination,
    onSelection,
    selectedRowKey,
    onSortChange,
    columnFilter,
    setColumnFilter,
    tableLoading,
    onClickRestoreDeveloper,
    developerImageUploaderProps,
    HandleProjectImagesUpload,
  } = props;
  const { Title } = Typography;
  const { Dragger } = Upload;
  const { Item } = Descriptions;

  const { getColumnFilterProps } = useColumnFilter(
    columnFilter,
    setColumnFilter
  );

  const propertyDeveloperColumns = useGetPropertyDeveloperColumns(
    onClickRestoreDeveloper
  );

  let newCols = useFilteredColumns(
    propertyDeveloperColumns,
    getColumnFilterProps
  );

  const ImageContainer = ({ medias, subType }) => {
    const media = medias.filter((media) => media.sub_type === subType);

    if (media.length === 0) {
      return (
        <Card size="small" style={{ width: "70%" }}>
          <Empty description="No Image Uploaded" />
        </Card>
      );
    }

    return (
      <Card
        size="small"
        style={{ width: "70%" }}
        actions={[
          <Button
            type="text"
            danger={true}
            onClick={() => {}}
            icon={<DeleteOutlined />}
          >
            Delete Image
          </Button>,
        ]}
      >
        <Image src={media[0].url} style={{ width: "100%" }} />
      </Card>
    );
  };

  return (
    <>
      <ModulesTableComponent
        expandable={{
          expandedRowRender: (record) => (
            <div>
              <Card>
                <Title level={4}>Other Details(s)</Title>
                <br />
                <Divider />
                <Title level={5}>Image(s)</Title>
                <br />
                <Row>
                  <Col span={16}>
                    <span>Logo (Square): </span>
                    <br />
                    <Row>
                      <Col span={12}>
                        <Dragger
                          {...developerImageUploaderProps}
                          customRequest={(e) =>
                            HandleProjectImagesUpload(e, record, "square")
                          }
                        >
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p
                            className="ant-upload-text"
                            style={{ fontSize: "0.95rem" }}
                          >
                            Click or drag file to this area to upload
                          </p>
                          <p
                            className="ant-upload-hint"
                            style={{ fontSize: "0.90rem" }}
                          >
                            Support Single File Upload Only
                          </p>
                        </Dragger>
                      </Col>
                      <Col span={12}>
                        <ImageContainer
                          medias={record.medias}
                          subType={"square"}
                        />
                      </Col>
                    </Row>
                    <br />
                    <span>Logo (Circle): </span>
                    <br />
                    <Row>
                      <Col span={12}>
                        <Dragger
                          {...developerImageUploaderProps}
                          customRequest={(e) =>
                            HandleProjectImagesUpload(e, record, "circle")
                          }
                        >
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p
                            className="ant-upload-text"
                            style={{ fontSize: "0.95rem" }}
                          >
                            Click or drag file to this area to upload
                          </p>
                          <p
                            className="ant-upload-hint"
                            style={{ fontSize: "0.90rem" }}
                          >
                            Support Single File Upload Only
                          </p>
                        </Dragger>
                      </Col>
                      <Col span={12}>
                        <ImageContainer
                          medias={record.medias}
                          subType={"circle"}
                        />
                      </Col>
                    </Row>
                    <br />
                    <span>Logo (Transparent) : </span>
                    <br />
                    <Row>
                      <Col span={12}>
                        <Dragger
                          {...developerImageUploaderProps}
                          customRequest={(e) =>
                            HandleProjectImagesUpload(e, record, "transparent")
                          }
                        >
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                          </p>
                          <p
                            className="ant-upload-text"
                            style={{ fontSize: "0.95rem" }}
                          >
                            Click or drag file to this area to upload
                          </p>
                          <p
                            className="ant-upload-hint"
                            style={{ fontSize: "0.90rem" }}
                          >
                            Support Single File Upload Only
                          </p>
                        </Dragger>
                      </Col>
                      <Col span={12}>
                        <ImageContainer
                          medias={record.medias}
                          subType={"transparent"}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col span={8}>
                    <Descriptions size="small" layout="vertical">
                      <Item label={<Title level={5}>Notes</Title>} span={3}>
                        {record.partner_notes}
                      </Item>
                      <Item
                        label={<Title level={5}>Person in Charge</Title>}
                        span={3}
                      >
                        {record.partner_person_in_charge}
                      </Item>
                      <Item label={<Title level={5}>Code</Title>} span={3}>
                        {record.partner_code}
                      </Item>
                      <Item
                        label={<Title level={5}>Project(s)</Title>}
                        span={3}
                      >
                        <ul>
                          {record.property_developer_projects_pivots.map(
                            (item) => (
                              <li>
                                {item?.property_projects
                                  ?.property_projects_name ||
                                  item?.property_projects_id}
                              </li>
                            )
                          )}
                        </ul>
                      </Item>
                    </Descriptions>
                  </Col>
                </Row>
              </Card>
            </div>
          ),
        }}
        data={data}
        onPaginationChange={onPaginationChange}
        onSelection={onSelection}
        selectedRowKey={selectedRowKey}
        tableColumns={newCols || []}
        scrollSize={700}
        currentPagination={currentPagination}
        columnFilter={columnFilter}
        setColumnFilter={setColumnFilter}
        onSortChange={onSortChange}
        tableLoading={tableLoading}
      />
    </>
  );
};
