import {
  CreateAssetMetaFields,
  DeleteAssetMetaFields,
  UpdateAssetMetaFields,
  FetchAssetMetaFields,
  UpdateAssetMetaFieldsByAssetCustom,
  FetchMetaFieldByQuery,
} from "../../api/AssetMetaField";
import { message } from "antd";
import { useAPIResponse } from "../useAPIResponse";

export const useFetchAssetMetaField = async (query) => {
  const data = await FetchAssetMetaFields(query);
  for (const item of data.data.data) {
    item["key"] = item.asset_meta_field_id;
  }
  return data.data;
};

export const useCreateASsetMetaField = async (payload) => {
  await message.loading("Creating Asset Meta Field", 2);
  const response = await CreateAssetMetaFields(payload);

  return useAPIResponse(response);
};

export const useUpdateAssetMetaField = async (asset_meta_field_id, payload) => {
  // await message.loading("Updating Asset Meta Field", 2);
  const response = await UpdateAssetMetaFields(asset_meta_field_id, payload);

  return useAPIResponse(response);
};

export const useUpdateAssetMetaFieldByAssetCustom = async (
  asset_id,
  asset_custom_field_id,
  payload
) => {
  // await message.loading("Updating Asset Meta Field", 2);
  const response = await UpdateAssetMetaFieldsByAssetCustom(
    asset_id,
    asset_custom_field_id,
    payload
  );

  return useAPIResponse(response);
};

export const useDeleteAssetMetaField = async (asset_meta_field_id) => {
  await message.loading("Deleting Asset Meta Field", 2);

  const response = await DeleteAssetMetaFields(asset_meta_field_id);

  return useAPIResponse(response);
};

export const useQueryMetaField = async (query) => {
  const data = await FetchMetaFieldByQuery(query);

  if (data.length) {
    for (const item of data.data.data) {
      item["key"] = item.asset_meta_field_id;
    }
  }

  return data.data;
};
