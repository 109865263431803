import { useEffect, useState } from "react";
import { Switch } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

const columns = [
  {
    title: "Id",
    dataIndex: "property_projects_id",
    key: "property_projects_id",
  },
  {
    title: "Project Name",
    dataIndex: "property_projects_name",
    key: "property_projects_name",
  },
];

export default function useGetPropertyProjectColumns(UpdatePropertyProjectTag) {
  const [tableColumns, setColumns] = useState(columns);

  useEffect(() => {
    setColumns([
      ...columns,
      {
        title: "Developer",
        render: (row) => {
          return (
            row.property_developer_projects_pivots[0]?.developers
              ?.partner_name || ""
          );
        },
      },
      {
        title: "Address",
        dataIndex: "full_address",
        key: "full_address",
      },
      {
        title: "Locale",
        dataIndex: ["locale", "locale_name"],
        key: ["locale", "locale_name"],
      },
      {
        title: "Category Name",
        render: (row) => {
          return row?.propertyCategory?.property_category_label || "";
        },
      },
      {
        title: "Number of Advisors (Assigned)",
        render: (_id, row) => {
          return row.advisors.length;
        },
      },

      {
        title: "Featured (?)",
        dataIndex: "is_featured",
        key: "is_featured",
        render: (_id, row) => (
          <Switch
            defaultChecked={row.is_featured}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onChange={(value) => {
              UpdatePropertyProjectTag(row.property_projects_id, {
                is_featured: value,
              });
            }}
          />
        ),
      },
      {
        title: "Pseudo (?)",
        dataIndex: "pseudo_project",
        key: "pseudo_project",
        render: (id, row) => (
          <Switch
            defaultChecked={row.pseudo_project}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onChange={(value) => {
              UpdatePropertyProjectTag(row.property_projects_id, {
                pseudo_project: value,
              });
            }}
          />
        ),
      },
      {
        title: "Active (?)",
        dataIndex: "is_active",
        key: "is_active",
        render: (id, row) => (
          <Switch
            defaultChecked={row.is_active}
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            onChange={(value) => {
              UpdatePropertyProjectTag(row.property_projects_id, {
                is_active: value,
              });
            }}
          />
        ),
      },
    ]);
  }, []);

  return tableColumns;
}
