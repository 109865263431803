import { useEffect, useState } from "react";
import { Tag, Button } from "antd";
import { RedoOutlined } from "@ant-design/icons";

const columns = [
  {
    title: "Id",
    dataIndex: "asset_custom_field_id",
    key: "asset_custom_field_id",
  },
  {
    title: "UUID",
    dataIndex: "asset_custom_field_uuid",
    key: "asset_custom_field_uuid",
  },
  {
    title: "Field Name",
    dataIndex: "asset_custom_field_name",
    key: "asset_custom_field_name",
  },
  {
    title: "Field Label",
    dataIndex: "asset_custom_field_label",
    key: "asset_custom_field_label",
  },
  {
    title: "Field Type",
    dataIndex: "asset_custom_field_type",
    key: "asset_custom_field_type",
  },
  {
    title: "Status",
    dataIndex: "is_active",
    key: "is_active",
    render: (is_active) =>
      is_active || is_active === null ? (
        <Tag color="green">Enable</Tag>
      ) : (
        <Tag color="orange">Archived</Tag>
      ),
  },
  {
    title: "Date Created",
    dataIndex: "created_at",
    key: "created_at",
  },
  {
    title: "Last Modified Date",
    dataIndex: "updated_at",
    key: "updated_at",
  },
];

export default function useGetCustomFieldColumns(onClickRestoreCustomField) {
  const [tableColumns, setColumns] = useState(columns);

  useEffect(() => {
    setColumns([
      ...columns,
      {
        dataIndex: "asset_custom_field_id",
        render: (id, row) => (
          <Button
            size="small"
            type="link"
            icon={<RedoOutlined />}
            onClick={() => onClickRestoreCustomField(id)}
            disabled={row.partner_status === null ? true : row.partner_status}
          />
        ),
      },
    ]);
  }, []);

  return tableColumns;
}
