import React from "react";
import { Table } from "antd";
import useColumnFilter from "../../../hooks/useColumnFilter";

const { Column } = Table;
export const OptionsTableComponent = (props) => {
  const {
    data = { data: [], total: 0 },
    onPaginateChange,
    onRowSelectionChange,
    selectedRowKeys,
    currentPagination,
    onSortChange,
    columnFilter,
    setColumnFilter,
  } = props;

  const { getColumnFilterProps } = useColumnFilter(
    columnFilter,
    setColumnFilter
  );

  return (
    <Table
      dataSource={data.data}
      size="small"
      rowSelection={{
        type: "radio",
        onChange: onRowSelectionChange,
        selectedRowKeys: [selectedRowKeys],
      }}
      onChange={(pagination, filters, sorter, action) => {
        if (action.action === "sort") {
          onSortChange(sorter.field, sorter.order);
        }
      }}
      scroll={{ x: 1000 }}
      bordered={true}
      pagination={{
        total: data.total,
        onChange: onPaginateChange,
        current: currentPagination,
      }}
    >
      <Column
        title="Id"
        dataIndex="id"
        key="id"
        sorter={true}
        {...getColumnFilterProps("id")}
      />
      <Column
        title="uuid"
        dataIndex="option_uuid"
        key="option_uuid"
        sorter={true}
        {...getColumnFilterProps("option_uuid")}
      />
      <Column
        title="Option Key"
        dataIndex="option_key"
        key="option_key"
        sorter={true}
        {...getColumnFilterProps("option_key")}
      />
      <Column
        title="Option Value"
        dataIndex="option_value"
        key="option_value"
        sorter={true}
        {...getColumnFilterProps("option_value")}
      />
      <Column
        title="Group"
        dataIndex="option_group"
        key="option_group"
        sorter={true}
        {...getColumnFilterProps("option_group")}
      />
      <Column
        title="Created Date"
        dataIndex="created_at"
        key="created_at"
        sorter={true}
        {...getColumnFilterProps("created_at")}
      />
      <Column
        title="Last Modified Date"
        dataIndex="updated_at"
        key="updated_at"
        sorter={true}
        {...getColumnFilterProps("updated_at")}
      />
    </Table>
  );
};
