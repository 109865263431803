import React from "react";
import { Modal, Input, Select, Form, Button } from "antd";
import { SaveOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { FormRules } from "../../../dist/functions/formRules";

const { Option } = Select;
export const CustomCreationFormModalComponent = (props) => {
  const { form = null, isVisible, onClickSubmit, onClickCancel } = props;

  /**
   * Form Rules
   *
   */

  const {
    REQUIRED_ASSET_CUSTOM_FIELD_NAME,
    REQUIRED_ASSET_CUSTOM_FIELD_LABEL,
    REQUIRED_ASSET_CUSTOM_FIELD_TYPE,
  } = FormRules;

  return (
    <Modal
      title="Create Custom Field"
      visible={isVisible}
      onOk={onClickSubmit}
      onCancel={onClickCancel}
      footer={[
        <Button key="back" onClick={onClickCancel} icon={<ArrowLeftOutlined />}>
          Return
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={onClickSubmit}
          icon={<SaveOutlined />}
        >
          Submit
        </Button>,
      ]}
    >
      <Form form={form} name="assets_custom_fields" layout="vertical">
        <Form.Item
          name="asset_custom_field_name"
          label="Asset Custom Field Name"
          rules={[REQUIRED_ASSET_CUSTOM_FIELD_NAME]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="asset_custom_field_label"
          label="Asset Custom Field Label"
          rules={[REQUIRED_ASSET_CUSTOM_FIELD_LABEL]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="asset_custom_field_type"
          label="Asset Custom Field Type"
          rules={[REQUIRED_ASSET_CUSTOM_FIELD_TYPE]}
        >
          <Select placeholder="Select Custom Field Type">
            <Option key="number" value="number">
              Number
            </Option>
            <Option key="string" value="string">
              String
            </Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};
