import { useFetch } from "../hooks/useFetch";
import { useCreate } from "../hooks/useCreate";
import { useUpdate } from "../hooks/useUpdate";
import { useDelete } from "../hooks/useDelete";
import {
  FETCH_USER_URL,
  CREATE_USER_URL,
  UPDATE_USER_URL,
  DELETE_USER_URL,
} from "../api/routes/users";

export const FetchUsers = async (query) => {
  try {
    return await useFetch(FETCH_USER_URL, query);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const CreateUser = async (payload) => {
  try {
    return await useCreate(CREATE_USER_URL, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const UpdateUser = async (userId, payload) => {
  try {
    return await useUpdate(`${UPDATE_USER_URL}/${userId}`, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const DeleteUser = async (listing_id) => {
  try {
    return await useDelete(`${DELETE_USER_URL}/${listing_id}`);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const FetchAdvisors = async () => {
  try {
    const response = await useFetch(
      `${process.env.REACT_APP_API_URL}/users/agents`
    );

    return response?.data || [];
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const FetchAPlusMembers = async (query) => {
  try {
    return await useFetch(
      `${process.env.REACT_APP_API_URL}/users/a_plus_members`,
      query
    );
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
