import { useEffect, useState } from "react";

const columns = [
  {
    title: "Id",
    dataIndex: "region_id",
    key: "region_id",
  },
  {
    title: "Region Name",
    dataIndex: "region_name",
    key: "region_name",
  },
  {
    title: "Alternative Name",
    dataIndex: "alternative_name",
    key: "alternative_name",
  },
  {
    title: "Island Group Name",
    dataIndex: ["island_groups", "island_group_name"],
    key: ["island_groups", "island_group_name"],
  },

  {
    title: "Date Created",
    dataIndex: "created_at",
    key: "created_at",
  },
  {
    title: "Last Modified Date",
    dataIndex: "updated_at",
    key: "updated_at",
  },
];

export default function useGetRegionColumns() {
  const [tableColumns, setColumns] = useState(columns);

  useEffect(() => {
    setColumns(setColumns);
  }, []);

  return tableColumns;
}
