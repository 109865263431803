import React from "react";
import { Button } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import extractPermission from "../../../dist/functions/extractPermission";

export const PermissionIndexFunctionalityComponent = ({
  handleShowModalPermission,
  permissions,
}) => {
  const isHideCreatePermissionIndex = extractPermission(
    permissions,
    "ADD_PERMISSION_INDEX"
  );

  return (
    <div style={{ textAlign: "right", marginBottom: "2%" }}>
      <Button
        type="primary"
        onClick={handleShowModalPermission}
        icon={<PlusCircleOutlined />}
        hidden={isHideCreatePermissionIndex === 0 ? true : false}
      >
        Create Permission
      </Button>
    </div>
  );
};
