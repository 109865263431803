import { message } from "antd";
import { useAPIResponse } from "../useAPIResponse";
import {
  FetchAssetInquiries,
  CreateAssetInquiries,
  UpdateAssetInquiries,
  DeleteAssetInquiries,
} from "../../api/AssetInquiries";

export const useFetchAssetInquiries = async (
  isForTable = true,
  query = { page: 1, limit: 10, columnFilter: [] }
) => {
  if (isForTable) {
    const data = await FetchAssetInquiries(query);

    for (const item of data.data.data) {
      item["key"] = item.asset_inquiry_id;
    }

    return data.data;
  }

  const data = await FetchAssetInquiries({
    page: 1,
    limit: null,
    columnFilter: [],
  });

  for (const item of data.data.data) {
    item["key"] = item.asset_inquiry_id;
  }

  return data.data.data;
};

export const useCreateAssetInquiries = async (data) => {
  await message.loading("Creating Asset Inquiries..", 2);
  const response = await CreateAssetInquiries(data);

  return useAPIResponse(response);
};

export const useUpdateAssetInquiries = async (asset_inquiry_id, payload) => {
  await message.loading("Updating Asset Inquiries..", 2);
  const response = await UpdateAssetInquiries(asset_inquiry_id, payload);

  return useAPIResponse(response);
};

export const useDeleteAssetInquiries = async (asset_inquiry_id) => {
  await message.loading("Updating Asset Inquiries..", 2);
  const response = await DeleteAssetInquiries(asset_inquiry_id);

  return useAPIResponse(response);
};
