import React from "react";
import { EditOutlined, SaveOutlined, DeleteOutlined } from "@ant-design/icons";
import { ModulesFunctionalitiesComponent } from "../../ModulesFunctionalitiesComponent";
import extractPermission from "../../../dist/functions/extractPermission";

export const PropertyCategoryFunctionalityComponent = ({
  onClickCreate,
  onClickUpdate,
  onClickDelete,
  selectedRowKey,
  onSearch,
  rolesRestriction,
  permissions = [],
}) => {
  const isHideCreatePropertyCategory = extractPermission(
    permissions,
    "CREATE_PROPERTY_CATEGORY"
  );
  const isHideUpdatePropertyCategory = extractPermission(
    permissions,
    "UPDATE_PROPERTY_CATEGORY"
  );
  const isHideArchivePropertyCategory = extractPermission(
    permissions,
    "ARCHIVE_PROPERTY_CATEGORY"
  );

  const functionalitiesComponent = [
    {
      component_type: "search_input",
      place_holder: "Search Property Category",
      _onSearch: onSearch,
    },
    {
      component_type: "button",
      type: "primary",
      icon: <EditOutlined />,
      _onClick: onClickCreate,
      label: "Create Property Category",
      size: "default",
      is_hidden: isHideCreatePropertyCategory,
    },
    {
      component_type: "button",
      type: "default",
      icon: <SaveOutlined />,
      _onClick: onClickUpdate,
      label: "Update Property Category",
      size: "default",
      is_hidden: selectedRowKey ? isHideUpdatePropertyCategory : true,
    },
    {
      component_type: "button",
      type: "default",
      icon: <DeleteOutlined />,
      _onClick: onClickDelete,
      label: "Archive Property Category",
      size: "default",
      is_hidden: selectedRowKey ? isHideArchivePropertyCategory : true,
      danger: true,
    },
  ];

  return (
    <>
      <ModulesFunctionalitiesComponent
        functionalitiesComponent={functionalitiesComponent}
        alignment="right"
        spaceSize="small"
      />
    </>
  );
};
