import { useFetch } from "../hooks/useFetch";

export const FetchLocales = async () => {
  try {
    return await useFetch(`${process.env.REACT_APP_API_URL}/locales`, {});
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
