import React, { useState, useEffect } from "react";
import { message } from "antd";

import { useAPIResponse, useAPIResponseID } from "../useAPIResponse";
import {
  FetchListings,
  CreateListing,
  UpdateListing,
  UpdateListingAssetListing,
  DeleteListing,
} from "../../api/Listings";
import { useGeneralFetch } from "../useGeneralFetch";

export const useFetchListings = (
  isForTable = true,
  query = { page: 1, limit: 10, columnFilter: [] }
) => {
  return useGeneralFetch(isForTable, query, FetchListings, "listing_id");
};

export const useCreateListing = async (data) => {
  await message.loading("Creating Listing..", 2);
  const response = await CreateListing(data);

  return useAPIResponseID(response);
};

export const useUpdateListing = async (listing_id, payload) => {
  await message.loading("Updating Listing..", 2);
  const response = await UpdateListing(listing_id, payload);

  return useAPIResponse(response);
};

export const UseUpdateListingAssetListing = async (listing_id, payload) => {
  await message.loading("Updating Listing Asset Listing..", 2);
  const response = await UpdateListingAssetListing(listing_id, payload);

  return useAPIResponse(response);
};

export const useDeleteListing = async (listing_id) => {
  await message.loading("Updating Listing..", 2);
  const response = await DeleteListing(listing_id);

  return useAPIResponse(response);
};
