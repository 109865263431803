import React from "react";
import { Button, Space, Input, Divider, DatePicker, Form } from "antd";
import {
  UserAddOutlined,
  UserSwitchOutlined,
  SearchOutlined,
  UserOutlined,
  ExportOutlined,
} from "@ant-design/icons";
import useIsMobile from "../../../hooks/useIsMobile";
import useIsTablet from "../../../hooks/useIsTablet";
import extractPermission from "../../../dist/functions/extractPermission";

const { RangePicker } = DatePicker;
export const UserManagementFunctionsComponent = ({
  changePasswordModal,
  createUserModal,
  updateUserRoleModal,
  addRolesModal,
  isForUpdate = null,
  onSearch,
  handleAdminChangePasswordModal,
  handleReportsModal,
  handleOnClickUpdateUserModal,
  form,
  selectedRowKeys,
  permissions = [],
}) => {
  // const {
  //   CREATE_USER = 0,
  //   CHANGE_PASSWORD = 0,
  //   ADD_UPDATE_USER_ROLE = 0,
  //   UPDATE_USER = 0,
  //   GENERATE_USER_REPORT = 0,
  // } = rolesRestrictions;

  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const isHideCreateUser = extractPermission(permissions, "CREATE_USER");
  const isHideChangePassword = extractPermission(
    permissions,
    "CHANGE_PASSWORD"
  );
  const isHideModifiyUserRole = extractPermission(
    permissions,
    "ADD_UPDATE_USER_ROLE"
  );
  const isHideUpdateUser = extractPermission(permissions, "UPDATE_USER");
  const isHideGenerateUserReport = extractPermission(
    permissions,
    "GENERATE_USER_REPORT"
  );

  return (
    <>
      <div style={{ textAlign: isMobile || isTablet ? "center" : "right" }}>
        <Space size={"small"} direction={isMobile ? "vertical" : "horizontal"}>
          <Form form={form} layout={isMobile ? "vertical" : "inline"}>
            <Form.Item name="search_value">
              <Input placeholder="Input Search" />
            </Form.Item>
            <Form.Item name="created_date_range">
              <RangePicker />
            </Form.Item>
            <Button
              icon={<SearchOutlined />}
              type="default"
              onClick={onSearch}
              block={isMobile ? true : false}
            >
              Search
            </Button>
          </Form>
        </Space>
        <Divider />
        <Space
          size={"small"}
          direction={isMobile || isTablet ? "vertical" : "horizontal"}
        >
          <Button
            hidden={isHideCreateUser}
            type="primary"
            size="middle"
            icon={<UserAddOutlined />}
            onClick={createUserModal}
            block={isMobile || isTablet ? true : false}
          >
            Create User
          </Button>
          <Button
            hidden={isHideGenerateUserReport}
            size="middle"
            icon={<ExportOutlined />}
            onClick={handleReportsModal}
            block={isMobile || isTablet ? true : false}
          >
            Generate Reports
          </Button>

          {selectedRowKeys.length !== 0 ? (
            <>
              <Button
                size="middle"
                icon={<UserOutlined />}
                hidden={isHideCreateUser}
                onClick={handleOnClickUpdateUserModal}
                block={isMobile || isTablet ? true : false}
              >
                Update User
              </Button>
              <Button
                hidden={isHideChangePassword}
                size="middle"
                icon={<UserAddOutlined />}
                onClick={handleAdminChangePasswordModal}
                block={isMobile || isTablet ? true : false}
              >
                Change Password
              </Button>
              <Button
                hidden={isHideChangePassword}
                size="middle"
                icon={<UserAddOutlined />}
                onClick={changePasswordModal}
                block={isMobile || isTablet ? true : false}
              >
                Reset Pasword
              </Button>
              <Button
                hidden={isHideModifiyUserRole}
                size="middle"
                icon={<UserSwitchOutlined />}
                onClick={isForUpdate ? updateUserRoleModal : addRolesModal}
                block={isMobile || isTablet ? true : false}
              >
                {isForUpdate ? "Update User Role" : "Add User Role"}
              </Button>
            </>
          ) : null}
        </Space>
      </div>
    </>
  );
};
