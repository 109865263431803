import { useCreate } from "../hooks/useCreate";
import { UPSERT, VALIDATE } from "./routes/bulk_uploader";

export const ValidateBulkUploaderData = async (payload) => {
  try {
    return await useCreate(VALIDATE, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const UpsertBulkUploaderData = async (payload) => {
  try {
    return await useCreate(UPSERT, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
