import React, { useState, useEffect, useRef } from "react";
import { PageHeader, Form, Divider, message, Modal, Tabs } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { DeleteAsset } from "../../api/assets/DeleteAsset";
import useGetPlatform from "../../hooks/useGetPlatform";
import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";
import { FetchAssetsList } from "../../api/assets/FetchAssetsList";
import { FetchPartnerList } from "../../api/assets/FetchPartnerList";
import { AssetModalContentComponent } from "../sub_components/assets/AssetModalContentComponent";
import { AssetUpdateComponent } from "../sub_components/assets/AssetUpdateComponent";
import { AssetCreationComponent } from "../sub_components/assets/AssetCreationComponent";
import { AssetTableComponent } from "../sub_components/assets/AssetTableComponent";
import { AssetFiltersAndSelectionComponent } from "../sub_components/assets/AssetFiltersAndSelectionComponent";
import { AssetFunctionComponent } from "../sub_components/assets/AssetFunctionsComponent";
import { FetchListingsList } from "../../api/assets/FetchListingsList";
import { FetchAgentList } from "../../api/assets/FetchAgentList";
import { useFetchPropertyDevelopers } from "../../hooks/property_developers/usePropertyDeveloperAPI";
import { useFetchPropertyProjects } from "../../hooks/property_projects/usePropertyProjectAPI";
import AssetImageData from "../sub_components/assets/AssetImageData";

import "../../dist/style/ads_editor.css";
import { useFetchProvinces } from "../../hooks/provinces/useProvinceAPI";
import { useFetchBarangaysHookOption } from "../../hooks/barangay/useBarangaysAPI";
import { useFetchCities } from "../../hooks/cities/useCityAPI";
import { useFetchPropertySubCategory } from "../../hooks/property_categories/usePropertySubCategoriesAPI";

const initialQueryFilters = { page: 1, limit: 10, filter: null };
const { confirm } = Modal;
const { TabPane } = Tabs;

const listInitialState = { data: [], pagination: { total: 0 } };

export const AssetsManagementComponent = ({ permissions }) => {
  const [addAssetListingForm] = Form.useForm();
  const currentPlatform = useGetPlatform();
  const [updateAssetForm] = Form.useForm();
  const [showAddAssetListing, setShowAddAssetListing] = useState(false);
  const [currentTab, setCurrentTab] = useState("1");
  const [partners, setPartners] = useState({ data: [], total: 0 });

  const [agents, setAgents] = useState({ data: [], total: 0 });
  const [assets, setAssets] = useState(listInitialState);
  const [listings, setListings] = useState({ data: [], total: 0 });
  const [developers, setDevelopers] = useState({ data: [], total: 0 });
  const [projects, setProjects] = useState({ data: [], total: 0 });
  const [propertyCategories, setPropertyCategories] = useState({
    data: [],
    total: 0,
  });
  const [queryFilters, setQueryFilters] = useState(initialQueryFilters);
  const [showSelection, setShowSelection] = useState(false);

  const [selectedAssets, setSelectedAssets] = useState(null);
  const [postHTMLcontent, setPostHTMLcontent] = useState(null);
  const [isShowPreviewModalPost, setIsShowPreviewModalPost] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [createModalVisiblity, setCreateModalVisibility] = useState(false);
  const [updateModalVisibility, setUpdateModalVisibility] = useState(false);
  const [mediaApi, setMediaApi] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [currentPagination, setCurrentPagination] = useState(1);
  const [currentCount, setCurrentCount] = useState(10);
  const [isShowColumnFilter, setIsShowColumnFilter] = useState(false);
  const [columnFilterTags, setColumnFilterTags] = useState([]);
  const [columnFilter, setColumnFilter] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const sortColumn = useRef(null);
  const sortType = useRef("asc");

  const [provincesQuery, setProvincesQuery] = useState({
    page: 1,
    limit: 10,
  });

  const [
    provinces,
    isLoadingProvinces,
    persistentProvinces,
    setShouldPersistProvince,
    setShouldResetPersistProvince,
  ] = useFetchProvinces(true, provincesQuery);

  const [barangaysQuery, setBarangaysQuery] = useState({
    page: 1,
    limit: 10,
  });

  const [citiesQuery, setCitiesQuery] = useState({
    page: 1,
    limit: 10,
  });

  const [
    cities,
    isLoadingCities,
    persistentCities,
    setShouldPersistCities,
    setShouldResetPersistCities,
  ] = useFetchCities(true, citiesQuery);

  const [
    barangays,
    isLoadingBarangays,
    persistentBarangays,
    setShouldPersistBarangay,
    setShouldResetPersistBarangay,
  ] = useFetchBarangaysHookOption(true, barangaysQuery);

  const setSortColumn = (value) => {
    sortColumn.current = value;
  };

  const stepsNavigator = (direction) => {
    setCurrentStep((prevState) => {
      return direction === "forward" ? prevState + 1 : prevState - 1;
    });
  };

  const setSortType = (value) => {
    if (value === "descend") {
      sortType.current = "desc";
    } else if (value === "ascend") {
      sortType.current = "asc";
    } else {
      sortType.current = "none";
    }
  };

  const GetAssetsList = async (page = 1, limit = 10, filter = null) => {
    setCurrentPagination(page);
    const query = {
      page: page,
      filter: filter,
      limit: limit,
      sortColumn: sortColumn.current,
      sortType: sortType.current,
      columnFilter: columnFilter,
    };
    setTableLoading(true);
    const data = await FetchAssetsList(query);
    for (const i of data.data) {
      i["key"] = i.asset_id;
    }

    setAssets(data);

    setTimeout(() => {
      setTableLoading(false);
    }, 1000);
  };

  const handleCreateModalVisibility = () => {
    setCreateModalVisibility(!createModalVisiblity);
  };

  const handleIsShowPreviewModalStatusPost = () => {
    setIsShowPreviewModalPost(!isShowPreviewModalPost);
  };

  const handleClickViewPostHTML = (html_content) => {
    setPostHTMLcontent(html_content);
    setIsShowPreviewModalPost(true);
  };

  const handleUpdateModalVisibility = async () => {
    const status = !updateModalVisibility;
    setUpdateModalVisibility(status);

    if (status) {
      const {
        asset_uuid,
        agent_id,
        street,
        subdivision,
        barangay_id,
        city_id,
        province_id,
        developer_id,
        project_id,
        partner_id,
        asset_status,
        longitude,
        latitude,
        price_min,
        price_max,
        assets_condition,
        property_category_pivot,
        is_featured,
      } = selectedAssets;

      // update Geo based on its parent
      // e.g: selected Province will show connected cities
      setShouldResetPersistCities(true);
      setCitiesQuery({
        columnFilter: [{ id: "province_id", value: province_id }],
      });

      setShouldResetPersistBarangay(true);
      setBarangaysQuery({ columnFilter: [{ id: "city_id", value: city_id }] });

      const _fields = {
        asset_uuid,
        agent_id,
        street,
        subdivision,
        barangay_id,
        city_id,
        province_id,
        developer_id,
        project_id,
        partner_id,
        asset_status,
        longitude,
        latitude,
        price_min,
        price_max,
        assets_condition,
        asset_property_category_id:
          property_category_pivot?.property_category?.property_category_id |
          null,
        is_featured,
      };

      for (const item of selectedAssets.asset_meta_field) {
        const {
          asset_meta_field_name,
          asset_meta_field_text_value,
          asset_meta_field_num_value,
        } = item;

        _fields[asset_meta_field_name] = asset_meta_field_num_value
          ? asset_meta_field_num_value
          : asset_meta_field_text_value;
      }
      // console.log({_fields})
      updateAssetForm.setFieldsValue(_fields);
    }
  };

  const onChange = (selectedRowKeys, selectedRows) => {
    setSelectedRowKeys(selectedRowKeys);
    setSelectedAssets(selectedRows[0]);
  };

  const onSortChange = async (sortCol, sortType) => {
    setSortColumn(sortCol);
    setSortType(sortType);

    await GetAssetsList(currentPagination, currentCount);
  };

  const onClearSelection = () => {
    onSearch(null);
    setSelectedRowKeys([]);
    setSelectedAssets(null);
    GetAssetsList(1, currentCount);
  };

  const FetchPartners = async (page = 1, limit = 10) => {
    const query = { page: page, limit: limit };
    const data = await FetchPartnerList(query);
    setPartners(data);
  };

  const FetchAgents = async (page = 1, limit = 10) => {
    const query = { page: page, limit: limit };
    const data = await FetchAgentList(query);
    setAgents(data);
  };

  const FetchListings = async (page = 1, limit = 10) => {
    const query = { page: page, limit: limit };
    const data = await FetchListingsList(query);
    setListings(data);
    return data;
  };

  const FetchDevelopers = async (page = 1, limit = 10) => {
    const query = { page: page, limit: limit };
    const data = await useFetchPropertyDevelopers(true, query);

    setDevelopers(data);
    return data;
  };

  const FetchProjects = async (page = 1, limit = 10) => {
    const query = { page: page, limit: limit };
    const data = await useFetchPropertyProjects(true, query);

    setProjects(data);
    return data;
  };

  const FetchPropertyCategories = async () => {
    const data = await useFetchPropertySubCategory({}, false);
    const filteredData = data.filter((item) => {
      return item.property_category_type !== "sub_type" && item.is_active === 1;
    });
    setPropertyCategories(filteredData);
  };

  const OnClickDeleteAsset = () => {
    confirm({
      title: `Are you sure, you want to remove this property?`,
      icon: <ExclamationCircleOutlined />,
      content: `All information attached to this property will be removed`,
      async onOk() {
        message
          .loading("Deleting Property Information..", 2.5)
          .then(async () => {
            await DeleteAsset(selectedRowKeys);
            message.success("Successfuly Deleted Property", 2);
            onClearSelection();
            GetAssetsList();
          });
      },
      onCancel() {},
    });
  };

  const onClickShowMoreDeveloper = async () => {
    const { current_page, last_page } = developers;
    if (current_page < last_page) {
      const query = { page: parseInt(current_page) + 1, limit: 10 };
      const data = await FetchDevelopers(query);

      const oldDevelopers = developers.data;
      for (const item of data.data) {
        oldDevelopers.push(item);
      }

      const newState = {
        ...data,
        data: oldDevelopers,
      };

      setDevelopers(newState);
      return;
    }
  };

  const onClickShowMoreProject = async () => {
    const { current_page, last_page } = projects;
    if (current_page < last_page) {
      const query = { page: parseInt(current_page) + 1, limit: 10 };
      const data = await FetchProjects(query);

      const oldProjects = projects.data;
      for (const item of data.data) {
        oldProjects.push(item);
      }

      const newState = {
        ...data,
        data: oldProjects,
      };

      setProjects(newState);
      return;
    }
  };

  const onClickShowMoreProvince = async () => {
    const { current_page, last_page } = provinces;
    if (current_page < last_page) {
      const query = { page: parseInt(current_page) + 1, limit: 10 };
      setShouldPersistProvince(true);
      setProvincesQuery(query);

      return;
    }
  };

  const onClickShowMoreBarangay = async () => {
    const { current_page, last_page } = barangays;
    if (current_page < last_page) {
      const query = { page: parseInt(current_page) + 1, limit: 10 };
      setShouldPersistBarangay(true);
      setBarangaysQuery(query);

      return;
    }
  };

  const showaddAssetListingModal = () => {
    const status = !showAddAssetListing;
    setShowAddAssetListing(status);

    if (status) {
      const { asset_listing } = selectedAssets;

      addAssetListingForm.setFieldsValue({
        asset_listing,
      });
    }
  };

  ///////////////////////////////
  // for column filter tags
  const onCloseColumnFilterTag = (id) => {
    setColumnFilterTags((prevState) => {
      return prevState.filter((item) => item.id !== id);
    });

    setColumnFilter((prevState) => {
      let newData = prevState.filter((item) => {
        return Array.isArray(item.id)
          ? JSON.stringify(item.id) !== id
          : item.id !== id;
      });

      return newData;
    });

    if (!columnFilterTags.length) {
      setIsShowColumnFilter(false);
    }
  };

  const onLoadColumnFilterTags = () => {
    if (columnFilter.length) {
      let filterTags = columnFilter.map((e) => {
        let [label, tagId] = Array(2).fill(e.id);

        if (Array.isArray(e.id)) {
          tagId = JSON.stringify(tagId);
          label = "";

          for (const wordLabel of e.id) {
            label += `${wordLabel} `;
          }
        }

        return {
          color: "processing",
          closable: true,
          selection_label: label,
          value: e.value,
          id: tagId,
          onClose: onCloseColumnFilterTag,
        };
      });

      setColumnFilterTags(filterTags);
      setIsShowColumnFilter(true);
    } else {
      // if columnFilter is just blank, then remove the tags
      setColumnFilterTags([]);
      setIsShowColumnFilter(false);
    }
  };

  const onClearColumnFilters = () => {
    setColumnFilterTags([]);
    setIsShowColumnFilter(false);
    setColumnFilter([]);
  };

  useComponentDidUpdate(() => {
    (async function () {
      await GetAssetsList(1, currentCount);
    })();

    onLoadColumnFilterTags();
  }, [columnFilter]);

  useComponentDidUpdate(() => {
    (async function () {
      if (
        selectedRowKeys !== undefined &&
        selectedRowKeys !== null &&
        selectedRowKeys.length
      ) {
        setSelectedAssets(
          assets.data.find((e) => e.asset_id === selectedRowKeys)
        );
      }
    })();
  }, [assets]);

  useEffect(() => {
    (async function () {
      await GetAssetsList();
    })();

    FetchPartners();
    FetchAgents();
    FetchListings();
    FetchDevelopers();
    FetchProjects();
    FetchPropertyCategories();
  }, []);

  useEffect(() => {
    const { filter } = queryFilters;
    if (filter) {
      setColumnFilterTags((prevState) => {
        const tag = {
          color: "blue",
          value: filter,
          closable: false,
          selection_label: "Includes",
        };
        return [...prevState, tag];
      });
    }
  }, [queryFilters]);

  useEffect(() => {
    const { page, limit, filter } = queryFilters;
    GetAssetsList(page, limit, filter);
  }, [queryFilters]);

  const onClickShowMorePartner = async () => {
    const { current_page, last_page } = partners;
    if (current_page < last_page) {
      const query = { page: parseInt(current_page) + 1, limit: 10 };
      const data = await FetchPartnerList(query);

      const oldPartners = partners.data;
      for (const item of data.data) {
        oldPartners.push(item);
      }

      const newState = {
        ...data,
        data: oldPartners,
      };

      setPartners(newState);
      return;
    }
  };

  const onClickShowMoreAgent = async () => {
    const { current_page, last_page } = agents;

    if (current_page < last_page) {
      const query = { page: parseInt(current_page) + 1, limit: 10 };
      const data = await FetchAgentList(query);

      const oldAgents = agents.data;
      for (const item of data.data) {
        oldAgents.push(item);
      }

      const newState = {
        ...data,
        data: oldAgents,
      };

      setAgents(newState);
      return;
    }
  };

  const onClickShowMoreCity = async () => {
    const { current_page, last_page } = cities;
    if (current_page < last_page) {
      const query = { page: parseInt(current_page) + 1, limit: 10 };

      setShouldPersistCities(true);
      setCitiesQuery(query);

      return;
    }
  };
  const onPaginationChange = (value, currentCount) => {
    setCurrentPagination(value);
    setCurrentCount(currentCount);
    GetAssetsList(value, currentCount);
    return;
  };

  const onSearch = (filter) => {
    const _showSelection = filter ? true : false;
    setShowSelection(_showSelection);
    setQueryFilters((queryFilters) => {
      return { ...queryFilters, filter: filter };
    });
    setIsShowColumnFilter(true);
  };

  return (
    <>
      <PageHeader
        title="Properties"
        subTitle="Properties Management"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["Properties"]} />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <Tabs onChange={setCurrentTab} activeKey={currentTab}>
          <TabPane tab="View Properties" key="1">
            <AssetFunctionComponent
              handleClickViewPostHTML={handleClickViewPostHTML}
              showaddAssetListingModal={showaddAssetListingModal}
              selectedAssets={selectedAssets}
              permissions={permissions}
              handleCreateModalVisibility={handleCreateModalVisibility}
              handleUpdateModalVisibility={handleUpdateModalVisibility}
              OnClickDeleteAsset={OnClickDeleteAsset}
              reportsData={assets}
              onSearch={onSearch}
            />
            <AssetFiltersAndSelectionComponent
              onClearSearcheAndSelection={onClearSelection}
              selectedAssets={selectedAssets}
              isShowColumnFilterTags={isShowColumnFilter}
              columnFilterTags={columnFilterTags}
              onClearColumnFilters={onClearColumnFilters}
              isShowSelection={showSelection}
            />

            <AssetTableComponent
              setCurrentTab={setCurrentTab}
              setSelectedRowKeys={setSelectedRowKeys}
              setSelectedAssets={setSelectedAssets}
              tableLoading={tableLoading}
              data={assets}
              onChange={onChange}
              selectedRowKeys={selectedRowKeys}
              handleClickViewPostHTML={handleClickViewPostHTML}
              onPaginationChange={onPaginationChange}
              currentPagination={currentPagination}
              platform={currentPlatform}
              onSortChange={onSortChange}
              columnFilter={columnFilter}
              setColumnFilter={setColumnFilter}
            />
          </TabPane>
          {selectedAssets !== null ? (
            <TabPane tab="Edit Property Media" key="2">
              <AssetImageData
                toEditData={selectedAssets}
                mediaApi={mediaApi}
                setMediaApi={setMediaApi}
                GetAssetsList={GetAssetsList}
              />
            </TabPane>
          ) : (
            ""
          )}
        </Tabs>
      </div>
      <AssetCreationComponent
        GetAssetsList={GetAssetsList}
        createModalVisiblity={createModalVisiblity}
        handleCreateModalVisibility={handleCreateModalVisibility}
        partners={partners}
        cities={persistentCities}
        agents={agents}
        developers={developers}
        projects={projects}
        onClickShowMorePartner={onClickShowMorePartner}
        onClickShowMoreAgent={onClickShowMoreAgent}
        onClickShowMoreCity={onClickShowMoreCity}
        onClickShowMoreDeveloper={onClickShowMoreDeveloper}
        onClickShowMoreProject={onClickShowMoreProject}
      />
      <AssetUpdateComponent
        propertyCategories={propertyCategories}
        partners={partners}
        cities={persistentCities}
        agents={agents}
        developers={developers}
        projects={projects}
        provinces={persistentProvinces}
        barangays={persistentBarangays}
        onClickShowMorePartner={onClickShowMorePartner}
        onClickShowMoreAgent={onClickShowMoreAgent}
        onClickShowMoreCity={onClickShowMoreCity}
        onClickShowMoreDeveloper={onClickShowMoreDeveloper}
        onClickShowMoreProject={onClickShowMoreProject}
        onClickShowMoreProvince={onClickShowMoreProvince}
        onClickShowMoreBarangay={onClickShowMoreBarangay}
        setBarangaysQuery={setBarangaysQuery}
        setProvincesQuery={setProvincesQuery}
        setCitiesQuery={setCitiesQuery}
        setShouldResetPersistCities={setShouldResetPersistCities}
        setShouldResetPersistBarangay={setShouldResetPersistBarangay}
        form={updateAssetForm}
        assetId={selectedRowKeys}
        updateModalVisibility={updateModalVisibility}
        handleUpdateModalVisibility={handleUpdateModalVisibility}
        GetAssetsList={GetAssetsList}
        setCurrentPagination={setCurrentPagination}
        onClearSelection={onClearSelection}
        currentStep={currentStep}
        setCurrentStep={setCurrentStep}
        stepsNavigator={stepsNavigator}
        selectedAssets={selectedAssets}
      />

      <AssetModalContentComponent
        visible={isShowPreviewModalPost}
        onOk={handleIsShowPreviewModalStatusPost}
        content={postHTMLcontent}
      />
    </>
  );
};
