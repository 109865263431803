import React, { useEffect, useState } from "react";
import { PageHeader, Divider, message, Modal, Form } from "antd";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { BulkUploaderFunctionalityComponent } from "../sub_components/bulk_uploader/BulkUploaderFunctionalityComponent";
import { FileDraggerComponent } from "../sub_components/bulk_uploader/FileDraggerComponent";
import { ValidationComponent } from "../sub_components/bulk_uploader/ValidationComponent";
import { UploadedItemListComponent } from "../sub_components/bulk_uploader/UploadedItemListComponent";
import { ItemLogsComponent } from "../sub_components/bulk_uploader/ItemLogsComponent";
import { parse } from "papaparse";
import { useQueryCustomField } from "../../hooks/custom_field/useCustomFieldAPI";
import { useFetchAssetColumns } from "../../hooks/assets/useAssetAPI";
import { SyncingLogsComponent } from "../sub_components/bulk_uploader/SyncingLogsComponent";
import { useQueryAgentCustomField } from "../../hooks/agent_custom_field/useAgentCustomFieldAPI";
import { BulkCityCreationModalComponent } from "../sub_components/bulk_uploader/BulkCityCreationModalComponent";
import {
  useValidateBulkUploaderData,
  useUpsertBulkUploaderData,
} from "../../hooks/bulk_uploader/useBulkUploaderAPI";
import {
  useCreateCity,
  useFetchCitiesAsOptions,
} from "../../hooks/cities/useCityAPI";
import {
  useCreateProvince,
  useFetchProvinceAsOptions,
} from "../../hooks/provinces/useProvinceAPI";
import { useCreatePartner } from "../../hooks/partners/usePartnerAPI";
import { useFetchPartnerTypes } from "../../hooks/partner_types/usePartnerTypeAPI";
import { useFetchIslandGroupsAsOptions } from "../../hooks/island_groups/useIslandGroupAPI";
import { useCreateBarangay } from "../../hooks/barangay/useBarangaysAPI";
import useCreateUser from "../../hooks/users/useCreateUser";
import useGetDataForCreation from "../../hooks/bulk_uploader/useGetDataForCreation";
import "../../dist/style/bulk_uploader.css";
import { BulkProvinceCreationModalComponent } from "../sub_components/bulk_uploader/BulkProvinceCreationModalComponent";
import { BulkPartnerCreationModalComponent } from "../sub_components/bulk_uploader/BulkPartnerCreationModalComponent";
import { BulkBarangayCreationModalComponent } from "../sub_components/bulk_uploader/BulkBarangayCreationModalComponent";

export const BulkUploaderManagementComponent = ({ permissions }) => {
  const [bulkCityCreationForm] = Form.useForm();
  const [partnerCreationForm] = Form.useForm();
  const [provinceCreationForm] = Form.useForm();
  const [bulkBarangayCreationForm] = Form.useForm();
  const [validationRemarks, setvalidationRemarks] = useState([]);
  const [validationStatus, setValidationStatus] = useState("default");
  const [uploadDataButtonStatus, setUploadDataButtonStatus] = useState(false);
  const [validatedData, setvalidatedData] = useState([]);
  const [forUpdateOCTlist, setForUpdateOCTlist] = useState([]);
  const [forCreateOCTlist, setForCreateOCTlist] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [csvColumns, setCSVColumns] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);
  const [islandGroups, setIslandGroups] = useState([]);
  const [partnerTypes, setPartnerTypes] = useState([]);
  const [syncingLogModalStatus, setSyncingLogModalStatus] = useState(false);
  const [agentListForCreation, setAgentListForCreation] = useState([]);
  const [partnerListForCreation, setPartnerListForCreation] = useState([]);
  const [provinceListForCreation, setProvinceListForCreation] = useState([]);
  const [cityListForCreation, setCityListForCreation] = useState([]);
  const [barangayListForCreation, setBarangayListForCreation] = useState([]);
  const [agentCustomField, setAgentCustomField] = useState([]);
  const [bulkCityCreationModal, setBulkCityCreationModal] = useState(false);
  const [bulkBarangayCreationModal, setBulkBarangayCreationModal] =
    useState(false);
  const [bulkProvinceCreationModal, setBulkProvinceCreationModal] =
    useState(false);
  const [bulkPartnerCreationModal, setBulkPartnerCreationModal] =
    useState(false);

  // Query restriction using hook
  // Then hook, to get local storage

  useEffect(() => {
    if (validationRemarks.length > 0 || tableLoading) {
      setUploadDataButtonStatus(true);
      return;
    }

    if (validatedData.length > 0) {
      const status = validatedData[0].status;
      if (!validatedData[0].hasOwnProperty("status")) {
        setUploadDataButtonStatus(false);
        return;
      }

      if (!status.includes("Successfuly")) {
        setUploadDataButtonStatus(false);
        return;
      }
    }
  }, [validationRemarks, uploadDataButtonStatus, tableLoading, validatedData]);

  useEffect(() => {
    if (validatedData.length > 0) {
      const forUpdateOCTList = validatedData.filter(
        (item) => item.status_item_remarks === "For Update"
      );

      setForUpdateOCTlist(forUpdateOCTList);

      const forCreateOCTList = validatedData.filter(
        (item) => item.status_item_remarks === "For Creation"
      );

      setForCreateOCTlist(forCreateOCTList);
    }
  }, [validatedData]);

  useEffect(() => {
    QueryAgentCustomField();
  }, []);

  useEffect(() => {
    if (!bulkCityCreationModal) {
      return;
    }

    FetchProvinces();
  }, [bulkCityCreationModal]);

  useEffect(() => {
    if (!bulkProvinceCreationModal) {
      return;
    }

    FetchislandGroups();
  }, [bulkProvinceCreationModal]);

  useEffect(() => {
    if (!bulkPartnerCreationModal) {
      return;
    }

    FetchPartnerTypes();
  }, [bulkPartnerCreationModal]);

  useEffect(() => {
    if (!bulkBarangayCreationModal) {
      return;
    }

    FetchCities();
  }, [bulkBarangayCreationModal]);

  /**
   * Functions for Bulk Uploader Management
   *
   */

  const FetchCities = async () => {
    const data = await useFetchCitiesAsOptions(false, {});
    setCities(data);
  };

  const FetchislandGroups = async () => {
    const data = await useFetchIslandGroupsAsOptions(false, {});
    setIslandGroups(data);
  };

  const FetchPartnerTypes = async () => {
    const data = await useFetchPartnerTypes(false, {});
    setPartnerTypes(data);
  };

  const FetchProvinces = async () => {
    const data = await useFetchProvinceAsOptions(false, {});
    setProvinces(data);
  };

  const QueryCustomField = async (query) => {
    const data = await useQueryCustomField(query);
    return data;
  };

  const FetchAsssetColumns = async () => {
    const data = await useFetchAssetColumns();
    return data.filter((item) => item !== "asset_uuid");
  };

  const QueryAgentCustomField = async () => {
    const data = await useQueryAgentCustomField();
    setAgentCustomField(data);
  };

  const CreatePartner = async (payload) => {
    return await useCreatePartner(payload);
  };

  const CreateProvince = async (payload) => {
    return await useCreateProvince(payload);
  };

  const CreateCity = async (payload) => {
    return await useCreateCity(payload);
  };

  const ExecuteCreateUser = async (payload) => {
    return await useCreateUser(payload);
  };

  const ExecuteCreateBarangay = async (payload) => {
    return await useCreateBarangay(payload);
  };

  const ValidateBulkUploaderData = async (payload) => {
    const response = await useValidateBulkUploaderData(payload);
    const { data, remarks } = response;

    const agent = useGetDataForCreation(data, "agent_status_remarks");
    const partner = useGetDataForCreation(data, "partner_status_remarks");
    const province = useGetDataForCreation(data, "province_status_remarks");
    const city = useGetDataForCreation(data, "city_status_remarks");
    const barangay = useGetDataForCreation(data, "barangay_status_remarks");

    /**
     *
     * Set unique data on list for creation
     *
     */

    setAgentListForCreation(agent.uniqueDataList);
    setPartnerListForCreation(partner.uniqueDataList);
    setProvinceListForCreation(province.uniqueDataList);
    setCityListForCreation(city.uniqueDataList);
    setBarangayListForCreation(barangay.uniqueDataList);

    /**
     *
     * Set list on form level
     *
     *
     */

    partnerCreationForm.setFieldsValue({
      partners: [...partner.uniqueDataListForm],
    });

    provinceCreationForm.setFieldsValue({
      provinces: [...province.uniqueDataListForm],
    });

    bulkCityCreationForm.setFieldsValue({
      cities: [...city.uniqueDataListForm],
    });

    bulkBarangayCreationForm.setFieldsValue({
      barangays: [...barangay.uniqueDataListForm],
    });

    setvalidationRemarks(remarks);
    setValidationStatus(() => {
      return remarks.length > 0 ? "error" : "success";
    });
    setvalidatedData(data);
    return response;
  };

  const UpsertBulkUploaderData = async () => {
    setTableLoading(true);
    setUploadDataButtonStatus(true);
    setvalidatedData((prevState) => {
      const _prevState = prevState;
      for (const item of _prevState) {
        item["status"] = "syncing";
      }
      return _prevState;
    });

    const data = await useUpsertBulkUploaderData(validatedData);
    setvalidatedData((prevState) => {
      const _prevState = prevState;
      for (const item of _prevState) {
        for (const uploadedItems of data.data.data.items) {
          if (item.oct_tct_cct_no === uploadedItems.oct_tct_cct_no) {
            item["status"] = `Successfuly ${
              uploadedItems.status_item_remarks === "For Update"
                ? "Updated"
                : "Created"
            }`;
          }
        }
      }
      return _prevState;
    });

    setTimeout(() => {
      setTableLoading(false);
    }, 1300);

    if (data.status === 200) {
      await message.info(
        "button for uploading will be disabled, to prevent duplicate entry on the database",
        3
      );
      await message.info("Refresh this page to upload another set of data", 4);
      setUploadDataButtonStatus(true);
    }
  };

  const onClickUploadNew = async () => {};

  const fileDraggerProps = {
    name: "file",
    multiple: false,
    accept: ".csv",
    action: null,
    customRequest: async (e) => {
      const rawArrayContent = await e.file.text().then((textContent) => {
        return parse(textContent);
      });

      const { data, errors } = rawArrayContent;

      if (errors.length > 0) {
        /**
         * File has encounter an error when parsing
         *
         */
        await message.error("Error encountered when parsing the CSV", 2);
        return false;
      }

      const bulkUploaderData = data.slice(3);

      if (bulkUploaderData.length === 0) {
        await message.error("Empty CSV file", 2);
        return false;
      }

      const activeColumnQuery = { is_active: 1 };
      const activeColumns = await QueryCustomField(activeColumnQuery);

      const columnHeaders = data[2];
      const lowerCaseColumnHeaders = columnHeaders.map((columnHeader) =>
        columnHeader.toLowerCase()
      );

      /**
       *
       * Construct table column for rendering
       *
       */

      const tableColumns = [];
      for (const header of lowerCaseColumnHeaders) {
        tableColumns.push({
          title: header,
          dataIndex: header,
          key: header,
        });
      }
      setCSVColumns(tableColumns);

      /**
       *
       * Field Validation for Custom Fields
       *  - For every active columns on the DB
       *  - Find it on the CSV column as custom fields
       *
       */

      for (const column of activeColumns) {
        const { asset_custom_field_name } = column;

        if (!lowerCaseColumnHeaders.includes(asset_custom_field_name)) {
          await message.error(
            "Columns on CSV file and active custom fields is not match",
            2
          );
          await message.info(
            `Missing ${asset_custom_field_name} on the CSV column list`,
            2
          );
          return false;
        }
      }

      /**
       *
       * Field Validation for Non-Custom Fields
       *  - Check if the CSV file have the non-custom fields
       *
       */

      const assetColumns = await FetchAsssetColumns();

      for (const assetColumn of assetColumns) {
        if (!lowerCaseColumnHeaders.includes(assetColumn)) {
          await message.error(
            "Columns on CSV file and asset fields is not match",
            2
          );
          await message.info(
            `Missing ${assetColumn} on the CSV column list`,
            2
          );
          return false;
        }
      }

      /**
       *
       * If the process reaches here
       * the CSV column is completed and ready for data validation using the backend
       *
       */

      const jsonStructureOfDataWithColumnNames = [];

      for (const row of bulkUploaderData) {
        const jsonRowItem = {};
        for (const [index, item] of row.entries()) {
          jsonRowItem[lowerCaseColumnHeaders[index]] = item;
        }
        if (row.length === lowerCaseColumnHeaders.length)
          jsonStructureOfDataWithColumnNames.push(jsonRowItem);
      }
      await ValidateBulkUploaderData(jsonStructureOfDataWithColumnNames);
      return true;
    },
  };

  const onClickSyncingLogs = () => {
    setSyncingLogModalStatus(!syncingLogModalStatus);
  };

  const onClickCityCreation = () => {
    setBulkCityCreationModal(!bulkCityCreationModal);
  };

  const onClickProvinceCreation = () => {
    setBulkProvinceCreationModal(!bulkProvinceCreationModal);
  };

  const onClickPartnerCreation = () => {
    setBulkPartnerCreationModal(!bulkPartnerCreationModal);
  };

  const onClickBarangayCreation = () => {
    setBulkBarangayCreationModal(!bulkBarangayCreationModal);
  };

  const onClickAgentCreation = () => {
    Modal.info({
      title: "By clicking Submit, below agents will be created on the database",
      centered: true,
      content: (
        <div>
          <ul>
            {agentListForCreation.map((item) => (
              <li>{item.agent_email}</li>
            ))}
          </ul>
        </div>
      ),
      async onOk() {
        for (const item of agentListForCreation) {
          const {
            agent_email,
            agent_in_charge,
            agent_mobile_smart,
            agent_mobile_globe,
            agent_notes,
            agent_viewing_schedule,
            agent_landline,
          } = item;

          const agent_custom_fields = [];

          const users_payload = {
            users_first_name: agent_in_charge.split(" ")[0] || "",
            users_middle_name: agent_in_charge.split(" ")[1] || "",
            users_last_name: agent_in_charge.split(" ")[2] || "",
            users_mobile: `${agent_mobile_globe} / ${agent_mobile_smart}`,
            users_gender: null,
            users_email: agent_email,
            users_address: "To be updated on admin 3.0 User Management",
            users_status: null,
            users_password: "p@ssw0rd",
            users_referrer: "Assetmart.Global",
            agent: {
              custom_field: [],
            },
          };

          for (const agentCustomFieldItem of agentCustomField) {
            const { agent_custom_field_name } = agentCustomFieldItem;

            const item = {
              agent_custom_field_id: agentCustomFieldItem.agent_custom_field_id,
              agent_id: null,
              agent_meta_field_type:
                agentCustomFieldItem.agent_custom_field_type,
              agent_meta_field_name: agent_custom_field_name,
            };

            if (agentCustomFieldItem.agent_custom_field_type === "string") {
              if (agent_custom_field_name === "agent_email") {
                item["agent_meta_field_text_value"] = agent_email;
              } else if (agent_custom_field_name === "agent_in_charge") {
                item["agent_meta_field_text_value"] = agent_in_charge;
              } else if (agent_custom_field_name === "agent_mobile_smart") {
                item["agent_meta_field_text_value"] = agent_mobile_smart;
              } else if (agent_custom_field_name === "agent_mobile_globe") {
                item["agent_meta_field_text_value"] = agent_mobile_globe;
              } else if (agent_custom_field_name === "agent_notes") {
                item["agent_meta_field_text_value"] = agent_notes;
              } else if (agent_custom_field_name === "agent_viewing_schedule") {
                item["agent_meta_field_text_value"] = agent_viewing_schedule;
              } else if (agent_custom_field_name === "agent_landline") {
                item["agent_meta_field_text_value"] = agent_landline;
              }
            } else {
              if (agent_custom_field_name === "agent_email") {
                item["agent_meta_field_num_value"] = agent_email;
              } else if (agent_custom_field_name === "agent_in_charge") {
                item["agent_meta_field_num_value"] = agent_in_charge;
              } else if (agent_custom_field_name === "agent_mobile_smart") {
                item["agent_meta_field_num_value"] = agent_mobile_smart;
              } else if (agent_custom_field_name === "agent_mobile_globe") {
                item["agent_meta_field_num_value"] = agent_mobile_globe;
              } else if (agent_custom_field_name === "agent_notes") {
                item["agent_meta_field_num_value"] = agent_notes;
              } else if (agent_custom_field_name === "agent_viewing_schedule") {
                item["agent_meta_field_num_value"] = agent_viewing_schedule;
              } else if (agent_custom_field_name === "agent_landline") {
                item["agent_meta_field_num_value"] = agent_landline;
              }
            }

            agent_custom_fields.push(item);
          }

          users_payload["agent"]["custom_field"] = agent_custom_fields;
          await ExecuteCreateUser(users_payload);
        }
      },
    });
  };

  const onSubmitCityCreation = async () => {
    const payloads = await bulkCityCreationForm.validateFields();
    for (const payload of payloads.cities) {
      await CreateCity(payload);
    }

    onClickCityCreation();
    bulkCityCreationForm.resetFields();
    await message.info("Successfuly Created City Information", 3);
    await message.info(
      "Please refresh/re-run the validation to apply the changes",
      3
    );
  };

  const onSubmitProvinceCreation = async () => {
    const payloads = await provinceCreationForm.validateFields();

    for (const payload of payloads.provinces) {
      payload["alternative_name"] = payload.province_name;
      await CreateProvince(payload);
    }

    onClickProvinceCreation();
    provinceCreationForm.resetFields();
    await message.info("Successfuly Created Province Information", 3);
    await message.info(
      "Please refresh/re-run the validation to apply the changes",
      3
    );
  };

  const onSubmitPartnerCreation = async () => {
    const payloads = await partnerCreationForm.validateFields();

    for (const payload of payloads.partners) {
      payload["partner_status"] = "active";
      await CreatePartner(payload);
    }

    onClickPartnerCreation();
    partnerCreationForm.resetFields();
    await message.info("Successfuly Created Partner Information", 3);
    await message.info(
      "Please refresh/re-run the validation to apply the changes",
      3
    );
  };

  const onSubmitBarangayCreation = async () => {
    const payloads = await bulkBarangayCreationForm.validateFields();

    for (const payload of payloads.barangays) {
      await ExecuteCreateBarangay(payload);
    }

    onClickBarangayCreation();
    bulkBarangayCreationForm.resetFields();
    await message.info("Successfuly Created Barangay Information", 3);
    await message.info(
      "Please refresh/re-run the validation to apply the changes",
      3
    );
  };

  return (
    <>
      <PageHeader
        title="Bulk Uploader"
        subTitle="Asset(s) Bulk Uploader Management"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["Bulk Uploader"]} />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <BulkUploaderFunctionalityComponent
          onClickUploadData={UpsertBulkUploaderData}
          onClickUploadNew={onClickUploadNew}
          uploadDataButtonStatus={uploadDataButtonStatus}
          permissions={permissions}
        />
        <FileDraggerComponent
          props={fileDraggerProps}
          permissions={permissions}
          validationStatus={validationStatus}
        />
        <ValidationComponent
          validationStatus={validationStatus}
          validationRemarks={validationRemarks}
          agentListForCreation={agentListForCreation}
          partnerListForCreation={partnerListForCreation}
          provinceListForCreation={provinceListForCreation}
          cityListForCreation={cityListForCreation}
          barangayListForCreation={barangayListForCreation}
          onClickAgentCreation={onClickAgentCreation}
          onClickCityCreation={onClickCityCreation}
          onClickProvinceCreation={onClickProvinceCreation}
          onClickPartnerCreation={onClickPartnerCreation}
          onClickBarangayCreation={onClickBarangayCreation}
        />
        <ItemLogsComponent
          forCreationList={forCreateOCTlist}
          forUpdateList={forUpdateOCTlist}
        />
        <UploadedItemListComponent
          columns={csvColumns}
          data={validatedData}
          loading={tableLoading}
          onClickSyncingLogs={onClickSyncingLogs}
        />
      </div>
      <SyncingLogsComponent
        data={validatedData}
        visible={syncingLogModalStatus}
        onClose={onClickSyncingLogs}
      />
      <BulkCityCreationModalComponent
        visible={bulkCityCreationModal}
        provinces={provinces}
        form={bulkCityCreationForm}
        onSubmitCityCreation={onSubmitCityCreation}
        onCancel={onClickCityCreation}
      />
      <BulkProvinceCreationModalComponent
        onClickProvinceCreation={onClickProvinceCreation}
        form={provinceCreationForm}
        islandGroups={islandGroups}
        visible={bulkProvinceCreationModal}
        onSubmit={onSubmitProvinceCreation}
        onCancel={onClickProvinceCreation}
      />
      <BulkPartnerCreationModalComponent
        form={partnerCreationForm}
        partnerTypes={partnerTypes}
        visible={bulkPartnerCreationModal}
        onSubmit={onSubmitPartnerCreation}
        onCancel={onClickPartnerCreation}
      />
      <BulkBarangayCreationModalComponent
        form={bulkBarangayCreationForm}
        cities={cities}
        visible={bulkBarangayCreationModal}
        onSubmit={onSubmitBarangayCreation}
        onCancel={onClickBarangayCreation}
      />
    </>
  );
};
