import { message } from "antd";
import {
  UpsertBulkUploaderData,
  ValidateBulkUploaderData,
} from "../../api/BulkUploader";

export const useValidateBulkUploaderData = async (payload) => {
  await message.loading("Validating Bulk Uploader Data", 2);
  const response = await ValidateBulkUploaderData(payload);
  await message.success("Validated Bulk Uploader Data", 2);
  // console.log({response})
  return response.data.data;
};

export const useUpsertBulkUploaderData = async (payload) => {
  await message.loading("Inserting/Updating Assets", 2);
  const response = await UpsertBulkUploaderData(payload);
  await message.success("Successfuly Created/Inserted Assets", 2);
  return response;
};
