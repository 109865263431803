import React, { useState } from "react";
import { Modal, Input, Select, Form, Button, Steps, Divider } from "antd";
import { SaveOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { FormRules } from "../../../dist/functions/formRules";
import { SelectOptionFooterExtraButtonComponent } from "../../SelectOptionFooterExtraButtonComponent";
import { useComponentDidUpdate } from "../../../hooks/useComponentDidUpdate";

const { Step } = Steps;
const { Option } = Select;
export const PartnerFormModalComponent = (props) => {
  const {
    form = null,
    isVisible,
    onClickSubmit,
    onClickCancel,
    partnerTypes,
    isUpdate,
    onClickShowMorePartnerTypes,
  } = props;
  const [currentStep, setCurrentStep] = useState(1);

  const stepsNavigator = (direction) => {
    setCurrentStep((prevState) => {
      return direction === "forward" ? prevState + 1 : prevState - 1;
    });
  };
  /**
   * Form Rules
   *
   */

  useComponentDidUpdate(() => {
    setCurrentStep(1);
  }, [isVisible]);

  const {
    REQUIRED_PARTNER_NAME,
    REQUIRED_PARTNER_PERSON_IN_CHARGE,
    REQUIRED_PARTNER_TYPE,
    REQUIRED_PARTNER_MOBILE,
    REQUIRED_PARTNER_LANDLINE,
    REQUIRED_PARTNER_EMAIL,
  } = FormRules;

  const returnModalFooterButtons = [
    <Button
      key="cancel_button"
      type="default"
      size="medium"
      onClick={onClickCancel}
      hidden={currentStep === 1 ? false : true}
      icon={<LeftOutlined />}
    >
      Cancel
    </Button>,
    <Button
      key="back_button"
      type="default"
      size="medium"
      onClick={() => stepsNavigator("backward")}
      hidden={currentStep > 1 ? false : true}
      icon={<LeftOutlined />}
    >
      Back
    </Button>,
    <Button
      key="next_button"
      type="default"
      size="medium"
      onClick={() => stepsNavigator("forward")}
      hidden={currentStep === 2}
      icon={<RightOutlined />}
    >
      Next
    </Button>,
    <Button
      key="submit_button"
      type="primary"
      size="medium"
      onClick={async () => {
        try {
          await onClickSubmit();
          setCurrentStep(1);
        } catch (e) {}
      }}
      hidden={currentStep !== 2}
      icon={<SaveOutlined />}
    >
      Submit
    </Button>,
  ];

  return (
    <Modal
      title={isUpdate ? "Update Partner" : "Create Partner"}
      visible={isVisible}
      // onOk={onClickSubmit}
      onCancel={onClickCancel}
      footer={returnModalFooterButtons}
      // footer={[
      //   <Button key="back" onClick={onClickCancel} icon={<ArrowLeftOutlined />}>
      //     Return
      //   </Button>,
      //   <Button
      //     key="submit"
      //     type="primary"
      //     onClick={onClickSubmit}
      //     icon={<SaveOutlined />}
      //   >
      //     Submit
      //   </Button>,
      // ]}
    >
      <div style={{ padding: "4%" }}>
        <Steps size="small" current={currentStep - 1}>
          <Step title="General Information" />
          <Step title="Contact Information" />
        </Steps>
        <Divider />
      </div>
      <Form form={form} layout="vertical" name="assets">
        <div hidden={currentStep === 1 ? false : true}>
          <Form.Item name="partner_code" label="Partner Code">
            <Input />
          </Form.Item>
          <Form.Item name="partner_notes" label="Partner Notes">
            <Input />
          </Form.Item>

          <Form.Item
            name="partner_name"
            label="Partner Name"
            rules={[REQUIRED_PARTNER_NAME]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="partner_type_id"
            fieldKey="partner_type_id"
            label="Partner Type Name"
            shouldUpdate={true}
            rules={[REQUIRED_PARTNER_TYPE]}
          >
            <Select
              placeholder="Select Partner Types"
              virtual={true}
              dropdownRender={(menu) => (
                <SelectOptionFooterExtraButtonComponent
                  key="show_more"
                  menu={menu}
                  onClickShowMoreList={onClickShowMorePartnerTypes}
                  isClickShowMoreDisabled={
                    partnerTypes.data.length < partnerTypes.total ? false : true
                  }
                />
              )}
            >
              {partnerTypes.data.map((item) => (
                <Option
                  id={item.partner_type_id}
                  key={item.partner_type_id}
                  value={item.partner_type_id}
                >
                  {item.partner_type_name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div hidden={currentStep === 2 ? false : true}>
          <Form.Item
            name="partner_person_in_charge"
            label="Partner Person In Charge"
            rules={[REQUIRED_PARTNER_PERSON_IN_CHARGE]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="partner_mobile"
            label="Partner Mobile"
            rules={[
              REQUIRED_PARTNER_MOBILE,
              FormRules.CONTACT_CHARACTER_COUNT,
              FormRules.NUMBER_FORMAT_ONLY,
            ]}
          >
            <Input prefix="+639" maxLength={9} />
          </Form.Item>
          <Form.Item
            name="partner_landline"
            label="Partner Landline"
            rules={[
              REQUIRED_PARTNER_LANDLINE,
              FormRules.PARTNERS_LANDLINE_CHARACTER_COUNT,
              FormRules.NUMBER_FORMAT_ONLY,
            ]}
          >
            <Input prefix={"(+632)"} maxLength={8} />
          </Form.Item>

          <Form.Item
            name="partner_email"
            label="Partner Email"
            rules={[REQUIRED_PARTNER_EMAIL, FormRules.EMAIL_FORMAT]}
          >
            <Input />
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};
