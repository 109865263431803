import { useEffect, useState } from "react";

const columns = [
  {
    title: "Id",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Platform Name",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Date Created",
    dataIndex: "created_at",
    key: "created_at",
  },
];

export default function useGetPlatformColumns() {
  const [tableColumns, setColumns] = useState(columns);

  useEffect(() => {
    setColumns(setColumns);
  }, []);

  return tableColumns;
}
