import { useFetch } from "../hooks/useFetch";
import { FETCH_PROPERTY_CATEGORY_CLASSIFICATIONS } from "../api/routes/property_category_classification";

export const FetchPropertyCategoryClassifications = async (query) => {
  console.log("called API");
  try {
    return await useFetch(FETCH_PROPERTY_CATEGORY_CLASSIFICATIONS, query);
  } catch (err) {
    console.log(err, "error");
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
