import { EyeOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { ListingListAssetComponent } from "../../components/sub_components/listings/ListingListAssetComponent";
import { Button, Tag } from "antd";
import { PropertyDeveloperListProjectComponent } from "../../components/sub_components/property_developers/PropertyDeveloperListProjectComponent";

const columnCallback = (handleClickViewPostHTML) => {
  return [
    {
      title: "Property Developers Pivot ID",
      dataIndex: "property_developer_projects_pivot_id",
      key: "property_developer_projects_pivot_id",
    },
    {
      title: "Property Project",
      key: "actions",
      render: (row) => (
        <Button
          type="primary"
          size="small"
          icon={<EyeOutlined />}
          onClick={() =>
            handleClickViewPostHTML(
              <PropertyDeveloperListProjectComponent
                projects={row.property_projects}
                property_developer_projects_pivot_id={
                  row.property_developer_projects_pivot_id
                }
              ></PropertyDeveloperListProjectComponent>
            )
          }
        >
          View Property Project
        </Button>
      ),
    },

    {
      title: "Date Creation",
      dataIndex: "created_at",
      key: "created_at",
    },
    {
      title: "Last Modified Date",
      dataIndex: "updated_at",
      key: "updated_at",
    },
  ];
};

export default function useGetPropertyDeveloperContentsColumns(
  handleClickViewPostHTML
) {
  let columns = columnCallback(handleClickViewPostHTML);
  const [tableColumns, setColumns] = useState(columns);

  useEffect(() => {
    setColumns(columns);
  }, []);

  return tableColumns;
}
