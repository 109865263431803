import React from "react";

import { PartnerTypeFormModalComponent } from "./PartnerTypeFormModalComponent";

export const PartnerTypeFormsModalFunctionalityComponent = (props) => {
  const {
    onSubmitPartnerTypeCreation,
    onCancelPartnerTypeCreation,
    partnerTypeModalVisibility,
    partnerTypeCreationForm,
    onSubmitPartnerTypeUpdate,
    onCancelPartnerTypeUpdate,
    partnerTypeUpdatedModalVisibility,
    partnerTypeUpdateForm,
  } = props;

  return (
    <>
      <PartnerTypeFormModalComponent
        form={partnerTypeCreationForm}
        isVisible={partnerTypeModalVisibility}
        onClickSubmit={onSubmitPartnerTypeCreation}
        onClickCancel={onCancelPartnerTypeCreation}
      />
      <PartnerTypeFormModalComponent
        form={partnerTypeUpdateForm}
        isVisible={partnerTypeUpdatedModalVisibility}
        onClickSubmit={onSubmitPartnerTypeUpdate}
        onClickCancel={onCancelPartnerTypeUpdate}
        isUpdate={true}
      />
    </>
  );
};
