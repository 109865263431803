import React from "react";
import { Modal, Tree, Form, InputNumber, Input } from "antd";
import { SaveOutlined, ArrowLeftOutlined } from "@ant-design/icons";

import { PropertyDeveloperFormModalComponent } from "./PropertyDeveloperFormModalComponent";

export const PropertyDeveloperFormsModalFunctionalityComponent = (props) => {
  const {
    onSubmitPropertyDeveloperCreation,
    onCancelPropertyDeveloperCreation,
    propertyDeveloperModalVisibility,
    propertyDeveloperCreationForm,
    onSubmitPropertyDeveloperUpdate,
    onCancelPropertyDeveloperUpdate,
    propertyDeveloperUpdatedModalVisibility,
    propertyDeveloperUpdateForm,
    showDeveloperSequenceModal = false,
    HandleOnSaveDeveloperSequenceOrder,
    onDrop,
    featuredDeveloperSequenceList,
    handleShowDeveloperSequenceModal,
    onClickTree,
    developerSequencePositionForm,
    showDeveloperSequencePositionModal,
    handleShowDeveloperSequencePositionModal,
    onHandleSubmitMovingDevelopersSequencePosition,
  } = props;

  return (
    <>
      <PropertyDeveloperFormModalComponent
        form={propertyDeveloperCreationForm}
        isVisible={propertyDeveloperModalVisibility}
        onClickSubmit={onSubmitPropertyDeveloperCreation}
        onClickCancel={onCancelPropertyDeveloperCreation}
      />
      <PropertyDeveloperFormModalComponent
        form={propertyDeveloperUpdateForm}
        isVisible={propertyDeveloperUpdatedModalVisibility}
        onClickSubmit={onSubmitPropertyDeveloperUpdate}
        onClickCancel={onCancelPropertyDeveloperUpdate}
        isUpdate={true}
      />
      <Modal
        visible={showDeveloperSequenceModal}
        title="Update Developer Sequence Order"
        okText="Save Changes"
        onOk={HandleOnSaveDeveloperSequenceOrder}
        onCancel={handleShowDeveloperSequenceModal}
        okButtonProps={{ icon: <SaveOutlined /> }}
        cancelButtonProps={{ icon: <ArrowLeftOutlined /> }}
        width={800}
      >
        <Tree
          height={500}
          onSelect={(key) => onClickTree(key)}
          className="draggable-tree"
          showIcon
          defaultExpandedKeys={[]}
          draggable
          blockNode
          onDrop={onDrop}
          treeData={featuredDeveloperSequenceList}
        />
        <Modal
          visible={showDeveloperSequencePositionModal}
          centered={true}
          title="Move Position?"
          okText="Yes, Submit"
          onOk={onHandleSubmitMovingDevelopersSequencePosition}
          onCancel={handleShowDeveloperSequencePositionModal}
          okButtonProps={{ icon: <SaveOutlined /> }}
          cancelButtonProps={{ icon: <ArrowLeftOutlined /> }}
          width={450}
        >
          <Form
            form={developerSequencePositionForm}
            size="middle"
            layout="horizontal"
          >
            <Form.Item hidden={true} name={"currentData"}>
              <Input.TextArea></Input.TextArea>
            </Form.Item>
            <Form.Item
              name={"sequencePosition"}
              label="Desired Sequence Position"
              required={true}
            >
              <InputNumber style={{ width: "100%" }} />
            </Form.Item>
          </Form>
        </Modal>
      </Modal>
    </>
  );
};
