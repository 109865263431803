import React from "react";
import { ModulesTableComponent } from "../../ModulesTableComponent";
import useColumnFilter from "../../../hooks/useColumnFilter";
import useGetPartnerColumns from "../../../hooks/partners/useGetPartnerColumns";
import { PartnersListDynamicComponent } from "./PartnersListDynamicComponent";
import GetPartnersSecondaryColumns from "../../../hooks/partners/GetPartnersSecondaryColumns";
import useFilteredColumns from "../../../hooks/useFilteredColumns";

export const PartnerListComponent = (props) => {
  const {
    data = { data: [], total: 0 },
    onPaginationChange,
    currentPagination,
    onSelection,
    selectedRowKey,
    onSortChange,
    columnFilter,
    setColumnFilter,
    tableLoading,
    onClickRestorePartner,
  } = props;

  const partnerColumns = useGetPartnerColumns(onClickRestorePartner);

  const { getColumnFilterProps } = useColumnFilter(
    columnFilter,
    setColumnFilter
  );

  let newCols = useFilteredColumns(partnerColumns, getColumnFilterProps);

  return (
    <>
      <ModulesTableComponent
        // expandable={{
        //   expandedRowRender: (record) => {
        //     return (
        //       <div style={{ padding: "1%" }}>
        //         <PartnersListDynamicComponent
        //           data={GetPartnersSecondaryColumns(record)}
        //         ></PartnersListDynamicComponent>
        //       </div>
        //     );
        //   },
        // }}
        data={data}
        onPaginationChange={onPaginationChange}
        onSelection={onSelection}
        selectedRowKey={selectedRowKey}
        tableColumns={newCols || []}
        currentPagination={currentPagination}
        columnFilter={columnFilter}
        setColumnFilter={setColumnFilter}
        onSortChange={onSortChange}
        tableLoading={tableLoading}
      />
    </>
  );
};
