import React from "react";

import { ListingFormModalComponent } from "./ListingFormModalComponent";

export const ListingFormsModalFunctionalityComponent = (props) => {
  const {
    onSubmitListingCreation,
    onCancelListingCreation,
    listingModalVisibility,
    listingCreationForm,
    onSubmitListingUpdate,
    onCancelListingUpdate,
    listingUpdatedModalVisibility,
    listingUpdateForm,
    partners = { data: [], total: 0 },
    onClickShowMorePartner,
    users = { data: [], total: 0 },
    onClickShowMoreUser,
    selectedRow,
    assets = [],
    onClickShowMoreAsset,
    onChangePartner,
  } = props;

  console.log(partners, "partners");

  return (
    <>
      <ListingFormModalComponent
        form={listingCreationForm}
        isVisible={listingModalVisibility}
        onClickSubmit={onSubmitListingCreation}
        onClickCancel={onCancelListingCreation}
        partners={partners}
        onClickShowMorePartner={onClickShowMorePartner}
        users={users}
        onClickShowMoreUser={onClickShowMoreUser}
        selectedRow={selectedRow}
        onClickShowMoreAsset={onClickShowMoreAsset}
        assets={assets}
        onChangePartner={onChangePartner}
      />
      <ListingFormModalComponent
        form={listingUpdateForm}
        isVisible={listingUpdatedModalVisibility}
        onClickSubmit={onSubmitListingUpdate}
        onClickCancel={onCancelListingUpdate}
        partners={partners}
        onClickShowMorePartner={onClickShowMorePartner}
        users={users}
        onClickShowMoreUser={onClickShowMoreUser}
        isUpdate={true}
        selectedRow={selectedRow}
        onClickShowMoreAsset={onClickShowMoreAsset}
        assets={assets}
        onChangePartner={onChangePartner}
      />
    </>
  );
};
