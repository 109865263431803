import React from "react";
import { Modal, Form, Input, Select } from "antd";
import { FormRules } from "../../../dist/functions/formRules";

const { Option } = Select;
const { REQUIRED_OPTION_KEY, REQUIRED_OPTION_GROUP, REQUIRED_OPTION_VALUE } =
  FormRules;

export const OptionCreationModalComponent = (props) => {
  const { showModal = false, OnFinish, form, onShowModal } = props;
  return (
    <>
      <Modal
        title="Create Option"
        visible={showModal}
        onOk={OnFinish}
        destroyOnClose={true}
        onCancel={onShowModal}
      >
        <Form form={form} name="options" layout="vertical">
          <Form.Item
            name="option_key"
            label="Key"
            rules={[REQUIRED_OPTION_KEY]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="option_value"
            label="value"
            rules={[REQUIRED_OPTION_VALUE]}
            help={
              "For the list type of option value, please seperate data by ','"
            }
          >
            <Input.TextArea rows={4} />
          </Form.Item>
          <Form.Item
            name={"option_group"}
            label="Option Group"
            rules={[REQUIRED_OPTION_GROUP]}
          >
            <Select placeholder="Select Option Group">
              <Option value={"admin"}>Admin</Option>
              <Option value={"marketing"}>Marketing</Option>
              <Option value={"developer"}>Developer</Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
