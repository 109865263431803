// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.upload-div-area-layout {
  width: 100%;
  text-align: center;
  vertical-align: baseline;
  border: darkgray;
  border-width: thin;
  border-style: solid;
  height: 360px;
  padding-top: 120px;
  padding-bottom: 180px;
  border-radius: 4px;
}

.site-result-demo-error-icon {
  color: red;
}

.upload-progress-div-layout {
  text-align: center;
  padding: 0% 20% 2% 20%;
  margin-top: 2%;
}

#errorLogsHeaderCollapse {
  color: white !important;
}

#errorLogsHeaderCollapse,
#duplicateHeaderCollapse,
#itemCreationHeaderCollapse,
#itemUpdateHeaderCollapse {
  font-weight: 500;
}

#itemCreationHeaderCollapse,
#itemUpdateHeaderCollapse {
  color: #2d2b2b;
}

.site-result-demo-error-icon {
  color: red;
}
`, "",{"version":3,"sources":["webpack://./src/dist/style/bulk_uploader.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,wBAAwB;EACxB,gBAAgB;EAChB,kBAAkB;EAClB,mBAAmB;EACnB,aAAa;EACb,kBAAkB;EAClB,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,sBAAsB;EACtB,cAAc;AAChB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;;;;EAIE,gBAAgB;AAClB;;AAEA;;EAEE,cAAc;AAChB;;AAEA;EACE,UAAU;AACZ","sourcesContent":[".upload-div-area-layout {\n  width: 100%;\n  text-align: center;\n  vertical-align: baseline;\n  border: darkgray;\n  border-width: thin;\n  border-style: solid;\n  height: 360px;\n  padding-top: 120px;\n  padding-bottom: 180px;\n  border-radius: 4px;\n}\n\n.site-result-demo-error-icon {\n  color: red;\n}\n\n.upload-progress-div-layout {\n  text-align: center;\n  padding: 0% 20% 2% 20%;\n  margin-top: 2%;\n}\n\n#errorLogsHeaderCollapse {\n  color: white !important;\n}\n\n#errorLogsHeaderCollapse,\n#duplicateHeaderCollapse,\n#itemCreationHeaderCollapse,\n#itemUpdateHeaderCollapse {\n  font-weight: 500;\n}\n\n#itemCreationHeaderCollapse,\n#itemUpdateHeaderCollapse {\n  color: #2d2b2b;\n}\n\n.site-result-demo-error-icon {\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
