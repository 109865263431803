import { message } from "antd";
import {
  FetchProvinces,
  CreateProvince,
  UpdateProvince,
  DeleteProvince,
} from "../../api/Province";
import { useAPIResponse } from "../useAPIResponse";
import { useGeneralFetch } from "../useGeneralFetch";
import { useGeneralFetchFunction } from "../useGeneralFetchFunction";

export const useFetchProvinces = (
  isForTable = true,
  query = { page: 1, limit: 10 || "ALL", columnFilter: [] }
) => {
  return useGeneralFetch(isForTable, query, FetchProvinces, "province_id");
};

export const useFetchProvincesFunction = () => {
  const [generalFetchFunction, loading] = useGeneralFetchFunction();

  const fetchProvincesFunction = async (
    isForTable = true,
    query = { page: 1, limit: 10, columnFilter: [] }
  ) => {
    return await generalFetchFunction(
      isForTable,
      query,
      FetchProvinces,
      "province_id"
    );
  };

  return [fetchProvincesFunction, loading];
};

// Temp Function
export const useFetchProvinceAsOptions = async () => {
  const data = await FetchProvinces({ page: 1, limit: "ALL" });
  return data.data.data.map((item) => {
    item["key"] = item.province_id;
    return item;
  });
};

export const useCreateProvince = async (data) => {
  await message.loading("Creating Province..", 2);
  const response = await CreateProvince(data);

  return useAPIResponse(response);
};

export const useUpdateProvince = async (province_id, payload) => {
  await message.loading("Updating Province..", 2);
  const response = await UpdateProvince(province_id, payload);

  return useAPIResponse(response);
};

export const useDeleteProvince = async (province_id, payload) => {
  await message.loading("Deleting Province..", 2);
  const response = await DeleteProvince(province_id, payload);

  return useAPIResponse(response);
};
