import React, { useState, useEffect } from "react";
import { message } from "antd";
import { FetchPlatforms, CreatePlatform } from "../../api/Platform";
import { useAPIResponse } from "../useAPIResponse";
import { useGeneralFetch } from "../useGeneralFetch";

export const useFetchPlatforms = (
  isForTable = true,
  query = { page: 1, limit: 10, columnFilter: [] }
) => {
  return useGeneralFetch(isForTable, query, FetchPlatforms, "id");
};

export const useCreatePlatform = async (data) => {
  await message.loading("Creating Platform..", 2);
  const response = await CreatePlatform(data);

  return useAPIResponse(response);
};
