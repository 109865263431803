import React, { useState, useEffect } from "react";
import { message } from "antd";
import {
  FetchModules,
  CreateModule,
  UpdateModule,
  DeleteModule,
  CreateBulkModule,
} from "../../api/Module";
import { useAPIResponse } from "../useAPIResponse";
import { useGeneralFetch } from "../useGeneralFetch";

export const useFetchModules = (
  isForTable = true,
  query = { page: 1, limit: 10, columnFilter: [] }
) => {
  return useGeneralFetch(isForTable, query, FetchModules, "id");
};

export const useCreateModule = async (data) => {
  await message.loading("Creating Module..", 2);
  const response = await CreateModule(data);

  return useAPIResponse(response);
};

export const useCreateBulkModule = async (platform_id, data) => {
  await message.loading("Creating Module by Bulk..", 2);
  const response = await CreateBulkModule(platform_id, data);

  return useAPIResponse(response);
};

export const useUpdateModule = async (module_id, payload) => {
  await message.loading("Updating Module..", 2);
  const response = await UpdateModule(module_id, payload);

  return useAPIResponse(response);
};

export const useDeleteModule = async (module_id) => {
  await message.loading("Updating Module..", 2);
  const response = await DeleteModule(module_id);

  return useAPIResponse(response);
};
