import React from "react";
import { Table, Typography } from "antd";

const { Column } = Table;
const { Title } = Typography;
export const PropertyDeveloperListProjectComponent = ({ projects = {} }) => {
  const _projects = [];
  for (const i in projects) {
    let projectObj = { key: i, value: projects[i] };
    _projects.push(projectObj);
  }

  return (
    <div style={{ padding: "2%" }}>
      <Table
        width="80%"
        dataSource={_projects}
        size="small"
        pagination={false}
        bordered={true}
      >
        <Column name="key" dataIndex="key" title="Key" />
        <Column name="value" dataIndex="value" title="Value" />
      </Table>
    </div>
  );
};
