import { useEffect, useState } from "react";
import { Button, Tag } from "antd";
import { RedoOutlined } from "@ant-design/icons";
import extractPermission from "../../dist/functions/extractPermission";

const columns = [
  {
    title: "Id",
    dataIndex: "property_category_id",
    key: "property_category_id",
  },
  {
    title: "Property Category Name",
    dataIndex: "property_category_name",
    key: "property_category_name",
  },
  {
    title: "Property Category Label",
    dataIndex: "property_category_label",
    key: "property_category_label",
  },
  {
    title: "Property Category Type",
    dataIndex: "property_category_type",
    key: "property_category_type",
    render: (property_category_type) => (
      <span>
        {property_category_type === "main" ? "Main Category" : "Sub Category"}
      </span>
    ),
  },
  {
    title: "Classification",
    dataIndex: [
      "property_category_classification",
      "property_category_classification_name",
    ],
    key: [
      "property_category_classification",
      "property_category_classification_name",
    ],
  },
  {
    title: "Status",
    dataIndex: "is_active",
    key: "is_active",
    render: (is_show) => (
      <Tag color={is_show ? "green" : "orange"}>
        {is_show ? "Enable" : "Archived"}
      </Tag>
    ),
  },
  {
    title: "Date Created",
    dataIndex: "created_at",
    key: "created_at",
  },
  {
    title: "Last Modified Date",
    dataIndex: "updated_at",
    key: "updated_at",
  },
];

export default function useGetPropertCategoriesColumn(
  onClickRestore,
  permissions = []
) {
  const [tableColumns, setColumns] = useState(columns);

  const isHiderestorePropertyCategory = extractPermission(
    permissions,
    "RESTORE_PROPERTY_CATEGORY"
  );

  useEffect(() => {
    setColumns([
      ...columns,
      {
        dataIndex: "property_category_id",
        key: "property_category_id",
        title: "Actions",
        render: (property_category_id, row) => (
          <Button
            type="link"
            icon={<RedoOutlined />}
            size="small"
            disabled={isHiderestorePropertyCategory}
            onClick={() => onClickRestore(property_category_id)}
            danger
          />
        ),
      },
    ]);
  }, []);

  return tableColumns;
}
