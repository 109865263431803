const extractPermission = (permissionList = [], permissionToFind) => {
  if (permissionList.length === 0) {
    return null;
  }

  const permission = permissionList.find(
    (item) => item.name === permissionToFind
  );

  if (!permission) {
    return null;
  }

  return permission.has_access === 1 ? false : true;
};

export default extractPermission;
