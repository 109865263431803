import React from "react";
import { Form, Modal, Input, Select, Divider } from "antd";

const { Option } = Select;

export const BulkProvinceCreationModalComponent = (props) => {
  const { form, visible = true, onSubmit, onCancel, islandGroups = [] } = props;

  return (
    <Modal
      visible={visible}
      title="Create Province"
      onOk={onSubmit}
      onCancel={onCancel}
    >
      <Form form={form} name="bulk_province_creation" layout="vertical">
        <Form.List name={"provinces"}>
          {(fields) => (
            <>
              {fields.map((field, index) => (
                <>
                  <Form.Item
                    key={[field.key, "province_name"]}
                    name={[field.key, "province_name"]}
                    label="Province Name"
                  >
                    <Input
                      key={[field.key, "city_name_input"]}
                      disabled={true}
                    />
                  </Form.Item>
                  <Form.Item
                    key={[field.key, "island_group_id"]}
                    name={[field.key, "island_group_id"]}
                    label="Island Group"
                  >
                    <Select
                      placeholder="Select Island Group"
                      key={[field.key, "select_island_group"]}
                    >
                      {islandGroups.map((island_group, index) => (
                        <Option
                          key={[
                            field.key,
                            index,
                            island_group.island_group_name,
                          ]}
                          value={island_group.island_group_id}
                        >
                          {island_group.island_group_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  {index === fields.length ? null : <Divider />}
                </>
              ))}
            </>
          )}
        </Form.List>
      </Form>
    </Modal>
  );
};
