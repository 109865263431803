import React from "react";
import { SuccessfulValidationComponent } from "./SuccessfulValidationComponent";
import { ErrorValidationComponent } from "./ErrorValidationComponent";

export const ValidationComponent = ({
  validationStatus,
  validationRemarks,
  agentListForCreation,
  partnerListForCreation,
  provinceListForCreation,
  barangayListForCreation,
  cityListForCreation,
  onClickAgentCreation,
  onClickCityCreation,
  onClickProvinceCreation,
  onClickPartnerCreation,
  onClickBarangayCreation,
}) => {
  return (
    <>
      {validationStatus === "success" ? (
        <SuccessfulValidationComponent />
      ) : validationStatus === "error" ? (
        <ErrorValidationComponent
          validationRemarks={validationRemarks}
          agentListForCreation={agentListForCreation}
          partnerListForCreation={partnerListForCreation}
          provinceListForCreation={provinceListForCreation}
          cityListForCreation={cityListForCreation}
          barangayListForCreation={barangayListForCreation}
          onClickAgentCreation={onClickAgentCreation}
          onClickCityCreation={onClickCityCreation}
          onClickProvinceCreation={onClickProvinceCreation}
          onClickPartnerCreation={onClickPartnerCreation}
          onClickBarangayCreation={onClickBarangayCreation}
        />
      ) : null}
    </>
  );
};
