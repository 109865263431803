import { useFetch } from "../hooks/useFetch";
import { useCreate } from "../hooks/useCreate";
import { useUpdate } from "../hooks/useUpdate";
import { FETCH_PLATFORM_URL, CREATE_PLATFORM_URL } from "./routes/platforms";
import { useDelete } from "../hooks/useDelete";

export const FetchPlatforms = async (query) => {
  try {
    return await useFetch(FETCH_PLATFORM_URL, query);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const CreatePlatform = async (payload) => {
  try {
    return await useCreate(CREATE_PLATFORM_URL, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
