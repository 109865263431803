import React, { useEffect, useState } from "react";
import { PageHeader, Divider, Form, Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { PropertyCategoryFunctionalityComponent } from "../sub_components/property_category/PropertyCategoryFunctionalityComponent";
import { PropertyCategoryListComponent } from "../sub_components/property_category/PropertyCategoryListComponent";
import { PropertyFiltersComponent } from "../sub_components/property_category/PropertyFiltersComponent";
import { PropertyCreationFormComponent } from "../sub_components/property_category/PropertyCreationFormComponent";
import { PropertyUpdatingFormComponent } from "../sub_components/property_category/PropertyUpdatingFormComponent";
import {
  useFetchPropertySubCategory,
  useCreatePropertySubCategory,
  useUpdatePropertySubCategory,
} from "../../hooks/property_categories/usePropertySubCategoriesAPI";
import { useFetchPropertyCategoryClassificationsForOption } from "../../hooks/property_category_classifications/usePropertyCategoryClassificationsAPI";

const initialTableState = { data: [], total: 0 };
const initialQueryFilters = { page: 1, limit: 10, filter: null };
const { confirm } = Modal;

export const PropertyCategoryManagementComponent = ({ permissions }) => {
  const [propertyCategories, setPropertyCategories] =
    useState(initialTableState);
  const [queryFilters, setQueryFilters] = useState(initialQueryFilters);
  const [tableLoading, setTableLoading] = useState(false);
  const [showSelection, setShowSelection] = useState(false);
  const [filterTags, setFilterTags] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [propertyCategoryClassifications, setPropertyCategoryClassifications] =
    useState([]);
  const [
    createPropertyCategoryModalStatus,
    setCreatePropertyCategoryModalStatus,
  ] = useState(false);
  const [
    updatePropertyCategoryModalStatus,
    setUpdatePropertyCategoryModalStatus,
  ] = useState(false);

  const [createPropertyCategoryForm] = Form.useForm();
  const [updatePropertyCategoryForm] = Form.useForm();

  /***
   *
   * Reusable Fetch Property Categories Function
   * Using by the other hooks if needed
   *
   */

  const FetchPropertyCategories = async (
    page = 1,
    limit = 10,
    filter = null
  ) => {
    setTableLoading(true);
    const query = { page, limit, search_value: filter };
    const data = await useFetchPropertySubCategory(query, true);
    setPropertyCategories(data);
    setTimeout(() => {
      setTableLoading(false);
    }, 1300);
  };

  const FetchPropertyCategoryClassifications = async () => {
    const data = await useFetchPropertyCategoryClassificationsForOption();
    setPropertyCategoryClassifications(data);
  };

  /***
   *
   * Initial Fetch of Property Categories when Rendered First time
   * Passing with default query parameters/filters
   *
   */

  useEffect(() => {
    FetchPropertyCategories();
    FetchPropertyCategoryClassifications();
  }, []);

  /***
   *
   * Triggered every the query filters changes it states via ff. functions
   *  - Pagination Change
   *  - Search
   *
   */

  useEffect(() => {
    const { page, limit, filter } = queryFilters;
    FetchPropertyCategories(page, limit, filter);
  }, [queryFilters]);

  /***
   *
   * Query Filter Tags hook
   * Will render tags and toggle filter/selection component when the filter is populated
   *
   */

  useEffect(() => {
    const { filter } = queryFilters;
    if (filter) {
      setFilterTags((prevState) => {
        const tag = {
          color: "blue",
          value: filter,
          closable: false,
          selection_label: "Includes",
        };
        return [...prevState, tag];
      });
    }
  }, [queryFilters]);

  /***
   *
   * Query Filter Tags hook
   * Will render tags and toggle filter/selection component when one of the rows is selected
   *
   */

  useEffect(() => {
    if (selectedRow) {
      const { property_category_name } = selectedRow;
      setFilterTags((prevState) => {
        const selectedSearchTagIndex = prevState.findIndex(
          (item) => item.selection_label === "Selected"
        );

        const tag = {
          color: "blue",
          value: property_category_name,
          closable: false,
          selection_label: "Selected",
        };

        if (selectedSearchTagIndex < 0) {
          return [...prevState, tag];
        }

        return prevState.splice(selectedSearchTagIndex, 1, tag);
      });
    }
  }, [selectedRow]);

  /***
   *
   * Update Form Ref Hook
   * Will populate the category update form when selectedRow state is populated
   *
   * It means that there is a selected row, and its possible to undergo update transaction/function
   *
   */

  useEffect(() => {
    if (selectedRow) {
      updatePropertyCategoryForm.setFieldsValue(selectedRow);
    }
  }, [updatePropertyCategoryForm, selectedRow]);

  /***
   *
   * Filter Tags hook
   * Automatic tagging to show selection filter components if filters tags has a data
   *
   *
   */

  useEffect(() => {
    if (filterTags.length > 0) {
      setShowSelection(true);
    }
  }, [filterTags]);

  /***
   *
   * On Pagination Change
   * Function that will trigger when pagination is executed
   *
   * Automatic updating query filter state base on the page and limit
   * Will trigger other hooks at the same time this state is Updated
   *
   *
   */

  const onPaginationChange = (page, limit) => {
    setQueryFilters((prevState) => {
      return { ...prevState, page: page, limit: limit };
    });
  };

  /***
   *
   * On Search Property category
   * Function that will trigger when pagination is executed
   *
   * Automatic updating query filter state base on filter value
   * Will trigger other hooks at the same time this state is Updated
   *
   *
   */

  const onSearchPropertyCategory = (filter) => {
    const _showSelection = filter ? true : false;
    setShowSelection(_showSelection);
    setQueryFilters((prevState) => {
      return { ...prevState, filter: filter };
    });
  };

  /***
   *
   * Reset Selections
   * Use to reset current states to its initial/original state
   *
   */

  const onClearSelection = () => {
    onSearchPropertyCategory(null);
    setFilterTags([]);
    setSelectedRow(null);
    setSelectedRowKey(null);
  };

  /***
   *
   * Row Selection
   * Udate state of selected row and id, to trigger other hooks after state update
   *
   */

  const onSelection = (selectedRowKey, selectedRow) => {
    setSelectedRowKey(selectedRowKey[0]);
    setSelectedRow(selectedRow[0]);
  };

  /***
   *
   * Show/Hide Modal for Creation and Updating
   *
   */

  const onClickCreatePropertyCategoryButton = () => {
    setCreatePropertyCategoryModalStatus(!createPropertyCategoryModalStatus);
  };

  const onClickUpdatePropertyCategoryButton = () => {
    setUpdatePropertyCategoryModalStatus(!updatePropertyCategoryModalStatus);
  };

  /***
   *
   * Outside Hooks/API Integration For the ff. functions
   *
   *  - Creation of Property Type
   *  - Updating of Selected Property Type
   *  - Deleting of Selected Property Type (Hard Delete)
   *
   */

  const OnSubmitPropertyTypeCreation = async () => {
    const values = await createPropertyCategoryForm.validateFields();
    const isSuccess = await useCreatePropertySubCategory(values);
    if (isSuccess) {
      /**
       *
       * Back to original state
       *
       */
      createPropertyCategoryForm.resetFields();
      onClearSelection();
      onClickCreatePropertyCategoryButton();
    }
  };

  const OnSubmitPropertyTypeUpdating = async () => {
    const values = await updatePropertyCategoryForm.validateFields();
    const isSuccess = await useUpdatePropertySubCategory(
      selectedRowKey,
      values
    );

    if (isSuccess) {
      /**
       *
       * Back to original state
       *
       */
      updatePropertyCategoryForm.resetFields();
      onClearSelection();
      onClickUpdatePropertyCategoryButton();
    }
  };

  const UpdatePropertyTypeStatus = async (
    property_category_id,
    status = false
  ) => {
    const payload = { is_active: status };
    const isSuccess = await useUpdatePropertySubCategory(
      property_category_id,
      payload
    );

    if (isSuccess) {
      /**
       *
       * Back to original state
       *
       */
      updatePropertyCategoryForm.resetFields();
      onClearSelection();
    }
  };

  const onClickRestore = (property_category_id) => {
    UpdatePropertyTypeStatus(property_category_id, true);
  };

  const onSubmitPropertyArchive = async () => {
    confirm({
      title: "Are you sure you want to archive this property category?",
      icon: <ExclamationCircleOutlined />,
      content:
        "archiving this property category will deactivate this property from the list",
      onOk() {
        UpdatePropertyTypeStatus(selectedRowKey, true);
      },
      onCancel() {
        return;
      },
    });
  };

  return (
    <>
      <PageHeader
        title="Property Category Management"
        subTitle="Property Category/Sub Category Management"
        breadcrumbRender={() => (
          <BreadCrumbsComponent
            breadcrumbsItem={["Settings", "Property Category"]}
          />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        {/* Functionality components, access to modal buttons to trigger the transaction modals */}
        <PropertyCategoryFunctionalityComponent
          onSearch={onSearchPropertyCategory}
          onClickCreate={onClickCreatePropertyCategoryButton}
          onClickUpdate={onClickUpdatePropertyCategoryButton}
          onClickDelete={onSubmitPropertyArchive}
          selectedRowKey={selectedRowKey}
          permissions={permissions}
        />
        {/* Filters, Chips and Tags component to see the filtered data */}
        <PropertyFiltersComponent
          isShowSelection={showSelection}
          onClearSearchAndSelection={onClearSelection}
          tags={filterTags}
        />
        {/* Property Category Table Sub Component */}
        <PropertyCategoryListComponent
          data={propertyCategories}
          onPaginationChange={onPaginationChange}
          tableLoading={tableLoading}
          onSelection={onSelection}
          currentPagination={queryFilters.page}
          selectedRowKey={selectedRowKey}
          onClickRestore={onClickRestore}
          permissions={permissions}
        />
      </div>
      {/* Property Category Modal Sub Components with Forms */}
      <PropertyCreationFormComponent
        visible={createPropertyCategoryModalStatus}
        form={createPropertyCategoryForm}
        onSubmit={OnSubmitPropertyTypeCreation}
        onCancel={onClickCreatePropertyCategoryButton}
        propertyCategoryClassifications={propertyCategoryClassifications}
      />
      <PropertyUpdatingFormComponent
        visible={updatePropertyCategoryModalStatus}
        form={updatePropertyCategoryForm}
        onSubmit={OnSubmitPropertyTypeUpdating}
        onCancel={onClickUpdatePropertyCategoryButton}
        propertyCategoryClassifications={propertyCategoryClassifications}
      />
    </>
  );
};
