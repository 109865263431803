import React from "react";
import { Modal, Table, Tag } from "antd";
import { SyncOutlined, CheckCircleOutlined } from "@ant-design/icons";

export const SyncingLogsComponent = (props) => {
  const { data, visible, onClose } = props;

  const columns = [
    {
      title: "UUID",
      dataIndex: "number",
      key: "number",
    },
    {
      title: "Remarks",
      dataIndex: "status_item_remarks",
      key: "status_item_remarks",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (item) => (
        <Tag
          icon={
            item === "syncing" ? <SyncOutlined spin /> : <CheckCircleOutlined />
          }
          color={item === "syncing" ? "processing" : "success"}
        >
          {item}
        </Tag>
      ),
    },
  ];

  return (
    <>
      <Modal visible={visible} footer={[[]]} onCancel={onClose}>
        <br />
        <Table
          size="small"
          dataSource={data}
          columns={columns}
          bordered={true}
        />
      </Modal>
    </>
  );
};
