import { createUserAttachedRoleByNewUser } from "../dao/userAttachedRoles";
import { createUserTransformer } from "../transformers/userTransformer";

const createUserDAO = (data) => {
  const user_attached_role = createUserAttachedRoleByNewUser(data);
  data["user_attached_role"] = user_attached_role;
  return createUserTransformer(data);
};

export { createUserDAO };
