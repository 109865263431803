import React from "react";
import { Descriptions, Typography } from "antd";

const { Text } = Typography;

export const AssetInquiriesListDynamicComponent = ({ data = {} }) => {
  const _data = [];
  for (const i in data) {
    let dataObj = { key: i, value: data[i] };
    _data.push(dataObj);
  }

  console.log(data, _data, "data");

  return (
    <Descriptions
      size="small"
      bordered
      layout="vertical"
      column={{ xxl: 4, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
    >
      {_data.map((item) => (
        <Descriptions.Item label={<Text strong>{item.key}</Text>}>
          {item.value}
        </Descriptions.Item>
      ))}
    </Descriptions>
  );
};
