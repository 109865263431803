import React, { useState, useEffect } from "react";
import { Menu, Layout, Space, Avatar, Dropdown, Modal } from "antd";
import { Link, useHistory } from "react-router-dom";
import { useDelete } from "../hooks/useDelete";
import { ProfileDrawerComponent } from "./ProfileDrawerComponent";
import AssetmartIcon from "../dist/img/assetmart-icon.png";
import {
  BuildOutlined,
  LogoutOutlined,
  ProfileOutlined,
  ExclamationCircleOutlined,
  UserOutlined,
  PieChartOutlined,
  SettingFilled,
  CloudUploadOutlined,
  UnorderedListOutlined,
  HomeOutlined,
  CompassOutlined,
  QuestionCircleOutlined,
  UsergroupAddOutlined,
  UserSwitchOutlined,
} from "@ant-design/icons";
import "../MenuComponent.css";
import { GetSessionDetails } from "../api/Sessions";

export const MenuComponent = ({ component, permissions }) => {
  const [userProfile, setUserProfile] = useState({
    fullName: "",
    token: null,
    roleName: "",
    permissions: [],
  });
  const CustomRouteComponent = component;
  const history = useHistory();
  const [, setCurrentMenu] = useState("1");
  const [collapsed, setCollapsedMode] = useState(false);
  const [showDrawer, setDrawerStatus] = useState(false);
  const { Header, Footer, Sider, Content } = Layout;
  const { SubMenu } = Menu;
  const { confirm } = Modal;

  useEffect(() => {
    const session_id = localStorage.getItem("session_id");
    if (!session_id) {
      localStorage.clear();
      history.push("/login");
      return;
    }
  }, []);

  useEffect(() => {
    const getCurrentSession = async () => {
      const session = await GetSessionDetails();
      if (!session?.jwt) {
        localStorage.clear();
        history.push("/login");
        return;
      }

      const { user_info, role, permissions = [] } = session;

      if (permissions.length === 0) {
        localStorage.clear();
        history.push("/login");
        return;
      }

      const formattedPermissionsStructure = [];

      for (const permission of permissions) {
        const { permission_index, has_access } = permission;
        const item = { name: permission_index.name, has_access };
        formattedPermissionsStructure.push(item);
      }

      const {
        users_name,
        users_first_name,
        users_middle_name,
        users_last_name,
      } = user_info;
      setUserProfile((prevState) => {
        return {
          ...prevState,
          fullName: users_name
            ? users_name
            : `${users_first_name} ${users_middle_name} ${users_last_name}`,
          roleName: role?.name,
          permissions: formattedPermissionsStructure,
        };
      });
    };

    getCurrentSession();
  }, []);

  const handleClick = (e) => {
    setCurrentMenu(e.key);
  };

  const toggle = () => {
    setCollapsedMode(!collapsed);
  };

  const OnConfirm = async () => {
    confirm({
      title: "Do you Want to Logout?",
      icon: <ExclamationCircleOutlined />,
      content: "all unfinished tasks, will be discarded after signing out",
      onOk() {
        Logout();
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const Logout = async () => {
    const session_id = localStorage.getItem("session_id");
    await useDelete(
      `${process.env.REACT_APP_API_URL}/sessions/current?session_id=${session_id}`
    );
    localStorage.clear();
    history.push("/login");
  };

  const showProfile = () => {
    setDrawerStatus(!showDrawer);
  };

  function handleMenuClick(e) {}

  const shouldShowDeveloper = () => {
    const roleName = localStorage.getItem("role_name");

    if (roleName && roleName.toLowerCase().includes("developer")) {
      return true;
    } else {
      return false;
    }
  };

  const hasModuleAccess = (permissionNames = []) => {
    if (permissionNames.length === 0) {
      return false;
    }

    var accessValues = [];

    for (const name of permissionNames) {
      const filteredData = userProfile.permissions.filter(
        (item) => item["name"] === name
      );

      if (filteredData.length > 0) {
        for (const data of filteredData) {
          accessValues.push(data.has_access);
        }
      }
    }

    return accessValues.includes(1) ? true : false;
  };

  return (
    <>
      <Layout>
        <Sider
          breakpoint="lg"
          collapsible
          collapsed={collapsed}
          onCollapse={toggle}
          collapsedWidth={95}
          style={{
            left: 0,
          }}
        >
          <div className="menu-logo-div-layout">
            <img
              src={AssetmartIcon}
              alt="Assetmart Icon"
              style={{ width: !collapsed ? "45px" : "30px" }}
            />
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={[]}
            onClick={handleClick}
            style={{ marginTop: "24%" }}
          >
            <Menu.Item key="0" icon={<PieChartOutlined />}>
              <Link to="/dashboard">Dashboard</Link>
            </Menu.Item>
            <SubMenu key="sub1" icon={<UserOutlined />} title="Administration">
              <Menu.Item
                key="1"
                hidden={
                  !hasModuleAccess([
                    "CREATE_PLATFORM",
                    "UPDATE_PLATFORM",
                    "DELETE_PLATFORM",
                  ])
                }
              >
                <Link to="/administration/platform">Platform</Link>
              </Menu.Item>
              <Menu.Item
                key="2"
                hidden={
                  !hasModuleAccess([
                    "CREATE_MODULES",
                    "UPDATE_MODULES",
                    "DELETE_MODULES",
                  ])
                }
              >
                <Link to="/administration/modules">Modules</Link>
              </Menu.Item>
              <Menu.Item
                key="3"
                hidden={
                  !hasModuleAccess([
                    "ADD_PERMISSION_INDEX",
                    "UPDATE_PERMISSION_INDEX",
                  ])
                }
              >
                <Link to="/administration/permission-index">
                  Permission Index
                </Link>
              </Menu.Item>
              <Menu.Item
                key="4"
                hidden={
                  !hasModuleAccess([
                    "CREATE_ROLES_ACCESS",
                    "UPDATE_ROLES_ACCESS",
                  ])
                }
              >
                <Link to="/administration/roles-access">Roles Access</Link>
              </Menu.Item>
              <Menu.Item
                key="5"
                hidden={
                  !hasModuleAccess([
                    "CREATE_USER",
                    "UPDATE_USER",
                    "ADD_UPDATE_USER_ROLE",
                    "UPDATE_STATUS",
                    "UPDATE_DEPOSIT_STATUS",
                    "GENERATE_USER_REPORT",
                  ])
                }
              >
                <Link to="/administration/user-management">
                  User Management
                </Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="24" icon={<UnorderedListOutlined />}>
              <Link to="/a-plus-network">APlus Network</Link>
            </Menu.Item>
            <Menu.Item key="23" icon={<UnorderedListOutlined />}>
              <Link to="/listings">Upload Sheet</Link>
            </Menu.Item>
            <Menu.Item
              key="25"
              icon={<HomeOutlined />}
              hidden={!hasModuleAccess(["CREATE_ASSETS", "UPDATE_ASSETS"])}
            >
              <Link to="/assets">Items</Link>
            </Menu.Item>
            <Menu.Item key="33" icon={<QuestionCircleOutlined />}>
              <Link to="/asset-inquiries">Asset Inquiries</Link>
            </Menu.Item>
            <Menu.Item key="26" icon={<UserSwitchOutlined />}>
              <Link to="/partner-types">Partner Types</Link>
            </Menu.Item>
            <Menu.Item key="27" icon={<UsergroupAddOutlined />}>
              <Link to="/partners">Partners</Link>
            </Menu.Item>
            <SubMenu key="sub2" icon={<HomeOutlined />} title="Property">
              <Menu.Item key="30">
                <Link to="/property-developers">Developers</Link>
              </Menu.Item>
              <Menu.Item key="31">
                <Link to="/property-projects">Projects</Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item
              key="bulk-uploader-1"
              icon={<CloudUploadOutlined />}
              hidden={!hasModuleAccess(["VALIDATE_DATA", "UPSERT_DATA"])}
            >
              <Link to="/bulk-uploader">Bulk Uploader</Link>
            </Menu.Item>
            <SubMenu key="sub5" title="Settings" icon={<SettingFilled />}>
              <Menu.Item key="sub-5-settings-1">
                <Link to="/settings/custom-field">Custom Field</Link>
              </Menu.Item>
              <Menu.Item
                key="sub-5-settings-2"
                hidden={
                  !hasModuleAccess([
                    "CREATE_PROPERTY_CATEGORY",
                    "UPDATE_PROPERTY_CATEGORY",
                    "DELETE_PROPERTY_CATEGORY",
                    "ARCHIVE_PROPERTY_CATEGORY",
                    "RESTORE_PROPERTY_CATEGORY",
                  ])
                }
              >
                <Link to="/settings/property-category">Property Category</Link>
              </Menu.Item>
              {shouldShowDeveloper() && (
                <Menu.Item key="sub-5-settings-3">
                  <Link to="/settings/options">Option</Link>
                </Menu.Item>
              )}
            </SubMenu>
            <SubMenu
              key={"sub6"}
              title={"Geolocation"}
              icon={<CompassOutlined />}
            >
              <Menu.Item key="sub6-geolocation-1">
                <Link to="/barangay">Barangay</Link>
              </Menu.Item>
              <Menu.Item
                key="sub6-geolocation-2"
                hidden={!hasModuleAccess(["CREATE_CITY", "UPDATE_CITY"])}
              >
                <Link to="/city">City</Link>
              </Menu.Item>
              <Menu.Item key="3sub6-geolocation-3">
                <Link to="/province">Province</Link>
              </Menu.Item>
              <Menu.Item key="sub6-geolocation-4">
                <Link to="/region">Regions</Link>
              </Menu.Item>
              <Menu.Item key="sub6-geolocation-5">
                <Link to="/island-group">Island Group</Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="22" icon={<BuildOutlined />}>
              <Link to="/activity">Activity Logs</Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout>
          <Header className="site-layout-background menu-header-custom-style">
            <Space size={0} align="center">
              {`${userProfile.fullName} / ${userProfile.roleName}`}
              <Dropdown.Button
                size="small"
                type="text"
                icon={
                  <Avatar
                    style={{ backgroundColor: "#001529" }}
                    icon={<UserOutlined />}
                  />
                }
                overlay={
                  <Menu onClick={handleMenuClick}>
                    <Menu.Item
                      key="1"
                      icon={<ProfileOutlined />}
                      onClick={showProfile}
                    >
                      View Profile
                    </Menu.Item>
                    <Menu.Item
                      key="2"
                      icon={<LogoutOutlined />}
                      onClick={OnConfirm}
                    >
                      Logout
                    </Menu.Item>
                  </Menu>
                }
              />
            </Space>
          </Header>
          <Content className="content-layout">
            <div className="site-layout-background content-style-padding-div">
              <CustomRouteComponent permissions={userProfile.permissions} />
            </div>
          </Content>
          <Footer style={{ textAlign: "center" }}>
            Web Admin 3.0 ©2021 Created by QMarketz
            <div>{"Assetmart.Global"}</div>
          </Footer>
        </Layout>
      </Layout>
      <ProfileDrawerComponent visible={showDrawer} onClose={showProfile} />
    </>
  );
};
