import React, { useEffect, useState, useReducer } from "react";
import { Divider, PageHeader, Form, Modal } from "antd";

import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { PartnerListComponent } from "../sub_components/partners/PartnerListComponent";
import { PartnersFunctionalityComponent } from "../sub_components/partners/PartnersFunctionalityComponent";
import { PartnerFormsModalFunctionalityComponent } from "../sub_components/partners/PartnerFormsModalFunctionalityComponent";
import {
  useArchivePartner,
  useCreatePartner,
  useFetchPartners,
  useRestorePartner,
  useUpdatePartner,
  useDeletePartner,
} from "../../hooks/partners/usePartnerAPI";

import { useQueryFilterReducer } from "../../hooks/useQueryFilterReducer";
import { ModulesSelectAndColumnTagsComponent } from "../ModulesSelectAndColumnTagsComponent";
import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";
import { useFetchPartnerTypesSelect } from "../../hooks/partner_types/usePartnerTypeAPI";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { confirm } = Modal;

export const PartnersComponent = ({ permissions }) => {
  const [createPartnerForm] = Form.useForm();
  const [updatePartnerForm] = Form.useForm();
  const [partnerCreationModalStatus, setPartnerCreationModalStatus] =
    useState(false);
  const [partnerUpdateModalStatus, setPartnerUpdateModalStatus] =
    useState(false);

  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const {
    queryFilters,
    actionsQueryFilters,
    onPaginationChange,
    onSortChange,
    columnFilterTags,
    onClearColumnFilters,
    onLoadColumnFilterTags,
    resetQueryFilters,
  } = useQueryFilterReducer();

  const [partnersData, isPartnersLoading] = useFetchPartners(
    true,
    queryFilters
  );

  const [partnerTypesQuery, setPartnerTypesQuery] = useState({
    page: 1,
    limit: 10,
  });

  const [
    partnerTypes,
    isLoadingPartnerTypes,
    persistentPartnerTypes,
    setShouldPersistPartnerTypes,
  ] = useFetchPartnerTypesSelect(true, partnerTypesQuery);

  /**
   * Functionalities
   *
   */

  /**
   *
   * Initial Partner Fetch
   *    - Fetch with 1 page, limit 10 with no filter as query parameters
   */
  useComponentDidUpdate(() => {
    onLoadColumnFilterTags();
  }, [queryFilters]);

  /**
   *
   * Use effect hooks if any of the data is selected
   * Selected row will populate the form for updating
   *
   */
  useEffect(() => {
    if (selectedRow && partnerUpdateModalStatus === true) {
      updatePartnerForm.setFieldsValue({
        ...selectedRow,
        partner_landline: selectedRow.partner_landline.toString(),
      });
    }
  }, [selectedRow, partnerUpdateModalStatus, updatePartnerForm]);

  /**
   *
   * On every selection change, update the row selection state to trigger the use effect hook with row selection dependency
   * It will re-trigger fetch function with the updated row selection and populate forms for updating
   *
   */
  const onSelectionChange = (keys, selectedRows) => {
    setSelectedRow(selectedRows[0]);
    setSelectedRowKey(keys[0]);
  };

  /**
   *
   * Create Partner function with success validation, connected to useCreatePartner hook
   *
   */

  const CreatePartner = async () => {
    const payload = await createPartnerForm.validateFields();
    const isSuccess = await useCreatePartner(payload);
    if (isSuccess) {
      createPartnerForm.resetFields();
      resetQueryFilters();
      onClickCreatePartnerButton();
      resetSelections();
    }
  };

  /**
   *
   * Update Partner function with success validation, connected to useUpdatePartner hook
   *
   */

  const UpdatePartner = async () => {
    const payload = await updatePartnerForm.validateFields();
    const isSuccess = await useUpdatePartner(selectedRowKey, payload);
    if (isSuccess) {
      updatePartnerForm.resetFields();
      resetQueryFilters();
      onClickUpdatePartnerButton();
      resetSelections();
    }
  };

  /**
   *
   * Restore Partner function with success validation, connected to useUpdatePartner hook
   * Redefine as restore function, uses the useRestorePartner hook function
   *
   */

  const RestorePartner = async (id) => {
    const isSuccess = await useRestorePartner(id);
    if (isSuccess) {
      resetQueryFilters();
      resetSelections();
    }
  };

  /**
   *
   * Archive Partner function with success validation, connected to useArchivePartner hook
   * Redefine as archive function, uses the useArchivePartner hook function
   *
   */

  const ArchivePartner = async () => {
    const isSuccess = await useArchivePartner(selectedRowKey);
    if (isSuccess) {
      resetQueryFilters();
      resetSelections();
    }
  };

  /**
   *
   * Delete Partner function with success validation, connected to useArchivePartner hook
   * Redefine as archive function, but using the same useUpdate hook function
   *
   */

  const DeletePartner = async () => {
    const isSuccess = await useDeletePartner(selectedRowKey);
    if (isSuccess) {
      resetQueryFilters();
      resetSelections();
    }
  };

  /**
   *
   * Helper functions:
   *
   * - Functions that will reset all selections, pagination and restore initial index/fetch
   *
   */

  const DeleteConfirm = async () => {
    confirm({
      title: "Do you want to delete this partner?",
      icon: <ExclamationCircleOutlined />,
      onOk() {
        DeletePartner();
      },
    });
  };

  const resetSelections = () => {
    setSelectedRowKey(null);
    setSelectedRow(null);
  };

  const onClickCreatePartnerButton = () => {
    setPartnerCreationModalStatus(!partnerCreationModalStatus);
  };

  const onClickUpdatePartnerButton = () => {
    setPartnerUpdateModalStatus(!partnerUpdateModalStatus);
  };

  const onClickShowMorePartnerTypes = async () => {
    const { current_page, last_page } = partnerTypes;
    if (current_page < last_page) {
      const query = { page: parseInt(current_page) + 1, limit: 10 };
      setShouldPersistPartnerTypes(true);
      setPartnerTypesQuery(query);

      return;
    }
  };

  return (
    <>
      <PageHeader
        title="Partner"
        subTitle="Partner Management"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["Partner"]} />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <PartnersFunctionalityComponent
          onClickCreatePartnerButton={onClickCreatePartnerButton}
          onClickUpdatePartnerButton={onClickUpdatePartnerButton}
          onClickArchivePartnerButton={ArchivePartner}
          onClickDeletePartnerButton={DeleteConfirm}
          selectedRowKey={selectedRowKey}
          selectedRow={selectedRow}
          permissions={permissions}
        />

        <ModulesSelectAndColumnTagsComponent
          onClearColumnFilters={onClearColumnFilters}
          isShowColumnFilterTags={columnFilterTags.length}
          columnFilterTags={columnFilterTags}
          isShowSelection={!!selectedRow}
          onClearSearcheAndSelection={resetSelections}
          tags={[
            {
              color: "processing",
              selection_label: "Selected Partner",
              value: selectedRow ? `${selectedRow.partner_name}` : null,
            },
          ]}
        />
        <PartnerListComponent
          data={partnersData}
          tableLoading={isPartnersLoading}
          onPaginationChange={onPaginationChange}
          selectedRowKey={selectedRowKey}
          onSelection={onSelectionChange}
          currentPagination={queryFilters.page}
          onSortChange={onSortChange}
          setColumnFilter={actionsQueryFilters.changeColumnFilter}
          columnFilter={queryFilters.columnFilter}
          onClickRestorePartner={RestorePartner}
        />
      </div>
      <PartnerFormsModalFunctionalityComponent
        onSubmitPartnerCreation={CreatePartner}
        onCancelPartnerCreation={onClickCreatePartnerButton}
        partnerModalVisibility={partnerCreationModalStatus}
        partnerCreationForm={createPartnerForm}
        onSubmitPartnerUpdate={UpdatePartner}
        onCancelPartnerUpdate={onClickUpdatePartnerButton}
        partnerUpdatedModalVisibility={partnerUpdateModalStatus}
        partnerUpdateForm={updatePartnerForm}
        partnerTypes={persistentPartnerTypes}
        onClickShowMorePartnerTypes={onClickShowMorePartnerTypes}
      />
    </>
  );
};
