import { useState, useEffect } from "react";
import { FetchPropertyCategoryClassifications } from "../../api/PropertyCategoryClassification";

const initialTableState = { data: [], total: 0 };

export const useFetchPropertyCategoryClassifications = async (
  query,
  isForTable = false
) => {
  const initialState = !isForTable ? [] : initialTableState;
  const [data, setData] = useState(initialState);

  useEffect(() => {
    const ExecuteFetchPropertyCategoryClassifications = async () => {
      const propertyCategoryClassifications =
        await FetchPropertyCategoryClassifications(query);
      const { data, ...metaData } = propertyCategoryClassifications.data;
      const propertyCategoryClassificationsResponseArray =
        propertyCategoryClassifications.data.data.map((item) => {
          item["key"] = item.barangay_id;
          return item;
        });

      setData(() => {
        return isForTable
          ? { ...metaData, data: propertyCategoryClassificationsResponseArray }
          : propertyCategoryClassificationsResponseArray;
      });
    };

    ExecuteFetchPropertyCategoryClassifications();
  }, [query, isForTable]);

  return data;
};

export const useFetchPropertyCategoryClassificationsForOption = async () => {
  const query = {
    page: 1,
    limit: "ALL",
  };

  const response = await FetchPropertyCategoryClassifications(query);

  if (response.data.data.length > 0) {
    return response.data.data.map((item) => {
      item["key"] = item.property_category_classification_id;
      return item;
    });
  }

  return [];
};
