import { useReducer, useState } from "react";

const initialQueryFilters = {
  page: 1,
  limit: 10,
  columnFilter: [],
  sortColumn: null,
  sortType: "asc",
  search_value: "",
};

/**
 * Reducer for filters. It's like a setState, but more detailed. If we want to change only the page, we call a
 * dispatch function for it
 */
const queryFiltersReducer = (state, action) => {
  switch (action.type) {
    case "changePage":
      return { ...state, page: action.payload };
    case "changeLimit":
      return { ...state, limit: action.payload };
    case "changeColumnFilter":
      return { ...state, columnFilter: action.payload, page: 1 };
    case "changeSortColumn":
      return { ...state, sortColumn: action.payload };
    case "changeSortType":
      return { ...state, sortType: action.payload };
    case "changeSearchValue":
      return { ...state, search_value: action.payload, page: 1 };
    case "changePaginationQuery":
      return { ...state, page: action.page, limit: action.limit };
    case "changeSortingQuery":
      return {
        ...state,
        sortColumn: action.sortColumn,
        sortType: action.sortType,
      };
    case "changeQuery":
      return {
        ...action.payload,
      };
    case "reset":
      return action.customQuery !== undefined
        ? { ...action.customQuery }
        : { ...initialQueryFilters };
    default:
      return state;
  }
};

/**
 * Dispatch returner for reducer, the "type" matches the switch-case on reducer
 * @param {*} dispatch dispatcher from useReducer
 * @returns object of all dispatch functions
 */
const mapDispatch = (dispatch) => ({
  changePage: (payload) => dispatch({ type: "changePage", payload: payload }),
  changeLimit: (payload) => dispatch({ type: "changeLimit", payload: payload }),
  changeColumnFilter: (payload) =>
    dispatch({ type: "changeColumnFilter", payload: payload }),
  changeSortColumn: (payload) =>
    dispatch({ type: "changeSortColumn", payload: payload }),
  changeSearchValue: (payload) =>
    dispatch({ type: "changeSearchValue", payload: payload }),
  changeSortType: (payload) => {
    if (payload === "descend") {
      payload = "desc";
    } else if (payload === "ascend") {
      payload = "asc";
    } else {
      payload = "none";
    }

    return dispatch({ type: "changeSortType", payload: payload });
  },
  changePaginationQuery: (page, limit) =>
    dispatch({ type: "changePaginationQuery", page: page, limit: limit }),
  changeSortingQuery: (sortColumn, sortType) => {
    if (sortType === "descend") {
      sortType = "desc";
    } else if (sortType === "ascend") {
      sortType = "asc";
    } else {
      sortType = "none";
    }
    return dispatch({
      type: "changeSortingQuery",
      sortColumn: sortColumn,
      sortType: sortType,
    });
  },
  changeQuery: (newQuery) => {
    dispatch({ type: "changeQuery", payload: newQuery });
  },
  resetQuery: (customQuery) => {
    dispatch({ type: "reset", customQuery: customQuery });
  },
});

/**
 * Hook for query filter reducer
 * @returns queryFilters
 * @returns actionsQueryFilters
 * @returns onPaginationChange
 * @returns onSortChange
 * @returns resetQueryFilters
 * @returns searchValue
 * @returns setSearchvalue
 */
export const useQueryFilterReducer = (dynamicInitialQuery = undefined) => {
  const [queryFilters, dispatchQueryFilters] = useReducer(
    queryFiltersReducer,
    dynamicInitialQuery ? dynamicInitialQuery : initialQueryFilters
  );
  const actionsQueryFilters = mapDispatch(dispatchQueryFilters);

  const [columnFilterTags, setColumnFilterTags] = useState([]);

  /**
   *
   * search_value query
   *
   */
  const onSearchValueChange = (payload) => {
    actionsQueryFilters.changeSearchValue(payload);
  };

  /**
   *
   * On every pagination change, update the query filter state to trigger the use effect hook with query filter dependency
   * It will re-trigger fetch function with the updated pagination filters
   *
   */
  const onPaginationChange = (page, limit) => {
    actionsQueryFilters.changePaginationQuery(page, limit);
  };

  /**
   *
   * On every sorting change, update the query filter state to trigger the use effect hook with query filter dependency
   * It will re-trigger fetch function with the updated sorting filters
   *
   */
  const onSortChange = (sortCol, sortType) => {
    actionsQueryFilters.changeSortingQuery(sortCol, sortType);
  };

  const resetQueryFilters = () => {
    actionsQueryFilters.resetQuery(dynamicInitialQuery);
  };

  ///////////////////////////////
  // for column filter tags

  // when a single tag of column filter close button is clicked
  const onCloseColumnFilterTag = (id) => {
    setColumnFilterTags((prevState) => {
      return prevState.filter((item) => item.id !== id);
    });

    let newData = queryFilters.columnFilter.filter((item) => {
      return Array.isArray(item.id)
        ? JSON.stringify(item.id) !== id
        : item.id !== id;
    });

    actionsQueryFilters.changeColumnFilter(newData);
  };

  // loads tags for column filter
  const onLoadColumnFilterTags = () => {
    if (queryFilters.columnFilter.length) {
      let filterTags = queryFilters.columnFilter.map((e) => {
        let [label, tagId] = Array(2).fill(e.id);

        if (Array.isArray(e.id)) {
          tagId = JSON.stringify(tagId);
          label = "";

          for (const wordLabel of e.id) {
            label += `${wordLabel} `;
          }
        }

        return {
          color: "processing",
          closable: true,
          selection_label: label,
          value: e.value,
          id: tagId,
          onClose: onCloseColumnFilterTag,
        };
      });

      setColumnFilterTags(filterTags);
    } else {
      // if columnFilter is just blank, then remove the tags
      setColumnFilterTags([]);
    }
  };

  const onClearColumnFilters = () => {
    setColumnFilterTags([]);

    actionsQueryFilters.changeColumnFilter([]);
  };

  return {
    queryFilters,
    actionsQueryFilters,
    onSearchValueChange,
    onPaginationChange,
    onSortChange,
    resetQueryFilters,
    columnFilterTags,
    onClearColumnFilters,
    onLoadColumnFilterTags,
  };
};
