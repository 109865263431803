import React from "react";
import { Space, Button, Input } from "antd";
import useIsMobile from "../hooks/useIsMobile";

const { Search } = Input;
export const ModulesFunctionalitiesComponent = (props) => {
  const { functionalitiesComponent, alignment, spaceSize } = props;
  const isMobile = useIsMobile();
  return (
    <div style={{ textAlign: alignment, marginBottom: "2%" }}>
      <Space size={spaceSize} direction={isMobile ? "vertical" : "horizontal"}>
        {functionalitiesComponent.map((item, index) =>
          item.component_type === "button" ? (
            <Button
              key={index}
              type={item.type}
              icon={item.icon}
              onClick={item._onClick}
              hidden={item.is_hidden}
              size={item.size}
              block={isMobile ? true : false}
              danger={item.danger ? item.danger : false}
              disabled={item.disabled ? item.disabled : false}
            >
              {item.label}
            </Button>
          ) : item.component_type === "search_input" ? (
            <Search
              key={index}
              onSearch={item._onSearch}
              placeholder={item.place_holder}
              enterButton
            />
          ) : null
        )}
      </Space>
    </div>
  );
};
