import React, { Fragment } from "react";
import {
  Table,
  Input,
  Button,
  Tabs,
  Tag,
  Switch,
  Radio,
  Space,
  Typography,
} from "antd";
import useColumnFilter from "../../../hooks/useColumnFilter";
import useGetAgentMetaFieldColumns from "../../../hooks/agent_meta_field/useGetAgentMetaFieldColumns";

const { Column } = Table;
const { TabPane } = Tabs;
const { Title } = Typography;

export const UserListComponent = (props) => {
  const {
    selectedRowKeys,
    selectedRows,
    onSelectRowChange,
    data,
    statusOnChange,
    statusDepositedOnChange,
    loading,
    rolesRestrictions,
    isFiltered,
    filteredData,
    onPaginationChange,
    onSortChange,
    currentPagination = 1,
    columnFilter,
    setColumnFilter,
  } = props;

  const { UPDATE_DEPOSIT_STATUS, UPDATE_STATUS } = rolesRestrictions;

  const { getColumnFilterProps, getColumnBooleanFilter } = useColumnFilter(
    columnFilter,
    setColumnFilter
  );

  const agentMetaFieldsColumns = useGetAgentMetaFieldColumns();

  return (
    <div style={{ marginTop: "2%" }}>
      <Table
        dataSource={isFiltered ? filteredData.data : data.data}
        loading={loading}
        size="small"
        bordered={true}
        scroll={{ x: 1500 }}
        rowSelection={{
          type: "radio",
          selectedRows: selectedRows,
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectRowChange,
        }}
        onChange={(pagination, filters, sorter, action) => {
          if (action.action === "sort") {
            onSortChange(sorter.field, sorter.order);
          }
        }}
        pagination={{
          total: isFiltered ? filteredData.total : data.total,
          onChange: onPaginationChange,
          defaultCurrent: 1,
          current: currentPagination,
        }}
        expandable={{
          expandedRowRender: (row) => (
            <>
              <Fragment>
                <br />
                <div style={{ padding: "0% 2% 0% 2%" }}>
                  <Tabs key="roles" tabPosition={"top"}>
                    <Fragment>
                      <TabPane
                        tabKey={1}
                        tab={row.user_attached_roles.roles.name}
                        className="table-user-tabpane-expendable"
                      >
                        <Table
                          size="small"
                          bordered={true}
                          dataSource={
                            row.user_attached_roles.is_default === 1
                              ? row.user_attached_roles.default_permissions
                              : row.user_attached_roles.custom_permissions
                          }
                        >
                          <Column
                            title="Permission name"
                            dataIndex={
                              row.user_attached_roles.is_default === 1
                                ? ["permission_index", "name"]
                                : ["permissions", "permission_index", "name"]
                            }
                            key={["permissions", "permission_index", "name"]}
                          />
                          <Column
                            title="Description"
                            dataIndex={
                              row.user_attached_roles.is_default === 1
                                ? ["permission_index", "description"]
                                : [
                                    "permissions",
                                    "permission_index",
                                    "description",
                                  ]
                            }
                            key={["permissions", "permission_index", "name"]}
                          />
                          <Column
                            title="Access Type"
                            dataIndex={"has_access"}
                            key={"has_access"}
                            render={(status) => (
                              <Tag color={status > 0 ? "green" : "red"}>
                                {status > 0 ? "ENABLED" : "DISABLED"}
                              </Tag>
                            )}
                          />
                          <Column
                            title="Module Name"
                            dataIndex={
                              row.user_attached_roles.is_default === 1
                                ? ["permission_index", "modules", "name"]
                                : [
                                    "permissions",
                                    "permission_index",
                                    "modules",
                                    "name",
                                  ]
                            }
                            key={
                              ("permissions",
                              "permission_index",
                              "modules",
                              "name")
                            }
                          />
                        </Table>
                      </TabPane>
                    </Fragment>
                  </Tabs>
                </div>
              </Fragment>

              {row["user_attached_roles,roles,name"] == "Agent" ||
              row["user_attached_roles,roles,name"] == "agent" ? (
                <div style={{ padding: "2% 20% 2% 2%" }}>
                  <Title level={4}>Agent Meta Fields</Title>
                  <Table
                    size="small"
                    bordered={true}
                    dataSource={row.agent_meta_field}
                    columns={agentMetaFieldsColumns || []}
                  />
                </div>
              ) : (
                ""
              )}
            </>
          ),
          rowExpandable: (row) => row.user_attached_roles,
        }}
      >
        <Column
          title="ID"
          dataIndex="users_id"
          key="users_id"
          sorter={true}
          {...getColumnFilterProps("users_id")}
        />

        <Column
          title="First Name"
          dataIndex="users_first_name"
          key="users_first_name"
          sorter={true}
          render={(users_first_name, record) => (
            <a
              href={`${window.location.origin}/user/edit?users_email=${record.users_email}`}
              target="_blank"
              rel="noreferrer"
            >
              {users_first_name}
            </a>
          )}
          {...getColumnFilterProps("users_first_name")}
        />
        <Column
          title="Middle Name"
          dataIndex="users_middle_name"
          key="users_middle_name"
          sorter={true}
          {...getColumnFilterProps("users_middle_name")}
        />

        <Column
          title="Last Name"
          dataIndex="users_last_name"
          key="users_last_name"
          sorter={true}
          {...getColumnFilterProps("users_last_name")}
        />

        <Column
          title="User Username"
          dataIndex="users_username"
          key="users_username"
          sorter={true}
          {...getColumnFilterProps("users_username")}
        />

        <Column
          title="Email"
          dataIndex="users_email"
          key="users_email"
          sorter={true}
          {...getColumnFilterProps("users_email")}
        />
        <Column
          title="Mobile Number"
          dataIndex="users_mobile"
          key="users_mobile"
          sorter={true}
          {...getColumnFilterProps("users_mobile")}
        />
        <Column
          title="Role"
          dataIndex={["user_attached_roles", "roles", "name"]}
          key={["user_attached_roles", "roles", "name"]}
          sorter={true}
          {...getColumnFilterProps(["user_attached_roles", "roles", "name"])}
        />
        <Column
          title="User Referrer"
          dataIndex="users_referrer"
          key="users_referrer"
          sorter={true}
          {...getColumnFilterProps("users_referrer")}
        />

        <Column
          title="Address"
          dataIndex="users_address"
          key="users_address"
          sorter={true}
          {...getColumnFilterProps("users_address")}
        />

        <Column
          title="Status"
          dataIndex="users_status"
          key="users_status"
          sorter={true}
          render={(users_status, record) => (
            <Switch
              disabled={UPDATE_STATUS === 1 ? false : true}
              className="user-status-switch"
              onChange={(switchValue) =>
                statusOnChange(users_status, record, switchValue)
              }
              checkedChildren="Yes"
              unCheckedChildren="No"
              defaultChecked={parseInt(users_status) === 1 ? true : false}
            />
          )}
          {...getColumnBooleanFilter("users_status")}
        />
        <Column
          title="Date Created"
          dataIndex="created_at"
          key="created_at"
          sorter={true}
          {...getColumnFilterProps("created_at")}
        />
      </Table>
    </div>
  );
};
