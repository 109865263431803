import React from "react";
import { ModulesFunctionalitiesComponent } from "../../ModulesFunctionalitiesComponent";
import { DeleteOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";

export const ModulesFunctionalityComponent = ({
  onClickCreateModuleButton,
  onClickUpdateModuleButton,
  onClickDeleteModuleButton,
  onClickCreateBulkModuleButton,
  selectedRowKey,
}) => {
  const functionalitiesComponent = [
    {
      component_type: "button",
      type: "primary",
      icon: <EditOutlined />,
      _onClick: onClickCreateModuleButton,
      label: "Create Module",
      size: "default",
    },
    {
      component_type: "button",
      type: "primary",
      icon: <EditOutlined />,
      _onClick: onClickCreateBulkModuleButton,
      label: "Create Bulk Module",
      size: "default",
    },
    {
      component_type: "button",
      type: "default",
      icon: <SaveOutlined />,
      _onClick: onClickUpdateModuleButton,
      label: "Update Module",
      size: "default",
      is_hidden: selectedRowKey ? false : true,
    },
    {
      component_type: "button",
      type: "default",
      icon: <DeleteOutlined />,
      _onClick: onClickDeleteModuleButton,
      label: "Delete Module",
      size: "default",
      is_hidden: selectedRowKey ? false : true,
    },
  ];

  return (
    <>
      <ModulesFunctionalitiesComponent
        functionalitiesComponent={functionalitiesComponent}
        alignment="right"
        spaceSize="small"
      />
    </>
  );
};
