import React from "react";
import { ModulesFunctionalitiesComponent } from "../../ModulesFunctionalitiesComponent";
import { EditOutlined, SaveOutlined } from "@ant-design/icons";

export const CustomFieldsFunctionalityComponent = (props) => {
  const {
    onClickCreateCustomFieldButton,
    onClickUpdateCustomFieldButton,
    onClickArchiveCustomFieldButton,
    selectedRowKey,
    selectedRow,
    rolesRestriction,
  } = props;

  const { CREATE_CUSTOM_FIELD, UPDATE_CUSTOM_FIELD, ARCHIVE_CUSTOM_FIELD } =
    rolesRestriction;

  const isDeleteButtonDisabled = () => {
    if (!selectedRow) {
      return true;
    }

    return selectedRow.is_active === null
      ? false
      : ARCHIVE_CUSTOM_FIELD === 0
      ? true
      : !selectedRow.is_active;
  };

  const functionalitiesComponent = [
    {
      component_type: "button",
      type: "primary",
      icon: <EditOutlined />,
      _onClick: onClickCreateCustomFieldButton,
      label: "Create Custom Field",
      size: "default",
      is_hidden: CREATE_CUSTOM_FIELD === 0 ? true : false,
    },
    {
      component_type: "button",
      type: "default",
      icon: <SaveOutlined />,
      _onClick: onClickUpdateCustomFieldButton,
      label: "Update Custom Field",
      size: "default",
      is_hidden: selectedRowKey
        ? UPDATE_CUSTOM_FIELD === 0
          ? true
          : false
        : true,
    },
  ];

  return (
    <>
      <ModulesFunctionalitiesComponent
        functionalitiesComponent={[
          ...functionalitiesComponent,
          {
            component_type: "button",
            type: "default",
            icon: <SaveOutlined />,
            _onClick: onClickArchiveCustomFieldButton,
            label: "Archive Custom Field",
            size: "default",
            is_hidden: selectedRowKey ? isDeleteButtonDisabled() : true,
            danger: true,
          },
        ]}
        alignment="right"
        spaceSize="small"
      />
    </>
  );
};
