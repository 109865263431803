import React, { useState } from "react";
import { Modal, Form, Button, message, Steps, Divider } from "antd";
import { RightOutlined, LeftOutlined, SaveOutlined } from "@ant-design/icons";
import { useCreate } from "../../../hooks/useCreate";
import { AssetGenralInformationFormComponent } from "./AssetGenralInformationFormComponent";
import { AssetsCustomFieldInformation } from "./AssetsCustomFieldInformation";
import { AssetAddressInformationComponent } from "./AssetAddressInformationComponent";
import { CreateAsset } from "../../../api/assets/CreateAsset";

const { Step } = Steps;
export const AssetCreationComponent = (props) => {
  const [currentStep, setCurrentStep] = useState(1);

  const {
    createModalVisiblity,
    handleCreateModalVisibility,
    GetAssetsList,
    partners = { data: [], total: 0 },
    agents = { data: [], total: 0 },
    cities = { data: [], total: 0 },
    developers = { data: [], total: 0 },
    projects = { data: [], total: 0 },
    onClickShowMoreDeveloper,
    onClickShowMoreProject,
    onClickShowMorePartner,
    onClickShowMoreAgent,
    onClickShowMoreCity,
  } = props;
  const [form] = Form.useForm();

  const HandleModaOkClicked = async () => {
    const {
      asset_code,
      agent_id,
      street,
      subdivision,
      barangay_id,
      city_id,
      province_id,
      developer_id,
      project_id,
      partner_id,
      asset_status,
      longitude,
      latitude,
    } = await form.validateFields();

    const data = {
      asset_code,
      agent_id,
      street,
      subdivision,
      barangay_id,
      city_id,
      province_id,
      developer_id,
      project_id,
      partner_id,
      asset_status,
      longitude,
      latitude,
    };
    message.loading("Creating Property Information", 2);
    await CreateAsset(data);

    message.success("Successfuly Created Property");
    form.resetFields();

    handleCreateModalVisibility();
    GetAssetsList();
  };

  const stepsNavigator = (direction) => {
    setCurrentStep((prevState) => {
      return direction === "forward" ? prevState + 1 : prevState - 1;
    });
  };

  const returnModalFooterButtons = () => {
    const footerButtons = [
      <Button
        key="submit_button"
        type="default"
        size="medium"
        onClick={handleCreateModalVisibility}
        hidden={currentStep === 1 ? false : true}
        icon={<LeftOutlined />}
      >
        Cancel
      </Button>,
      <Button
        key="submit_button"
        type="default"
        size="medium"
        onClick={() => stepsNavigator("backward")}
        hidden={currentStep > 1 ? false : true}
        icon={<LeftOutlined />}
      >
        Back
      </Button>,
      <Button
        key="submit_button"
        type="default"
        size="medium"
        onClick={() => stepsNavigator("forward")}
        hidden={currentStep !== 3 || currentStep > 1 ? false : true}
        icon={<RightOutlined />}
      >
        Next
      </Button>,
      <Button
        key="submit_button"
        type="primary"
        size="medium"
        onClick={HandleModaOkClicked}
        icon={<SaveOutlined />}
      >
        Submit
      </Button>,
    ];

    return footerButtons;
  };

  return (
    <>
      <Modal
        visible={createModalVisiblity}
        title="Create New Property"
        okText="Create"
        cancelText="Cancel"
        onCancel={handleCreateModalVisibility}
        centered={true}
        width={700}
        footer={returnModalFooterButtons()}
        destroyOnClose={true}
      >
        <div style={{ padding: "4%" }}>
          <Steps size="small" current={currentStep - 1}>
            <Step title="General Information" />
            <Step title="Address Information" />
            <Step title="Custom Fields" />
          </Steps>
          <Divider />
        </div>
        <Form form={form} layout="vertical" name="assets">
          <div hidden={currentStep === 1 ? false : true}>
            <AssetGenralInformationFormComponent
              partners={partners}
              agents={agents}
              developers={developers}
              projects={projects}
              onClickShowMorePartner={onClickShowMorePartner}
              onClickShowMoreAgent={onClickShowMoreAgent}
              onClickShowMoreDeveloper={onClickShowMoreDeveloper}
              onClickShowMoreProject={onClickShowMoreProject}
            />
          </div>
          <div hidden={currentStep === 2 ? false : true}>
            <AssetAddressInformationComponent
              cities={cities}
              onClickShowMoreCity={onClickShowMoreCity}
            />
          </div>
          <div
            hidden={currentStep === 3 ? false : true}
            style={{ overflow: "auto", height: "600px" }}
          >
            <AssetsCustomFieldInformation />
          </div>
        </Form>
      </Modal>
    </>
  );
};
