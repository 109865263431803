import { useFetch } from "../hooks/useFetch";
import { useCreate } from "../hooks/useCreate";
import { useUpdate } from "../hooks/useUpdate";
import {
  FETCH_CITY_URL,
  CREATE_CITY_URL,
  UPDATE_CITY_URL,
} from "../api/routes/cities";

export const FetchCities = async (query) => {
  try {
    return await useFetch(FETCH_CITY_URL, query);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const CreateCity = async (payload) => {
  try {
    return await useCreate(CREATE_CITY_URL, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const UpdateCity = async (city_id, payload) => {
  try {
    return await useUpdate(`${UPDATE_CITY_URL}/${city_id}`, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
