import { useEffect, useRef, useState } from "react";
import { useComponentDidUpdate } from "./useComponentDidUpdate";

const initialTableState = { data: [], total: 0 };

export const useGeneralFetch = (isForTable, query, fetcherFunction, idKey) => {
  const [data, setData] = useState(initialTableState);
  const [loading, setLoading] = useState(true);

  // used for options select/dropdown
  const persistentData = useRef(initialTableState);
  const shouldPersist = useRef(true);
  const shouldResetPersist = useRef(false);

  const setShouldPersist = (param) => {
    shouldPersist.current = param;
  };

  const setShouldResetPersist = (param) => {
    shouldResetPersist.current = param;
  };

  const setPersistentData = (param) => {
    persistentData.current = param;
  };

  useEffect(() => {
    setLoading(true);

    (async () => {
      const mainData = await fetcherFunction(query);
      // console.log({mainData})
      const { data, ...metaData } = mainData.data;
      // console.log("MAIN DATA IS: ", mainData)
      const responseArray = mainData.data.data.map((item) => {
        item["key"] = item[idKey];
        return item;
      });

      setData(() => {
        return isForTable
          ? { ...metaData, data: responseArray }
          : responseArray;
      });

      setTimeout(() => {
        setLoading(false);
      }, 800);
    })();
  }, [query, isForTable, fetcherFunction, idKey]);

  useComponentDidUpdate(() => {
    // console.log("PDATA= " , persistentData.current)
    if (shouldPersist.current && data.data.length) {
      setShouldPersist(false);
      setPersistentData({
        ...data,
        data: [...persistentData.current.data, ...data.data],
      });
    }

    if (shouldResetPersist.current) {
      setPersistentData(data);
      setShouldResetPersist(false);
    }
  }, [data]);

  return [
    data,
    loading,
    persistentData.current,
    setShouldPersist,
    setShouldResetPersist,
  ];
};
