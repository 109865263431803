import { message } from "antd";
import {
  FetchAgentCustomFields,
  CreateAgentCustomField,
  UpdateAgentCustomField,
  FetchAgentCustomFieldByQuery,
  DeleteAgentCustomField,
} from "../../api/AgentCustomField";
import { useAPIResponse } from "../useAPIResponse";

export const useFetchAgentCustomFields = async (
  isForTable = true,
  query = { page: 1, limit: 10, filter: null }
) => {
  if (isForTable) {
    const data = await FetchAgentCustomFields(query);

    for (const item of data.data.data) {
      item["key"] = item.agent_custom_field_id;
    }

    return data.data;
  }

  const data = await FetchAgentCustomFields({
    page: 1,
    limit: null,
    filter: "",
  });

  for (const item of data.data.data) {
    item["key"] = item.agent_custom_field_id;
  }

  return data.data.data;
};

export const useCreateAgentCustomField = async (data) => {
  await message.loading("Creating Agent Custom Field..", 2);
  const response = await CreateAgentCustomField(data);

  return useAPIResponse(response);
};

export const useUpdateAgentCustomField = async (
  agent_custom_field_id,
  payload
) => {
  await message.loading("Updating Agent Custom Field..", 2);
  const response = await UpdateAgentCustomField(agent_custom_field_id, payload);

  return useAPIResponse(response);
};

export const useQueryAgentCustomField = async (query) => {
  const data = await FetchAgentCustomFieldByQuery(query);

  for (const item of data.data) {
    item["key"] = item.agent_custom_field_id;
  }

  return data.data;
};

export const useDeleteAgentCustomField = async (agent_custom_field_id) => {
  await message.loading("Deleting Agent Custom Field", 2);

  const response = await DeleteAgentCustomField(agent_custom_field_id);

  return useAPIResponse(response);
};
