import React from "react";
import { ModulesFunctionalitiesComponent } from "../../ModulesFunctionalitiesComponent";
import {
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import extractPermission from "../../../dist/functions/extractPermission";

export const PropertyDevelopersFunctionalityComponent = ({
  onClickCreatePropertyDeveloperButton,
  onClickUpdatePropertyDeveloperButton,
  onClickUpdatePropertyDevelopersPivotButton,
  onClickDeletePropertyDeveloperButton,
  selectedRowKey,
  selectedRow,
  userPermissions,
  onClickArchiveDeveloperButton,
  handleShowDeveloperSequenceModal,
  permissions = [],
}) => {
  const isDeleteButtonDisabled = () => {
    if (!selectedRow) {
      return true;
    }

    return selectedRow.partner_status === "0" ? true : false;
  };

  const isHideDeveloperCreate = extractPermission(
    permissions,
    "CREATE_PROPERTY_DEVELOPER"
  );

  const isHideDeveloperUpdate = extractPermission(
    permissions,
    "UPDATE_PROPERTY_DEVELOPER"
  );

  const isHideDeveloperDelete = extractPermission(
    permissions,
    "DELETE_PROPERTY_DEVELOPER"
  );

  const functionalitiesComponent = [
    {
      component_type: "button",
      type: "primary",
      icon: <EditOutlined />,
      _onClick: onClickCreatePropertyDeveloperButton,
      hidden: isHideDeveloperCreate,
      label: "Create Property Developer",
      size: "default",
    },
    {
      component_type: "button",
      type: "default",
      icon: <UnorderedListOutlined />,
      _onClick: handleShowDeveloperSequenceModal,
      label: "Update Developer Sequence",
      size: "default",
    },
    {
      component_type: "button",
      type: "default",
      icon: <SaveOutlined />,
      _onClick: onClickUpdatePropertyDeveloperButton,
      hidden: isHideDeveloperUpdate,
      label: "Update Property Developer",
      size: "default",
      is_hidden: selectedRowKey ? false : true,
    },
    {
      component_type: "button",
      type: "default",
      icon: <SaveOutlined />,
      _onClick: onClickUpdatePropertyDevelopersPivotButton,
      hidden: isHideDeveloperUpdate,
      label: "Update Property Developer Pivot",
      size: "default",
      is_hidden: selectedRowKey ? false : true,
    },
    {
      component_type: "button",
      type: "default",
      icon: <SaveOutlined />,
      _onClick: onClickArchiveDeveloperButton,
      label: "Archive Developer",
      size: "default",
      is_hidden: selectedRowKey ? isDeleteButtonDisabled() : true,
      danger: true,
    },
    {
      component_type: "button",
      type: "default",
      icon: <DeleteOutlined />,
      _onClick: onClickDeletePropertyDeveloperButton,
      hidden: isHideDeveloperDelete,
      label: "Delete Property Developer",
      size: "default",
      is_hidden: selectedRowKey ? false : true,
    },
  ];

  return (
    <>
      <ModulesFunctionalitiesComponent
        functionalitiesComponent={functionalitiesComponent}
        alignment="right"
        spaceSize={"small"}
      />
    </>
  );
};
