import React from "react";
import { Button, Divider, Space } from "antd";
import { PlusCircleOutlined, SyncOutlined } from "@ant-design/icons";
import extractPermission from "../../../dist/functions/extractPermission";

export const RolesAccessFunctionalitiesComponent = ({
  handleModalVisibleOnClick,
  GetRolesList,
  loading = [],
  permissions,
}) => {
  const isHideCreateRolesAccess = extractPermission(
    permissions,
    "CREATE_ROLES_ACCESS"
  );

  return (
    <div style={{ textAlign: "right" }}>
      <Space size="small" direction={"horizontal"} split={<Divider />}>
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={handleModalVisibleOnClick}
          hidden={isHideCreateRolesAccess}
        >
          Create Roles
        </Button>
        <Divider type={"vertical"} />
        <Button
          loading={loading}
          type="default"
          icon={<SyncOutlined />}
          onClick={GetRolesList}
        >
          Load Roles
        </Button>
      </Space>
    </div>
  );
};
