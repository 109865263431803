import React, { useState } from "react";
import { PageHeader, Divider, Form } from "antd";
import { PlatformWithRolesAndModulesTableComponent } from "../sub_components/platform/PlatformWithRolesAndModulesTableComponent";
import { PlatformFormComponent } from "../sub_components/platform/PlatformFormComponent";
import { PlatformFunctionalitiesComponent } from "../sub_components/platform/PlatformFunctionalitiesComponent";
import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import "../../dist/style/platform_component.css";
import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";
import { ModulesSelectAndColumnTagsComponent } from "../ModulesSelectAndColumnTagsComponent";
import {
  useCreatePlatform,
  useFetchPlatforms,
} from "../../hooks/platforms/usePlatformAPI";
import { useQueryFilterReducer } from "../../hooks/useQueryFilterReducer";

// const initialRolesRestriction = {
//   CREATE_PLATFORM: 0,
// };

// const permissionSet = ["CREATE_PLATFORM"];

export const PlatformComponent = ({ permissions }) => {
  const [form] = Form.useForm();
  const [isModalVisible, setModalVisibility] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  // const rolesRestriction = useFetchPermissionSets(
  //   permissionSet,
  //   initialRolesRestriction
  // );

  const {
    queryFilters,
    actionsQueryFilters,
    onPaginationChange,
    onSortChange,
    columnFilterTags,
    onClearColumnFilters,
    onLoadColumnFilterTags,
    resetQueryFilters,
  } = useQueryFilterReducer();

  const [platforms, isLoadingPlatforms] = useFetchPlatforms(true, queryFilters);

  useComponentDidUpdate(() => {
    onLoadColumnFilterTags();
  }, [queryFilters]);

  const OnFinishFailed = (error) => {};

  const createPlatformOnClick = () => {
    setModalVisibility(true);
  };

  const onCancel = () => {
    setModalVisibility(false);
  };

  const CreatePlatform = async () => {
    setIsConfirmLoading(true);
    const payload = await form.validateFields();
    const isSuccess = await useCreatePlatform(payload);
    if (isSuccess) {
      form.resetFields();
      resetQueryFilters();
      setModalVisibility(!isModalVisible);
      setIsConfirmLoading(false);
    }
  };

  return (
    <>
      <div>
        <PageHeader
          className="site-page-header"
          title="PLATFORMS"
          subTitle="PLATFORMS MANAGEMENT MODULE"
          breadcrumbRender={() => (
            <BreadCrumbsComponent
              breadcrumbsItem={["Administration", "Platforms"]}
            />
          )}
        />
        <div className="content-pane-transaction-div-style">
          <Divider />
          <PlatformFunctionalitiesComponent
            createPlatformOnClick={createPlatformOnClick}
            permissions={permissions}
          />
          <ModulesSelectAndColumnTagsComponent
            onClearColumnFilters={onClearColumnFilters}
            isShowColumnFilterTags={columnFilterTags.length}
            columnFilterTags={columnFilterTags}
          />
          <PlatformWithRolesAndModulesTableComponent
            data={platforms}
            tableLoading={isLoadingPlatforms}
            onPaginationChange={onPaginationChange}
            currentPagination={queryFilters.page}
            setColumnFilter={actionsQueryFilters.changeColumnFilter}
            columnFilter={queryFilters.columnFilter}
            onSortChange={onSortChange}
          />
        </div>
        <PlatformFormComponent
          onOk={CreatePlatform}
          form={form}
          isConfirmLoading={isConfirmLoading}
          isModalVisible={isModalVisible}
          OnFinishFailed={OnFinishFailed}
          onCancel={onCancel}
        />
      </div>
    </>
  );
};
