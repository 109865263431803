import { useFetch } from "../../hooks/useFetch";

export const FetchPartnerList = async (query) => {
  try {
    const data = await useFetch(
      `${process.env.REACT_APP_API_URL}/partners`,
      query
    );

    return data.data;
  } catch (err) {
    return null;
  }
};
