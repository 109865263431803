export const FormRules = {
  REQUIRED_CONTACT: {
    required: true,
    message: "Please input your Contact!",
  },
  CONTACT_CHARACTER_COUNT: {
    min: 9,
    message: "Contact Number must be minimum of 9 characters.",
  },
  NUMBER_FORMAT_ONLY: {
    pattern: new RegExp(/^\d+$/),
    message: "This field is acccepting only a number",
  },
  REQUIRED_LANDLINE: {
    required: true,
    message: "Please input your Landline!",
  },
  LANDLINE_CHARACTER_COUNT: {
    min: 7,
    message: "Landline must be minimum of 7 characters.",
  },
  PARTNERS_LANDLINE_CHARACTER_COUNT: {
    min: 8,
    message: "Landline must be minimum of 8 characters.",
  },
  EMAIL_FORMAT: {
    type: "email",
    message: "The input is not valid E mail Address!",
  },
  REQUIRED_EMAIL: {
    required: true,
    message: "Please input your E-mail!",
  },
  REQUIRED_ADDRESS: {
    required: true,
    message: "Please Fillup Address Information",
  },
  REQUIRED_FIRSTNAME: {
    required: true,
    message: "Please input firstname",
  },
  REQUIRED_MIDDLENAME: {
    required: true,
    message: "Please input middlename",
  },
  REQUIRED_LASTNAME: {
    required: true,
    message: "Please input lastname",
  },
  REQUIRED_GENDER: {
    required: true,
    message: "Please select gender",
  },
  REQUIRED_USERNAME: {
    required: true,
    message: "Please input username",
  },
  REQUIRED_PASSWORD: {
    required: true,
    message: "Please input password",
  },
  REQUIRED_USER_TYPE: {
    required: true,
    message: "Please select User Type",
  },
  REQUIRED_USER_ATTACHED_ROLES: {
    required: false,
    message:
      "Please select atleast 1 role to proceed, when Admin is Selected as User Type",
  },
  REQUIRED_FUEL_TYPE: {
    required: true,
    message: "Please input Fuel Type",
  },
  REQUIRED_MAKE_ID: {
    required: true,
    message: "Please select Vehicle Brand",
  },
  REQUIRED_TRANSMISSION_TYPE: {
    required: true,
    message: "Please select Transmission Type",
  },
  REQUIRED_VEHICLE_TYPE: {
    required: true,
    message: "Please select Vehicle Type",
  },
  REQUIRED_MODEL_NAME: {
    required: true,
    message: "Please input Model Name",
  },
  REQUIRED_GENERAL_NAME: {
    required: true,
    message: "Please input General Name",
  },
  REQUIRED_SPECIFIC_NAME: {
    required: true,
    message: "Please input Specific Name",
  },
  REQUIRED_CANCELLATION_REASON: {
    required: true,
    message: "Please input reason for cancellation",
  },
  REQUIRED_TITLE: {
    required: true,
    message: "Please input title",
  },
  REQUIRED_SUB_TITLE: {
    required: true,
    message: "Please input sub title",
  },
  REQUIRED_SLUG: {
    required: true,
    message: "Please input slug",
  },
  REQUIRED_STATUS: {
    required: true,
    message: "Please select status",
  },
  REQUIRED_FULL_ADDRESS: {
    required: true,
    message: "Pleae input the full Address",
  },
  REQUIRED_LOCATION: {
    required: true,
    message: "Pleae input the Location",
  },
  REQUIRED_LANDMARK: {
    required: true,
    message: "Pleae input the Nearest Landmark",
  },
  REQUIRED_COUNTRY: {
    required: true,
    message: "Pleae Select Country",
  },
  REQUIRED_BANK: {
    required: true,
    message: "Pleae Select Bank",
  },
  REQUIRED_ADVISERS: {
    required: true,
    message: "Please select advisers!",
    type: "array",
  },
  REQUIRED_CITY: {
    required: true,
    message: "Please select city",
  },
  REQUIRED_FULL_NAME: {
    required: true,
    message: "Please Input Full Name",
  },
  REQUIRED_RATINGS: {
    required: true,
    message: "Please Input Ratings",
  },
  REQUIRED_REVIEW_DATE: {
    required: true,
    message: "Please Input Review Date",
  },
  REQUIRED_COMMENT: {
    required: true,
    message: "Please Input Comment",
  },
  REQUIRED_CHANNEL: {
    required: true,
    message: "Please Input Comment",
  },
  REQUIRED_REVIEW_TYPE: {
    required: true,
    message: "Please Review Type",
  },
  REQUIRED_ASSET_CUSTOM_FIELD_NAME: {
    required: true,
    message: "Please input custom field name",
  },
  REQUIRED_ASSET_CUSTOM_FIELD_LABEL: {
    required: true,
    message: "Please input custom field label",
  },
  REQUIRED_ASSET_CUSTOM_FIELD_TYPE: {
    required: true,
    message: "Please choose custom field type",
    REQUIRED_PARTNER_ID: {
      required: true,
      message: "Partner ID is required",
    },
    REQUIRED_STARTED_AT: {
      required: true,
      message: "Started At is required",
    },
    REQUIRED_ENDED_AT: {
      required: true,
      message: "Ended At is required",
    },
    REQUIRED_CLOSED_AT: {
      required: true,
      message: "Closed At is required",
    },
    REQUIRED_CLOSED_TYPE: {
      required: true,
      message: "Closed Type is required",
    },
    REQUIRED_CLOSED_BY: {
      required: true,
      message: "Closed By is required",
    },
    REQUIRED_LISTING_ID: {
      required: true,
      message: "Listing ID is required",
    },
    REQUIRED_ASSET_ID: {
      required: true,
      message: "ASSET ID is required",
    },
    REQUIRED_LISTING_METHOD: {
      required: true,
      message: "LISTING METHOD is required",
    },
    REQUIRED_ASSET_LISTING_PRICE: {
      required: true,
      message: "ASSET LISTING PRICE is required",
    },
    REQUIRED_ASSET_LISTING_PRICE_SQM: {
      required: true,
      message: "ASSET LISTING PRICE SQM is required",
    },

    REQUIRED_ASSET_CODE: {
      required: true,
      message: "ASSET CODE is required",
    },
    REQUIRED_AGENT_ID: {
      required: true,
      message: "AGENT ID is required",
    },
    REQUIRED_STREET: {
      required: true,
      message: "STREET is required",
    },
    REQUIRED_SUBDIVISION: {
      required: true,
      message: "SUBDIVISION is required",
    },
    REQUIRED_BARANGAY: {
      required: true,
      message: "BARANGAY is required",
    },

    REQUIRED_CITY_ID: {
      required: true,
      message: "CITY ID is required",
    },
    REQUIRED_PROVINCE_ID: {
      required: true,
      message: "PROVINCE ID is required",
    },
    REQUIRED_ASSET_STATUS: {
      required: true,
      message: "ASSET STATUS is required",
    },
    REQUIRED_LONGITUDE: {
      required: true,
      message: "LONGITUDE is required",
    },
    REQUIRED_LATITUDE: {
      required: true,
      message: "LATITUDE is required",
    },
  },
  REQUIRED_PROPERTY_CATEGORY_NAME: {
    required: true,
    message: "Required Property Category Name",
  },
  REQUIRED_PROPERTY_CATEGORY_TYPE: {
    required: true,
    message: "Required Property Category Type",
  },
  REQUIRED_PROPERTY_CATEGORY_LABEL: {
    required: true,
    message: "Required Property Category Label",
  },
  REQUIRED_PROPERTY_CATEGORY_STATUS: {
    required: true,
    message: "Required Property Category Status",
  },
  REQUIRED_PARTNER_NAME: {
    required: true,
    message: "Required Partner Name",
  },
  REQUIRED_PARTNER_PERSON_IN_CHARGE: {
    required: true,
    message: "Required Person In Charge",
  },
  REQUIRED_PARTNER_MOBILE: {
    required: true,
    message: "Required Mobile Number",
  },
  REQUIRED_PARTNER_LANDLINE: {
    required: true,
    message: "Required Partner Landline",
  },
  REQUIRED_PARTNER_EMAIL: {
    required: true,
    message: "Required Partner Email",
  },
  REQUIRED_PARTNER_TYPE: {
    required: true,
    message: "Required Partner Type",
  },
  REQUIRED_OPTION_KEY: {
    required: true,
    message: "Required Option Key",
  },
  REQUIRED_OPTION_VALUE: {
    required: true,
    message: "Required Option Value",
  },
  REQUIRED_OPTION_GROUP: {
    required: true,
    message: "Required Option Group",
  },
  REQUIRED_PROPERTY_CLASSIFICATION: {
    required: true,
    message: "Required Property Classification",
  },
  REQUIRED_AGENT_ID: {
    required: true,
    message: "Required Agent",
  },
  REQUIRED_PARTNER_ID: {
    required: true,
    message: "Required Partner",
  },
  REQUIRED_ASSET_STATUS: {
    required: true,
    message: "Required Asset Status",
  },
  REQUIRED_PROVINCE_ID: {
    required: true,
    message: "Required Province",
  },
  REQUIRED_CITY_ID: {
    required: true,
    message: "Required City",
  },
  REQUIRED_BARANGAY_ID: {
    required: true,
    message: "Required Barangay",
  },
  REQUIRED_LISTING_STATUS: {
    required: true,
    message: "Required Listing Status",
  },
  REQUIRED_LISTING_ENDED_AT: {
    required: true,
    message: "Required Listing Ended At",
  },
  REQUIRED_LISTING_CLOSED_AT: {
    required: true,
    message: "Required Listing Closed At",
  },
  REQUIRED_LISTING_STARTED_AT: {
    required: true,
    message: "Required Listing Started At",
  },
  REQUIRED_ASSET: {
    required: true,
    message: "Required Asset",
  },
  REQUIRED_LISTING_METHOD: {
    required: true,
    message: "Required Listing Method",
  },
  REQUIRED_ASSET_LISTING_PRICE: {
    required: true,
    message: "Required Asset Listing Price",
  },
  REQUIRED_ASSET_LISTING_ASSET_ID: {
    required: true,
    message: "Required Asset",
  },
  REQUIRED_PROJECT_NAME: {
    required: true,
    message: "Required Project Name",
  },
};
