import { useFetch } from "../hooks/useFetch";
import { useCreate } from "../hooks/useCreate";
import { useUpdate } from "../hooks/useUpdate";
import {
  FETCH_PROPERTY_PROJECT_URL,
  CREATE_PROPERTY_PROJECT_URL,
  UPDATE_PROPERTY_PROJECT_URL,
  DELETE_PROPERTY_PROJECT_URL,
  UPDATE_PROPERTY_PROJECTS_PIVOT_URL,
  UPDATE_PROPERTY_PROJECTS_TAGS_URL,
  UPLOAD_PROPERTY_PROJECT_IMAGE_URL,
} from "../api/routes/property_projects";
import { useDelete } from "../hooks/useDelete";

export const FetchPropertyProjects = async (query) => {
  try {
    return await useFetch(FETCH_PROPERTY_PROJECT_URL, query);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const CreatePropertyProject = async (payload) => {
  try {
    return await useCreate(CREATE_PROPERTY_PROJECT_URL, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const UpdatePropertyProject = async (property_projects_id, payload) => {
  try {
    return await useUpdate(
      `${UPDATE_PROPERTY_PROJECT_URL}/${property_projects_id}`,
      payload
    );
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const DeletePropertyProject = async (property_projects_id) => {
  try {
    return await useDelete(
      `${DELETE_PROPERTY_PROJECT_URL}/${property_projects_id}`
    );
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const UpdatePropertyProjectPivot = async (
  property_projects_id,
  payload
) => {
  try {
    const data = await useUpdate(
      `${UPDATE_PROPERTY_PROJECTS_PIVOT_URL}/${property_projects_id}`,
      payload
    );
    return data;
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const UpdatePropertyProjecTags = async (
  property_projects_id,
  payload
) => {
  try {
    const data = await useUpdate(
      `${UPDATE_PROPERTY_PROJECTS_TAGS_URL}/${property_projects_id}`,
      payload
    );
    return data;
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const UploadProjectImages = async (payload) => {
  try {
    const data = await useCreate(
      `${UPLOAD_PROPERTY_PROJECT_IMAGE_URL}`,
      payload
    );
    return data;
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
