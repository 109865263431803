import React from "react";
import { ModuleBulkCreationFormModalComponent } from "./ModuleBulkCreationFormModalComponent";

import {
  ModuleFormModalComponent,
  ModuleUpdateFormModalComponent,
} from "./ModuleFormModalComponent";

export const ModuleFormsModalFunctionalityComponent = (props) => {
  const {
    onSubmitModuleCreation,
    onSubmitBulkModuleCreation,
    onCancelBulkModuleUpdate,
    bulkModuleCreationForm,
    onCancelModuleCreation,
    moduleModalVisibility,
    moduleCreationForm,
    bulkModuleModalVisibility,
    onSubmitModuleUpdate,
    onCancelModuleUpdate,
    moduleUpdatedModalVisibility,
    moduleUpdateForm,
    platforms,
  } = props;

  return (
    <>
      <ModuleBulkCreationFormModalComponent
        form={bulkModuleCreationForm}
        isVisible={bulkModuleModalVisibility}
        onClickSubmit={onSubmitBulkModuleCreation}
        onClickCancel={onCancelBulkModuleUpdate}
        platforms={platforms}
      />
      <ModuleFormModalComponent
        form={moduleUpdateForm}
        isVisible={moduleUpdatedModalVisibility}
        onClickSubmit={onSubmitModuleUpdate}
        onClickCancel={onCancelModuleUpdate}
        platforms={platforms}
        isUpdate={true}
      />
      <ModuleFormModalComponent
        form={moduleCreationForm}
        isVisible={moduleModalVisibility}
        onClickSubmit={onSubmitModuleCreation}
        onClickCancel={onCancelModuleCreation}
        platforms={platforms}
      />
    </>
  );
};
