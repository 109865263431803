import React from "react";
import { ModulesTableComponent } from "../../ModulesTableComponent";
import useColumnFilter from "../../../hooks/useColumnFilter";
import useGetAssetInquiriesColumns from "../../../hooks/asset_inquiries/useGetAssetInquiriesColumns";
import useGetAssetMetaFieldColumns from "../../../hooks/asset_meta_field/useGetAssetMetaFieldColumns";
import GetAssetsChildColumns from "../../../hooks/asset_inquiries/GetAssetsChildColumns";
import { AssetInquiriesListDynamicComponent } from "./AssetInquiriesListDynamicComponent";
import { Table, Typography, Empty, Divider } from "antd";
import useFilteredColumns from "../../../hooks/useFilteredColumns";

const { Title } = Typography;

export const AssetInquiriesListComponent = (props) => {
  const {
    data = { data: [], total: 0 },
    onPaginationChange,
    currentPagination,
    onSelection,
    selectedRowKey,
    onSortChange,
    columnFilter,
    setColumnFilter,
    tableLoading,
  } = props;

  const { getColumnFilterProps } = useColumnFilter(
    columnFilter,
    setColumnFilter
  );

  const assetInquiriesColumns = useGetAssetInquiriesColumns();
  const assetMetaFieldsColumns = useGetAssetMetaFieldColumns();
  let newCols = useFilteredColumns(assetInquiriesColumns, getColumnFilterProps);

  return (
    <>
      <ModulesTableComponent
        expandable={{
          expandedRowRender: (record) => {
            return (
              <div style={{ padding: "3%" }}>
                <Title level={5}>Asset/Property Information(s)</Title>
                <br />
                {record.assets ? (
                  <AssetInquiriesListDynamicComponent
                    data={GetAssetsChildColumns(record.assets)}
                  />
                ) : (
                  <Empty
                    description={
                      "No Asset/Property is Included in this inquiry"
                    }
                  />
                )}
                <Divider />
                <Title level={5}>Other Information</Title>
                <br />
                {record.assets?.asset_meta_field ? (
                  <Table
                    size="small"
                    bordered={true}
                    dataSource={record.assets.asset_meta_field}
                    columns={assetMetaFieldsColumns || []}
                  />
                ) : (
                  <Empty
                    description={
                      "No Asset/Property is Included in this inquiry"
                    }
                  />
                )}
              </div>
            );
          },
        }}
        data={data}
        onPaginationChange={onPaginationChange}
        onSelection={onSelection}
        selectedRowKey={selectedRowKey}
        tableColumns={newCols || []}
        currentPagination={currentPagination}
        columnFilter={columnFilter}
        setColumnFilter={setColumnFilter}
        onSortChange={onSortChange}
        tableLoading={tableLoading}
        scrollSize={1500}
        hasSelection={false}
      />
    </>
  );
};
