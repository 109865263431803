import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Form, Input, Button, Typography, message } from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { FormRules } from "../dist/functions/formRules";
import { Login } from "../api/authentication/Login";
import AssetmartLogo from "../dist/img/assetmart_logo.png";
import AssetmartLeftBG from "../dist/img/login_side_bg_green.jpg";
import { GetSessionDetails } from "../api/Sessions";

import "../dist/style/login.css";

export const LoginComponent = () => {
  const history = useHistory();
  const { Title } = Typography;
  const [loginForm] = Form.useForm();

  useEffect(() => {
    const getCurrentSession = async () => {
      const session = await GetSessionDetails();
      if (session?.jwt) {
        history.push("/dashboard");
      }
    };

    getCurrentSession();
  }, []);

  const handleLogin = async () => {
    const values = await loginForm.validateFields();
    const response = await Login(values);
    const { status } = response;
    if (status === 401) {
      /**
       *
       * Assign default notification message, In case API doesn't return any response message
       *
       */
      var notificationMessage = "Invalid credentials provided";

      /**
       *
       * Validate the response message if there is a response message returned from API
       * - to check also if the response if parsable to JSON
       */
      if (response.response || response.response !== "") {
        const parsedResponse = JSON.parse(response.response);
        const parsedResponseBody = parsedResponse?.data || null;

        if (parsedResponseBody) {
          const { message } = parsedResponseBody;
          /**
           *
           * Set new notification message returned by the APIA
           *
           */
          notificationMessage = message;
        }
      }

      message.error(notificationMessage, 2);
    }

    if (status === 200) {
      const { data } = response.data;
      const { session_id } = data;

      if (data.status === "AUTHENTICATION_SUCCESS") {
        if (data.role.name === "User") {
          message.warning(
            "Sorry, your current account doesn't have permission to access this system",
            2
          );

          message.info("Provide the email address used when logging in", 2);
          return false;
        }

        localStorage.setItem("session_id", session_id);
        history.push("/dashboard");
      }
    }
  };

  return (
    <div className="login-container">
      <div className="login-sub-container-by-half left-container-div login-right-form-styling-container">
        <img
          src={AssetmartLogo}
          alt="assetmart_login_logo"
          className="assetmart-header-image "
        />
        <Title level={3}>Welcome Back!</Title>
        <div className="login-form-container ">
          <Form
            form={loginForm}
            name="normal_login"
            layout="vertical"
            size="middle"
          >
            <Form.Item name="email_address" rules={[FormRules.REQUIRED_EMAIL]}>
              <Input prefix={<UserOutlined />} placeholder="Email" />
            </Form.Item>
            <Form.Item name="password" rules={[FormRules.REQUIRED_PASSWORD]}>
              <Input
                prefix={<LockOutlined />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Form.Item>
              <div className="login-button-container">
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  onClick={handleLogin}
                >
                  Log in
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
      <div className="login-sub-container-by-half bg-color">
        <img
          src={AssetmartLeftBG}
          alt="assetmart_login_image_bg"
          className="bg-image"
        />
      </div>
    </div>
  );
};
