import React, { useEffect, useState } from "react";
import { Divider, PageHeader, Form, Modal } from "antd";

import { BreadCrumbsComponent } from "../BreadCrumbsComponent";
import { RegionsListComponent } from "../sub_components/regions/RegionsListComponent";
import { RegionsFunctionalityComponent } from "../sub_components/regions/RegionsFunctionalityComponent";
import { RegionsFormsModalFunctionalityComponent } from "../sub_components/regions/RegionsFormsModalFunctionalityComponent";
import {
  useCreateRegion,
  useDeleteRegion,
  useFetchRegions,
  useUpdateRegion,
} from "../../hooks/regions/useRegionAPI";

import { useQueryFilterReducer } from "../../hooks/useQueryFilterReducer";
import { ModulesSelectAndColumnTagsComponent } from "../ModulesSelectAndColumnTagsComponent";
import { useComponentDidUpdate } from "../../hooks/useComponentDidUpdate";

import { useFetchIslandGroups } from "../../hooks/island_groups/useIslandGroupAPI";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { confirm } = Modal;
const initialRegionsData = { page: 1, total: 0, data: [] };

export const RegionsComponent = () => {
  const [createRegionsForm] = Form.useForm();
  const [updateRegionsForm] = Form.useForm();
  const [regionsCreationModalStatus, setRegionsCreationModalStatus] =
    useState(false);
  const [regionsUpdateModalStatus, setRegionsUpdateModalStatus] =
    useState(false);

  const [selectedRowKey, setSelectedRowKey] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const [islandGroupsQuery, setIslandGroupsQuery] = useState({
    page: 1,
    limit: 10,
  });

  const [
    island_groups,
    isLoadingIslandGroups,
    persistentIslandGroups,
    setShouldPersistIslandGroups,
  ] = useFetchIslandGroups(true, islandGroupsQuery);

  const {
    queryFilters,
    actionsQueryFilters,
    onPaginationChange,
    onSortChange,
    columnFilterTags,
    onClearColumnFilters,
    onLoadColumnFilterTags,
    resetQueryFilters,
  } = useQueryFilterReducer();

  const [regionsData, isLoadingRegions] = useFetchRegions(true, queryFilters);

  /**
   * Functionalities
   *
   */

  const DeleteRegions = async () => {
    const isSuccess = await useDeleteRegion(selectedRowKey);
    if (isSuccess) {
      resetSelections();
      resetQueryFilters();
    }
  };

  useComponentDidUpdate(() => {
    onLoadColumnFilterTags();
  }, [queryFilters]);

  /**
   *
   * Use effect hooks if any of the data is selected
   * Selected row will populate the form for updating
   *
   */
  useEffect(() => {
    if (selectedRow && regionsUpdateModalStatus === true) {
      updateRegionsForm.setFieldsValue(selectedRow);
    }
  }, [selectedRow, regionsUpdateModalStatus, updateRegionsForm]);

  /**
   *
   * On every selection change, update the row selection state to trigger the use effect hook with row selection dependency
   * It will re-trigger fetch function with the updated row selection and populate forms for updating
   *
   */
  const onSelectionChange = (keys, selectedRows) => {
    setSelectedRow(selectedRows[0]);
    setSelectedRowKey(keys[0]);
  };

  /**
   *
   * Create Regions function with success validation, connected to useCreateRegion hook
   *
   */

  const CreateRegions = async () => {
    const payload = await createRegionsForm.validateFields();

    const isSuccess = await useCreateRegion(payload);
    if (isSuccess) {
      createRegionsForm.resetFields();
      resetQueryFilters();
      onClickCreateRegionsButton();
      resetSelections();
    }
  };

  /**
   *
   * Update Regions function with success validation, connected to useUpdateRegion hook
   *
   */

  const UpdateRegions = async () => {
    const payload = await updateRegionsForm.validateFields();

    const isSuccess = await useUpdateRegion(selectedRowKey, payload);

    if (isSuccess) {
      updateRegionsForm.resetFields();
      resetQueryFilters();
      onClickUpdateRegionsButton();
      resetSelections();
    }
  };

  const OnClickDeleteRegions = async () => {
    confirm({
      title: `Are you sure, you want to remove this regions?`,
      icon: <ExclamationCircleOutlined />,
      content: `All information attached to this regions will be removed`,
      onOk() {
        DeleteRegions();
      },
    });
  };

  const onClickShowMoreIslandGroup = async () => {
    const { current_page, last_page } = island_groups;
    if (current_page < last_page) {
      const query = { page: parseInt(current_page) + 1, limit: 10 };
      setShouldPersistIslandGroups(true);
      setIslandGroupsQuery(query);

      return;
    }
  };

  /**
   *
   * Helper functions:
   *
   * - Functions that will reset all selections, pagination and restore initial index/fetch
   *
   */

  const resetSelections = () => {
    setSelectedRowKey(null);
    setSelectedRow(null);
  };

  const onClickCreateRegionsButton = () => {
    setRegionsCreationModalStatus(!regionsCreationModalStatus);
  };

  const onClickUpdateRegionsButton = () => {
    setRegionsUpdateModalStatus(!regionsUpdateModalStatus);
  };

  return (
    <>
      <PageHeader
        title="Regions"
        subTitle="Regions Management"
        breadcrumbRender={() => (
          <BreadCrumbsComponent breadcrumbsItem={["Regions"]} />
        )}
      />
      <div className="content-pane-transaction-div-style">
        <Divider />
        <RegionsFunctionalityComponent
          onClickCreateRegionsButton={onClickCreateRegionsButton}
          onClickUpdateRegionsButton={onClickUpdateRegionsButton}
          onClickDeleteRegionsButton={OnClickDeleteRegions}
          selectedRowKey={selectedRowKey}
          selectedRow={selectedRow}
        />

        <ModulesSelectAndColumnTagsComponent
          onClearColumnFilters={onClearColumnFilters}
          isShowColumnFilterTags={columnFilterTags.length}
          columnFilterTags={columnFilterTags}
          isShowSelection={!!selectedRow}
          onClearSearcheAndSelection={resetSelections}
          tags={[
            {
              color: "processing",
              selection_label: "Selected Region Name",
              value: selectedRow ? `${selectedRow.region_name}` : null,
            },
          ]}
        />
        <RegionsListComponent
          data={regionsData}
          tableLoading={isLoadingRegions}
          onPaginationChange={onPaginationChange}
          selectedRowKey={selectedRowKey}
          onSelection={onSelectionChange}
          currentPagination={queryFilters.page}
          onSortChange={onSortChange}
          setColumnFilter={actionsQueryFilters.changeColumnFilter}
          columnFilter={queryFilters.columnFilter}
        />
      </div>
      <RegionsFormsModalFunctionalityComponent
        onSubmitRegionsCreation={CreateRegions}
        onCancelRegionsCreation={onClickCreateRegionsButton}
        regionsModalVisibility={regionsCreationModalStatus}
        regionsCreationForm={createRegionsForm}
        onSubmitRegionsUpdate={UpdateRegions}
        onCancelRegionsUpdate={onClickUpdateRegionsButton}
        regionsUpdatedModalVisibility={regionsUpdateModalStatus}
        regionsUpdateForm={updateRegionsForm}
        onClickShowMoreIslandGroup={onClickShowMoreIslandGroup}
        island_groups={persistentIslandGroups}
      />
    </>
  );
};
