import React from "react";
import { Form, Input, Select } from "antd";
import { FormRules } from "../../../dist/functions/formRules";

export const AddressAndContactStepFormComponent = ({ isHidden }) => {
  return (
    <div
      style={{
        display: isHidden ? "none" : null,
        overflowY: "auto",
        height: 500,
      }}
    >
      <Form.Item
        name="address_line_1"
        label="Address Line1"
        rules={[FormRules.REQUIRED_ADDRESS]}
        extra="Street Address"
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item
        name="address_line_2"
        label="Address Line2"
        rules={[FormRules.REQUIRED_ADDRESS]}
        extra="Apartment, suite , unit, building, floor, etc."
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item name="city" label="City" rules={[FormRules.REQUIRED_ADDRESS]}>
        <Input />
      </Form.Item>
      <Form.Item
        name="state_province_region"
        label="Province"
        rules={[FormRules.REQUIRED_ADDRESS]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="users_email"
        label="Email"
        rules={[FormRules.REQUIRED_EMAIL, FormRules.EMAIL_FORMAT]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="users_mobile"
        label="Mobile"
        rules={[
          FormRules.CONTACT_CHARACTER_COUNT,
          FormRules.NUMBER_FORMAT_ONLY,
        ]}
      >
        <Input prefix="+639" maxLength={9} />
      </Form.Item>
      {/* <Form.Item
        name="phone"
        label="Phone"
        rules={[
          FormRules.LANDLINE_CHARACTER_COUNT,
          FormRules.NUMBER_FORMAT_ONLY,
        ]}
      >
        <Input prefix={"(+632)"} maxLength={8} />
      </Form.Item> */}
    </div>
  );
};
