import React from "react";

import { PartnerFormModalComponent } from "./PartnerFormModalComponent";

export const PartnerFormsModalFunctionalityComponent = (props) => {
  const {
    onSubmitPartnerCreation,
    onCancelPartnerCreation,
    partnerModalVisibility,
    partnerCreationForm,
    onSubmitPartnerUpdate,
    onCancelPartnerUpdate,
    partnerUpdatedModalVisibility,
    partnerUpdateForm,
    partnerTypes,
    onClickShowMorePartnerTypes,
  } = props;

  return (
    <>
      <PartnerFormModalComponent
        form={partnerCreationForm}
        isVisible={partnerModalVisibility}
        onClickSubmit={onSubmitPartnerCreation}
        onClickCancel={onCancelPartnerCreation}
        partnerTypes={partnerTypes}
        onClickShowMorePartnerTypes={onClickShowMorePartnerTypes}
      />
      <PartnerFormModalComponent
        form={partnerUpdateForm}
        isVisible={partnerUpdatedModalVisibility}
        onClickSubmit={onSubmitPartnerUpdate}
        onClickCancel={onCancelPartnerUpdate}
        isUpdate={true}
        partnerTypes={partnerTypes}
        onClickShowMorePartnerTypes={onClickShowMorePartnerTypes}
      />
    </>
  );
};
