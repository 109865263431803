import React from "react";
import { Modal, Form, Select, Divider } from "antd";

import { AddNewPropertyDevelopersPivotFormComponent } from "./AddNewPropertyDevelopersPivotFormComponent";
import { ReturnModalFooterComponents } from "../modules/ReturnModalFooterComponents";

export const AddNewPropertyDevelopersPivotComponent = (props) => {
  const {
    form,
    isVisible,
    handleOk,
    handleCancel,
    loading,
    projects = [],
    onClickShowMoreProject,
    selectedRowKeys,
  } = props;

  return (
    <>
      <Modal
        visible={isVisible}
        title="Add Property Developers Pivot"
        onOk={handleOk}
        onCancel={handleCancel}
        width="60%"
        footer={
          <ReturnModalFooterComponents
            handleCancel={handleCancel}
            handleOk={handleOk}
            loading={loading}
          />
        }
        destroyOnClose={true}
      >
        <Form form={form} layout="vertical">
          <AddNewPropertyDevelopersPivotFormComponent
            projects={projects}
            onClickShowMoreProject={onClickShowMoreProject}
            selectedRowKeys={selectedRowKeys}
          />
        </Form>
      </Modal>
    </>
  );
};
