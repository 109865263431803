import { useFetch } from "../hooks/useFetch";
import { useCreate } from "../hooks/useCreate";
import { useUpdate } from "../hooks/useUpdate";
import {
  FETCH_ISLAND_GROUP_URL,
  CREATE_ISLAND_GROUP_URL,
  UPDATE_ISLAND_GROUP_URL,
  DELETE_ISLAND_GROUP_URL,
} from "../api/routes/island_groups";
import { useDelete } from "../hooks/useDelete";

export const FetchIslandGroups = async (query) => {
  try {
    return await useFetch(FETCH_ISLAND_GROUP_URL, query);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const CreateIslandGroup = async (payload) => {
  try {
    return await useCreate(CREATE_ISLAND_GROUP_URL, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const UpdateIslandGroup = async (island_group_id, payload) => {
  try {
    return await useUpdate(
      `${UPDATE_ISLAND_GROUP_URL}/${island_group_id}`,
      payload
    );
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const DeleteIslandGroup = async (island_group_id) => {
  try {
    return await useDelete(`${DELETE_ISLAND_GROUP_URL}/${island_group_id}`);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
