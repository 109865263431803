import { useFetch } from "../hooks/useFetch";
import { useCreate } from "../hooks/useCreate";
import { useUpdate } from "../hooks/useUpdate";
import {
  FETCH_AGENT_CUSTOM_FIELD_URL,
  CREATE_AGENT_CUSTOM_FIELD_URL,
  UPDATE_AGENT_CUSTOM_FIELD_URL,
  QUERY_AGENT_CUSTOM_FIELD_URL,
  DELETE_AGENT_CUSTOM_FIELD_URL,
} from "../api/routes/agent_custom_field";

import { useDelete } from "../hooks/useDelete";

export const FetchAgentCustomFields = async (query) => {
  try {
    return await useFetch(FETCH_AGENT_CUSTOM_FIELD_URL, query);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const CreateAgentCustomField = async (payload) => {
  try {
    return await useCreate(CREATE_AGENT_CUSTOM_FIELD_URL, payload);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const UpdateAgentCustomField = async (
  agent_custom_field_id,
  payload
) => {
  try {
    return await useUpdate(
      `${UPDATE_AGENT_CUSTOM_FIELD_URL}/${agent_custom_field_id}`,
      payload
    );
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const FetchAgentCustomFieldByQuery = async (query) => {
  try {
    return await useFetch(QUERY_AGENT_CUSTOM_FIELD_URL, query);
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};

export const DeleteAgentCustomField = async (
  agent_custom_field_id,
  payload
) => {
  try {
    return await useDelete(
      `${DELETE_AGENT_CUSTOM_FIELD_URL}/${agent_custom_field_id}`,
      payload
    );
  } catch (err) {
    if (err.request) return err.request;
    if (err.response) return err.response;
    return err.message;
  }
};
