const useGetDataForCreation = (data, statusFieldRemarksName = null) => {
  if (!data || !statusFieldRemarksName) {
    return null;
  }

  const uniqueDataList = [];
  const uniqueDataListForm = [];

  /* Filtering the data and returning the data that has the status of "For Creation" */
  const forCreationDataList = data.filter(
    (item) => item[statusFieldRemarksName] === "For Creation"
  );

  for (const item of forCreationDataList) {
    if (uniqueDataList.length === 0) {
      const postRawData = constructArrayItemForm(statusFieldRemarksName, item);
      if (postRawData) {
        uniqueDataList.push(postRawData._item);
        uniqueDataListForm.push(postRawData.formItem);
      }
      continue;
    }

    const isFoundItem = uniqueDataList.find((currentItem) => {
      return constructValidationRulesPerRemarksField(
        statusFieldRemarksName,
        currentItem,
        item
      );
    });

    if (!isFoundItem) {
      const postRawData = constructArrayItemForm(statusFieldRemarksName, item);
      if (postRawData) {
        uniqueDataList.push(postRawData._item);
        uniqueDataListForm.push(postRawData.formItem);
      }
    }
  }

  return {
    uniqueDataList,
    uniqueDataListForm,
  };
};

/* Custom Function supporting Custom Hooks */

const constructValidationRulesPerRemarksField = (
  remarksFieldName,
  currentItem,
  creationListItem
) => {
  if (remarksFieldName === "agent_status_remarks") {
    return currentItem.agent_email === creationListItem.agent_email;
  }

  if (remarksFieldName === "partner_status_remarks") {
    return (
      currentItem.partner_institution === creationListItem.partner_institution
    );
  }

  if (remarksFieldName === "province_status_remarks") {
    return currentItem.province === creationListItem.province;
  }

  if (remarksFieldName === "city_status_remarks") {
    return currentItem.city === creationListItem.city;
  }

  if (remarksFieldName === "barangay_status_remarks") {
    return (
      currentItem.barangay === creationListItem.barangay &&
      currentItem.city_id === creationListItem.city_id
    );
  }

  return null;
};

const constructArrayItemForm = (remarksFieldName, item) => {
  if (remarksFieldName === "agent_status_remarks") {
    return {
      _item: item,
      formItem: item,
    };
  }

  if (remarksFieldName === "partner_status_remarks") {
    const { partner_institution } = item;
    const formItem = { partner_name: partner_institution };
    return {
      _item: item,
      formItem: formItem,
    };
  }

  if (remarksFieldName === "province_status_remarks") {
    const { province } = item;
    const formItem = { province_name: province, island_group_id: null };
    return {
      _item: item,
      formItem: formItem,
    };
  }

  if (remarksFieldName === "city_status_remarks") {
    const { city } = item;
    const formItem = { city_name: city, province_id: null };
    return {
      _item: item,
      formItem,
    };
  }

  if (remarksFieldName === "barangay_status_remarks") {
    const { barangay, city_id } = item;
    const formItem = { barangay_name: barangay, city_id: city_id };
    return {
      _item: item,
      formItem,
    };
  }

  return null;
};

export default useGetDataForCreation;
