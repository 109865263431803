import React from "react";
import { Modal } from "antd";

export const PropertyProjectModalContentComponent = (props) => {
  const { visible, onOk, content = null } = props;

  return (
    <>
      <Modal
        title="View Developer"
        visible={visible}
        onOk={onOk}
        onCancel={onOk}
        width="80%"
        centered={false}
        closable={true}
      >
        {content}
      </Modal>
    </>
  );
};
