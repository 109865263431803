import React from 'react';
import { Button, Table, Tag } from 'antd';

import useColumnFilter from '../../../hooks/useColumnFilter';

import useGetAssetContentsColumns from '../../../hooks/assets/useGetAssetContentsColumns';
import useGetAssetMetaFieldColumns from '../../../hooks/asset_meta_field/useGetAssetMetaFieldColumns';

const { Column } = Table;

export const AssetTableComponent = (props) => {
  const {
    data,
    onChange,
    tableLoading,
    selectedRowKeys,
    onPaginationChange,
    currentPagination = 1,
    onSortChange,
    columnFilter,
    setColumnFilter,
    handleClickViewPostHTML,
    setCurrentTab,
    setSelectedAssets,
    setSelectedRowKeys,
  } = props;

  const { getColumnFilterProps } = useColumnFilter(
    columnFilter,
    setColumnFilter
  );

  // for sub table of asset listing
  const assetContentsColumns = useGetAssetContentsColumns(
    handleClickViewPostHTML
  );

  // for sub table of asset meta fields listing
  const assetMetaFieldsColumns = useGetAssetMetaFieldColumns();

  return (
    <>
      <Table
        loading={tableLoading}
        size={'small'}
        dataSource={data.data}
        bordered={true}
        rowSelection={{
          selectedRowKeys,
          type: 'radio',
          onChange: onChange,
        }}
        onChange={(pagination, filters, sorter, action) => {
          if (action.action === 'sort') {
            onSortChange(sorter.field, sorter.order);
          }
        }}
        pagination={{
          total: data ? data.total : 0,
          onChange: onPaginationChange,
          current: currentPagination,
        }}
        scroll={{ x: 1700 }}
        expandable={{
          expandedRowRender: (record) => {
            return (
              <div style={{ padding: '3%' }}>
                <Table
                  size='small'
                  bordered={true}
                  dataSource={record.asset_listing}
                  columns={assetContentsColumns || []}
                />
                <br />
                <Table
                  size='small'
                  bordered={true}
                  dataSource={record.asset_meta_field}
                  columns={assetMetaFieldsColumns || []}
                />
              </div>
            );
          },
        }}
      >
        <Column
          title='Asset UUID'
          dataIndex='asset_uuid'
          key='asset_uuid'
          fixed='left'
          width={200}
          sorter={true}
          {...getColumnFilterProps('asset_uuid')}
        />
        <Column
          title='Property Name'
          fixed='left'
          width={400}
          dataIndex={'property_title'}
        />
        <Column
          title='Street'
          dataIndex='street'
          key='street'
          sorter={true}
          width={100}
          {...getColumnFilterProps('street')}
        />
        <Column
          title='Subdivision'
          dataIndex='subdivision'
          key='subdivision'
          sorter={true}
          width={100}
          {...getColumnFilterProps('subdivision')}
        />
        <Column
          title='Barangay'
          dataIndex={['barangay', 'barangay_name']}
          key={['barangay', 'barangay_name']}
          width={100}
          sorter={true}
        />
        <Column
          title='City'
          dataIndex={['cities', 'city_name']}
          key={['cities', 'city_name']}
          width={100}
          sorter={true}
          {...getColumnFilterProps(['cities', 'city_name'])}
        />
        <Column
          title='Province'
          dataIndex={['province', 'province_name']}
          key={['province', 'province_name']}
          width={100}
          sorter={true}
          {...getColumnFilterProps('province_name')}
        />
        <Column
          title='Partner Name'
          dataIndex={['partners', 'partner_name']}
          key={['partners', 'partner_name']}
          width={100}
          sorter={true}
          {...getColumnFilterProps(['partners', 'partner_name'])}
        />
        <Column
          title='Agent'
          sorter={true}
          width={100}
          render={({ users }) => {
            var fullName = '';

            if (users) {
              if (users.users_first_name) {
                fullName += `${users.users_first_name}`;
              }

              if (users.users_middle_name) {
                fullName += ` ${users.users_middle_name}`;
              }

              if (users.users_last_name) {
                fullName += ` ${users.users_last_name}`;
              }
            }

            return fullName.trim();
          }}
          {...getColumnFilterProps(['users', 'users_uuid'])}
        />
        <Column
          title='Developer Name'
          dataIndex={['developers', 'partner_name']}
          key={['developers', 'partner_name']}
          width={100}
          sorter={true}
          {...getColumnFilterProps(['developers', 'partner_name'])}
        />
        <Column
          title='Project Name'
          dataIndex={['property_projects', 'property_projects_name']}
          key={['property_projects', 'property_projects_name']}
          width={100}
          sorter={true}
          {...getColumnFilterProps([
            'property_projects',
            'property_projects_name',
          ])}
        />

        <Column
          title='Property Status'
          dataIndex='asset_status'
          key='asset_status'
          sorter={true}
          width={100}
          render={(status) =>
            status === 'active' ? (
              <Tag color='green'>{status}</Tag>
            ) : (
              <Tag color='red'>{status}</Tag>
            )
          }
          {...getColumnFilterProps('asset_status')}
        />
        <Column
          title='Date Created'
          dataIndex='created_at'
          key='created_at'
          width={100}
          sorter={true}
          {...getColumnFilterProps('created_at')}
        />

        <Column
          title='Actions'
          fixed='right'
          width={100}
          render={(text, record, index) => (
            <Button
              size='small'
              onClick={() => {
                setCurrentTab('2');
                setSelectedAssets(record);
                setSelectedRowKeys([index + 1]);
              }}
            >
              Edit Media
            </Button>
          )}
        />
      </Table>
    </>
  );
};
